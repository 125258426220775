<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Edit {{defaults.URBNAME}} ({{defaults.URBFLNM}})</h2>
			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />
			<div class="table-responsive mb-3">
				<b-table responsive class="table-valign-m mb-0" :items="urbans" :fields="fieldsHeading">
					<template v-slot:cell(value)="data">
						<b-form-input v-model.trim="$v.item[data.item.variable].$model" type="number" step="any" :state="getValidState($v.item[data.item.variable])"></b-form-input>
					</template>
				</b-table>
			</div>

			<fixed-action-bar>
				<save-button :saving="page.saving" @click.native="save" class="mr-2" :disabled="!$v.$anyDirty" />
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</page-authorization-container>

		<router-view></router-view>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'EditUrban',
		data() {
			return {
				scenarioID: this.$route.params.scenarioID,
				urbanID: this.$route.params.urbanID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false
				},
				definitions: {},
				defaults: {},
				item: {},
				ignoreFields: ['Dataset', 'DatasetID'],
				urbans: [],
				urbanFields: ['FIMP', 'FCIMP', 'CURBDEN', 'URBCOEF', 'DIRTMX','THALF', 'TNCONC', 'TPCONC', 'TNO3CONC', 'URBCN2'],

				fieldsHeading: [
					{ key: 'value', class: 'min-w-100 table-input-col' },
					{ key: 'units', class: 'min' },
					{ key: 'description', class: 'wrap' },
					'variable', 'default', 'valid_range'
				],
			}
		},
		validations() {
			var item = {};
			for (var i = 0; i < this.urbanFields.length; i++) {
				item[this.urbanFields[i]] = { required };
			}
			return { item: item };
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/general/urban/${this.scenarioID}/${this.urbanID}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.item;
					this.defaults = response.data.default;
					this.definitions = response.data.definitions;
					this.log(this.$v);

					this.urbans = [];
					for (var i = 0; i < this.urbanFields.length; i++) {
						var n = this.urbanFields[i];
						this.urbans.push({
							variable: n,
							description: this.definitions[n].description,
							value: this.item[n],
							units: this.definitions[n].units,
							valid_range: this.definitions[n].maxValue === this.definitions[n].minValue ? '' : `${this.definitions[n].minValue} to ${this.definitions[n].maxValue}`,
							default: this.defaults[n]
						});
					}

				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {

					try {
						var data = {};
						for (var i = 0; i < this.urbanFields.length; i++) {
							if (this.$v.item[this.urbanFields[i]].$dirty || this.item[this.urbanFields[i]] != this.defaults[this.urbanFields[i]]) {
								data[this.urbanFields[i]] = Number(this.item[this.urbanFields[i]]);
							}
						}
						const response = await this.$http.post(`scenarios/general/urban/${this.scenarioID}/${this.urbanID}`, data, this.getTokenHeader());
						this.log(response.data);

						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}
				this.page.saving = false;
			}
		}
	}
</script>

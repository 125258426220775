<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">Send an email to all users</h2>

			<success-alert ref="savedAlert" text="Your email has been sent to the queue. Please allow users several minutes to get the email." />
			<error-list :errors="page.errors"></error-list>

			<b-form>
				<b-form-group label="Subject" :invalid-feedback="requiredFeedback($v.form.subject)">
					<b-form-input v-model="form.subject" type="text" autofocus :state="getValidState($v.form.subject)"></b-form-input>
				</b-form-group>

				<b-form-group label="Email message" :invalid-feedback="requiredFeedback($v.form.body)">
					<b-form-textarea v-model.trim="$v.form.body.$model" rows="8" :state="getValidState($v.form.body)"></b-form-textarea>
				</b-form-group>

				<b-form-group class="my-4">
					<b-form-checkbox v-model.trim="$v.form.bccSender.$model">
						BCC sender?
					</b-form-checkbox>
				</b-form-group>
			</b-form>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" class="mr-2" @click.native="save" />
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'AdminEmailUsers',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				form: {
					subject: '',
					body: '',
					bccSender: false
				}
			}
		},
		validations: {
			form: {
				subject: { required },
				body: { required },
				bccSender: {}
			}
		},
		methods: {
			async save() {
				this.page.errors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('admin/users/emailusers', this.form, this.getTokenHeader());
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
						this.form.subject = '';
						this.form.body = '';
						this.form.bccSender = false;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>

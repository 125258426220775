<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Climate sensitivity/variability analysis</h2>

			<p>
				Adjust monthly rainfall and temperature for each subbasin in your project.
				Read the <a href="/content/docs/io/ch05_input_sub.pdf" target="_blank">SWAT2012 IO documentation chapter on subbasin inputs</a>,
				see page 6 for climate sensitivity variables.
			</p>

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<div>
					<label for="RFINC">Increase or decrease rainfall by up to 100%:</label>
				</div>
				<b-row class="align-items-center mb-2">
					<b-col lg="5" md="9" sm="10" class="d-flex align-items-center">
						<b-form-input v-model.trim="$v.RFINC.$model" type="range" :min="options.rmin" :max="options.rmax"></b-form-input>
					</b-col>
					<b-col lg="2" md="3" sm="2">
						<b-input-group append="%">
							<b-form-input id="RFINC" v-model.trim="$v.RFINC.$model" type="number" step="any" :min="options.rmin" :max="options.rmax" :state="getValidState($v.RFINC)"></b-form-input>
						</b-input-group>
						<b-form-invalid-feedback v-if="!$v.RFINC.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.RFINC.between">Must be between {{$v.RFINC.$params.between.rmin}} and {{$v.RFINC.$params.between.rmax}}</b-form-invalid-feedback>
					</b-col>
				</b-row>

				<div>
					<label for="TMPINC">Increase or decrease temperature by up to 20°C:</label>
				</div>

				<b-row class="align-items-center mb-4">
					<b-col lg="5" md="9" sm="10" class="d-flex align-items-center">
						<b-form-input v-model.trim="$v.TMPINC.$model" type="range" :min="options.tmin" :max="options.tmax"></b-form-input>
					</b-col>
					<b-col lg="2" md="3" sm="2">
						<b-input-group>
							<template v-slot:append>
								<b-input-group-text>&deg;C</b-input-group-text>
							</template>
							<b-form-input id="TMPINC" v-model.trim="$v.TMPINC.$model" type="number" step="any" :min="options.tmin" :max="options.tmax" :state="getValidState($v.TMPINC)"></b-form-input>
						</b-input-group>
						<b-form-invalid-feedback v-if="!$v.TMPINC.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.TMPINC.between">Must be between {{$v.TMPINC.$params.between.tmin}} and {{$v.TMPINC.$params.between.tmax}}</b-form-invalid-feedback>
					</b-col>
				</b-row>

				<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange" subbasin-and-months></hru-selector>

				<scenario-mod-viewer v-if="mods.length > 0" @deleted="modDeleted" :data="mods" value-label="Values" value-is-object></scenario-mod-viewer>

				<fixed-action-bar>
					<span id="save-button-tooltip">
						<save-button :saving="page.saving" :disabled="!validHruSelection || !$v.$anyDirty" class="mr-2" /> 
					</span>
					<b-tooltip :disabled="validHruSelection" target="save-button-tooltip">
						Select at least one subbasin.
					</b-tooltip>
					<back-button class="btn btn-secondary mr-2" />

					<save-button v-if="mods.length > 0" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete all climate sensitivityr data changes?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, between } from 'vuelidate/lib/validators';
	import HruSelector from '@/components/HruSelector';
	import ScenarioModViewer from '@/components/ScenarioModViewer';

	export default {
		name: 'EditClimateSensitivity',
		components: {
			HruSelector, ScenarioModViewer
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				options: {
					rmin: -100,
					rmax: 100,
					tmin: -20,
					tmax: 20,
				},
				hruSelection: {
					applyToAll: true,
					subbasins: [],
					months: []
				},
				RFINC: 0,
				TMPINC: 0,
				mods: []
			}
		},
		validations: {
			RFINC: { required, between: between(-100, 100) },
			TMPINC: { required, between: between(-20, 20) }
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/getmods/${this.scenarioID}/ClimateSensitivity`, this.getTokenHeader());
					this.mods = response.data.mods;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							applyToAll: this.hruSelection.applyToAll,
							subbasins: this.hruSelection.subbasins,
							modificationObject: {}
						};

						if (this.RFINC !== 0) data.modificationObject.RFINC = Number(this.RFINC);
						if (this.TMPINC !== 0) data.modificationObject.TMPINC = Number(this.TMPINC);
						data.modificationObject.Months = this.hruSelection.months;

						this.log(data);
						const response = await this.$http.post(`scenarios/savemods/${this.scenarioID}/ClimateSensitivity`, data, this.getTokenHeader());
						this.log(response.data);
						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/ClimateSensitivity`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			hruSelectionChange(selection) {
				this.hruSelection = selection;
			}
		},
		computed: {
			validHruSelection() {
				return this.hruSelection.applyToAll ||
					(this.hruSelection.subbasins.length > 0 &&
						this.hruSelection.months.length > 0);
			}
		}
	}
</script>

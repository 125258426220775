<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-3">Reports</h2>

			<p>
				<span v-if="data.reports.length > 0">
					Select an existing report from the table, or create a new report / update it using the form below.
				</span>
				<span v-else>
					Generate a CSV file of data using the drop-downs below.
				</span>
				Larger reports, such as HRUs, may take several minutes to process.
			</p>
			<p>
				Note: <router-link to="/admin/projects">Projects</router-link>,
				<router-link to="/admin/projects/scenarios">scenarios</router-link>, and
				<router-link to="/admin/users">users</router-link> tables may be downloaded from their respective sections in the administrative area.
			</p>

			<error-list :errors="page.saveErrors"></error-list>

			<b-form inline @submit.prevent="save">
				<b-form-select v-model="form.name" :options="data.reportTypes" :state="getValidState($v.form.name)" class="mr-2">
					<template slot="first">
						<option :value="null" disabled>Select a report type...</option>
					</template>
				</b-form-select>

				<b-form-select v-model="form.datasetID" :options="data.datasets" :state="getValidState($v.form.datasetID)" class="mr-2" v-if="form.name !== 'User Statistics'">
					<template slot="first">
						<option :value="null" disabled>Select a dataset...</option>
					</template>
				</b-form-select>

				<save-button :saving="page.saving" text="Generate Report" class="mr-2" />
			</b-form>

			<div class="my-4" v-if="data.reports.length > 0">
				<hr class="my-4" />
				<div class="mb-2 form-inline">
					<b-form-input v-model="table.filter" placeholder="Search..."></b-form-input>
				</div>
				<b-table responsive striped small class="nowrap-headers border-bottom" :items="data.reports" :fields="table.fields" :filter="table.filter">
					<template v-slot:cell(createdDate)="data">
						{{data.item.createdDate | date}}
					</template>
					<template v-slot:cell(name)="data">
						<a v-if="data.item.status == 'complete'" :href="data.item.fileName">{{getDisplayName(data.item)}}</a>
						<span v-else>{{getDisplayName(data.item)}}</span>
					</template>
					<template v-slot:cell(status)="data">
						<font-awesome-icon v-if="data.item.status == 'running' || runningReportIDs.includes(data.item.id)" :icon="['fas', 'spinner']" spin />
						<span v-else></span>
					</template>
					<template v-slot:cell(delete)="data">
						<button class="plain text-danger" v-b-tooltip.hover.right="'Delete'" @click="askDelete(data.item.id, data.item.name)">
							<font-awesome-icon :icon="['fas', 'times']" />
						</button>
					</template>
				</b-table>
			</div>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete <strong>{{page.delete.name}}</strong>?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>


		</page-authorization-container>
	</div>
</template>

<script>
	import { required, requiredIf } from 'vuelidate/lib/validators';
	export default {
		name: 'AdminReports',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					delete: {
						show: false,
						id: undefined,
						name: '',
						errors: [],
						saving: false
					}
				},
				table: {
					fields: [
						{ key: 'status', label: '', class: 'min' },
						{ key: 'name', sortable: true },
						{ key: 'createdDate', sortable: true },
						{ key: 'delete', label: '', sortable: false, class: 'min' }
					],
					filter: null
				},
				data: {
					reports: [],
					datasets: [],
					reportTypes: []
				},
				form: {
					datasetID: null,
					name: null
				},
				runningReportIDs: []
			}
		},
		validations: {
			form: {
				name: { required },
				datasetID: { required: requiredIf(function () { return this.form.name !== 'User Statistics'; }) }
			}
		},
		async created() {
			await this.get();
			this.$statusHub.adminReportOpened();
			this.$statusHub.$on('admin-report-status-change', this.onStatusChanged);
		},
		beforeDestroy() {
			this.$statusHub.adminReportClosed();
			this.$statusHub.$off('admin-report-status-change', this.onStatusChanged);
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/reports', this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('admin/reports', this.form, this.getTokenHeader());
						this.log(response.data);
						this.$v.$reset();
						await this.get();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			onStatusChanged(data) {
				this.runningReportIDs = data.reportIDs;
				this.get();
			},
			getDisplayName(item) {
				if (this.isNullOrEmpty(item.dataset))
					return item.name;

				return `${item.name} - ${item.dataset}`;
			},
			askDelete(id, name) {
				this.page.delete.id = id;
				this.page.delete.name = name;
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`admin/reports/${this.page.delete.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>

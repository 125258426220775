<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">
			<b-alert :show="siteSettings.enableAlertNotification" variant="warning" class="mb-0">
				{{ siteSettings.alertNotification }}
			</b-alert>
			<div id="main-panel">
				<div v-if="$route.path == '/projects'" class="container py-5">
					<page-loading :loading="page.loading"></page-loading>
					<div v-if="!page.loading">
						<login-form v-if="page.showLogin" show-header no-redirect @success="loginSuccess"></login-form>
						<div v-else-if="!isAuthorized(roleNames.registered)">
							<b-alert variant="warning" show>
								Your account is pending approval. You will receive a notification by email when it is approved.
							</b-alert>
						</div>
						<div v-else>
							<b-alert variant="info" :show="isImporting">
								We're importing your projects from version 1. You will receive a notification and email when the process is complete. <a href="#" @click.prevent="refresh">Refresh projects list now.</a>
							</b-alert>

							<b-tabs content-class="mt-4" class="page-nav-tabs">
								<template v-slot:tabs-end>
									<b-nav-item to="create" append><font-awesome-icon :icon="['fas', 'plus-square']" fixed-width class="text-info" /> New Project</b-nav-item>
								</template>
								<b-tab v-if="recent.length > 0" title="Recent Activity">
									<b-card-group deck>
										<b-card no-body class="border" v-for="(r, i) in recent" :key="i">
											<project-map :project-map="r.map" height="250px" :ref-name="`leafmap${i}`" :is-regional="r.isRegional"></project-map>
											<b-card-body>
												<b-card-title class="text-center">{{r.name}}</b-card-title>
												<b-card-text>
													<div class="text-center">
														{{r.numSubbasins | number(0)}} subbasins /
														{{r.numHrus | number(0)}} HRUs /
														{{r.totalArea | number(2)}} km<sup>2</sup>
													</div>
												</b-card-text>

												<b-list-group flush class="border-bottom">
													<b-list-group-item v-if="r.scenarios.length > 0" class="bg-light py-0 text-center">
														<small class="text-uppercase text-muted">Recent scenarios</small>
													</b-list-group-item>
													<b-list-group-item v-for="(s, j) in r.scenarios" :key="j" class="d-flex justify-content-between align-items-center py-2">
														<router-link :to="`${r.id}/scenarios/${s.id}`" append>{{s.name}}</router-link>
														<div v-if="s.hasRun">
															<small class="text-muted">Last ran {{s.lastRunDate | dateFromNow}}</small>
														</div>
														<font-awesome-icon v-else-if="s.isRunning" :icon="['fas', 'spinner']" spin class="icon ml-2" title="Running" />
														<div v-else>
															<small class="text-muted">Modified {{s.lastModifiedDate | dateFromNow}}</small>
														</div>
													</b-list-group-item>
												</b-list-group>
											</b-card-body>

											<router-link :to="`projects/${r.id}`" class="btn btn-primary btn-card-flush py-3">
												<small class="text-uppercase">Go to project</small>
											</router-link>
										</b-card>
									</b-card-group>
								</b-tab>
								<b-tab title="Projects">
									<grid-view api-url="projects" delete-api-url="projects" show-refresh ref="projectsGrid" use-filter
											   :fields="projects.table.fields" link-name
											   :default-sort="projects.table.sort" :default-reverse="projects.table.reverse" :default-per-page="projects.table.itemsPerPage"
											   collection-description="projects"
											   item-name="project"
											   create-route="create" show-create show-help
											   extra-delete-message="All files and scenarios related to this project will be removed."></grid-view>

									<div v-if="hasAccessToUploads">
										<hr class="my-4" />
										<h4 class="mb-3">Looking to upload projects edited offline?</h4>
										<p>
											If you checked the box to write SWAT Editor tables on any of your scenario runs
											and modified the project offline, you may upload those changes back into the
											system as a new project dataset.
											After uploading your project, you may create new projects in the system based off
											it, as well as share the project dataset with your groups or request it be made public
											to all users.
										</p>
										<p>
											<router-link to="/datasets" class="btn btn-success">Go to project uploads</router-link>
										</p>
									</div>
								</b-tab>
								<b-tab title="Group Projects">
									<div v-if="groupInvites > 0" class="mb-4">
										<router-link to="/groups/invites" class="btn btn-primary">
											<b-badge variant="light">{{groupInvites}}</b-badge>
											Pending group invitations
										</router-link>
									</div>

									<grid-view api-url="groups/projects"
											   :fields="groups.table.fields"
											   :default-sort="groups.table.sort" :default-reverse="groups.table.reverse" :default-per-page="groups.table.itemsPerPage"
											   collection-description="groups"
											   item-name="group"
											   has-items-intro="Projects shared by other members in your groups are listed below. You may view, edit, and run* these projects. (*Note: tier limits still apply.)"
											   :custom-empty-message="hasGroups ? 'No members of your groups have shared any projects yet.' : 'You are not a member of any groups.'"></grid-view>

									<div>
										<router-link to="/groups" class="btn btn-success">Go to your groups / create a new group</router-link>
									</div>
								</b-tab>
							</b-tabs>
						</div>
					</div>
				</div>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import DashboardHeader from '@/components/DashboardHeader';
	import GridView from '@/components/helpers/GridView';
	import ProjectMap from '@/components/ProjectMap';
	import moment from 'moment';

	export default {
		name: 'ProjectList',
		components: {
			DashboardHeader, GridView, ProjectMap
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				projects: {
					table: {
						fields: [
							{ key: 'name', sortable: true, class: 'min-w-200' },
							{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
							{ key: 'dataset', sortable: false },
							{ key: 'endingSubbasin', label: 'Downstream ID', sortable: true },
							{ key: 'numSubbasins', label: 'Subbasins', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
							{ key: 'numHrus', label: 'HRUs', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
							{ key: 'totalArea', label: 'Area (sq. km.)', sortable: false, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
							{ key: 'numScenarios', label: 'Scenarios', sortable: false },
							{ key: 'delete', label: '', sortable: false, class: 'min' }
						],
						sort: 'lastModifiedDate',
						reverse: true,
						itemsPerPage: 10
					}
				},
				groups: {
					table: {
						fields: [
							{ key: 'group', sortable: true },
							{ key: 'project', sortable: true },
							{ key: 'userName', label: 'Belongs to', sortable: true },
							{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
							{ key: 'dataset', sortable: false },
							{ key: 'endingSubbasin', label: 'Downstream ID', sortable: true },
							{ key: 'numSubbasins', label: 'Subbasins', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
							{ key: 'numHrus', label: 'HRUs', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
							{ key: 'totalArea', label: 'Area (sq. km.)', sortable: false, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
							{ key: 'numScenarios', label: 'Scenarios', sortable: false }
						],
						sort: 'lastModifiedDate',
						reverse: true,
						itemsPerPage: 10
					}
				},
				recent: [],
				hasGroups: false,
				hasAccessToUploads: false,
				groupInvites: 0,
				isImporting: false
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			loginSuccess(wasSuccessful) {
				this.page.showLogin = !wasSuccessful;
			},
			async get() {
				this.page.showLogin = !this.isAuthenticated;

				if (this.$route.path === '/projects') {
					this.page.errors = [];
					this.page.loading = true;

					try {
						const response = await this.$http.get(`projects/recent-activity`, this.getTokenHeader());
						this.log(response.data);
						this.recent = response.data.projects;
						this.hasGroups = response.data.hasGroups;
						this.hasAccessToUploads = response.data.hasAccessToUploads;
						this.groupInvites = response.data.groupInvites;
						this.isImporting = response.data.isImporting;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				}
			},
			fromNow(d) {
				return moment(d).fromNow();
			},
			async refresh() {
				await this.get();
				await this.$refs.projectsGrid.get();
			}
		}
	}
</script>

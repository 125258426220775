<template>
	<div>
		<success-alert ref="savedAlert" text="Changes saved." />
		<error-list :errors="page.errors"></error-list>

		<b-form>
			<b-form-group label="Name" :invalid-feedback="requiredFeedback($v.form.name)">
				<b-form-input v-model="form.name" type="text" autofocus :state="getValidState($v.form.name)"></b-form-input>
			</b-form-group>

			<b-row>
				<b-col md>
					<b-form-group label="Release executable file name" :invalid-feedback="requiredFeedback($v.form.releaseFileName)">
						<b-form-input v-model="form.releaseFileName" type="text" :state="getValidState($v.form.releaseFileName)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="Debug executable file name" :invalid-feedback="requiredFeedback($v.form.debugFileName)">
						<b-form-input v-model="form.debugFileName" type="text" :state="getValidState($v.form.debugFileName)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group>
				<b-checkbox v-model="form.isDefault">Set as default version?</b-checkbox>
			</b-form-group>

			<b-form-group class="mb-4">
				<b-checkbox v-model="form.isDisabled">Disabled? Check to not include in drop-down form.</b-checkbox>
			</b-form-group>
		</b-form>

		<fixed-action-bar :cols-lg="10" :offset-lg="2">
			<save-button :saving="page.saving" class="mr-2" @click.native="save" />
			<back-button class="btn btn-secondary mr-2" />
			<b-button v-if="isUpdate && isAuthorized(roleNames.admin)" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
		</fixed-action-bar>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this SWAT version?
				This action is permanent and cannot be undone. Files will not be removed from the server.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'AdminSwatVersionForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			data: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				form: this.data
			}
		},
		validations: {
			form: {
				name: { required },
				releaseFileName: { required },
				debugFileName: { required },
				isDefault: {},
				isDisabled: {}
			}
		},
		methods: {
			async save() {
				this.page.errors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						if (!this.isUpdate) {
							await this.$http.post('admin/swatversions', this.form, this.getTokenHeader());
							this.$router.push({ name: 'AdminSwatVersionList' }).catch(err => { this.log(err); });
						} else {
							await this.$http.put(`admin/swatversions/${this.form.id}`, this.form, this.getTokenHeader());
							this.$refs.savedAlert.startAlert();
							this.$v.$reset();
						}
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`admin/swatversions/${this.form.id}`, this.getTokenHeader());
					this.$router.push({ name: 'AdminSwatVersionList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>

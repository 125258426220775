<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-3">Special Administrative Jobs</h2>
			<b-alert show variant="danger">
				Do not run without specific permission from the developer.
			</b-alert>

			<error-list :errors="page.saveErrors"></error-list>

			<p>
				<save-button :saving="page.saving" text="Set Calibrations 11/2020" type="button" @click.native="submit" />
			</p>
		</page-authorization-container>
	</div>
</template>

<script>
	export default {
		name: 'AdminJobs',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					saving: false,
					saveErrors: [],
					showLogin: false
				}
			}
		},
		methods: {
			async submit() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					await this.$http.post('admin/SetCalibrations', {}, this.getTokenHeader());
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">
			<div id="main-panel">
				<page-loading :loading="page.loading"></page-loading>
				<div v-if="!page.loading">
					<div v-if="page.registered" class="max-w-400 mx-auto py-4">
						<b-alert show variant="success" class="mb-3">
							Account successfully registered. If you selected projects to import, this will
							finish in the background over the next several hours.
						</b-alert>
						<p>
							<b-button to="/projects">Log in</b-button>
						</p>
					</div>
					<div v-else-if="page.verified" class="container py-5">
						<h2 class="mb-4">Account registration from HAWQS version 1</h2>
						<p>
							Update your information below and select a new password.
						</p>

						<error-list :errors="page.errors"></error-list>

						<b-form @submit.prevent="register" class="my-4">
							<b-row>
								<b-col md>
									<b-form-group label="First name" :invalid-feedback="requiredFeedback($v.form.register.firstName)">
										<b-form-input v-model.trim="$v.form.register.firstName.$model" type="text" autofocus :state="getValidState($v.form.register.firstName)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Last name" :invalid-feedback="requiredFeedback($v.form.register.lastName)">
										<b-form-input v-model.trim="$v.form.register.lastName.$model" type="text" :state="getValidState($v.form.register.lastName)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-form-group label="Email address" description="Cannot be changed during registration. You may update your email after the registration process is complete.">
								<b-form-input v-model.trim="$v.form.register.email.$model" type="email" readonly></b-form-input>
							</b-form-group>

							<b-form-group label="Company or organization" :invalid-feedback="requiredFeedback($v.form.register.affiliation)">
								<b-form-input v-model.trim="$v.form.register.affiliation.$model" type="text" :state="getValidState($v.form.register.affiliation)"></b-form-input>
							</b-form-group>

							<b-form-group label="Category" :invalid-feedback="requiredFeedback($v.form.register.organizationCategory)">
								<b-form-select v-model.trim="$v.form.register.organizationCategory.$model" :options="location.organizationCategories" :state="getValidState($v.form.register.organizationCategory)">
									<template v-slot:first>
										<option :value="null" disabled>-- Select a category --</option>
									</template>
								</b-form-select>
							</b-form-group>

							<b-form-group label="What is your reason for trying HAWQS?" :invalid-feedback="requiredFeedback($v.form.register.reasonForUse)">
								<b-form-input v-model.trim="$v.form.register.reasonForUse.$model" type="text" :state="getValidState($v.form.register.reasonForUse)"></b-form-input>
							</b-form-group>

							<b-form-group label="In what country are you located?" :invalid-feedback="requiredFeedback($v.form.register.country)">
								<b-form-select v-model.trim="$v.form.register.country.$model" :options="location.countries" :state="getValidState($v.form.register.country)">
									<template v-slot:first>
										<option :value="null" disabled>-- Select a country --</option>
									</template>
								</b-form-select>
							</b-form-group>

							<b-form-group v-if="form.register.country === 'United States'" label="Select your state or territory" :invalid-feedback="requiredFeedback($v.form.register.state)">
								<b-form-select v-model.trim="$v.form.register.state.$model" :options="location.states" :state="getValidState($v.form.register.state)">
									<template v-slot:first>
										<option :value="null" disabled>-- Select a state or territory --</option>
									</template>
								</b-form-select>
							</b-form-group>

							<hr class="my-3" />

							<b-form-group label="Enter a user name" :invalid-feedback="requiredFeedback($v.form.register.userName)">
								<b-form-input v-model.trim="$v.form.register.userName.$model" type="text" :state="getValidState($v.form.register.userName)"></b-form-input>
							</b-form-group>
							<b-row>
								<b-col md>
									<b-form-group label="Password" description="Minimum 10 characters with a number and special character">
										<b-form-input v-model.trim="$v.form.register.password.$model" type="password" :state="getValidState($v.form.register.password)"></b-form-input>
										<b-form-invalid-feedback v-if="!$v.form.register.password.required">Required</b-form-invalid-feedback>
										<b-form-invalid-feedback v-if="!$v.form.register.password.strongPassword">Must be at least 10 characters with a number and special character</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
								<b-col md>
									<b-form-group label="Confirm your password">
										<b-form-input v-model.trim="$v.form.register.comparePassword.$model" type="password" :state="getValidState($v.form.register.comparePassword)"></b-form-input>
										<b-form-invalid-feedback v-if="!$v.form.register.comparePassword.required">Required</b-form-invalid-feedback>
										<b-form-invalid-feedback v-if="!$v.form.register.comparePassword.sameAsPassword">Passwords must match</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
							</b-row>

							<div v-if="projects.length > 0">
								<p>
									We can import your project and scenario settings from version 1 of HAWQS. This will import input settings only, and you will need to re-run scenarios as needed once imported.
									The following inputs cannot be imported: landuse update, point source, and print variables.
									You will need to visit the old version of HAWQS and upload this data to your projects after they are imported.
									Please select which projects you want to import from the list below.
								</p>

								<div class="checklist-header">
									<b-form-checkbox v-model.trim="$v.form.register.importAllProjects.$model" @change="toggleAllProjects">
										Import Projects
									</b-form-checkbox>
								</div>
								<div class="checklist">
									<b-form-checkbox-group v-model="form.register.projectsToImport" stacked>
										<div class="item" v-for="(p, i) in projects" :key="i">
											<b-form-checkbox :value="p.id">{{p.name}}</b-form-checkbox>
										</div>
									</b-form-checkbox-group>
								</div>
							</div>

							<div class="mt-4">
								<save-button :saving="page.saving" text="Register" size="lg" />
							</div>
						</b-form>
					</div>
					<div v-else class="max-w-400 mx-auto py-4">
						<error-list :errors="page.errors"></error-list>

						<p>
							Please submit your verification code and email address to continue.
						</p>

						<b-form @submit.prevent="verifyUser" class="my-4">
							<b-form-group label="Verification code" :invalid-feedback="requiredFeedback($v.form.verify.code)">
								<b-form-input v-model.trim="$v.form.verify.code.$model" type="text" :state="getValidState($v.form.verify.code)"></b-form-input>
							</b-form-group>

							<b-form-group label="Email address">
								<b-form-input v-model.trim="$v.form.verify.email.$model" type="email" :state="getValidState($v.form.verify.email)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.form.verify.email.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.form.verify.email.email">Please enter a valid email address</b-form-invalid-feedback>
							</b-form-group>

							<div class="mt-4">
								<save-button :saving="page.saving" text="Verify my account" size="lg" />
							</div>
						</b-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators';
	import DashboardHeader from '@/components/DashboardHeader';

	export default {
		name: 'Version1VerifyUser',
		components: {
			DashboardHeader
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					saving: false,
					verified: false,
					registered: false
				},
				form: {
					verify: {
						email: null,
						code: this.$route.params.code
					},
					register: {
						firstName: null,
						lastName: null,
						email: null,
						userName: null,
						password: null,
						comparePassword: null,
						country: 'United States',
						state: null,
						affiliation: null,
						organizationCategory: null,
						reasonForUse: null,
						importAllProjects: false,
						projectsToImport: []
					}
				},
				projects: [],
				location: {
					countries: [],
					states: [],
					organizationCategories: []
				}
			}
		},
		validations: {
			form: {
				register: {
					firstName: { required },
					lastName: { required },
					email: { required, email },
					userName: { required },
					password: {
						required,
						strongPassword(password) {
							return (
								/[a-z]/.test(password) && // checks for a-z
								/[0-9]/.test(password) && // checks for 0-9
								/\W|_/.test(password) && // checks for special char
								password.length >= 10
							);
						}
					},
					comparePassword: { required, sameAsPassword: sameAs('password') },
					country: { required },
					state: { required: requiredIf(function () { return this.form.register.country == 'United States'; }) },
					organizationCategory: { required },
					affiliation: { required },
					reasonForUse: { required },
					importAllProjects: {},
					projectsToImport: {}
				},
				verify: {
					email: { required, email },
					code: { required }
				}
			}
		},
		methods: {
			async verifyUser() {
				this.page.errors = [];
				this.page.saving = true;
				this.page.verified = false;
				this.page.registered = false;
				this.$v.form.verify.$touch();

				if (this.$v.form.verify.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('version1/verify', this.form.verify);
						this.form.register = {
							firstName: response.data.firstName,
							lastName: response.data.lastName,
							email: response.data.email,
							userName: response.data.userName,
							password: null,
							comparePassword: null,
							country: 'United States',
							state: null,
							organizationCategory: null,
							affiliation: response.data.affiliation,
							reasonForUse: response.data.reasonForUse,
							importAllProjects: false,
							projectsToImport: []
						};
						this.projects = response.data.projects;
						this.location = response.data.location;
						this.page.verified = true;
					} catch (error) {
						this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async register() {
				this.page.errors = [];
				this.page.saving = true;
				this.page.registered = false;
				this.$v.form.register.$touch();

				if (this.$v.form.register.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							firstName: this.form.register.firstName,
							lastName: this.form.register.lastName,
							email: this.form.register.email,
							userName: this.form.register.userName,
							password: this.form.register.password,
							country: this.form.register.country,
							state: this.form.register.state,
							organizationCategory: this.form.register.organizationCategory,
							affiliation: this.form.register.affiliation,
							reasonForUse: this.form.register.reasonForUse,
							importAllProjects: this.form.register.importAllProjects,
							projectsToImport: this.form.register.projectsToImport
						};
						this.log(data);

						await this.$http.post('version1/register', data);						
						this.page.registered = true;
					} catch (error) {
						this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async toggleAllProjects(checked) {
				this.form.register.projectsToImport = checked ? this.projects.map(function (i) { return i.id }) : [];
			}
		}
	}
</script>

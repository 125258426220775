<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Curve number</h2>

			<p>
				The SCS curve number is a function of the soil's permeability, land use and antecedent soil water conditions.
				Read the <a href="/content/docs/io/ch20_input_mgt.pdf" target="_blank">SWAT2012 IO documentation chapter on MGT inputs</a>,
				pages 4-6, for more information about curve numbers (CN2).
			</p>

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<div>
					<label for="cn2">Increase or decrease curve number by up to 10%</label>
				</div>
				<b-row class="align-items-center mb-4">
					<b-col lg="5" md="9" sm="10" class="d-flex align-items-center">
						<b-form-input v-model="cn2" type="range" :min="options.min" :max="options.max"></b-form-input>
					</b-col>
					<b-col lg="2" md="3" sm="2">
						<b-input-group append="%">
							<b-form-input id="cn2" v-model="cn2" type="number" step="any" :min="options.min" :max="options.max" :state="getValidState($v.cn2)"></b-form-input>
						</b-input-group>
						<b-form-invalid-feedback v-if="!$v.cn2.required">Required</b-form-invalid-feedback>
						<b-form-invalid-feedback v-if="!$v.cn2.between">Must be between {{$v.cn2.$params.between.min}} and {{$v.cn2.$params.between.max}}</b-form-invalid-feedback>
					</b-col>
				</b-row>

				<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange"></hru-selector>

				<scenario-mod-viewer v-if="mods.length > 0" @deleted="modDeleted" :data="mods" value-label="CN2" append-to-value="%"></scenario-mod-viewer>

				<fixed-action-bar>
					<span id="save-button-tooltip">
						<save-button :saving="page.saving" :disabled="!validHruSelection" class="mr-2" />
					</span>
					<b-tooltip :disabled="validHruSelection" target="save-button-tooltip">
						Select at least one subbasin, land use, soil, and slope.
					</b-tooltip>
					<back-button class="btn btn-secondary mr-2" />

					<save-button v-if="mods.length > 0" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete all curve number data changes?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</b-form>
		</page-authorization-container>		
	</div>
</template>

<script>
	import { required, between } from 'vuelidate/lib/validators';
	import HruSelector from '@/components/HruSelector';
	import ScenarioModViewer from '@/components/ScenarioModViewer';

	export default {
		name: 'EditCurveNumber',
		components: {
			HruSelector, ScenarioModViewer
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				options: {
					min: -10,
					max: 10
				},
				hruSelection: {
					applyToAll: true,
					subbasins: [],
					landuse: [],
					soils: [],
					slopes: []
				},
				cn2: 0,
				mods: []
			}
		},
		validations: {
			cn2: { required, between: between(-10, 10) }
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/subbasin/cn2/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.mods = response.data.mods;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else if (this.cn2 === 0) {
					this.page.saveErrors.push('A value of zero will result in no change. Please enter a number greater or less than 10.');
				} else {
					try {
						var data = {
							applyToAll: this.hruSelection.applyToAll,
							subbasins: this.hruSelection.subbasins,
							landuses: this.hruSelection.landuse,
							soils: this.hruSelection.soils,
							slopes: this.hruSelection.slopes,
							modificationObject: {
								value: Number(this.cn2)
							}
						};
						const response = await this.$http.post(`scenarios/subbasin/save/${this.scenarioID}/Cn2Percent`, data, this.getTokenHeader());
						this.log(response.data);
						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/Cn2Percent`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			hruSelectionChange(selection) {
				this.hruSelection = selection;
			}
		},
		computed: {
			validHruSelection() {
				return this.hruSelection.applyToAll ||
					(this.hruSelection.subbasins.length > 0 &&
						this.hruSelection.landuse.length > 0 &&
						this.hruSelection.soils.length > 0 &&
						this.hruSelection.slopes.length > 0);
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">CE-QUAL-W2 File Generation</h2>

			<div v-if="$parent.scenario.status.isRunning">
				<page-running />
			</div>
			<div v-else-if="!siteSettings.includeCeQual">
				<b-alert variant="warning" show>This feature is not enabled. Please contact an administrator if you are expecting it to be available.</b-alert>
			</div>
			<div v-else-if="data.noOutputProcessing">
				<b-alert variant="warning" show>Output processing was skipped for this scenario run. Please re-run your scenario without the "skip output processing" box checked to use this feature.</b-alert>
			</div>
			<div v-else>
				<b-alert variant="info" :show="data.printSetting !== 'daily'">
					CE-QUAL-W2 output files require that your scenario is run with <strong>daily</strong> output selected.
					Your scenario was run with <strong>{{data.printSetting}}</strong> output. Please edit your scenario settings and re-run
					the scenario to use the CE-QUAL feature.
				</b-alert>
				<div v-if="data.printSetting === 'daily'">
					<success-alert ref="savedAlert" text="Your request has been submitted. Please wait while we create your files." />

					<p>
						CE-QUAL-W2 is a water quality and hydrodynamic model in 2D (longitudinal-vertical) for rivers, estuaries, lakes, reservoirs, and river basin systems.
						W2 models basic eutrophication processes such as temperature-nutrient-algae-dissolved oxygen-organic matter and sediment relationships.
					</p>

					<p>
						For watershed simulations run on a daily timestep with output processing selected, {{siteText.siteName}} generates the concentration inflow (cin) files used in the CE-QUAL-W2 model.
						All cin files are generated with the assumption of ISS1 – 3 represent sand (10%), silt (50%), and clay (40%), respectively.  ISS4 - 6 are defined as 0.
						In addition, monthly variations of Algae (T1 and T2 ALG1-3) are calculated using this
						<a @click.prevent="page.showMonthlyAlgae = true" href="#">monthly fraction table</a>.
						<a href="https://hawqs.tamu.edu/content/docs/ce-qual/CE-QUAL_input_calculations_readonly.xlsx">Download an Excel file</a> showing the calculations.
					</p>

					<p class="mb-1">
						For “Subbasin output” requests, please note:
					</p>

					<ol>
						<li>There is not an Ammonia (NH4) value available, so all values are set to 0.</li>
						<li>There is only NO3 available, so the Nitrite + Nitrate (NO23) variable is calculated using only NO3.</li>
					</ol>

					<p>
						For “Reach output” requests, all variables necessary for the (cin) files are available.
					</p>
					<p>
						When deciding which output to use for CE-QUAL-W2, “Reach output” is typically used for subbasins that have rivers flowing through or into the lakes,
						whereas “Subbasin output” is typically used for areas adjacent to the lake and contributing to the lake directly.
						Ultimately, it is the user’s discretion to determine which output is suitable for the modeled watershed.
					</p>
					<p>
						For more information on the CE-QUAL-W2 model, and additional model requirements, please visit:
						<a href="http://www.ce.pdx.edu/w2/" target="_blank">www.ce.pdx.edu/w2/</a>
					</p>

					<b-modal v-model="page.showMonthlyAlgae" size="lg" title="Monthly fraction table" scrollable hide-footer>
						<table class="table table-sm">
							<thead>
								<tr>
									<th>Month</th>
									<th>Algae 1</th>
									<th>Algae 2</th>
									<th>Algae 3</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="m in monthlyAlgae" :key="m.month">
									<td>{{m.month}}</td>
									<td>{{m.a1}}</td>
									<td>{{m.a2}}</td>
									<td>{{m.a3}}</td>
								</tr>
							</tbody>
						</table>
					</b-modal>

					<div class="my-4" v-if="data.requests.length > 0">
						<b-table responsive striped small class="nowrap-headers border-bottom mb-1" :items="data.requests" :fields="table.fields">
							<template v-slot:cell(status)="data">
								<font-awesome-icon v-if="data.item.status == 'running'" :icon="['fas', 'spinner']" spin />
								<font-awesome-icon v-else-if="data.item.status == 'error'" :icon="['fas', 'exclamation-triangle']" class="text-danger" />
								<span v-else></span>
							</template>
							<template v-slot:cell(lastModifiedDate)="data">
								{{data.item.lastModifiedDate | date}}
							</template>
							<template v-slot:cell(downloadLink)="data">
								<a v-if="data.item.status == 'complete'" :href="data.item.downloadLink">Download</a>
								<em v-else>{{data.item.status == 'error' ? `Error: ${data.item.statusMessage}` : data.item.statusMessage}}</em>
							</template>
							<template v-slot:cell(subbasins)="data">
								{{writeArray(data.item.subbasins)}}
							</template>
							<template v-slot:cell(delete)="data">
								<button class="plain text-danger" v-b-tooltip.hover.right="'Delete'" @click="askDelete(data.item.id)" v-if="data.item.status !== 'running'">
									<font-awesome-icon :icon="['fas', 'times']" />
								</button>
								<span v-else></span>
							</template>
						</b-table>
						<div v-if="anyRunning"><small><a href="#" @click.prevent="get" class="text-secondary"><font-awesome-icon :icon="['fas', 'redo']" class="mr-1" /> Refresh progress</a></small></div>
					</div>

					<h3>Request CE-QUAL-W2 Files</h3>

					<b-form @submit.prevent="save">
						<error-list :errors="page.saveErrors"></error-list>

						<b-form-group>
							<b-form-select v-model="form.outputType" :options="options.outputTypes"></b-form-select>
						</b-form-group>

						<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange" subbasin-only drop-down-label="Request for"></hru-selector>

						<fixed-action-bar :cols="10" :offset="2" :cols-lg="10" :offset-lg="2">
							<save-button :saving="page.saving" :disabled="!validHruSelection" text="Request Files" class="mr-2" />
							<back-button class="btn btn-secondary mr-2" />
						</fixed-action-bar>
					</b-form>

					<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
						<error-list :errors="page.delete.errors"></error-list>

						<p>
							Are you sure you want to delete this request?
							This action is permanent and cannot be undone.
						</p>

						<div slot="modal-footer">
							<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
							<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
						</div>
					</b-modal>
				</div>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import HruSelector from '@/components/HruSelector';

	export default {
		name: 'OutputCeQual',
		components: {
			HruSelector
		},
		data() {
			return {
				scenarioID: this.$route.params.scenarioID,
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						id: null,
						errors: [],
						saving: false
					},
					showMonthlyAlgae: false
				},
				data: {
					printSetting: null,
					noOutputProcessing: false,
					requests: []
				},
				form: {
					outputType: 'RCH',
					applyToAll: true,
					subbasins: []
				},
				options: {
					outputTypes: [
						{ value: 'RCH', text: 'Reach output' },
						{ value: 'SUB', text: 'Subbasin output' }
					]
				},
				table: {
					fields: [
						{ key: 'status', label: '', class: 'min' },
						{ key: 'downloadLink', label: 'Request', sortable: true },
						{ key: 'outputType', label: 'Output Type', sortable: true },
						{ key: 'subbasins', sortable: true },
						{ key: 'lastModifiedDate', label: 'Date', sortable: true },
						{ key: 'delete', label: '', sortable: false, class: 'min' }
					],
					filter: null
				},
				monthlyAlgae: [
					{ month: 'Jan', a1: '0.7', a2: '0.2', a3: '0.1' },
					{ month: 'Feb', a1: '0.7', a2: '0.2', a3: '0.1' },
					{ month: 'Mar', a1: '0.7', a2: '0.2', a3: '0.1' },
					{ month: 'Apr', a1: '0.5', a2: '0.4', a3: '0.1' },
					{ month: 'May', a1: '0.3', a2: '0.4', a3: '0.3' },
					{ month: 'Jun', a1: '0.1', a2: '0.4', a3: '0.5' },
					{ month: 'Jul', a1: '0.1', a2: '0.2', a3: '0.7' },
					{ month: 'Aug', a1: '0.1', a2: '0.2', a3: '0.7' },
					{ month: 'Sep', a1: '0.1', a2: '0.4', a3: '0.5' },
					{ month: 'Oct', a1: '0.3', a2: '0.4', a3: '0.3' },
					{ month: 'Nov', a1: '0.5', a2: '0.4', a3: '0.1' },
					{ month: 'Dec', a1: '0.7', a2: '0.2', a3: '0.1' }
				]
			}
		},
		async created() {
			await this.get();
			this.$statusHub.customOutputRequestOpened(this.$route.params.scenarioID, 'CE-QUAL');
			this.$statusHub.$on('custom-output-request-status-changed', this.onStatusChanged);
		},
		beforeDestroy() {
			this.$statusHub.customOutputRequestClosed(this.$route.params.scenarioID, 'CE-QUAL');
			this.$statusHub.$off('custom-output-request-status-changed', this.onStatusChanged);
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				await this.getData();

				this.page.loading = false;
			},
			async getData() {
				try {
					const response = await this.$http.get(`scenarios/output/cequal/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`scenarios/output/cequal/${this.scenarioID}`, this.form, this.getTokenHeader());
					this.log(response.data);
					await this.get();
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			askDelete(id) {
				this.page.delete.id = id;
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`scenarios/output/deletecustomoutputrequest/${this.page.delete.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			hruSelectionChange(selection) {
				this.form.applyToAll = selection.applyToAll;
				this.form.subbasins = selection.subbasins;
			},
			async onStatusChanged(data) {
				await this.getData();
			},
			writeArray(items, limit = 5) {
				if (items === null || items.length === 0) return 'All';
				if (limit === 0) limit = items.length;
				var s = items.slice(0, limit).join(', ');

				if (items.length > limit) return s + ' (...)';
				return s;
			}
		},
		watch: {
			'$route': 'get'
		},
		computed: {
			validHruSelection() {
				return this.form.applyToAll ||
					(this.form.subbasins.length > 0);
			},
			anyRunning() {
				let matches = this.data.requests.filter(function (el) { return el.status === 'running'; });
				return matches.length > 0;
			}
		}
	}
</script>

<template>
	<div class="container pt-0 pb-5">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<b-breadcrumb :items="generalBreadCrumbs" class="no-curves bg-white px-0"></b-breadcrumb>
			<h2 class="mb-3">Land Use Codes and Definitions</h2>

			<b-alert variant="info" :show="cropCodes.length < 1">
				Data unavailable.
			</b-alert>
			<div v-if="datasets.length > 0">
				<b-form inline class="mb-3">
					<label class="mr-sm-2" for="selectedDataset">{{siteText.siteName}} Dataset:</label>
					<b-form-select v-model="selectedDataset" id="selectedDataset" @change="getCrops" class="mr-4">
						<b-form-select-option-group v-for="(o, i) in datasets" :key="i" :label="isNullOrEmpty(o.label) ? 'System datasets' : o.label" :options="o.options"></b-form-select-option-group>
					</b-form-select>

					<b-input-group>
						<b-form-input v-model="table.filter" placeholder="Search land use table..."></b-form-input>

						<b-input-group-append class="pointer mr-2">
							<b-input-group-text @click="table.filter = null">
								<font-awesome-icon :icon="['fas', 'times']" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form>

				<b-table striped :items="cropCodes" :fields="table.fields" :filter="table.filter" show-empty :busy="page.loadingCrops">
					<template #empty>
						<b-alert variant="info" show>
							Data unavailable.
						</b-alert>
					</template>
					<template #emptyfiltered>
						<b-alert variant="info" show>
							No land use found matching '{{table.filter}}'
						</b-alert>
					</template>
					<template #table-busy>
						<div class="text-center my-2">
							<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
						</div>
					</template>
				</b-table>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HelpLanduse',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					loadingCrops: false
				},
				cropCodes: [],
				datasets: [],
				selectedDataset: null,
				table: {
					filter: null,
					fields: [
						{ key: 'code', label: 'Code', sortable: true, class: 'min font-weight-bold' },
						{ key: 'name', label: 'Description', sortable: true }
					]
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`datasets/publiclist`);
					this.datasets = response.data;
					this.log(this.datasets);

					if (this.datasets.length > 0) {
						this.selectedDataset = this.datasets[0].options[0].value;
					}

					await this.getCrops();
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getCrops() {
				this.page.errors = [];
				this.page.loadingCrops = true;

				try {
					let url = 'datasets/cropcodes';
					if (this.selectedDataset !== null) url += `?id=${this.selectedDataset}`;
					const response = await this.$http.get(url);
					this.cropCodes = response.data;
					this.log(this.cropCodes.length);
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loadingCrops = false;
			}
		}
	}
</script>

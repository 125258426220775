<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-3">HUC Data Version 2 Imports</h2>

			<b-alert variant="info" show>
				It is highly recommended to just run one region at a time. Please check with Jaclyn before running.
			</b-alert>

			<b-table-simple small responsive class="table-valign-m">
				<b-thead>
					<b-tr>
						<b-th>Region</b-th>
						<b-th>HUC8</b-th>
						<b-th>HUC10</b-th>
						<b-th>HUC12</b-th>
						<b-th>HUC14</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="(r, i) in data.regions" :key="i">
						<b-td>{{r.name}}</b-td>
						<b-td v-for="d in r.resolutions" :key="d" style="width:20%">
							<div v-if="data.imports[d].status.isRunning">
								<b-progress :value="data.imports[d].progress.percent" :max="100" animated></b-progress>
								<div>{{data.imports[d].progress.message}}</div>
								<a href="#" @click.prevent="abortImport(d)">Abort</a>
							</div>
							<div v-else-if="data.imports[d].status.hasError">
								<b-button v-b-modal="`error-popover-${d}`" size="sm" variant="danger">
									Error
								</b-button>
								<b-modal :id="`error-popover-${d}`" hide-footer size="xl">
									<template #modal-title>
										Error importing region {{r.name}} HUC{{data.imports[d].digits}}
									</template>
									<div class="border p-1 mb-3" style="font-size:0.8rem">
										<pre>{{data.imports[d].status.exceptionMessage}}</pre>
									</div>
									<b-button size="sm" variant="secondary" @click="importData(d)">Re-start</b-button>
								</b-modal>
							</div>
							<div v-else-if="data.imports[d].status.isComplete">
								<font-awesome-icon :id="`complete-popover-${d}`" :icon="['fas', 'check']" class="pointer icon mx-2 text-success" />
								<b-popover :target="`complete-popover-${d}`" triggers="hover" :id="`complete-popover-${d}`">
									<div class="mb-2">Imported {{data.imports[d].importedDate | date}} in {{data.imports[d].importTime}}.</div>
									<b-button size="sm" variant="secondary" @click="importData(d)">Re-import</b-button>
								</b-popover>
							</div>
							<div v-else>
								<b-button size="sm" variant="secondary" @click="importData(d)">Import</b-button>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<b-card class="border my-3">
				<b-card-text>
					SSURGO Soils Count: {{data.soils.count}}
					<div v-if="data.soils.progress.percent === 0 || data.soils.progress.percent === 100">
						<b-button size="sm" variant="secondary" @click="importSoils">{{data.soils.count > 0 ? 'Re-import' : 'Start Import'}}</b-button>
					</div>
					<div v-else>
						<b-progress :value="data.soils.progress.percent" :max="100" animated></b-progress>
						<div>{{data.soils.progress.message}}</div>
					</div>
				</b-card-text>
			</b-card>

			<b-card class="border my-3">
				<b-card-text>
					Import all regions that haven't run. Will skip regions with errors.
					<div>
						<b-button size="sm" variant="secondary" @click="importAll">Start Import</b-button>
					</div>
				</b-card-text>
			</b-card>
		</page-authorization-container>
	</div>
</template>

<script>
	export default {
		name: 'AdminImportHuc2',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				data: {
					regions: [
						{
							name: '01',
							resolutions: [1]
						}
					],
					soils: {
						count: 0,
						progress: {
							percent: 0,
							message: null
						}
					},
					imports: {
						1: {
							region: '01',
							digits: 8,
							importedDate: null,
							importTime: null,
							status: {
								isRunning: false,
								hasError: false,
								exceptionMessage: null,
								isComplete: false
							},
							progress: {
								percent: 0,
								message: null
							}
						}
					}
				}
			}
		},
		async created() {
			await this.get();
			this.$statusHub.importHucVersion2Opened();
			this.$statusHub.$on('import-huc-version2-status-changed', this.onStatusChanged);
		},
		beforeDestroy() {
			this.$statusHub.importHucVersion2Closed();
			this.$statusHub.$off('import-huc-version2-status-changed', this.onStatusChanged);
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/importHuc2', this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async onStatusChanged(data) {
				if (data.importID in this.data.imports) {
					let res = this.data.imports[data.importID];

					if (data.progress == 100) {
						res.status.isRunning = false;
						await this.get();
					}

					res.progress.percent = data.progress;
					res.progress.message = data.statusMessage;
				} else if (data.importID === 0) {
					if (data.progress == 100) {
						await this.get();
					}

					this.data.soils.progress.percent = data.progress;
					this.data.soils.progress.message = data.statusMessage;
				}
				return;
			},
			async importData(id) {
				this.page.errors = [];

				try {
					const response = await this.$http.post(`admin/importHuc2/${id}`, {}, this.getTokenHeader());
					this.log(response.data);
					this.data.imports[id].progress = {
						percent: 1,
						message: 'Starting import...'
					};
					this.data.imports[id].status = {
						isRunning: true,
						hasError: false,
						exceptionMessage: null,
						isComplete: false
					};
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async importSoils() {
				this.page.errors = [];

				try {
					const response = await this.$http.post('admin/importHuc2/soils', {}, this.getTokenHeader());
					this.log(response.data);
					this.data.soils.progress = {
						percent: 1,
						message: 'Starting import...'
					};
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async importAll() {
				this.page.errors = [];

				try {
					const response = await this.$http.post('admin/importHuc2/all', {}, this.getTokenHeader());
					this.log(response.data);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async abortImport(id) {
				this.page.errors = [];

				try {
					const response = await this.$http.post(`admin/importHuc2/abort/${id}`, {}, this.getTokenHeader());
					this.log(response.data);
					this.data.imports[id].progress.message = 'Aborting please wait...';
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			}
		}
	}
</script>

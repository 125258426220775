<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Group Invitations</h2>

			<b-alert :show="page.accept.success"
					 dismissible
					 variant="success">
				<font-awesome-icon :icon="['fas', 'check']" />
				You are now a member of the group, {{page.accept.name}}
				<router-link :to="`/groups/${page.accept.id}`">Visit this group.</router-link>
			</b-alert>

			<div v-if="invites.length > 0">
				<b-table striped responsive class="nowrap-headers mb-3 border-bottom table-valign-m"
						 :items="invites" :fields="table.fields">
					<template v-slot:cell(invitedOn)="data">
						{{data.item.invitedOn | date}}
					</template>
					<template v-slot:cell(reject)="data">
						<b-button type="button" variant="danger" @click="askReject(data.item.groupID, data.item.group)">
							<font-awesome-icon :icon="['fas', 'times']" /> Reject
						</b-button>
					</template>
					<template v-slot:cell(accept)="data">
						<b-button type="button" variant="success" @click="accept(data.item.groupID, data.item.group)">
							<font-awesome-icon :icon="['fas', 'check']" /> Accept
						</b-button>
					</template>
				</b-table>
			</div>
			<div v-else>
				<p class="lead">You have no group invitations at this time.</p>
			</div>

			<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>

			<b-modal v-model="page.reject.show" size="md" title="Confirm invitation rejection" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.reject.errors"></error-list>

				<p>
					Are you sure you want to reject the invitation to join the group, <strong>{{page.reject.name}}</strong>?
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.reject.saving" @click.native="confirmReject" text="Reject Invitation" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.reject.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</page-authorization-container>
	</div>
</template>

<script>
	export default {
		name: 'GroupInvites',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					reject: {
						show: false,
						errors: [],
						saving: false,
						id: null,
						name: null
					},
					accept: {
						show: false,
						errors: [],
						saving: false,
						id: null,
						name: null,
						success: false
					}
				},
				invites: [],
				table: {
					fields: [
						{ key: 'accept', label: '', class: 'min' },
						{ key: 'reject', label: '', class: 'min' },
						'group',
						'groupDescription',
						'invitedOn',
						'invitedBy'
					]
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`groups/invites`, this.getTokenHeader());
					this.invites = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			askReject(id, name) {
				this.page.reject.id = id;
				this.page.reject.name = name;
				this.page.reject.show = true;
			},
			async confirmReject() {
				this.page.reject.errors = [];
				this.page.reject.saving = true;

				try {
					const response = await this.$http.patch(`groups/reject/${this.page.reject.id}`, {}, this.getTokenHeader());
					this.page.reject.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.reject.errors = this.logError(error);
				}

				this.page.reject.saving = false;
			},
			async accept(id, name) {
				this.page.accept.id = id;
				this.page.accept.name = name;
				this.page.accept.errors = [];
				this.page.accept.saving = true;

				try {
					const response = await this.$http.patch(`groups/accept/${id}`, {}, this.getTokenHeader());
					this.page.accept.show = false;
					this.page.accept.success = true;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.accept.errors = this.logError(error);
				}

				this.page.accept.saving = false;
			}
		}
	}
</script>

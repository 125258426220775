<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">Create a new SWAT version</h2>
			<p>Files must be uploaded or placed on the server.</p>

			<admin-swat-version-form :data="data"></admin-swat-version-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import AdminSwatVersionForm from '@/views/admin/datasets/swatversions/AdminSwatVersionForm';

	export default {
		name: 'AdminSwatVersionCreate',
		components: {
			AdminSwatVersionForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				data: {}
			}
		}
	}
</script>

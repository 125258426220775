<template>
	<b-alert variant="warning" show>
		This {{isProject ? 'project' : 'scenario'}} is currently running and cannot be modified at this time.
	</b-alert>
</template>

<script>
export default {
	name: 'PageRunning',
	props: {
		isProject: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<template>
	<div>
		<page-authorization-container :page="page" show-errors-with-object>
			<h2 class="mb-3">Create a new regional project</h2>

			<p>
				This form allows you to create a project for an entire region.
				Once created, regional projects may be accessed through the normal {{siteText.siteName}} interface and project list.
			</p>

			<error-list :errors="page.saveErrors"></error-list>

			<b-form @submit.prevent="save">
				<b-form-group :label="`${siteText.siteName} dataset`" :invalid-feedback="requiredFeedback($v.form.datasetID)">
					<b-form-select v-model="form.datasetID" @change="getRegions" :state="getValidState($v.form.datasetID)">
						<b-form-select-option-group v-for="(o, i) in options.datasets" :key="i" :label="isNullOrEmpty(o.label) ? 'System datasets' : o.label" :options="o.options"></b-form-select-option-group>
						<b-form-select-option-group v-if="options.userDatasets.length > 0" label="User-submitted datasets" :options="options.userDatasets"></b-form-select-option-group>
					</b-form-select>
				</b-form-group>

				<b-alert variant="warning" :show="options.regions.length < 1">
					There are no regions configured for the selected dataset. Please contact the developer if it is needed.
				</b-alert>

				<b-form-group label="Region" :invalid-feedback="requiredFeedback($v.form.regionID)">
					<b-form-select v-model="form.regionID" :options="options.regions" @change="setProjectName" :state="getValidState($v.form.regionID)">
						<template #first>
							<b-form-select-option :value="null" disabled>-- Select a region --</b-form-select-option>
						</template>
					</b-form-select>
				</b-form-group>

				<b-form-group label="Give your project a name" class="mt-4">
					<b-form-input v-model="form.projectName" type="text" :state="getValidState($v.form.projectName)"></b-form-input>
					<b-form-invalid-feedback v-if="!$v.form.projectName.required">Required</b-form-invalid-feedback>
					<b-form-invalid-feedback v-if="!$v.form.projectName.maxLength">Must be fewer than 100 characters</b-form-invalid-feedback>
					<b-form-invalid-feedback v-if="!$v.form.projectName.hasAlphaNumeric">Must contain at least one letter or number</b-form-invalid-feedback>
				</b-form-group>

				<fixed-action-bar :cols-lg="10" :offset-lg="2">
					<save-button :saving="page.saving" class="mr-2" :disabled="options.regions.length < 1" text="Create Project" />
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, maxLength } from 'vuelidate/lib/validators';

	export default {
		name: 'ApiRegionalCreate',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: [],
					regions: {
						errors: [],
						loading: false
					}
				},
				options: {
					datasets: [],
					userDatasets: [],
					regions: []
				},
				form: {
					datasetID: null,
					regionID: null,
					projectName: null
				}
			}
		},
		validations: {
			form: {
				datasetID: { required },
				regionID: { required },
				projectName: {
					required,
					maxLength: maxLength(100),
					hasAlphaNumeric(value) {
						return (
							/[a-z]/.test(value) || // checks for a-z
							/[0-9]/.test(value) // checks for 0-9
						);
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`api-requests/regionaloptions`, this.getTokenHeader());
					this.log(response.data);
					this.options.datasets = response.data.datasets;
					this.options.userDatasets = response.data.userDatasets;

					this.form.datasetID = response.data.defaultDataset;

					if (this.isNullOrEmpty(this.form.datasetID) && this.options.datasets.length > 0 && this.options.datasets[0].options.length > 0) {
						this.form.datasetID = this.options.datasets[0].options[0].value;
					}

					if (!this.isNullOrEmpty(this.form.datasetID)) {
						await this.getRegions();
					}

				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getRegions() {
				this.page.regions.errors = [];
				this.page.regions.loading = true;

				if (this.isNullOrEmpty(this.form.datasetID)) {
					this.page.regions.errors.push('Please select a dataset.');
				} else {
					try {
						const response = await this.$http.get(`api-requests/datasetregionoptions/${this.form.datasetID}`, this.getTokenHeader());
						this.log(response.data);
						this.options.regions = response.data.regions;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.regions.errors = this.logError(error);
					}
				}

				this.page.regions.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('api-requests/createregionalproject', this.form, this.getTokenHeader());
						this.$router.push({ name: 'ProjectView', params: { id: response.data } }).catch(err => { });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			getSelectedDataset() {
				let id = this.form.datasetID;
				let matches = [];
				for (let group of this.options.datasets) {
					matches = group.options.filter(function (el) { return el.value == id; });
					if (matches.length > 0) break;
				}
				if (matches.length == 0) {
					matches = this.options.userDatasets.filter(function (el) { return el.value == id; });
				}
				this.selectedDataset = matches[0];
				return matches[0]; 
			},
			getSelectedRegion() {
				let id = this.form.regionID;
				return this.options.regions.filter(function (el) { return el.value == id; })[0]; 
			},
			setProjectName() {
				let dataset = this.getSelectedDataset();
				let region = this.getSelectedRegion();

				this.form.projectName = `${dataset.text} - Region ${region.text}`;
				this.log('set name');
				this.log(this.form.projectName);
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<div v-if="project.hasScenarios && !status.hasSetHrus">
				<h2 class="mb-3">Review Project HRUs</h2>

				<p>
					Your project has <a :href="project.hrusOriginalCsv" class="font-weight-bold" v-b-tooltip.hover title="Download CSV">{{project.numHrus | number(0)}} HRUs <font-awesome-icon icon="file-csv" /></a>.
					HRUs may not be modified after scenarios are created; you will need to delete all scenarios for this project or create a new project.
					Read the <a href="https://hawqs.tamu.edu/content/docs/HAWQS2.0_HRUAggregation_Guidance.pdf" target="_blank">documentation</a> on HRU aggregation.
				</p>

				<b-row>
					<b-col md>
						<b-table striped responsive small
								 :items="project.originalGroups.landuse"
								 :fields="landuseFields.slice(1)">
							<template v-slot:cell(name)="data">
								<span v-b-tooltip.hover :title="getLanduseDescription(data.item.name)">{{data.item.name}}</span>
							</template>
							<template v-slot:cell(area)="data">
								{{data.item.area | number(2)}} km
								<sup>2</sup>
							</template>
							<template v-slot:cell(percent)="data">
								{{(data.item.area / project.totalLandArea * 100) | number(3)}} %
							</template>
						</b-table>
					</b-col>
					<b-col md>
						<highcharts :options="charts.original.landuse"></highcharts>
					</b-col>
				</b-row>

				<fixed-action-bar>
					<router-link :to="`/projects/${projectID}/scenarios/create`" class="btn btn-success mr-2">Create a scenario</router-link>
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</div>
			<div v-else-if="status.hasSetHrus">
				<h2 class="mb-3">Review Project HRUs</h2>

				<p v-if="project.method == 'Area'">
					You removed HRUs with an area less than
					<strong>
						{{project.targetThreshold | number(2)}}
						<span v-if="project.units == 'Percent'">
							% of each subbasin
						</span>
						<span v-else>
							km<sup>2</sup>
						</span>
					</strong>
					<span v-if="project.exemptLanduse.length > 0">
						(exempting land uses {{project.exemptLanduse.join(', ')}})
					</span>
					eliminating {{(project.numOriginalHrus - project.numHrus) | number(0)}} HRUs and redistributing
					{{project.trimmedLanduseArea | number(2)}} km<sup>2</sup> of your project area<span v-if="project.noAreaRedistribution.length > 0"> (area was not redistributed in {{project.noAreaRedistribution.join(', ')}})</span>.
				</p>
				<p v-else-if="project.method == 'TargetHrus'">
					You aimed to retain {{project.targetThreshold | number(0)}} HRUs
					<span v-b-tooltip.hover.bottom title="We try to get as close to this number as possible, however it may not be exact due to landuse exemptions or ensuring all HRUs in the subbasin do not get eliminated.">
						<font-awesome-icon icon="info-circle" class="text-info" />
					</span>
					<span v-if="project.exemptLanduse.length > 0">
						(exempting land uses {{project.exemptLanduse.join(', ')}} from elimination)
					</span>
					which resulted in {{(project.numOriginalHrus - project.numHrus) | number(0)}} HRUs removed and
					{{project.trimmedLanduseArea | number(2)}} km<sup>2</sup> of your project area redistributed<span v-if="project.noAreaRedistribution.length > 0"> (area was not redistributed in {{project.noAreaRedistribution.join(', ')}})</span>.
				</p>

				<p>
					Read the <a href="https://hawqs.tamu.edu/content/docs/HAWQS2.0_HRUAggregation_Guidance.pdf" target="_blank">documentation</a> on HRU aggregation.
				</p>

				<table class="table table-sm table-borderless mb-4">
					<tbody>
						<tr>
							<td class="min pl-0">
								<a :href="project.hrusTrimmedCsv" class="btn btn-primary btn-sm">Download <font-awesome-icon icon="file-csv" /></a>
							</td>
							<td class="align-middle">
								<b-progress height="1.875rem" :max="project.numOriginalHrus">
									<b-progress-bar :value="project.numHrus" :label="`${numberWithCommas(project.numHrus)} HRUs after thresholds applied`" variant="primary"></b-progress-bar>
								</b-progress>
							</td>
						</tr>
						<tr>
							<td class="min pl-0">
								<a :href="project.hrusOriginalCsv" class="btn btn-secondary btn-sm">Download <font-awesome-icon icon="file-csv" /></a>
							</td>
							<td class="align-middle">
								<b-progress height="1.875rem" :max="project.numOriginalHrus">
									<b-progress-bar :value="project.numOriginalHrus" :label="`${numberWithCommas(project.numOriginalHrus)} default HRUs`" variant="secondary"></b-progress-bar>
								</b-progress>
							</td>
						</tr>
					</tbody>
				</table>

				<p v-if="!project.hasScenarios">
					<save-button class="btn btn-primary mr-2" :saving="page.saving" @click.native="reset" text="Reset / Clear Thresholds" />
				</p>
				<p v-else>
					HRUs may not be modified after scenarios are created; you will need to delete all scenarios for this project or create a new project.
				</p>

				<b-row class="mt-5">
					<b-col md="5">
						<highcharts :options="charts.trimmed.landuse"></highcharts>
					</b-col>
					<b-col md="5" offset="1">
						<highcharts :options="charts.original.landuse"></highcharts>
					</b-col>
				</b-row>

				<h3 class="mt-5">Distribution comparison tables</h3>

				<b-tabs content-class="mt-4" class="page-nav-tabs">
					<b-tab title="Landuse">
						<table class="table table-striped table-definitions table-responsive">
							<thead>
								<tr class="bg-light">
									<th></th>
									<th class="text-right border-right" colspan="2">With thresholds applied</th>
									<th class="text-right" colspan="2">Original</th>
								</tr>
								<tr>
									<th>Land Use</th>
									<th class="text-right">Area</th>
									<th class="text-right border-right">% of Total Area</th>
									<th class="text-right">Area</th>
									<th class="text-right">% of Total Area</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in landuseComparison" :key="index">
									<td><span v-b-tooltip.hover :title="getLanduseDescription(row.name)">{{row.name}}</span></td>
									<td class="text-right" v-if="row.trimmedArea">{{row.trimmedArea | number(2)}} km<sup>2</sup></td>
									<td class="text-right" v-else>-</td>
									<td class="text-right border-right" v-if="row.trimmedArea">{{row.trimmedPercent | number(2)}} %</td>
									<td class="text-right border-right" v-else>-</td>
									<td class="text-right">{{row.originalArea | number(2)}} km<sup>2</sup></td>
									<td class="text-right">{{row.originalPercent | number(2)}} %</td>
								</tr>
							</tbody>
						</table>
					</b-tab>
					<b-tab title="Soil">
						<p>(after thresholds applied)</p>
						<b-table striped head-variant="light" style="width:auto"
								 :items="project.trimmedGroups.soil"
								 :fields="soilFields">
							<template v-slot:cell(area)="data">
								{{data.item.area | number(2)}} km
								<sup>2</sup>
							</template>
							<template v-slot:cell(percent)="data">
								{{(data.item.area / project.totalArea * 100) | number(3)}} %
							</template>
						</b-table>
					</b-tab>
					<b-tab title="Slope class">
						<p>(after thresholds applied)</p>
						<b-table striped head-variant="light" class="mb-3" style="width:auto"
								 :items="project.trimmedGroups.slope"
								 :fields="slopeFields">
							<template v-slot:cell(area)="data">
								{{data.item.area | number(2)}} km
								<sup>2</sup>
							</template>
							<template v-slot:cell(percent)="data">
								{{(data.item.area / project.totalArea * 100) | number(3)}} %
							</template>
						</b-table>
					</b-tab>
				</b-tabs>

				<fixed-action-bar>
					<router-link :to="`/projects/${projectID}/scenarios/create`" class="btn btn-success mr-2">Create a scenario</router-link>
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</div>
			<div v-else>
				<b-form @submit.prevent="save">
					<h2 class="mb-3">Set Project HRUs</h2>

					<b-alert variant="warning" :show="!$parent.withinTierLimits" class="mt-3">
						Your account tier limits you to {{$parent.tier.hruLimit | number(0)}} HRUs or fewer.
						Reduce the number of HRUs to the limit in order to create and run scenarios.
					</b-alert>

					<p>
						Reduce the number of HRUs in your project for faster simulation runs.
						Your project has <strong>{{project.numSubbasins | number(0)}}</strong> subbasins and
						<strong>{{project.numHrus | number(0)}}</strong> HRUs covering a land area of
						<strong>{{project.totalLandArea | number(3)}} km<sup>2</sup></strong>.
					</p>

					<p>
						Read the <a href="https://hawqs.tamu.edu/content/docs/HAWQS2.0_HRUAggregation_Guidance.pdf" target="_blank">documentation</a> on HRU aggregation.
					</p>

					<error-list :errors="formData.errors"></error-list>

					<b-row class="my-4">
						<b-col lg="7">
							<div style="max-width:700px">
								<b-form-group label="Select a method for reducing HRUs:">
									<b-form-select v-model="formData.method" :options="hruReductionMethods">
										<b-form-select-option v-if="!isNullOrEmpty(iclusHruThreshold)" value="iclus">Set for use with Integrated Climate and Land Use Scenarios (ICLUS)</b-form-select-option>
									</b-form-select>
								</b-form-group>
								<div class="mt-4">
									{{hruReductionDescriptions[formData.method]}}
									<span v-if="formData.method == 'iclus'">
										HRUs with an area less than <strong>{{iclusHruThreshold | number(1)}} km<sup>2</sup></strong> will be removed.
									</span>
								</div>

								<b-row v-if="formData.method == 'targetHrus'" class="align-items-center my-3">
									<b-col cols="9" sm="10" class="d-flex align-items-center">
										<b-form-input v-model="formData.targetHrus" type="range" :min="project.numSubbasins" :max="project.numOriginalHrus"></b-form-input>
									</b-col>
									<b-col>
										<b-form-input size="sm" v-model="formData.targetHrus" type="number" :min="project.numSubbasins" :max="project.numOriginalHrus"></b-form-input>
									</b-col>
								</b-row>

								<b-row v-else-if="formData.method == 'area'" class="align-items-center my-3">
									<b-col cols="8" class="d-flex align-items-center">
										<b-form-input v-model="formData.minimumArea" type="range" step="0.25" :min="0" :max="formData.units == 'area' ? project.totalLandArea : 100"></b-form-input>
									</b-col>
									<b-col cols="2">
										<b-form-input size="sm" v-model="formData.minimumArea" type="number" step="any" :min="0" :max="formData.units == 'area' ? project.totalLandArea : 100"></b-form-input>
									</b-col>
									<b-col cols="2">
										<b-form-select size="sm" v-model="formData.units" :options="unitsOptions" @change="setAreaLimit"></b-form-select>
									</b-col>
								</b-row>

								<div v-else-if="formData.method == 'landuseSoilSlope'">
									<div class="form-inline mt-3">
										<label for="formUnits" class="mr-2">Units</label>
										<b-form-select id="formUnits" v-model="formData.units" :options="unitsOptions" @change="setLSSLimit"></b-form-select>
									</div>

									<b-row class="align-items-center my-3">
										<b-col cols="12" sm="3">Land use threshold</b-col>
										<b-col cols="9" sm="7" class="d-flex align-items-center">
											<b-form-input v-model="form.landuseThreshold" type="range" :min="0" :max="formData.units == 'area' ? project.totalLandArea : 100"></b-form-input>
										</b-col>
										<b-col cols="3" sm="2">
											<b-form-input v-model="form.landuseThreshold" type="number" :min="0" :max="formData.units == 'area' ? project.totalLandArea : 100"></b-form-input>
										</b-col>
									</b-row>

									<b-row class="align-items-center my-3">
										<b-col cols="12" sm="3">Soil threshold</b-col>
										<b-col cols="9" sm="7" class="d-flex align-items-center">
											<b-form-input v-model="form.soilThreshold" type="range" :min="0" :max="formData.units == 'area' ? project.totalLandArea : 100"></b-form-input>
										</b-col>
										<b-col cols="3" sm="2">
											<b-form-input v-model="form.soilThreshold" type="number" :min="0" :max="formData.units == 'area' ? project.totalLandArea : 100"></b-form-input>
										</b-col>
									</b-row>

									<b-row class="align-items-center my-3">
										<b-col cols="12" sm="3">Slope threshold</b-col>
										<b-col cols="9" sm="7" class="d-flex align-items-center">
											<b-form-input v-model="form.slopeThreshold" type="range" :min="0" :max="formData.units == 'area' ? project.totalLandArea : 100"></b-form-input>
										</b-col>
										<b-col cols="3" sm="2">
											<b-form-input v-model="form.slopeThreshold" type="number" :min="0" :max="formData.units == 'area' ? project.totalLandArea : 100"></b-form-input>
										</b-col>
									</b-row>
								</div>

								<div v-if="formData.method != 'dominantHru'" class="text-muted mt-4">
									<font-awesome-icon icon="info-circle" class="mr-1 text-info" />
									<em>Please note: one HRU per subbasin will always be retained.</em>
								</div>
							</div>
						</b-col>
						<b-col lg>
							<highcharts :options="charts.original.landuse"></highcharts>
						</b-col>
					</b-row>
					

					<hr class="my-4" />

					<div v-if="formData.method == 'iclus'">
						<p>
							For ICLUS, water and wetland land use are exempt and retained. The exemptions below cannot be changed when the ICLUS method is selected.
						</p>
					</div>
					<div v-else>
						<p>
							In the table below, check the box in the "Exempt" column for any land use that you would like to exempt from elimination.
							Check the "Retain Area" column next to any land use you do not want to redistribute area to during HRU elimination
							(e.g., if you have a WATR HRU with area of 10km<sup>2</sup>, if you check the "Retain Area" box, it will remain 10km<sup>2</sup>;
							if you do not check the box, the area will increase proportionally based on its fraction of total area in the subbasin).
						</p>
						<p>
							Any water and wetland land use are exempt and retained by default.
						</p>
					</div>

					<b-table striped responsive small
							 :items="project.originalGroups.landuse"
							 :fields="landuseFields">
						<template v-slot:cell(exempt)="data">
							<b-form-checkbox v-model="form.exemptLanduse" :value="data.item.name" :disabled="formData.method == 'iclus'"></b-form-checkbox>
						</template>
						<template v-slot:cell(noRedistrib)="data">
							<b-form-checkbox v-model="form.noAreaRedistribution" :value="data.item.name" :disabled="formData.method == 'iclus'"></b-form-checkbox>
						</template>
						<template v-slot:cell(name)="data">
							<span v-b-tooltip.hover :title="getLanduseDescription(data.item.name)">{{data.item.name}}</span>
						</template>
						<template v-slot:cell(description)="data">
							{{getLanduseDescription(data.item.name)}}
						</template>
						<template v-slot:cell(area)="data">
							{{data.item.area | number(2)}} km
							<sup>2</sup>
						</template>
						<template v-slot:cell(percent)="data">
							{{(data.item.area / project.totalLandArea * 100) | number(3)}} %
						</template>
					</b-table>

					<fixed-action-bar>
						<save-button class="btn btn-primary mr-2" :saving="page.saving" />
						<back-button class="btn btn-secondary mr-2" />
					</fixed-action-bar>
				</b-form>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	export default {
		name: 'ProjectHrus',
		data() {
			return {
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false
				},
				project: {
					charts: {},
					exemptLanduse: [],
					originalGroups: {}
				},
				status: {
					hasError: false,
					isSettingHrus: false,
					hasSetHrus: false
				},
				cropCodes: [],
				defaultWetlands: [],
				iclusHruThreshold: null,
				progress: {
					percent: 0,
					message: 'Please wait...'
				},
				charts: {
					original: {
						landuse: {},
						soil: {},
						slope: {}
					},
					trimmed: {
						landuse: {},
						soil: {},
						slope: {}
					}
				},
				form: {
					projectID: this.$route.params.id,
					method: 'area',
					targetThreshold: 0,
					landuseThreshold: 0,
					soilThreshold: 0,
					slopeThreshold: 0,
					exemptLanduse: [],
					noAreaRedistribution: [],
					units: 'none'
				},
				formData: {
					method: 'area',
					targetHrus: 0,
					minimumArea: 1,
					units: 'area',
					errors: []
				},
				unitsOptions: [
					{ value: 'area', text: 'km2' },
					{ value: 'percent', text: '%' }
				],
				landuseFields: [
					{ key: 'exempt', label: 'Exempt', sortable: false, class: 'text-center' },
					{ key: 'noRedistrib', label: 'Retain Area', sortable: false, class: 'text-center' },
					{ key: 'name', label: 'Land Use', sortable: true },
					{ key: 'description', label: 'Description', sortable: true },
					{ key: 'area', sortable: true, class: 'text-right' },
					{ key: 'percent', label: '% of Total Area', sortable: false, class: 'text-right' }
				],
				soilFields: [
					{ key: 'name', label: 'Soil', sortable: false },
					{ key: 'area', sortable: false, class: 'text-right' },
					{ key: 'percent', label: '% of Total Area', sortable: false, class: 'text-right' }
				],
				slopeFields: [
					{ key: 'name', label: 'Slope Class', sortable: false },
					{ key: 'area', sortable: false, class: 'text-right' },
					{ key: 'percent', label: '% of Total Area', sortable: false, class: 'text-right' }
				],
				hruReductionMethods: [
					{ value: 'area', text: 'Set by area' },
					{ value: 'targetHrus', text: 'Target number of HRUs' },
					//{ value: 'dominantHru', text: 'Dominant HRU' },
					//{ value: 'landuseSoilSlope', text: 'Filter by landuse, soil, and slope' }
				],
				hruReductionDescriptions: {
					'area': 'Enter a minimum area threshold. HRUs with an area less than this threshold will be removed.',
					'targetHrus': 'Select a target number of HRUs to be retained. HRUs with the smallest area will be removed.',
					'dominantHru': 'This method will keep one HRU per subbasin, the HRU with the largest area.',
					'landuseSoilSlope': 'Enter minimum land use, soil, and slope thresholds below. HRUs with land uses, soils, or slopes that cover area less than the threshold levels will be removed.',
					'iclus': 'ICLUS require specific HRU reduction settings to run. Select this option if you intend to run these scenarios.'
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`projects/hrus/${this.projectID}`, this.getTokenHeader());
					this.log(response.data);
					this.project = response.data.project;
					this.status = response.data.status;
					this.cropCodes = response.data.cropCodes;
					this.defaultWetlands = response.data.defaultWetlands;
					this.iclusHruThreshold = response.data.iclusHruThreshold;

					this.form.exemptLanduse = this.defaultWetlands;
					this.form.noAreaRedistribution = this.defaultWetlands;

					this.charts.original.landuse = this.getPieChart('Land Use Distribution', this.project.charts.original.landuse);

					if (this.project.exemptLanduse === null) this.project.exemptLanduse = [];
					if (this.project.noAreaRedistribution === null) this.project.noAreaRedistribution = [];

					if (this.status.hasSetHrus) {
						this.charts.original.landuse.title.text = 'Default Land Use Distribution';
						this.charts.trimmed.landuse = this.getPieChart('Land Use Distribution With Thresholds Applied', this.project.charts.trimmed.landuse);

						/*this.charts.original.soil = this.getPieChart('Default Soil Distribution', this.project.charts.original.soil);
						this.charts.trimmed.soil = this.getPieChart('Soil Distribution With Thresholds Applied', this.project.charts.trimmed.soil);

						this.charts.original.slope = this.getPieChart('Default Slope Class Distribution', this.project.charts.original.slope);
						this.charts.trimmed.slope = this.getPieChart('Slope Class Distribution With Thresholds Applied', this.project.charts.trimmed.slope);*/
					}

					this.formData.targetHrus = Math.ceil(this.project.numOriginalHrus * 0.75);

					if (!this.isNullOrEmpty(response.data.defaultHruThreshold)) {
						this.formData.minimumArea = response.data.defaultHruThreshold;
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			setIclus() {
				if (!this.isNullOrEmpty(this.iclusHruThreshold) && this.formData.method == 'iclus') {
					this.form.method = 'area';
					this.form.targetThreshold = this.iclusHruThreshold;
					this.formData.minimumArea = this.iclusHruThreshold;
					this.form.units = 'area';
					this.formData.units = 'area';
					this.form.exemptLanduse = this.defaultWetlands;
					this.form.noAreaRedistribution = this.defaultWetlands;
				}
			},
			getPieChart(title, data) {
				return {
					plotOptions: { pie: { dataLabels: { enabled: false }, showInLegend: true } },
					title: { text: title },
					tooltip: { pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> ({point.additional} km<sup>2</sup>)' },
					series: [{ data: data, name: 'Area', type: 'pie' }]
				};
			},
			async save() {
				this.page.saving = true;
				this.formData.errors = [];

				if (this.formData.method == 'area') {
					this.form.method = 'area';
					this.form.targetThreshold = this.formData.minimumArea;
					this.form.units = this.formData.units;
				} else if (this.formData.method == 'targetHrus') {
					this.form.method = 'targetHrus';
					this.form.targetThreshold = this.formData.targetHrus;
				} else if (this.formData.method == 'iclus') {
					this.setIclus();
				}

				this.form.projectID = this.projectID;
				if (this.form.targetThreshold <= 0) {
					this.formData.errors.push('You must enter a threshold greater than 0.');
				} else {
					try {
						await this.$http.post('projects/hrus', this.form, this.getTokenHeader());
						this.$router.push({ name: 'ProjectView', params: { id: this.projectID } }).catch(err => { this.log(err); });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.formData.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async reset() {
				this.page.saving = true;
				this.page.errors = [];

				try {
					await this.$http.patch(`projects/hrus/reset/${this.projectID}`, {}, this.getTokenHeader());
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.saving = false;
			},
			setAreaLimit() {
				if (this.formData.units == 'percent' && this.formData.minimumArea > 100) {
					this.formData.minimumArea = 0;
				}
			},
			setLSSLimit() {
				if (this.formData.units == 'percent') {
					if (this.form.landuseThreshold > 100) this.form.landuseThreshold = 0;
					if (this.form.soilThreshold > 100) this.form.soilThreshold = 0;
					if (this.form.slopeThreshold > 100) this.form.slopeThreshold = 0;
				}
			},
			findGroupRow(group, name) {
				var index = group.findIndex(x => x.name == name);
				if (index > -1)
					return group[index];

				return undefined;
			},
			getLanduseDescription(name) {
				if (this.cropCodes.length > 0) {
					var filtered = this.cropCodes.filter(function (el) { return el.code === name; });
					if (filtered.length > 0) return filtered[0].name;
				}
				return '';
			}
		},
		computed: {
			landuseComparison() {
				var landuses = [];
				var original = this.project.originalGroups.landuse;
				var trimmed = this.project.trimmedGroups.landuse;

				for (var i = 0; i < original.length; i++) {
					var trimmedLanduse = this.findGroupRow(trimmed, original[i].name);

					landuses.push({
						name: original[i].name,
						originalArea: original[i].area,
						originalPercent: original[i].area / this.project.totalArea * 100,
						trimmedArea: trimmedLanduse === undefined ? undefined : trimmedLanduse.area,
						trimmedPercent: trimmedLanduse === undefined ? undefined : trimmedLanduse.area / this.project.totalArea * 100
					})
				}

				return landuses;
			}
		}
	}
</script>

<template>
	<div>
		<div v-if="$route.name == 'AdminTiers'">
			<h2 class="mb-3">Tiers</h2>

			<p>
				All users will be assigned to the default tier automatically.
				To grant a user access to another tier, select the user from the
				<router-link to="/admin/users">users page</router-link>.
			</p>

			<grid-view api-url="admin/tiers"
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="tiers"
					   item-name="tier"
					   show-create create-route="create"></grid-view>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'AdminTiers',
		components: {
			GridView
		},
		data() {
			return {
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true },
						{ key: 'description', sortable: true },
						{ key: 'isDefault', label: 'Default?', sortable: true, formatter: (value) => { return value ? 'Yes' : 'No' } },
						{ key: 'numUsers', label: '# Users', sortable: true, formatter: (value) => { return value === 0 ? '-' : this.numberWithCommas(value) } },
						{ key: 'queuePriority', sortable: true, formatter: (value) => { return value === 'middle' ? 'higher' : (value === 'default' ? 'normal' : value) } },
						{ key: 'subbasinLimit', label: 'Max Subbasins', sortable: true, formatter: (value) => { return value === 0 ? '-' : this.numberWithCommas(value) } },
						{ key: 'hruLimit', label: 'Max HRUs', sortable: true, formatter: (value) => { return value === 0 ? '-' : this.numberWithCommas(value) } },
						{ key: 'taskLimit', label: 'Run Frequency', sortable: true },
						{ key: 'concurrencyLimit', label: 'Concurrently', sortable: true, formatter: (value) => { return value === 0 ? '-' : this.numberWithCommas(value) } },
						{ key: 'datasetUploadLimit', label: 'Max Project Uploads', sortable: true }
					],
					sort: 'name',
					reverse: true,
					itemsPerPage: 50
				}
			}
		}
	}
</script>

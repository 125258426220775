<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">Create a new tier</h2>

			<admin-tier-form :data="data"></admin-tier-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import AdminTierForm from '@/views/admin/users/tiers/AdminTierForm';

	export default {
		name: 'AdminTierCreate',
		components: {
			AdminTierForm
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				data: {
					queuePriority: "default",
					taskLimitInterval: 1
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				/*this.page.errors = [];
				this.page.loading = true;
				this.tierID = this.$route.params.id;

				try {
					const response = await this.$http.get(`admin/tiers/${this.tierID}`, this.getTokenHeader());
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;*/
			}
		}
	}
</script>

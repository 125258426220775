<template>
	<div>
		<div v-if="$route.name === 'ScenarioList'">
			<h2>Scenarios</h2>

			<b-alert variant="warning" :show="!$parent.withinTierLimits" class="my-3">
				Your account only has access to create and run scenarios with {{$parent.tier.subbasinLimit | number(0)}} subbasins
				and {{$parent.tier.hruLimit | number(0)}} HRUs or fewer.
			</b-alert>

			<grid-view :api-url="apiUrl" delete-api-url="scenarios"
					   :fields="table.fields" link-name
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="scenarios"
					   item-name="scenario"
					   create-route="create" :show-create="$parent.withinTierLimits"
					   extra-delete-message="All files related to this scenario will be removed."></grid-view>
		</div>
		<router-view></router-view>		
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'ScenarioList',
		components: {
			GridView
		},
		data() {
			return {
				apiUrl: `projects/scenarios/${this.$route.params.id}`,
				table: {
					fields: [
						{ key: 'name', sortable: true, class: 'min-w-200' },
						{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
						{ key: 'hasRun', label: 'Ran?', sortable: false, formatter: (value) => { return value === true ? 'Yes' : 'No' } },
						{ key: 'weatherDataset', label: 'Weather', sortable: false },
						{ key: 'startingSimulationDate', label: 'Start', sortable: false, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'endingSimulationDate', label: 'End', sortable: false, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'skipYears', label: 'Skip', sortable: false },
						{ key: 'printSetting', label: 'Print', sortable: false },
						{ key: 'swatVersion', label: 'SWAT', sortable: false },
						{ key: 'delete', label: '', sortable: false, class: 'min' }
					],
					sort: 'lastModifiedDate',
					reverse: true,
					itemsPerPage: 10
				}
			}
		}
	}
</script>

<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">
			<div id="main-panel">
				<div v-if="$route.name == 'DatasetList'" class="container py-5">
					<page-authorization-container :page="page">
						<h2 class="mb-4">My project dataset uploads</h2>

						<p>
							If you checked the box to write SWAT Editor tables on any of your scenario runs
							and modified the project offline, you may upload those changes back into the
							system as a new project dataset.
							After uploading your project, you may create new projects in the system based off
							it, as well as share the project dataset with your groups or request it be made public
							to all users.
							Use your uploaded datasets by selecting it from the drop down list in the
							<router-link to="/projects/create">create project page</router-link>.
						</p>

						<div v-if="!data.hasAccess">
							<b-alert variant="warning" show>
								Your tier does not have access to project uploads.
								If this is a feature you need, visit your <router-link to="/account">account profile</router-link>
								to request access to a higher tier.
							</b-alert>
						</div>
						<div v-else>
							<b-alert variant="warning" :show="data.reachedLimit">
								You have reached the maximum number of project uploads allowed in your tier.
								If you need more uploads, visit your <router-link to="/account">account profile</router-link>
								to request access to a higher tier.
							</b-alert>

							<grid-view api-url="importdatasets"
									   :fields="table.fields"
									   :default-sort="table.sort" :default-reverse="table.reverse"
									   collection-description="uploaded projects"
									   create-route="create" :show-create="!data.reachedLimit"
									   item-name="project dataset"></grid-view>

							<b-alert variant="info" :show="data.uploadLimit < 100" class="my-4">
								You are allowed {{data.uploadLimit | number(0)}} project dataset uploads.
							</b-alert>
						</div>

						<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
							<back-button class="btn btn-secondary mr-2" />
						</fixed-action-bar>
					</page-authorization-container>
				</div>
				<div v-else class="container">
					<b-breadcrumb :items="breadCrumbs" class="no-curves bg-white"></b-breadcrumb>
					<div class="container-fluid pb-4">
						<router-view></router-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DashboardHeader from '@/components/DashboardHeader';
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'DatasetList',
		components: {
			DashboardHeader, GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true },
						{ key: 'attribution', label: 'Credit', sortable: true },
						{ key: 'importedDate', sortable: true, formatter: (value) => { return moment(value).format('llll') } },
						{ key: 'isActive', label: 'Active?', sortable: true, formatter: (value) => { return value ? '✓' : '' }, tdClass: 'text-success font-weight-bold text-center', thClass: 'text-center' },
						{ key: 'status', sortable: false }
					],
					sort: 'name',
					reverse: false,
					itemsPerPage: 20
				},
				data: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('/importdatasets/options', this.getTokenHeader());
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (var i = 0; i < this.$route.matched.length; i++) {
					var item = this.$route.matched[i];
					var name = item.meta.title ? item.meta.title : item.name;

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			}
		}
	}
</script>

<template>
	<div>
		<h2 class="mb-3">Scenarios</h2>

		<grid-view api-url="admin/projects/scenarios" use-filter allow-export export-url="admin/exports/scenarios"
				delete-api-url="scenarios"
				link-user-name
				use-edit-route-pieces :edit-route-pieces="table.editRoutePieces" :edit-route-append="false"
				:fields="table.fields"
				:default-sort="table.sort" :default-reverse="table.reverse"
				collection-description="scenarios"
				item-name="scenario"></grid-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'AdminScenarios',
		components: {
			GridView
		},
		data() {
			return {
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true, class: 'min-w-200' },
						{ key: 'project', sortable: true },
						{ key: 'userName', sortable: true },
						{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
						{ key: 'hasRun', label: 'Ran?', sortable: false, formatter: (value) => { return value === true ? 'Yes' : 'No' } },
						{ key: 'weatherDataset', label: 'Weather', sortable: false },
						{ key: 'startingSimulationDate', label: 'Start', sortable: false, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'endingSimulationDate', label: 'End', sortable: false, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'skipYears', label: 'Skip', sortable: false },
						{ key: 'printSetting', label: 'Print', sortable: false },
						{ key: 'swatVersion', label: 'SWAT', sortable: false },
						{ key: 'delete', label: '', sortable: false, class: 'min' }
					],
					sort: 'lastModifiedDate',
					reverse: true,
					itemsPerPage: 50,
					editRoutePieces: [
						{ type: 'text', value: 'projects' },
						{ type: 'key', value: 'projectID' },
						{ type: 'text', value: 'scenarios' },
						{ type: 'key', value: 'id' }
					]
				}
			}
		}
	}
</script>

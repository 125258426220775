<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">
			<div id="main-panel">
				<div v-if="$route.name == 'GroupList'" class="container py-5">
					<page-authorization-container :page="page">
						<h2>My groups</h2>

						<p>
							Groups are a way to share your projects and scenarios with other users.
						</p>

						<div v-if="invites.length > 0" class="mb-4">
							<router-link to="invites" append class="btn btn-primary">
								<b-badge variant="light">{{invites.length}}</b-badge>
								Pending group invitations
							</router-link>
						</div>

						<grid-view api-url="groups" delete-api-url="groups" delete-if-field="isAdmin"
								   :fields="table.fields" link-name
								   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
								   collection-description="groups"
								   item-name="group" create-route="create" show-create
								   extra-delete-message="No projects or files will be deleted. Access will return to just the creator."></grid-view>

						<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
							<back-button class="btn btn-secondary mr-2" />
						</fixed-action-bar>
					</page-authorization-container>
				</div>
				<div v-else class="container">
					<b-breadcrumb :items="breadCrumbs" class="no-curves bg-white"></b-breadcrumb>
					<div class="container-fluid pb-4">
						<router-view></router-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DashboardHeader from '@/components/DashboardHeader';
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'GroupList',
		components: {
			DashboardHeader, GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				invites: [],
				table: {
					fields: [
						{ key: 'name', sortable: true, class: 'min-w-200' },
						{ key: 'numProjects', label: 'Projects', sortable: true },
						{ key: 'numMembers', label: 'Members', sortable: true },
						{ key: 'numInvitedUsers', label: 'Outstanding Invitations', sortable: true },
						{ key: 'delete', label: '', sortable: false, class: 'min' }
					],
					sort: 'name',
					reverse: false,
					itemsPerPage: 20
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('groups/invites', this.getTokenHeader());
					this.log(response.data);
					this.invites = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (var i = 0; i < this.$route.matched.length; i++) {
					var item = this.$route.matched[i];
					var name = item.meta.title ? item.meta.title : item.name;

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			}
		}
	}
</script>

<template>
	<div>
		<success-alert ref="savedAlert" text="Changes saved." />
		<error-list :errors="page.errors"></error-list>

		<b-form>
			<b-form-group label="Name" :invalid-feedback="requiredFeedback($v.form.name)">
				<b-form-input v-model="form.name" type="text" autofocus :state="getValidState($v.form.name)"></b-form-input>
			</b-form-group>

			<b-form-group label="Path to files (relative to main weather file directory)" :invalid-feedback="requiredFeedback($v.form.pathToFiles)">
				<b-form-input v-model="form.pathToFiles" type="text" :state="getValidState($v.form.pathToFiles)"></b-form-input>
			</b-form-group>

			<b-form-group label="Override dataset path to files (not the path above, but set to change the dataset's Weather Path setting)" :invalid-feedback="requiredFeedback($v.form.overrideDatasetPath)">
				<b-form-input v-model="form.overrideDatasetPath" type="text" :state="getValidState($v.form.overrideDatasetPath)"></b-form-input>
			</b-form-group>

			<b-row>
				<b-col md>
					<b-form-group label="Start date" :invalid-feedback="requiredFeedback($v.form.startDate)">
						<b-form-input v-model="form.startDate" type="date" :state="getValidState($v.form.startDate)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="End date" :invalid-feedback="requiredFeedback($v.form.endDate)">
						<b-form-input v-model="form.endDate" type="date" :state="getValidState($v.form.endDate)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md>
					<b-form-group label="File format" :invalid-feedback="requiredFeedback($v.form.fileFormat)">
						<b-form-select v-model="form.fileFormat" :options="options.fileFormats" :state="getValidState($v.form.fileFormat)"></b-form-select>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="CMIP version (if applicable)" :invalid-feedback="requiredFeedback($v.form.cmipVersion)">
						<b-form-input v-model="form.cmipVersion" type="number" :state="getValidState($v.form.cmipVersion)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-row>
				<b-col md="6">
					<b-form-group label="Sort order" :invalid-feedback="requiredFeedback($v.form.sortOrder)">
						<b-form-input v-model="form.sortOrder" type="number" :state="getValidState($v.form.sortOrder)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>

			<b-form-group>
				<b-checkbox v-model="form.isActive">Is active?</b-checkbox>
			</b-form-group>

			<b-form-group>
				<b-checkbox v-model="form.canUseLowerResolution">Use lower resolution files if not available? (USA HUC only)</b-checkbox>
			</b-form-group>

			<b-form-group>
				<b-checkbox v-model="form.isInSharedLocation">Are the files located in a shared weather directory?</b-checkbox>
			</b-form-group>

			<b-form-group class="mb-4">
				<b-checkbox v-model="form.hasIclusFolder">If CMIP and ICLUS is available, does it have its own ICLUS data folder? (Will use "nocc" ICLUS otherwise)</b-checkbox>
			</b-form-group>

			<p>Available weather files:</p>

			<b-form-group>
				<b-checkbox v-model="form.hasPcp">PCP - Precipitation</b-checkbox>
			</b-form-group>
			<b-form-group>
				<b-checkbox v-model="form.hasTmp">TMP - Temperature</b-checkbox>
			</b-form-group>
			<b-form-group>
				<b-checkbox v-model="form.hasHmd">HMD - Relative humidity</b-checkbox>
			</b-form-group>
			<b-form-group>
				<b-checkbox v-model="form.hasSlr">SLR - Solar radiation</b-checkbox>
			</b-form-group>
			<b-form-group class="mb-4">
				<b-checkbox v-model="form.hasWnd">WND - Wind</b-checkbox>
			</b-form-group>


			<p>The following may be applicable to uploaded datasets:</p>

			<b-row>
				<b-col md>
					<b-form-group label="nrgage" :invalid-feedback="requiredFeedback($v.form.nrgage)">
						<b-form-input v-model="form.nrgage" type="number" :state="getValidState($v.form.nrgage)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="nrtot" :invalid-feedback="requiredFeedback($v.form.nrtot)">
						<b-form-input v-model="form.nrtot" type="number" :state="getValidState($v.form.nrtot)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="nrgfil" :invalid-feedback="requiredFeedback($v.form.nrgfil)">
						<b-form-input v-model="form.nrgfil" type="number" :state="getValidState($v.form.nrgfil)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col md>
					<b-form-group label="ntgage" :invalid-feedback="requiredFeedback($v.form.ntgage)">
						<b-form-input v-model="form.ntgage" type="number" :state="getValidState($v.form.ntgage)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="nttot" :invalid-feedback="requiredFeedback($v.form.nttot)">
						<b-form-input v-model="form.nttot" type="number" :state="getValidState($v.form.nttot)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="ntgfil" :invalid-feedback="requiredFeedback($v.form.ntgfil)">
						<b-form-input v-model="form.ntgfil" type="number" :state="getValidState($v.form.ntgfil)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col md>
					<b-form-group label="nstot" :invalid-feedback="requiredFeedback($v.form.nstot)">
						<b-form-input v-model="form.nstot" type="number" :state="getValidState($v.form.nstot)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="nhtot" :invalid-feedback="requiredFeedback($v.form.nhtot)">
						<b-form-input v-model="form.nhtot" type="number" :state="getValidState($v.form.nhtot)"></b-form-input>
					</b-form-group>
				</b-col>
				<b-col md>
					<b-form-group label="nwtot" :invalid-feedback="requiredFeedback($v.form.nwtot)">
						<b-form-input v-model="form.nwtot" type="number" :state="getValidState($v.form.nwtot)"></b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
		</b-form>

		<fixed-action-bar :cols-lg="10" :offset-lg="2">
			<save-button :saving="page.saving" class="mr-2" @click.native="save" />
			<back-button class="btn btn-secondary mr-2" />
			<b-button v-if="isUpdate && isAuthorized(roleNames.admin)" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
		</fixed-action-bar>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this weather dataset?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'AdminWeatherForm',
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			data: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				options: {
					fileFormats: [
						{ value: null, text: 'Select...' },
						{ value: 1, text: 'HAWQS' },
						{ value: 2, text: 'SWAT' },
						{ value: 3, text: 'Original' }
					]
				},
				form: this.data
			}
		},
		validations: {
			form: {
				name: { required },
				pathToFiles: { required },
				startDate: { required },
				endDate: { required },
				fileFormat: { required },
				isActive: { required },
				cmipVersion: {},
				hasPcp: {},
				hasTmp: {},
				hasHmd: {},
				hasSlr: {},
				hasWnd: {},
				nrgage: {},
				nrtot: {},
				nrgfil: {},
				ntgage: {},
				nttot: {},
				ntgfil: {},
				nstot: {},
				nhtot: {},
				nwtot: {},
				sortOrder: { required },
				hasIclusFolder: {},
				isInSharedLocation: {},
				overrideDatasetPath: {}
			}
		},
		methods: {
			async save() {
				this.page.errors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						if (!this.isUpdate) {
							await this.$http.post('admin/weather', this.form, this.getTokenHeader());
							this.$router.push({ name: 'AdminWeatherList' }).catch(err => { this.log(err); });
						} else {
							await this.$http.put(`admin/weather/${this.form.id}`, this.form, this.getTokenHeader());
							this.$refs.savedAlert.startAlert();
							this.$v.$reset();
						}
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`admin/weather/${this.form.id}`, this.getTokenHeader());
					this.$router.push({ name: 'AdminWeatherList' }).catch(err => { this.log(err); });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Point source</h2>

			<p>
				SWAT directly simulates the loading of water, sediment and other constituents off of land areas in the watershed.
				To simulate the loading of water and pollutants from sources not associated with a land area (e.g. sewage treatment plants, regional groundwater recharge, etc.),
				SWAT allows point source information to be read in at any point along the channel network. The point source loadings may be summarized on a daily, monthly, yearly, or constant basis.
			</p>

			<p>
				Read the <a href="/content/docs/io/ch31_input_meas.pdf" target="_blank">SWAT2012 IO documentation chapter on measured inputs</a>.
				Please note we accept CSV files instead of spaced .dat files described in the documentation.
			</p>

			<div v-html="pageText"></div>

			<b-row>
				<b-col md>
					<h3 class="mb-3">Sample data</h3>
					<p>
						Please format your files like the samples below. Note: you will need to match the dates to your scenario simulation dates. The files below are only an example.
					</p>
					<ul>
						<li><a :href="data.sampleFiles.constant">Constant</a> (default values)</li>
						<li><a :href="data.sampleFiles.daily">Daily sample</a></li>
						<li><a :href="data.sampleFiles.monthly">Monthly sample</a></li>
						<li><a :href="data.sampleFiles.yearly">Yearly sample</a></li>
					</ul>
				</b-col>
				<b-col md>
					<h3 class="mb-3">Uploading guidelines</h3>
					<ul>
						<li>You may mix types; for example, you can have one subbasin with constant data, another two with daily, and one with monthly.</li>
						<li>Do not upload more than one type per subbasin.</li>
						<li>You do not need to upload data for all subbasins in your project.</li>
						<li>Keep the file names as shown in the samples.</li>
					</ul>
				</b-col>
			</b-row>

			<div v-if="data.hasMods">
				<hr class="my-4" />

				<h3 class="my-3">Existing uploaded data</h3>
				<p>
					You have already uploaded custom point source data for this scenario. Download it below.
				</p>
				<p>
					<a :href="data.userDataUrl" class="btn btn-primary">Download</a>
				</p>
			</div>

			<hr class="my-4" />

			<b-form @submit.prevent="save">
				<h3 class="mb-3">Upload data</h3>
				<p>
					Drag a zip file of CSV files into the space below.
					For daily, monthly, and yearly point source data, you will have one file for each subbasin in your project.
					For constant point source data, you will have a single CSV file containing data for each subbasin on a separate row.
				</p>
				<p v-if="data.hasMods">
					<strong>Adding a new file below will replace all existing data.</strong>
				</p>

				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

				<fixed-action-bar>
					<back-button class="btn btn-secondary mr-2" />

					<save-button v-if="data.hasMods" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete your uploaded data?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'EditPointSource',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				dropzoneOptions: {
					url: `/api/scenarios/subbasin/pointsource/${this.$route.params.scenarioID}`,
					headers: this.getTokenHeaderPart(),
					acceptedFiles: '.zip',
					timeout: 300000
				},
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				data: {
					hasMods: false,
					userDataUrl: '',
					sampleFiles: {
						constant: '',
						daily: '',
						monthly: '',
						yearly: ''
					}
				},
				pageText: null
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/subbasin/pointsource/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;

					const response2 = await this.$http.get(`datasets/websitetextpage/PointSource`);
					this.pageText = response2.data.text;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/Ptsrc`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async fileUploaded(file, response) {
				this.log(response);
				this.page.saveErrors = [];
				await this.get();
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			}
		}
	}
</script>

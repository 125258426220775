<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">{{pageName}} page custom text</h2>

			<p>Leave blank to use default text.</p>

			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<b-form @submit.prevent="save">
				<b-form-group>
					<tinymce-editor :api-key="tinymce.api" v-model="data.text" :init="tinymce.init"></tinymce-editor>
				</b-form-group>

				<fixed-action-bar :cols-lg="10" :offset-lg="2">
					<save-button :saving="page.saving" class="mr-2" />
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import Editor from '@tinymce/tinymce-vue';

	export default {
		name: 'AdminWebsiteTextPage',
		components: {
			'tinymce-editor': Editor
		},
		data() {
			return {
				pageName: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				data: {
					text: null
				},
				tinymce: {
					api: 'umc6fcf2lmc4vpzyrqb2jfcr2slzvqb94kk4ac6wl757gx5b',
					init: {
						height: 600,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
						],
						toolbar: 'undo redo | formatselect | bold italic underline | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
					}
				}
			}
		},
		watch: {
			'$route': 'get'
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.pageName = this.$route.params.id;

				try {
					const response = await this.$http.get(`admin/websitetextpage/${this.pageName}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;

					if (this.isNullOrEmpty(this.data.text) && this.pageName === 'Help') this.data.text = this.siteText.helpPageText;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					let data = { value: this.data.text };
					const response = await this.$http.post(`admin/websitetextpage/${this.pageName}`, data, this.getTokenHeader());
					this.log(response.data);
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">			
			<div id="main-panel">
				<div v-if="$route.path == '/help'" class="container py-5">
					<div v-if="siteSettings.useCustomHelpPage">
						<div v-html="pageText"></div>
					</div>
					<div v-else>
						<h2 class="mb-3">Documentation &amp; Support</h2>

						<div v-html="pageText"></div>

						<div v-if="tiers.length > 0">
							<h3 class="mt-4">{{siteText.siteName}} User Tiers</h3>
							<p>
								All users are placed in <b>Tier I</b> upon registration.
								To access a higher tier, <router-link to="/account">visit your account profile</router-link>
								and click the Tier Limitations tab.
							</p>
							<table class="table table-striped table-responsive">
								<thead>
									<tr>
										<th>Tier</th>
										<th>Max. Subbasins</th>
										<th>Max. HRUs</th>
										<th>Run Frequency</th>
										<th>Run Concurrently</th>
										<th>Queue Priority</th>
										<th>Max. Project Uploads</th>
										<th>Dataset Access</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(tier, i) in tiers" :key="i">
										<td>{{tier.name}}</td>
										<td>{{tier.subbasinLimit === 0 ? 'unlimited' : numberWithCommas(tier.subbasinLimit)}}</td>
										<td>{{tier.hruLimit === 0 ? 'unlimited' : numberWithCommas(tier.hruLimit)}}</td>
										<td>{{tier.taskLimit}}</td>
										<td>{{tier.concurrencyLimit === 0 ? 'unlimited' : numberWithCommas(tier.concurrencyLimit)}}</td>
										<td>{{tier.queuePriority}}</td>
										<td>{{tier.datasetUploadLimit | number(0)}}</td>
										<td>{{tier.datasets.join(', ')}}</td>
									</tr>
								</tbody>
							</table>
						</div>

						<h3 class="mt-4">{{siteText.siteName}} Run Time Guide</h3>

						<p>
							The table below gives an estimate of the time it takes to run a scenario in {{siteText.siteName}}.
							The times below are subject to fluctuation based on number of concurrent users and projects running on {{siteText.siteName}} at any given time.
						</p>

						<div class="table-responsive">
							<table class="table table-striped table-bordered">
								<thead>
									<tr>
										<th rowspan="2">Number of HRUs</th>
										<th colspan="4" class="text-center">Modeled Time Frame</th>
									</tr>
									<tr>
										<th>5 Years</th>
										<th>10 Years</th>
										<th>20 Years</th>
										<th>30 Years</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>2,500</td>
										<td>8m</td>
										<td>9m</td>
										<td>11m</td>
										<td>13m</td>
									</tr>
									<tr>
										<td>5,000</td>
										<td>15m</td>
										<td>20m</td>
										<td>29m</td>
										<td>39m</td>
									</tr>
									<tr>
										<td>25,000</td>
										<td>1h 29m</td>
										<td>2h 9m</td>
										<td>3h 7m</td>
										<td>4h 29m</td>
									</tr>
									<tr>
										<td>50,000</td>
										<td>4h 11m</td>
										<td>6h 9m</td>
										<td>9h 29m</td>
										<td>13h 38m</td>
									</tr>
									<tr>
										<td>75,000</td>
										<td>7h 29m</td>
										<td>10h 47m</td>
										<td>19h 42m</td>
										<td>1d 3h 55m</td>
									</tr>
									<tr>
										<td>100,000</td>
										<td>10h 51m</td>
										<td>19h 7m</td>
										<td>1d 8h 11m</td>
										<td>1d 20h 26m</td>
									</tr>
									<tr>
										<td>125,000</td>
										<td>17h 27m</td>
										<td>1d 3h 44m</td>
										<td>2d 16m</td>
										<td>2d 19h 38m</td>
									</tr>
									<tr>
										<td>150,000</td>
										<td>1d 6h 4m</td>
										<td>1d 17h 9m</td>
										<td>3d 2h 26m</td>
										<td>4d 13h 30m</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<router-view></router-view>
			</div>
		</div>

		<footer-data></footer-data>
	</div>
</template>

<script>
	import DashboardHeader from '@/components/DashboardHeader';
	import FooterData from '@/components/FooterData';

	export default {
		name: 'Help',
		components: {
			DashboardHeader, FooterData
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false
				},
				pageText: null,
				tiers: []
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`datasets/websitetextpage/Help`);
					this.pageText = response.data.text;
					if (this.isNullOrEmpty(this.pageText)) this.pageText = this.siteText.helpPageText;

					const response2 = await this.$http.get(`account/alltiers`);
					this.log(response2.data);
					this.tiers = response2.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>

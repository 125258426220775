<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">Manually add new user</h2>

			<p>
				It is strongly recommended for the user to register via the home page. However, you may add a user below manually.
				The user will not need to confirm their email and they will already be registered.
				You should inform the user of their user name and email address, and instruct them to use the "Forgot Password" link under the
				log-in form when possible. Otherwise, send them their password and notify that they should change it after logging in.
			</p>

			<success-alert ref="savedAlert" text="User account created." />

			<b-form @submit.prevent="register" class="my-4" v-if="!registration.submitted">
				<b-row>
					<b-col md>
						<b-form-group label="First name" :invalid-feedback="requiredFeedback($v.registration.form.firstName)">
							<b-form-input v-model="registration.form.firstName" type="text" autofocus :state="getValidState($v.registration.form.firstName)"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Last name" :invalid-feedback="requiredFeedback($v.registration.form.lastName)">
							<b-form-input v-model="registration.form.lastName" type="text" :state="getValidState($v.registration.form.lastName)"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col md>
						<b-form-group label="Email address">
							<b-form-input v-model="registration.form.email" type="email" :state="getValidState($v.registration.form.email)"></b-form-input>
							<b-form-invalid-feedback v-if="!$v.registration.form.email.required">Required</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="!$v.registration.form.email.email">Please enter a valid email address</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Confirm your email">
							<b-form-input v-model="registration.form.compareEmail" type="email" :state="getValidState($v.registration.form.compareEmail)"></b-form-input>
							<b-form-invalid-feedback v-if="!$v.registration.form.compareEmail.required">Required</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="!$v.registration.form.compareEmail.email">Please enter a valid email address</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="!$v.registration.form.compareEmail.sameAsEmail">Email addresses must match</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
				</b-row>

				<b-form-group label="Company or organization" :invalid-feedback="requiredFeedback($v.registration.form.affiliation)">
					<b-form-input v-model="registration.form.affiliation" type="text" :state="getValidState($v.registration.form.affiliation)"></b-form-input>
				</b-form-group>

				<b-form-group label="Category" :invalid-feedback="requiredFeedback($v.registration.form.organizationCategory)">
					<b-form-select v-model="registration.form.organizationCategory" :options="location.organizationCategories" :state="getValidState($v.registration.form.organizationCategory)">
						<template v-slot:first>
							<option :value="null" disabled>-- Select a category --</option>
						</template>
					</b-form-select>
				</b-form-group>

				<b-form-group :label="`What is your reason for trying ${siteText.siteName}?`" :invalid-feedback="requiredFeedback($v.registration.form.reasonForUse)">
					<b-form-input v-model="registration.form.reasonForUse" type="text" :state="getValidState($v.registration.form.reasonForUse)"></b-form-input>
				</b-form-group>

				<b-form-group label="In what country are you located?" :invalid-feedback="requiredFeedback($v.registration.form.country)">
					<b-form-select v-model="registration.form.country" :options="location.countries" :state="getValidState($v.registration.form.country)">
						<template v-slot:first>
							<option :value="null" disabled>-- Select a country --</option>
						</template>
					</b-form-select>
				</b-form-group>

				<b-form-group v-if="registration.form.country === 'United States'" label="Select your state or territory" :invalid-feedback="requiredFeedback($v.registration.form.state)">
					<b-form-select v-model="registration.form.state" :options="location.states" :state="getValidState($v.registration.form.state)">
						<template v-slot:first>
							<option :value="null" disabled>-- Select a state or territory --</option>
						</template>
					</b-form-select>
				</b-form-group>

				<hr class="my-3" />

				<b-form-group label="Enter a user name" :invalid-feedback="requiredFeedback($v.registration.form.userName)">
					<b-form-input v-model="registration.form.userName" type="text" :state="getValidState($v.registration.form.userName)"></b-form-input>
				</b-form-group>
				<b-row>
					<b-col md>
						<b-form-group label="Password" :description="globals.passwordRequirements">
							<b-form-input v-model="registration.form.password" type="password" :state="getValidState($v.registration.form.password)"></b-form-input>
							<b-form-invalid-feedback v-if="!$v.registration.form.password.required">Required</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="!$v.registration.form.password.strongPassword">{{globals.passwordRequirements}}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Confirm your password">
							<b-form-input v-model="registration.form.comparePassword" type="password" :state="getValidState($v.registration.form.comparePassword)"></b-form-input>
							<b-form-invalid-feedback v-if="!$v.registration.form.comparePassword.required">Required</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="!$v.registration.form.comparePassword.sameAsPassword">Passwords must match</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="registration.saving" text="Create account" @click.native="register" />
			</fixed-action-bar>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators';

	export default {
		name: 'AdminUserCreate',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				registration: {
					form: {
						firstName: '',
						lastName: '',
						email: '',
						compareEmail: '',
						userName: '',
						password: '',
						comparePassword: '',
						country: 'United States',
						state: null,
						affiliation: '',
						organizationCategory: null,
						reasonForUse: ''
					},
					errors: [],
					saving: false,
					submitted: false,
					recaptchaToken: ''
				},
				location: {
					countries: [],
					states: [],
					organizationCategories: []
				}
			}
		},
		validations: {
			registration: {
				form: {
					firstName: { required },
					lastName: { required },
					email: { required, email },
					compareEmail: { required, email, sameAsEmail: sameAs('email') },
					userName: { required },
					password: {
						required,
						strongPassword(password) {
							return (
								/[a-z]/.test(password) && // checks for a-z
								/[0-9]/.test(password) && // checks for 0-9
								/\W|_/.test(password) && // checks for special char
								password.length >= 10
							);
						}
					},
					comparePassword: { required, sameAsPassword: sameAs('password') },
					country: { required },
					state: { required: requiredIf(function () { return this.registration.form.country == 'United States'; }) },
					organizationCategory: { required },
					affiliation: { required },
					reasonForUse: { required }
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('account/locationdata', this.getTokenHeader());
					this.location = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async register() {
				this.registration.errors = [];
				this.registration.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.registration.errors.push('Please fix the errors below and try again.');
				} else {
					var data = {
						firstName: this.registration.form.firstName,
						lastName: this.registration.form.lastName,
						email: this.registration.form.email,
						userName: this.registration.form.userName,
						password: this.registration.form.password,
						country: this.registration.form.country,
						state: this.registration.form.state,
						organizationCategory: this.registration.form.organizationCategory,
						affiliation: this.registration.form.affiliation,
						reasonForUse: this.registration.form.reasonForUse
					};

					try {
						await this.$http.post('admin/users', data, this.getTokenHeader());
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						this.registration.errors = this.logError(error);
					}
				}

				this.registration.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">{{group.name}}</h2>

			<p>
				{{group.description}}
			</p>

			<h3 class="mt-4 mb-3">Group Projects</h3>

			<grid-view :api-url="`groups/projects/${groupID}`"
					   :fields="tables.projects.fields"
					   :default-sort="tables.projects.sort" :default-reverse="tables.projects.reverse" :default-per-page="tables.itemsPerPage"
					   collection-description="group projects"
					   item-name="project"></grid-view>

			<h3 class="mt-4 mb-3" id="membersTop">Group Members</h3>

			<grid-view :api-url="`groups/members/${groupID}`"
					   :fields="tables.members.fields"
					   :selectable="group.isAdmin" @selectionChanged="memberSelectionChanged"
					   :default-sort="tables.members.sort" :default-reverse="tables.members.reverse" :default-per-page="tables.itemsPerPage"
					   collection-description="group members"
					   item-name="member"></grid-view>

			<div v-if="group.invitedUsers.length > 0">
				<h3 class="mt-4 mb-3">Pending Invitations</h3>

				<b-table striped responsive class="nowrap-headers mb-3 border-bottom"
						 :items="group.invitedUsers" :fields="tables.invites.fields">
				</b-table>
			</div>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</page-authorization-container>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'GroupView',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				groupID: this.$route.params.id,
				group: {
					invitedUsers: []
				},
				tables: {
					projects: {
						fields: [
							{ key: 'project', sortable: true, class: 'min-w-200' },
							{ key: 'userName', label: 'Belongs to', sortable: true },
							{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
							{ key: 'dataset', sortable: true },
							{ key: 'endingSubbasin', label: 'Downstream ID', sortable: true },
							{ key: 'numSubbasins', label: 'Subbasins', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
							{ key: 'numHrus', label: 'HRUs', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
							{ key: 'totalArea', label: 'Area (sq. km.)', sortable: false, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
							{ key: 'numScenarios', label: 'Scenarios', sortable: false }
						],
						sort: 'lastModifiedDate',
						reverse: true
					},
					members: {
						fields: [
							{ key: 'userName', sortable: true },
							{ key: 'email', sortable: true },
							{ key: 'firstName', sortable: true },
							{ key: 'lastName', sortable: true },
							{ key: 'affiliation', sortable: true },
							{ key: 'isAdmin', label: 'Group Admin?', sortable: false, formatter: (value) => { return value === true ? 'Yes' : 'No' } }
						],
						sort: 'userName',
						reverse: false
					},
					invites: {
						fields: [
							{ key: 'userName', sortable: true },
							{ key: 'email', sortable: true },
							{ key: 'firstName', sortable: true },
							{ key: 'lastName', sortable: true },
							{ key: 'affiliation', sortable: true }
						],
						sort: 'userName',
						reverse: false
					},
					itemsPerPage: 10
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.groupID = this.$route.params.id;
				this.page.errors = [];
				this.page.loading = true;
				this.selectedMember = null;

				try {
					const response = await this.$http.get(`groups/${this.groupID}`, this.getTokenHeader());
					this.group = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<login-form v-if="!isAuthenticated || page.showLogin" show-header></login-form>
			<div v-else-if="!isAuthorized(roleNames.registered)">
				<b-alert variant="warning" show>
					Your account is pending approval. You will receive a notification by email when it is approved.
				</b-alert>
			</div>
			<div v-else-if="requireAdmin && !(isAuthorized(roleNames.admin) || isAuthorized(roleNames.partialAdmin))">
				<b-alert variant="warning" show>
					You are not authorized to view this page.
				</b-alert>
			</div>
			<error-list v-else-if="!showErrorsWithObject && page.errors.length > 0" :errors="page.errors"></error-list>
			<div v-else>
				<error-list v-if="showErrorsWithObject" :errors="page.errors" :no-bottom-marg="noBottomMarg"></error-list>
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PageAuthorizationContainer',
		props: {
			page: {
				type: Object,
				required: true
			},
			showErrorsWithObject: {
				type: Boolean,
				default: false
			},
			requireAdmin: {
				type: Boolean,
				default: false
			},
			noBottomMarg: {
				type: Boolean,
				default: false
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Sediment routing method</h2>

			<p>
				Read the <a href="/content/docs/io/ch25_input_rte.pdf" target="_blank">SWAT2012 IO documentation chapter on main channel inputs</a>, page 9 for sediment routing methods (CH_EQN).
			</p>

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<b-form-group>
					<b-form-select v-model.trim="$v.chEqn.$model" :options="options" :state="getValidState($v.chEqn)"></b-form-select>
				</b-form-group>

				<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange" subbasin-only></hru-selector>

				<scenario-mod-viewer v-if="mods.length > 0" @deleted="modDeleted" :data="mods" value-label="CH_EQN"></scenario-mod-viewer>

				<fixed-action-bar>
					<span id="save-button-tooltip">
						<save-button :saving="page.saving" :disabled="!validHruSelection || !$v.$anyDirty" class="mr-2" />
					</span>
					<b-tooltip :disabled="validHruSelection" target="save-button-tooltip">
						Select at least one subbasin.
					</b-tooltip>
					<back-button class="btn btn-secondary mr-2" />

					<save-button v-if="mods.length > 0" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete all curve number data changes?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';
	import HruSelector from '@/components/HruSelector';
	import ScenarioModViewer from '@/components/ScenarioModViewer';

	export default {
		name: 'EditSedimentRouting',
		components: {
			HruSelector, ScenarioModViewer
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				options: [
					{ value: 0, text: '0 - Simplified Bagnold Equation (Default)' },
					{ value: 1, text: '1 - Simplified Bagnold Equation by each particle size' },
					{ value: 2, text: '2 - Kodatie model' },
					{ value: 3, text: '3 - Molinas and Wu model' },
					{ value: 4, text: '4 - Yang sand and gravel model' }
				],
				hruSelection: {
					applyToAll: true,
					subbasins: [],
					landuse: [],
					soils: [],
					slopes: []
				},
				chEqn: 0,
				mods: []
			}
		},
		validations: {
			chEqn: { required }
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/subbasin/sedimentrouting/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.mods = response.data.mods;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							applyToAll: this.hruSelection.applyToAll,
							subbasins: this.hruSelection.subbasins,
							landuses: this.hruSelection.landuse,
							soils: this.hruSelection.soils,
							slopes: this.hruSelection.slopes,
							modificationObject: {
								value: this.chEqn
							}
						};
						const response = await this.$http.post(`scenarios/subbasin/save/${this.scenarioID}/RteChEqn`, data, this.getTokenHeader());
						this.log(response.data);
						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/RteChEqn`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			hruSelectionChange(selection) {
				this.hruSelection = selection;
			}
		},
		computed: {
			validHruSelection() {
				return this.hruSelection.applyToAll ||
					(this.hruSelection.subbasins.length > 0);
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">Edit SWAT version</h2>

			<admin-swat-version-form is-update :data="data"></admin-swat-version-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import AdminSwatVersionForm from '@/views/admin/datasets/swatversions/AdminSwatVersionForm';

	export default {
		name: 'AdminSwatVersionView',
		components: {
			AdminSwatVersionForm
		},
		data() {
			return {
				versionID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				data: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.versionID = this.$route.params.id;

				try {
					const response = await this.$http.get(`admin/swatversions/${this.versionID}`, this.getTokenHeader());
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>

<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">
			<div id="main-panel">
				<div v-if="$route.name == 'AccountProfile'" class="container py-5">
					<page-authorization-container :page="page">
						<h2 class="mb-4">Account information for {{profile.userName}}</h2>

						<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
							<b-tab title="Profile">
								<error-list :errors="page.profile.errors"></error-list>
								<success-alert ref="profileSavedAlert" text="Changes saved." />

								<b-form @submit.prevent="saveProfile">
									<b-row>
										<b-col md>
											<b-form-group label="First name" :invalid-feedback="requiredFeedback($v.profile.firstName)">
												<b-form-input v-model="profile.firstName" type="text" autofocus :state="getValidState($v.profile.firstName)"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col md>
											<b-form-group label="Last name" :invalid-feedback="requiredFeedback($v.profile.lastName)">
												<b-form-input v-model="profile.lastName" type="text" :state="getValidState($v.profile.lastName)"></b-form-input>
											</b-form-group>
										</b-col>
									</b-row>

									<b-form-group label="Company or organization" :invalid-feedback="requiredFeedback($v.profile.affiliation)">
										<b-form-input v-model="profile.affiliation" type="text" :state="getValidState($v.profile.affiliation)"></b-form-input>
									</b-form-group>

									<b-form-group label="Category" :invalid-feedback="requiredFeedback($v.profile.organizationCategory)">
										<b-form-select v-model="profile.organizationCategory" :options="location.organizationCategories" :state="getValidState($v.profile.organizationCategory)"></b-form-select>
									</b-form-group>

									<b-form-group :label="`What is your reason for trying ${siteText.siteName}?`" :invalid-feedback="requiredFeedback($v.profile.reasonForUse)">
										<b-form-input v-model="profile.reasonForUse" type="text" :state="getValidState($v.profile.reasonForUse)"></b-form-input>
									</b-form-group>

									<b-form-group label="In what country are you located?" :invalid-feedback="requiredFeedback($v.profile.country)">
										<b-form-select v-model="profile.country" :options="location.countries" :state="getValidState($v.profile.country)"></b-form-select>
									</b-form-group>

									<b-form-group v-if="profile.country === 'United States'" label="Select your state or territory" :invalid-feedback="requiredFeedback($v.profile.state)">
										<b-form-select v-model="profile.state" :options="location.states" :state="getValidState($v.profile.state)"></b-form-select>
									</b-form-group>

									<hr class="my-3" />

									<b-form-group label="Units preference" :invalid-feedback="requiredFeedback($v.profile.units)">
										<b-form-select v-model="profile.units" :options="options.units" :state="getValidState($v.profile.units)"></b-form-select>
									</b-form-group>

									<div class="mt-4">
										<save-button :saving="page.profile.saving" />
									</div>
								</b-form>
							</b-tab>

							<b-tab title="Change email">
								<p>
									The current email address on your account is
									<strong>{{profile.email}}</strong>.
								</p>
								<p>
									To change it, enter a valid and working email address below.
									We will send a confirmation email to the new address.
								</p>

								<error-list :errors="page.email.errors"></error-list>

								<b-alert variant="warning" :show="page.email.submitted">
									A verification email has been sent to <strong>{{email.address}}</strong>.
									Please check your email and follow the instructions to confirm the change.
								</b-alert>

								<b-form v-if="!page.email.submitted" @submit.prevent="saveEmail">
									<b-form-group label="New email address">
										<b-form-input v-model="email.address" type="email" :state="getValidState($v.email.address)"></b-form-input>
										<b-form-invalid-feedback v-if="!$v.email.address.required">Required</b-form-invalid-feedback>
										<b-form-invalid-feedback v-if="!$v.email.address.email">Please enter a valid email address</b-form-invalid-feedback>
									</b-form-group>

									<b-form-group label="Confirm new email">
										<b-form-input v-model="email.confirm" type="email" :state="getValidState($v.email.confirm)"></b-form-input>
										<b-form-invalid-feedback v-if="!$v.email.confirm.required">Required</b-form-invalid-feedback>
										<b-form-invalid-feedback v-if="!$v.email.confirm.email">Please enter a valid email address</b-form-invalid-feedback>
										<b-form-invalid-feedback v-if="!$v.email.confirm.sameAsEmail">Email addresses must match</b-form-invalid-feedback>
									</b-form-group>

									<div class="mt-4">
										<save-button :saving="page.email.saving" />
									</div>
								</b-form>
							</b-tab>

							<b-tab title="Change password">
								<error-list :errors="page.password.errors"></error-list>
								<success-alert ref="passwordSavedAlert" text="Changes saved." />

								<b-form @submit.prevent="savePassword">
									<b-form-group label="Current password">
										<b-form-input v-model="password.old" type="password" :state="getValidState($v.password.old)"></b-form-input>
										<b-form-invalid-feedback v-if="!$v.password.old.required">Required</b-form-invalid-feedback>
									</b-form-group>

									<b-form-group label="New password" :description="globals.passwordRequirements">
										<b-form-input v-model="password.new" type="password" :state="getValidState($v.password.new)"></b-form-input>
										<b-form-invalid-feedback v-if="!$v.password.new.required">Required</b-form-invalid-feedback>
										<b-form-invalid-feedback v-if="!$v.password.new.strongPassword">{{globals.passwordRequirements}}</b-form-invalid-feedback>
									</b-form-group>

									<b-form-group label="Confirm new password">
										<b-form-input v-model="password.confirm" type="password" :state="getValidState($v.password.confirm)"></b-form-input>
										<b-form-invalid-feedback v-if="!$v.password.confirm.required">Required</b-form-invalid-feedback>
										<b-form-invalid-feedback v-if="!$v.password.confirm.sameAsPassword">Passwords must match</b-form-invalid-feedback>
									</b-form-group>

									<div class="mt-4">
										<save-button :saving="page.password.saving" />
									</div>
								</b-form>
							</b-tab>

							<b-tab title="Tier Limitations">
								<p>
									Your account is a member of
									<strong>{{tier.name}} - {{tier.description}}</strong>.
									<span v-if="!isNullOrEmpty(tier.expirationDate)">
										Access to this tier expires on
										<span class="text-danger">{{tier.expirationDate | date('M/D/YYYY')}}</span>.
									</span>
								</p>

								<p>
									This tier is subject to the following project and scenario run limitations:
								</p>

								<table class="table table-striped border-bottom table-responsive">
									<thead>
										<tr>
											<th>Max. Subbasins</th>
											<th>Max. HRUs</th>
											<th>Run Frequency</th>
											<th>Run Concurrently</th>
											<th>Queue Priority</th>
											<th>Max. Project Uploads</th>
											<th>Dataset Access</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{{tier.subbasinLimit === 0 ? 'unlimited' : numberWithCommas(tier.subbasinLimit)}}</td>
											<td>{{tier.hruLimit === 0 ? 'unlimited' : numberWithCommas(tier.hruLimit)}}</td>
											<td>{{tier.taskLimit}}</td>
											<td>{{tier.concurrencyLimit === 0 ? 'unlimited' : numberWithCommas(tier.concurrencyLimit)}}</td>
											<td>{{tier.queuePriority}}</td>
											<td>{{tier.datasetUploadLimit | number(0)}}</td>
											<td>{{tier.datasets.join(', ')}}</td>
										</tr>
									</tbody>
								</table>

								<p class="mt-4">
									<strong>You may request access to another tier by filling out the form below.</strong>
									Please describe your projects and how access to a less restrictive tier will benefit your work.
									An administrator will follow up with you via email as soon as possible.
								</p>

								<error-list :errors="page.tier.errors"></error-list>
								<success-alert ref="tierRequestAlert" text="Your request has been sent." />

								<b-form @submit.prevent="requestTier" v-if="!page.tier.sent">
									<b-form-group label="Reasons for request:">
										<b-form-textarea v-model="tierRequest.value" rows="5" :state="getValidState($v.tierRequest.value)"></b-form-textarea>
										<b-form-invalid-feedback v-if="!$v.tierRequest.value.required">Required</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group label="Please estimate how long you need access to the tier:" :invalid-feedback="requiredFeedback($v.tierRequest.time)">
										<b-form-input v-model="tierRequest.time" type="text" :state="getValidState($v.tierRequest.time)"></b-form-input>
									</b-form-group>

									<div class="mt-4">
										<save-button :saving="page.tier.saving" text="Send Request" />
									</div>
								</b-form>
							</b-tab>

							<b-tab title="Delete account" title-link-class="text-danger">
								<p>
									Are you sure you want to delete your account?
									This action is <strong>permanent and cannot be undone</strong>.
									All projects and files will be removed.
								</p>

								<error-list :errors="page.deleteAccount.errors"></error-list>

								<b-form @submit.prevent="confirmDelete">
									<b-form-group label="Type DELETE to confirm">
										<b-form-input v-model="deleteAccount.value" type="text" :state="getValidState($v.deleteAccount.value)"></b-form-input>
										<b-form-invalid-feedback v-if="!$v.deleteAccount.value.required">Required</b-form-invalid-feedback>
										<b-form-invalid-feedback v-if="deleteAccount.value !== 'DELETE'">You must type DELETE</b-form-invalid-feedback>
									</b-form-group>

									<div class="mt-4">
										<save-button :saving="page.deleteAccount.saving"
													 variant="danger" text="Delete Account"
													 :disabled="deleteAccount.value !== 'DELETE'" />
									</div>
								</b-form>
							</b-tab>
						</b-tabs>

						<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
							<back-button class="btn btn-secondary mr-2" />
						</fixed-action-bar>
					</page-authorization-container>
				</div>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators';
	import DashboardHeader from '@/components/DashboardHeader';

	export default {
		name: 'AccountProfile',
		components: {
			DashboardHeader
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					profile: {
						errors: [],
						saving: false
					},
					email: {
						errors: [],
						saving: false,
						submitted: false
					},
					password: {
						errors: [],
						saving: false
					},
					deleteAccount: {
						errors: [],
						saving: false
					},
					tier: {
						errors: [],
						saving: false,
						sent: false
					}
				},
				tabIndex: 0,
				options: {
					units: [
						{ value: 0, text: 'Metric' },
						{ value: 1, text: 'Imperial' }
					]
				},
				profile: {},
				email: {
					address: '',
					confirm: ''
				},
				password: {
					old: '',
					new: '',
					confirm: ''
				},
				deleteAccount: {
					value: ''
				},
				tierRequest: {
					value: '',
					time: ''
				},
				tier: {
					name: '',
					description: '',
					queuePriority: '',
					subbasinLimit: 0,
					hruLimit: 0,
					taskLimit: '',
					concurrencyLimit: 0
				},
				location: {
					countries: [],
					states: [],
					organizationCategories: []
				}
			}
		},
		validations: {
			profile: {
				firstName: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				lastName: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				country: { required: requiredIf(function () { return this.tabIndex == 0; }) },
				state: { required: requiredIf(function () { return this.tabIndex == 0 && this.profile.country == 'United States'; }) },
				organizationCategory: { required: requiredIf(function () { return this.tabIndex == 0; }) },
				affiliation: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				reasonForUse: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				units: { required: requiredIf(function(){ return this.tabIndex == 0; }) }
			},
			email: {
				address: {
					email,
					required: requiredIf(function(){ return this.tabIndex == 1; })
				},
				confirm: {
					email,
					sameAsEmail: sameAs('address'),
					required: requiredIf(function(){ return this.tabIndex == 1; })
				}
			},
			password: {
				old: { required: requiredIf(function(){ return this.tabIndex == 2; }) },
				new: {
					strongPassword(password) {
						return (
							this.tabIndex != 2 || (
							/[a-z]/.test(password) && // checks for a-z
							/[0-9]/.test(password) && // checks for 0-9
							/\W|_/.test(password) && // checks for special char
							password.length >= 10)
						);
					},
					required: requiredIf(function(){ return this.tabIndex == 2; })
				},
				confirm: {
					sameAsPassword: sameAs('new'),
					required: requiredIf(function(){ return this.tabIndex == 2; })
				}
			},
			tierRequest: {
				value: { required: requiredIf(function () { return this.tabIndex == 3; }) },
				time: { required: requiredIf(function () { return this.tabIndex == 3; }) }
			},
			deleteAccount: {
				value: { required: requiredIf(function(){ return this.tabIndex == 4; }) }
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				if (this.$route.name == 'AccountProfile') {
					this.page.errors = [];
					this.page.loading = true;

					try {
						const response = await this.$http.get('account/profile', this.getTokenHeader());
						this.log(response.data);
						this.profile = response.data.profile;
						this.tier = response.data.tier;
						this.location = response.data.location;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				}
			},
			async saveProfile() {
				this.page.profile.errors = [];
				this.page.profile.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.profile.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						await this.$http.post('account/profile', this.profile, this.getTokenHeader());
						this.$refs.profileSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.profile.errors = this.logError(error);
					}
				}

				this.page.profile.saving = false;
			},
			async saveEmail() {
				this.page.email.errors = [];
				this.page.email.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.email.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('account/changeemail', { value: this.email.address }, this.getTokenHeader());
						this.$v.$reset();
						this.page.email.submitted = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.email.errors = this.logError(error);
					}
				}

				this.page.email.saving = false;
			},
			async savePassword() {
				this.page.password.errors = [];
				this.page.password.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.password.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							oldPassword: this.password.old,
							newPassword: this.password.new
						};
						const response = await this.$http.post('account/changepassword', data, this.getTokenHeader());
						this.$refs.passwordSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.password.errors = this.logError(error);
					}
				}

				this.page.password.saving = false;
			},
			async confirmDelete() {
				this.page.deleteAccount.errors = [];
				this.page.deleteAccount.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.deleteAccount.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.delete('account/delete', this.getTokenHeader());
						this.logout();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteAccount.errors = this.logError(error);
					}
				}

				this.page.deleteAccount.saving = false;
			},
			async requestTier() {
				this.page.tier.errors = [];
				this.page.tier.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.tier.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('account/tierrequest', this.tierRequest, this.getTokenHeader());
						this.page.tier.sent = true;
						this.$refs.tierRequestAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.tier.errors = this.logError(error);
					}
				}

				this.page.tier.saving = false;
			}
		}
	}
</script>

import Vue from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';

Vue.mixin({
	data() {
		return {
			roleNames: {
				pending: 'Pending',
				registered: 'Registered',
				admin: 'Admin',
				partialAdmin: 'PartialAdmin',
				apiUser: 'ApiUser'
			},
			featureNames: {
				batchProjects: 'BatchProjects',
				regionalProjects: 'RegionalProjects'
			},
			globals: {
				mapColors: {
					default: {
						subbasin: '#e4fabe',
						downstream: '#b6dd6c',
						nearby: '#fff1d6',
						stream: '#006da0',
						selected: '#6fedda',
						highContrast: false
					},
					highContrast: {
						subbasin: '#FFB061',
						downstream: '#FF8000',
						nearby: '#fff1d6',
						stream: '#000FFF',
						selected: '#6fedda',
						highContrast: true
					}
				},
				passwordRequirements: 'Minimum 10 characters with at least one number, upper and lowercase letter, and special character',
				maxSubbasinsForMap: 2000
			}
		}
	},
	watch: {
		'$route'() {
			var storedToken = localStorage.getItem('auth_token');
			if (!storedToken || (storedToken !== this.token)) this.$store.commit('logout');
		}
	},
	computed: {
		...mapGetters(['isAuthenticated', 'user', 'roles', 'token', 'siteText', 'siteSettings', 'mapColors', 'rememberMeToken']),
		localStorageToken() {
			return localStorage.getItem('auth_token');
		},
		getValidState() {
			return (prop) => {
				return prop.$dirty ? !prop.$error : null;
			}
		},
		requiredFeedback() {
			return (prop) => {
				if (!prop.required) return 'Required';
			}
		},
		defaultMapBounds() {
			if (this.isNullOrEmpty(this.siteSettings.customMapBounds) || this.siteSettings.customMapBounds.length < 1) return [39.8282, -98.5795];
			return this.siteSettings.customMapBounds;
		},
		defaultMapZoom() {
			if (this.isNullOrEmpty(this.siteSettings.customMapZoom) || this.siteSettings.customMapZoom === 0) return 5;
			return this.siteSettings.customMapZoom;
		},
		defaultMapTileProviders() {
			if (this.isNullOrEmpty(this.siteSettings.customTileProviders) || this.siteSettings.customTileProviders.length < 1) {
				return [
					{
						name: 'Default map',
						visible: true,
						attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
						url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
					},
					{
						name: 'Topography map',
						visible: false,
						url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
						attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
					},
					{
						name: 'Satellite map',
						visible: false,
						url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
						attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
					}
				];
			}
			return this.siteSettings.customTileProviders;
		},
		generalBreadCrumbs() {
			var crumbs = [];
			for (var i = 0; i < this.$route.matched.length; i++) {
				var item = this.$route.matched[i];
				var name = item.meta.title ? item.meta.title : item.name;

				crumbs.push({
					text: name,
					to: { name: item.name }
				});
			}
			return crumbs;
		}
	},
	methods: {
		isDevMode() {
			return process.env.NODE_ENV === 'development';
		},
		isNullOrEmpty(value) {
			return value === undefined || value == null || value == '';
		},
		capitalizeFirstLette(s) {
			return s.charAt(0).toUpperCase() + s.slice(1);
		},
		numberWithCommas(x) {
			var parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		},
		toDate(value, format = 'llll') {
			if (value) {
				return moment(String(value)).format(format);
			}
		},
		log(message) {
			if (this.isDevMode()) {
				console.log(message);
			}
		},
		logError(error, defaultMessage = undefined) {
			let messages = [];
			if (defaultMessage) {
				messages.push(defaultMessage);
			}

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log(error.response.data);
				//console.log(error.response.status);
				//console.log(error.response.headers);

				if (error.response.status === 401 || error.response.status === 403) {
					messages.push('Invalid credentials. Either your user name or password is incorrect, or you do not have permission to view this page.');
				} else if (error.response.status === 404) {
					messages.push('Requested data not found. Please check the URL in your browser and make sure you have the correct ID numbers if any.');
				} else if (error.response.data && error.response.data.errors) {
					let errors = error.response.data.errors;
					for (let key in errors) {
						for (let j = 0; j < errors[key].length; j++) {
							messages.push(errors[key][j]);
						}
					}
				} else if (error.response.data) {
					if (typeof error.response.data === 'string' || error.response.data instanceof String)
						messages.push(error.response.data);
					else {
						try {
							let errors = Object.keys(error.response.data);
							for (let key of errors) {
								for (let val of error.response.data[key])
									messages.push(val);
							}
						} catch (e) {
							this.log(e);
							messages.push('There was an error processing your request.');
						}
					}
				}

				if (error.response.data.stackTrace) {
					console.log(error.response.data.stackTrace);
				}
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
			//console.log(error.config);

			return messages;
		},
		errorContainsKey(error, key) {
			return error.response && error.response.data && error.response.data.errors && Object.prototype.hasOwnProperty.call(error.response.data.errors, key);
		},
		setMapColors(highContrast = true) {
			var colors = highContrast ? this.globals.mapColors.highContrast : this.globals.mapColors.default;
			localStorage.setItem('map_colors', JSON.stringify(colors));
			this.$store.commit('setMapColors', colors);
		},
		loadMapColors() {
			var storedColors = JSON.parse(localStorage.getItem('map_colors'));
			if (!storedColors) {
				storedColors = this.globals.mapColors.default;
				localStorage.setItem('map_colors', JSON.stringify(storedColors));
			}
			this.$store.commit('setMapColors', storedColors);
			this.log(storedColors);
			this.log(this.mapColors);
		},
		getTokenHeader() {
			return {
				headers: { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }
			};
		},
		getTokenHeaderPart() {
			return { 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') };
		},
		isAuthorized(role) {
			return this.isAuthenticated && this.user.emailConfirmed && this.roles.includes(role);
		},
		hasFeatureAccess(feature = '') {
			if (!this.user.featureAccess) return false;
			if (this.isNullOrEmpty(feature)) return this.user.featureAccess.length > 0;
			return this.user.featureAccess.includes(feature);
		},
		logout(redirect = true) {
			localStorage.removeItem('auth_token');
			this.$store.commit('logout');
			if (redirect)
				this.$router.push('/').catch(err => { });
		},
		isApiUnauthorized(error) {
			return error.response && (error.response.status === 401 || error.response.status === 403);
		},
		rand() {
			return Math.random().toString(36).substr(2); // remove `0.`
		},
		generateRand() {
			return this.rand() + this.rand();
		},
		setRememberMe(userName) {
			let storedRememberDeviceString = localStorage.getItem('remember_device');
			let storedRememberDevice = [];
			if (storedRememberDeviceString !== undefined && storedRememberDeviceString !== null) {
				storedRememberDevice = JSON.parse(storedRememberDeviceString);
				let remembered = storedRememberDevice.filter(function (el) { return el.user !== userName; });
				storedRememberDevice = remembered;
			}

			let token = this.generateRand();

			storedRememberDevice.push({
				user: userName,
				timestamp: moment().format(),
				token: token
			});

			localStorage.setItem('remember_device', JSON.stringify(storedRememberDevice));

			this.$store.commit('storeRememberMeToken', {
				token: token
			});

			this.log(storedRememberDevice);
			this.log(token);

			return token;
		},
		getRememberMe(userName) {
			let storedRememberDeviceString = localStorage.getItem('remember_device');
			this.log(storedRememberDeviceString);
			if (storedRememberDeviceString === undefined || storedRememberDeviceString === null) {
				return null;
			}

			let storedRememberDevice = JSON.parse(storedRememberDeviceString);
			let remembered = storedRememberDevice.filter(function (el) { return el.user === userName; });
			if (remembered === null || remembered.length < 1) {
				return null;
			}

			return remembered[0].token;
		},
		splitString(str, separator = ',') {
			if (this.isNullOrEmpty(str)) return [];
			return str
				.split(separator)
				.map(element => element.trim())
				.filter(element => element !== '');
		},
		joinArray(arr, separator = ',') {
			if (this.isNullOrEmpty(arr)) return '';
			return arr.join(separator);
		}
	}
})

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Basin input data</h2>

			<div v-if="$parent.scenario.status.isRunning">
				<page-running />
			</div>
			<div v-else>
				<p>
					General watershed attributes are defined in the basin input file. These attributes control a diversity of physical processes at the watershed level.
					The interfaces will automatically set these variables to the default or recommended values listed in the variable documentation.
					You can use the default values or change the available values below to better reflect what is happening in your watershed.
					Read the <a href="/content/docs/io/ch04_input_bsn.pdf" target="_blank">SWAT2012 IO documentation chapter on basin inputs</a>.
				</p>

				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<b-form @submit.prevent="save">
					<b-tabs content-class="mt-3" v-model="tabIndex" class="page-nav-tabs">
						<b-tab title="Water Balance" active>
							<div class="table-responsive mb-3">
								<b-table responsive class="table-valign-m mb-0" :items="form.tables.waterBalance" :fields="form.fieldsHeading">
									<template v-slot:cell(value)="data">
										<b-form-select v-if="form.selectListFields.includes(data.item.variable)"
													   :options="options[data.item.variable]"
													   v-model.trim="$v.item[data.item.variable].$model"
													   :state="getValidState($v.item[data.item.variable])"></b-form-select>

										<b-form-input v-else v-model.trim="$v.item[data.item.variable].$model" type="number" step="any" :state="getValidState($v.item[data.item.variable])"></b-form-input>
									</template>
								</b-table>
							</div>
						</b-tab>

						<b-tab title="Surface Runoff">
							<div class="table-responsive mb-3">
								<b-table responsive class="table-valign-m mb-0" :items="form.tables.surfaceRunoff" :fields="form.fieldsHeading">
									<template v-slot:cell(value)="data">
										<b-form-select v-if="form.selectListFields.includes(data.item.variable)"
													   :options="options[data.item.variable]"
													   v-model.trim="$v.item[data.item.variable].$model"
													   :state="getValidState($v.item[data.item.variable])"></b-form-select>

										<b-form-input v-else v-model.trim="$v.item[data.item.variable].$model" type="number" step="any" :state="getValidState($v.item[data.item.variable])"></b-form-input>
									</template>
								</b-table>
							</div>
						</b-tab>

						<b-tab title="Nutrient Cycling">
							<div class="table-responsive mb-3">
								<b-table responsive class="table-valign-m mb-0" :items="form.tables.nutrientCycling" :fields="form.fieldsHeading">
									<template v-slot:cell(value)="data">
										<b-form-select v-if="form.selectListFields.includes(data.item.variable)"
													   :options="options[data.item.variable]"
													   v-model.trim="$v.item[data.item.variable].$model"
													   :state="getValidState($v.item[data.item.variable])"></b-form-select>

										<b-form-input v-else v-model.trim="$v.item[data.item.variable].$model" type="number" step="any" :state="getValidState($v.item[data.item.variable])"></b-form-input>
									</template>
								</b-table>
							</div>
						</b-tab>

						<b-tab title="Reaches">
							<div class="table-responsive mb-3">
								<b-table responsive class="table-valign-m mb-0" :items="form.tables.reaches" :fields="form.fieldsHeading">
									<template v-slot:cell(value)="data">
										<b-form-select v-if="form.selectListFields.includes(data.item.variable)"
													   :options="options[data.item.variable]"
													   v-model.trim="$v.item[data.item.variable].$model"
													   :state="getValidState($v.item[data.item.variable])"></b-form-select>

										<b-form-input v-else v-model.trim="$v.item[data.item.variable].$model" type="number" step="any" :state="getValidState($v.item[data.item.variable])"></b-form-input>
									</template>
								</b-table>
							</div>
						</b-tab>
					</b-tabs>

					<fixed-action-bar>
						<b-button variant="light" @click="nextTab(-1)" class="border mr-2" :disabled="tabIndex == 0" title="Previous tab"><font-awesome-icon icon="chevron-left" /></b-button>
						<b-button variant="light" @click="nextTab(1)" class="border mr-2" :disabled="tabIndex == numTabs - 1" title="Next tab"><font-awesome-icon icon="chevron-right" /></b-button>

						<save-button :saving="page.saving" class="ml-2 mr-2" :disabled="!$v.$anyDirty" />
						<back-button class="btn btn-secondary mr-2" />

						<save-button v-if="changes.hasChanges" :saving="page.delete.saving" type="button"
									 variant="danger" text="Remove Changes"
									 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
									 @click.native="askDelete" class="ml-auto" />
					</fixed-action-bar>
				</b-form>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete your basin input data changes?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'EditBsn',
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				tabIndex: 1,
				numTabs: 4,

				form: {
					tables: {
						waterBalance: [],
						surfaceRunoff: [],
						nutrientCycling: [],
						reaches: []
					},
					fields: {
						waterBalance: ['SFTMP', 'SMTMP', 'SMFMX', 'SMFMN', 'TIMP', 'IPET', 'ESCO', 'EPCO'],
						surfaceRunoff: ['ICN', 'CNCOEF', 'ICRK', 'SURLAG'],
						nutrientCycling: ['RCN', 'CDN', 'SDNCO', 'NPERCO', 'PPERCO', 'PHOSKD', 'PSP'],
						reaches: ['IRTE', 'MSK_COL1', 'MSK_COL2', 'MSK_X', 'TRNSRCH', 'EVRCH', 'IDEG', 'PRF', 'SPCON', 'SPEXP', 'IWQ', 'ADJ_PKR']
					},
					fieldsHeading: [
						{ key: 'value', class: 'min-w-100 table-input-col' },
						{ key: 'units', class: 'min' },
						{ key: 'description', class: 'wrap' },
						'variable', 'default', 'valid_range'
					],
					selectListFields: ['IPET', 'ICNT', 'ICRK', 'IRTE', 'IDEG', 'IWQ'],
				},

				definitions: {},
				defaults: {},
				item: {},
				changes: {},
				options: {
					IPET: [
						{ value: 0, text: '0 - Priestley-Taylor method' },
						{ value: 1, text: '1 - Penman/Monteith method' },
						{ value: 2, text: '2 - Hargreaves method' }
					],
					ICNT: [
						{ value: 0, text: '0 - Calculate as a function of soil moisture' },
						{ value: 1, text: '1 - Calculate as a function of plant evaporatnspiration' }
					],
					ICRK: [
						{ value: 0, text: '0 - Do not model crack flow in soil' },
						{ value: 1, text: '1 - Model crack flow in soil' }
					],
					IRTE: [
						{ value: 0, text: '0 - Variable Storage method' },
						{ value: 1, text: '1 - Muskingum method' }
					],
					IDEG: [
						{ value: 0, text: '0 - Channel dimensions are not updated as a result of degradation' },
						{ value: 1, text: '1 - Channel dimensions are updated as a result of degradation' }
					],
					IWQ: [
						{ value: 0, text: '0 - Do not model in-stream nutrient and pesticide transformations' },
						{ value: 1, text: '1 - Model in-stream nutrient and pesticide transformations' }
					]
				}
			}
		},
		validations() {
			var item = {};
			var fields = this.form.fields.waterBalance.concat(this.form.fields.surfaceRunoff).concat(this.form.fields.nutrientCycling).concat(this.form.fields.reaches);
			for (var i = 0; i < fields.length; i++) {
				item[fields[i]] = { required };
			}
			return { item: item };
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/general/bsn/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.item = response.data.item;
					this.defaults = response.data.default;
					this.definitions = response.data.definitions;
					this.changes = response.data.changes;
					this.log(this.$v);

					//Water Balance Tab
					this.form.tables.waterBalance = [];
					for (var i = 0; i < this.form.fields.waterBalance.length; i++) {
						var n = this.form.fields.waterBalance[i];
						this.form.tables.waterBalance.push({
							variable: n,
							description: this.definitions[n].description,
							value: this.item[n],
							units: this.definitions[n].units,
							valid_range: this.definitions[n].maxValue === this.definitions[n].minValue ? '' : `${this.definitions[n].minValue} to ${this.definitions[n].maxValue}`,
							default: this.defaults[n]
						});
					}

					//Surface Runoff Tab
					this.form.tables.surfaceRunoff = [];
					for (i = 0; i < this.form.fields.surfaceRunoff.length; i++) {
						n = this.form.fields.surfaceRunoff[i];
						this.form.tables.surfaceRunoff.push({
							variable: n,
							description: this.definitions[n].description,
							value: this.item[n],
							units: this.definitions[n].units,
							valid_range: this.definitions[n].maxValue === this.definitions[n].minValue ? '' : `${this.definitions[n].minValue} to ${this.definitions[n].maxValue}`,
							default: this.defaults[n]
						});
					}

					//Nutrient Cycling Tab
					this.form.tables.nutrientCycling = [];
					for (i = 0; i < this.form.fields.nutrientCycling.length; i++) {
						n = this.form.fields.nutrientCycling[i];
						this.form.tables.nutrientCycling.push({
							variable: n,
							description: this.definitions[n].description,
							value: this.item[n],
							units: this.definitions[n].units,
							valid_range: this.definitions[n].maxValue === this.definitions[n].minValue ? '' : `${this.definitions[n].minValue} to ${this.definitions[n].maxValue}`,
							default: this.defaults[n]
						});
					}

					//Reaches Tab
					this.form.tables.reaches = [];
					for (i = 0; i < this.form.fields.reaches.length; i++) {
						n = this.form.fields.reaches[i];
						this.form.tables.reaches.push({
							variable: n,
							description: this.definitions[n].description,
							value: this.item[n],
							units: this.definitions[n].units,
							valid_range: this.definitions[n].maxValue === this.definitions[n].minValue ? '' : `${this.definitions[n].minValue} to ${this.definitions[n].maxValue}`,
							default: this.defaults[n]
						});
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {};
						var fields = this.form.fields.waterBalance.concat(this.form.fields.surfaceRunoff).concat(this.form.fields.nutrientCycling).concat(this.form.fields.reaches);
						for (var i = 0; i < fields.length; i++) {
							if (this.$v.item[fields[i]].$dirty || this.item[fields[i]] != this.defaults[fields[i]]) {
								data[fields[i]] = Number(this.item[fields[i]]);
							}
						}

						this.log(data);

						const response = await this.$http.post(`scenarios/general/bsn/${this.scenarioID}`, data, this.getTokenHeader());
						this.log(response.data);
						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemod/${this.changes.modID}`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			nextTab(position) {
				var maxTabPos = this.numTabs - 1;
				this.tabIndex += position;

				if (this.tabIndex > maxTabPos)
					this.tabIndex = 0;
				if (this.tabIndex < 0)
					this.tabIndex = maxTabPos;
			}
		}
	}
</script>

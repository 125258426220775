<template>
	<div>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading" class="max-w-400 mx-auto py-4">
			<error-list :errors="page.errors"></error-list>

			<div v-if="account.confirmed">
				<div>
					Thank you for confirming your email address!
					An administrator will review your account and you will receive an email when it is approved.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AccountConfirm',
		data() {
			return {
				page: {
					errors: [],
					loading: true
				},
				account: {
					confirmed: false
				}
			}
		},
		async created() {
			await this.confirmAccount();
		},
		methods: {
			confirmAccount: async function () {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('account/confirm?userId=' + encodeURIComponent(this.$route.params.userId) + '&code=' + encodeURIComponent(this.$route.params.code));
					this.account.confirmed = true;
					this.logout(false);
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">Website settings</h2>

			<b-alert variant="info" show>
				Please be cautious editing this page.
				Contact the developer for help. 
			</b-alert>

			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<b-form @submit.prevent="save">
				<b-form-checkbox v-model="data.apiConnectivityEnabled">Enable API connectivity features for users (development only)</b-form-checkbox>
				<b-form-checkbox v-model="data.includeCeQual">Enable CE-QUAL output feature</b-form-checkbox>
				<b-form-checkbox v-model="data.includeFfa">Enable flood frequency analysis (FFA) output feature</b-form-checkbox>
				<b-form-checkbox v-model="data.includeBaseflowSeparation">Enable baseflow separation output feature</b-form-checkbox>
				<b-form-checkbox v-model="data.useCustomHelpPage">Remove standard help page text and only use custom text from the 'Website text' setting</b-form-checkbox>

				<hr class="my-4" />
				<h3>Map Settings</h3>
				<b-form-group label="Custom default map center" description="[lat,lng]" class="my-3">
					<b-form-input type="text" v-model="data.customMapBounds"></b-form-input>
				</b-form-group>

				<b-form-group label="Custom default map zoom" class="my-3">
					<b-form-input type="number" v-model="data.customMapZoom"></b-form-input>
				</b-form-group>

				<b-form-group label="Custom map tile providers" description="JSON format. See example below or contact developer for instructions." class="my-3">
					<b-form-textarea v-model="data.customTileProviders" rows="5"></b-form-textarea>
				</b-form-group>

				<div>Example tile providers:</div>
				<div class="bg-light p-3">
					<pre>[
	{
		name: 'Default map',
		visible: true,
		attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
	},
	{
		name: 'Topography map',
		visible: false,
		url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
		attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
	},
	{
		name: 'Satellite map',
		visible: false,
		url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
		attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
	}
]</pre>
				</div>

				<hr class="my-4" />

				<b-form-checkbox v-model="data.enableAlertNotification">Enable site-wide alert notification</b-form-checkbox>

				<b-form-group label="Notification message" class="my-3">
					<b-form-textarea v-model="data.alertNotification" rows="3"></b-form-textarea>
				</b-form-group>

				<hr class="my-4" />
				<p class="text-danger">The settings below are not yet active:</p>

				<b-form-checkbox v-model="data.isOffline">Take the website offline</b-form-checkbox>

				<fixed-action-bar :cols-lg="10" :offset-lg="2">
					<save-button :saving="page.saving" class="mr-2" />
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	export default {
		name: 'AdminWebsiteSettings',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				data: {
					appName: '',
					isOffline: false,
					useCustomHelpPage: false,
					customTileProviders: null,
					includeCeQual: false,
					includeFfa: false,
					includeBaseflowSeparation: false,
					customMapBounds: null,
					customMapZoom: null,
					apiConnectivityEnabled: false,
					enableAlertNotification: false,
					alertNotification: null,
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/websitesettings', this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post('admin/websitesettings', this.data, this.getTokenHeader());
					this.log(response.data);
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<div v-if="status.isRunning" class="max-w-400 py-5 mx-auto text-center">
				<b-progress :value="progress.percent" :max="100" animated></b-progress>
				<p>
					{{progress.message}}
				</p>
			</div>
			<div v-else-if="status.hasError">
				<h2 class="mb-4">There was an error importing this dataset</h2>
				<p>
					Please refer to the error message below and check your MS Access database for
					potential problems. You may <router-link to="/help" target="_blank">contact support</router-link>
					if you need help, or click the button below to
					remove the error and uploaded data to try again. If you choose to contact support, please do
					not click the button to remove.
				</p>
				<p>
					<b-form-textarea v-model="status.exceptionMessage" rows="8"></b-form-textarea>
				</p>
				<p>
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Remove error and uploaded data" variant="danger" />
				</p>
			</div>
			<div v-else>
				<h2 class="mb-3">Uploaded dataset settings</h2>

				<p class="mb-4">
					To use this project dataset, <router-link to="/projects/create">create a new project</router-link> and select it from the drop-down menu.
				</p>

				<success-alert ref="deactivateAlert" text="This dataset has been deactivated." />
				<success-alert ref="activateAlert" text="This dataset has been reactivated and can now be used to create new projects." />

				<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
					<b-tab title="Display settings">
						<b-form @submit.prevent="save">
							<error-list :errors="page.saveErrors"></error-list>
							<success-alert ref="savedAlert" text="Changes saved." />

							<b-form-group label="Name of your project (fewer than 50 characters)" :invalid-feedback="requiredFeedback($v.data.name)">
								<b-form-input v-model.trim="$v.data.name.$model" type="text" autofocus :state="getValidState($v.data.name)"></b-form-input>
							</b-form-group>

							<b-form-group label="Description (fewer than 200 characters)" :invalid-feedback="requiredFeedback($v.data.description)">
								<b-form-textarea v-model.trim="$v.data.description.$model" rows="4" :state="getValidState($v.data.description)"></b-form-textarea>
							</b-form-group>

							<b-form-group label="Agency, organization, or person to whom this project should be credited (optional)" :invalid-feedback="requiredFeedback($v.data.attribution)">
								<b-form-input v-model.trim="$v.data.attribution.$model" type="text" :state="getValidState($v.data.attribution)"></b-form-input>
							</b-form-group>

							<b-form-group label="Default SWAT version for new scenarios using this dataset" :invalid-feedback="requiredFeedback($v.data.defaultSWATVersionID)">
								<b-form-select v-model.trim="$v.data.defaultSWATVersionID.$model" :options="options.swatVersions" :state="getValidState($v.data.defaultSWATVersionID)">
									<template #first>
										<b-form-select-option :value="null">System default (latest version)</b-form-select-option>
									</template>
								</b-form-select>
							</b-form-group>

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.allowOnlyDefaultSWATVersion.$model">
									Allow projects to only use the SWAT version selected above?
								</b-form-checkbox>
							</b-form-group>

							<div class="mt-4">
								<save-button :saving="page.saving" class="mr-2" :disabled="!$v.data.$anyDirty" />
							</div>
						</b-form>
					</b-tab>
					<b-tab title="Share permissions">
						<div v-if="data.isPublic">
							<b-alert variant="info" show>
								This project dataset has been shared with all {{$parent.data.siteName}} users.
								Any user may create their own projects based on your model.
							</b-alert>
						</div>
						<div v-else>
							<p>
								Share this project with your <router-link to="/groups">groups</router-link> or all {{$parent.data.siteName}} users.
							</p>

							<error-list :errors="page.share.errors"></error-list>

							<b-alert :show="page.share.sharedSuccess" variant="success" dismissible>
								Your project has been shared with
								<router-link :to="`/groups/${page.share.currentItem.value}`">{{page.share.currentItem.text}}</router-link>.
							</b-alert>
							<b-alert :show="page.share.unsharedSuccess" variant="success" dismissible>
								Your project has been removed from
								<router-link :to="`/groups/${page.share.currentItem.value}`">{{page.share.currentItem.text}}</router-link>.
							</b-alert>

							<b-form-group label="Share this project dataset with:">
								<b-form-select v-model="data.accessibility" :options="shareOptions"></b-form-select>
							</b-form-group>

							<div v-if="data.accessibility === 'Group'">
								<b-form-group v-if="groupOptions.length > 0">
									<b-form-select v-model="page.share.groupID" :options="groupOptions">
										<template v-slot:first>
											<option :value="null" disabled>-- Select a group for sharing --</option>
										</template>
									</b-form-select>
								</b-form-group>

								<b-alert variant="info" :show="groupOptions.length <= 0 && data.groups.length <= 0">
									You don't belong to any groups.
									<router-link to="/groups/create">Create one now.</router-link>
								</b-alert>

								<b-alert variant="info" :show="groupOptions.length <= 0 && data.groups.length > 0">
									This project dataset is already shared with all of your groups.
								</b-alert>

								<div v-if="data.groups.length > 0" class="my-4">
									<div>Currently shared with (click to remove):</div>
									<b-button v-for="(g, i) in data.groups" :key="i" variant="light" size="sm" class="mr-2 mb-2" @click="unshare(g.value)">
										{{g.text}}
										<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
									</b-button>
								</div>

								<div class="mt-4" v-if="page.share.groupID !== null">
									<save-button :saving="page.saving" class="mr-2" text="Share" @click.native="share" />
								</div>
							</div>
							<div v-else-if="data.accessibility === 'Public'">
								<div v-if="data.publicRequest !== null">
									<b-alert :show="data.publicRequest.status === 'Denied'" variant="danger">
										You requested public sharing of this project on
										{{data.publicRequest.requestedOn | date}}.
										Unfortunately administrators denied your request.
										{{data.publicRequest.adminMessage}}
									</b-alert>
									<b-alert :show="data.publicRequest.status === 'Submitted'" variant="info">
										You requested public sharing of this project on
										{{data.publicRequest.requestedOn | date}}.
										Administrators will review your request as soon as possible.
									</b-alert>
								</div>
								<div v-else>
									<b-alert :show="page.share.sent" variant="success" dismissible>
										Your request has been sent to administrators for review.
									</b-alert>
									<b-form @submit.prevent="requestPublic" v-if="!page.share.sent">
										<p>
											If you would like to share this project datasets with all
											{{$parent.data.siteName}} users, submit the form below and
											administrators will review the request.
										</p>
										<p>
											Once shared, any user in the system can create their own projects
											from your data. If you wish to unshare this data at a later time,
											you will need to contact {{$parent.data.siteName}} administrators.
										</p>

										<b-form-group label="Reasons for request:">
											<b-form-textarea v-model="publicRequest.value" rows="5" :state="getValidState($v.publicRequest.value)"></b-form-textarea>
											<b-form-invalid-feedback v-if="!$v.publicRequest.value.required">Required</b-form-invalid-feedback>
										</b-form-group>

										<div class="mt-4">
											<save-button :saving="page.share.saving" text="Send Request" />
										</div>
									</b-form>
								</div>
							</div>
						</div>
					</b-tab>
				</b-tabs>

				<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
					<back-button class="btn btn-secondary mr-2" />
					<b-button variant="warning"
							  v-if="data.isActive"
							  @click="page.activation.show = true" class="ml-auto mr-2">Deactivate</b-button>
					<save-button variant="success"
							  v-if="!data.isActive" :saving="page.activation.saving" text="Activate"
							  @click.native="activate" class="ml-auto mr-2" />
					<b-button variant="danger"
							  v-if="!data.hasProjects && data.accessibility === 'User'"
							  @click="page.delete.show = true">Delete</b-button>
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete this uploaded project dataset?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>

				<b-modal v-model="page.activation.show" size="md" title="Confirm deactivation" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.activation.errors"></error-list>

					<p>
						Are you sure you want to deactivate this dataset?
						Deactivating will not delete the dataset or any projects using it.
						Instead, it will remove it as an available option in the create projects page,
						so no new projects may be created from it unless you reactivate it.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.activation.saving" @click.native="deactivate" text="Deactivate" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.activation.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import { requiredIf, maxLength } from 'vuelidate/lib/validators';
	import _ from 'lodash';

	export default {
		name: 'DatasetView',
		data() {
			return {
				importID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					share: {
						errors: [],
						saving: false,
						groupID: null,
						currentItem: {},
						sharedSuccess: false,
						unsharedSuccess: false,
						sent: false
					},
					activation: {
						show: false,
						errors: [],
						saving: false
					}
				},
				data: {
					name: null,
					description: null,
					attribution: null,
					defaultSWATVersionID: null,
					allowOnlyDefaultSWATVersion: false
				},
				status: {
					isRunning: false,
					hasError: false,
					exceptionMessage: ''
				},
				progress: {
					percent: 0,
					message: 'Please wait...'
				},
				groupOptions: [],
				tabIndex: 0,
				shareOptions: [
					{ value: 'User', text: 'No one; only I can use this dataset (default)' },
					{ value: 'Group', text: 'My groups' },
					{ value: 'Public', text: 'All system users' }
				],
				publicRequest: {
					value: null
				},
				options: {
					swatVersions: []
				}
			}
		},
		validations: {
			data: {
				name: {
					required: requiredIf(function () { return this.tabIndex == 0; }),
					maxLength: maxLength(50)
				},
				description: {
					maxLength: maxLength(200)
				},
				attribution: {
					maxLength: maxLength(100)
				},
				defaultSWATVersionID: {},
				allowOnlyDefaultSWATVersion: {}
			},
			publicRequest: {
				value: {
					required: requiredIf(function () { return this.tabIndex == 1; })
				}
			}
		},
		async created() {
			await this.get();
			this.$statusHub.importDatasetOpened(this.importID);
			this.$statusHub.$on('import-dataset-status-changed', this.onStatusChanged);
		},
		beforeDestroy() {
			this.$statusHub.importDatasetClosed(this.importID);
			this.$statusHub.$off('import-dataset-status-changed', this.onStatusChanged);
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.importID = this.$route.params.id;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`importdatasets/${this.importID}`, this.getTokenHeader());
					this.data = response.data.dataset;
					this.status = response.data.status;
					this.progress = response.data.progress;
					this.groupOptions = response.data.groupOptions;
					this.options.swatVersions = response.data.swatVersions;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async onStatusChanged(data) {
				if (this.importID != data.importID) return;
				if (data.progress == 100) {
					this.status.isRunning = false;
					await this.get();
				}

				this.progress.percent = data.progress;
				this.progress.message = data.statusMessage;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							name: this.data.name,
							description: this.data.description,
							attribution: this.data.attribution,
							defaultSWATVersionID: this.data.defaultSWATVersionID,
							allowOnlyDefaultSWATVersion: this.data.allowOnlyDefaultSWATVersion
						};
						const response = await this.$http.put(`importdatasets/${this.importID}`, data, this.getTokenHeader());
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`importdatasets/${this.importID}`, this.getTokenHeader());
					this.$router.push({ name: 'DatasetList'}).catch(err => {});
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async share() {
				this.page.share.errors = [];
				this.page.share.saving = true;
				this.page.share.unsharedSuccess = false;
				this.page.share.sharedSuccess = false;

				if (this.isNullOrEmpty(this.page.share.groupID)) {
					this.page.share.errors.push('Please select a group.');
				} else {
					try {
						const response = await this.$http.patch(`importdatasets/groupshare/${this.page.share.groupID}/${this.data.newDatasetID}`, {}, this.getTokenHeader());
						this.page.share.currentItem = response.data;
						this.groupOptions = _.remove(this.groupOptions, function (n) { return n.value !== response.data.value; });
						this.data.groups.push(response.data);
						this.page.share.groupID = null;
						this.page.share.sharedSuccess = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.share.errors = this.logError(error);
					}
				}

				this.page.share.saving = false;
			},
			async unshare(id) {
				this.page.share.errors = [];
				this.page.share.saving = true;
				this.page.share.unsharedSuccess = false;
				this.page.share.sharedSuccess = false;

				if (!this.isNullOrEmpty(id)) {
					try {
						const response = await this.$http.patch(`importdatasets/groupunshare/${id}/${this.data.newDatasetID}`, {}, this.getTokenHeader());
						this.page.share.currentItem = response.data;
						this.data.groups = _.remove(this.data.groups, function (n) { return n.value !== response.data.value; });
						this.groupOptions.push(response.data);
						this.page.share.unsharedSuccess = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.share.errors = this.logError(error);
					}
				}

				this.page.share.saving = false;
			},
			async requestPublic() {
				this.page.share.errors = [];
				this.page.share.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.share.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.put(`importdatasets/publicshare/${this.data.newDatasetID}`, this.publicRequest, this.getTokenHeader());
						this.page.share.sent = true;
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.share.errors = this.logError(error);
					}
				}

				this.page.share.saving = false;
			},
			async deactivate() {
				this.page.activation.errors = [];
				this.page.activation.saving = true;

				try {
					const response = await this.$http.patch(`importdatasets/deactivate/${this.data.newDatasetID}`, {}, this.getTokenHeader());
					this.data.isActive = false;
					this.page.activation.show = false;
					this.$refs.deactivateAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.activation.errors = this.logError(error);
				}

				this.page.activation.saving = false;
			},
			async activate() {
				this.page.activation.errors = [];
				this.page.activation.saving = true;

				try {
					const response = await this.$http.patch(`importdatasets/activate/${this.data.newDatasetID}`, {}, this.getTokenHeader());
					this.data.isActive = true;
					this.$refs.activateAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.activation.errors = this.logError(error);
				}

				this.page.activation.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<div v-if="$route.name == 'AdminDatasets'">
			<h2 class="mb-3">Datasets</h2>

			<p>
				Datasets are SWAT model data from which users may create projects.
				Depending on their <router-link to="/admin/users/tiers">tier settings</router-link>, users may upload their own datasets.
				By default, user-uploaded datasets may be used by the user only. The user may share the dataset with their groups,
				or request it be made publicly available by contacting administrators.
			</p>
			<p>
				Browse existing datasets below. Select one to activate/deactivate it, set its permissions, or edit its name and description.
			</p>

			<grid-view api-url="admin/datasets" use-filter link-user-name
					:fields="table.fields"
					:default-sort="table.sort" :default-reverse="table.reverse"
					collection-description="datasets"
					item-name="dataset"></grid-view>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'AdminDatasets',
		components: {
			GridView
		},
		data() {
			return {
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'isActive', label: 'Active?', sortable: true, formatter: (value) => { return value ? '✓' : '' }, tdClass: 'text-success font-weight-bold text-center', thClass: 'text-center' },
						{ key: 'optionOrder', label: 'Order', sortable: true },
						{ key: 'optionGroup', label: 'Grouping', sortable: true },
						{ key: 'name', sortable: true },
						{ key: 'attribution', label: 'Credit', sortable: true },
						{ key: 'version', sortable: true, class: 'text-center' },
						{ key: 'isUserSubmitted', label: 'User-submitted?', sortable: true, formatter: (value) => { return value ? '✓' : '' }, tdClass: 'text-success font-weight-bold text-center', thClass: 'text-center' },
						{ key: 'createdOn', sortable: true, formatter: (value) => { return moment(value).format('YYYY') == '0001' ? '' : moment(value).format('llll') } },
						{ key: 'userName', label: 'Created By', sortable: true }
					],
					sort: '',
					reverse: false,
					itemsPerPage: 50
				}
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">User Categories</h2>

			<p>
				Set the organization categories a user may choose from during registration. Drag and drop to sort.
			</p>

			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<b-alert variant="light" :show="categories.length < 1" class="border">
				There are no categories saved. Add one below.
			</b-alert>
			<draggable v-if="categories.length > 0" class="list-group" element="ul" v-model="categories">
				<li class="list-group-item bg-light d-flex justify-content-between align-items-center" v-for="element in categories" :key="element">
					{{element}}
					<font-awesome-icon :icon="['fas', 'times']" class="ml-auto text-danger pointer" @click="remove(element)" v-b-tooltip.hover.right="'Delete'" />
				</li>
			</draggable>

			<b-form-group label="Add a category" class="mt-4">
				<b-input-group>
					<b-form-input v-model="newCategory" type="text"></b-form-input>
					<b-input-group-append>
						<b-button variant="info" @click="add">Add</b-button>
					</b-input-group-append>
				</b-input-group>				
			</b-form-group>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button :saving="page.saving" @click.native="save" class="mr-2" />
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</page-authorization-container>
	</div>
</template>

<script>
	import draggable from 'vuedraggable';

	export default {
		name: 'AdminUserCategories',
		components: {
			draggable
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					saveErrors: []
				},
				categories: [],
				newCategory: null
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`admin/users/categories`, this.getTokenHeader());
					this.categories = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					await this.$http.post(`admin/users/categories`, this.categories, this.getTokenHeader());
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			add: function () {
				var alreadyExists = this.categories.includes(this.newCategory);
				if (!this.isNullOrEmpty(this.newCategory) && !alreadyExists) {
					this.categories.push(this.newCategory);
					this.newCategory = null;
				}

				if (alreadyExists)
					this.newCategory = null;
			},
			remove: function (element) {
				this.categories.splice(this.categories.indexOf(element), 1);
			}
		}
	}
</script>

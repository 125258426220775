<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Output Charts</h2>

			<div v-if="$parent.scenario.status.isRunning">
				<page-running />
			</div>
			<div v-else-if="options.noOutputProcessing">
				<b-alert variant="warning" show>Output processing was skipped for this scenario run. Please re-run your scenario without the "skip output processing" box checked to use this feature.</b-alert>
			</div>
			<div v-else>
				<b-alert variant="info" :show="options.printSetting !== 'daily'">
					Some statistics may be limited because this scenario ran in {{options.printSetting}} mode.
				</b-alert>
				<b-form @submit.prevent="save">
					<b-row>
						<b-col md="6">
							<b-form-group :invalid-feedback="requiredFeedback($v.selection.outputType)">
								<b-form-select v-model="selection.outputType" :options="options.outputTypes.types" :state="getValidState($v.selection.outputType)" @change="outputTypeChg">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>-- Select an output type --</b-form-select-option>
									</template>
								</b-form-select>
							</b-form-group>
							<b-form-group :invalid-feedback="requiredFeedback($v.selection.statistic)">
								<b-form-select v-model="selection.statistic" :options="options.statistics" :state="getValidState($v.selection.statistic)">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>-- Select a statistic --</b-form-select-option>
									</template>
								</b-form-select>
							</b-form-group>

							<b-form-select v-model="selection.subbasin" :state="getValidState($v.selection.subbasin)" class="mb-3">
								<template v-slot:first>
									<b-form-select-option :value="null" disabled>-- Select a subbasin from this list or click the map below --</b-form-select-option>
								</template>
								<b-form-select-option v-for="(c, i) in options.map.connections" :key="i" :value="{ id: c.fromID, name: c.fromName }">
									{{c.fromName}}
								</b-form-select-option>
							</b-form-select>

							<div v-if="selection.outputType === 'OutputHru' && selection.subbasin !== null">
								<b-alert variant="warning" :show="!subbasinHasHrus">
									You have not printed output for any HRUs in the selected subbasin.
									All available HRUs are in the list below, or select another subbasin to filter.
								</b-alert>

								<b-form-select v-model="selection.hru" :state="getValidState($v.selection.hru)" class="mb-3">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>-- Select an HRU --</b-form-select-option>
									</template>
									<b-form-select-option v-for="(h, i) in filteredHrus" :key="i" :value="h.id">
										{{h.subbasin}} / {{h.landuse}} / {{h.soil}} / {{h.slopeClass}}
									</b-form-select-option>
								</b-form-select>
							</div>

							<project-map :project-map="options.map" height="300px"
										 highlight-selected
										 :selected-subbasins="selection.subbasin === null ? [] : [selection.subbasin]"
										 @subbasin-click="mapClick"></project-map>

							<error-list :errors="page.saveErrors" class="mt-3"></error-list>

							<div class="mt-4 mb-3">
								<save-button :saving="page.saving" class="mr-2" text="Get Chart" :disabled="selection.parameters.length < 1 || selection.compareScenarios.length > page.maxToCompare" />
							</div>
						</b-col>
						<b-col md>
							<div v-if="!isNullOrEmpty(selection.outputType)" class="mb-4">
								<div class="checklist-header">
									Select parameters to chart
								</div>
								<div class="checklist" style="max-height:200px">
									<b-form-checkbox-group v-model="selection.parameters" stacked>
										<div class="item" v-for="(o, i) in options.outputTypes.parameters[selection.outputType]" :key="i">
											<b-form-checkbox :value="o.variable" @change="checkYAxis(o.variable)">{{o.variable}} - {{o.description}}</b-form-checkbox>
											<div v-if="selection.parameters.includes(o.variable)" class="ml-4 mt-1 mb-2 form-inline">
												<b-form-select v-model="chartSelection.types[o.variable]" size="sm" class="mr-1" v-if="selection.statistic !== 'boxplot'">
													<b-form-select-option value="line">Line</b-form-select-option>
													<b-form-select-option value="area">Area</b-form-select-option>
													<b-form-select-option value="scatter">Scatter</b-form-select-option>
													<b-form-select-option value="column">Column</b-form-select-option>
													<b-form-select-option value="spline">Spline</b-form-select-option>
												</b-form-select>

												<b-form-select v-model="chartSelection.yAxis[o.variable]" size="sm" class="mr-1">
													<b-form-select-option :value="true">Add y-axis</b-form-select-option>
													<b-form-select-option :value="false">No y-axis</b-form-select-option>
												</b-form-select>
											</div>
										</div>
									</b-form-checkbox-group>
								</div>

								<b-form-group :invalid-feedback="requiredFeedback($v.selection.units)" class="mt-3">
									<b-form-select v-model="selection.units" :options="options.outputTypes.units" :state="getValidState($v.selection.units)"></b-form-select>
									<small class="text-muted">Set the default units displayed from your <router-link to="/account">account settings</router-link></small>
								</b-form-group>
							</div>

							<div v-if="options.scenarios.length > 0" class="mb-3">
								<div class="checklist-header">
									Select up to {{page.maxToCompare}} other scenarios for output comparison (optional)
								</div>
								<div class="checklist" style="max-height:200px">
									<b-form-checkbox-group v-model="selection.compareScenarios" stacked>
										<div class="item" v-for="(o, i) in options.scenarios" :key="i">
											<b-form-checkbox :value="o.value">{{o.text}}</b-form-checkbox>
										</div>
									</b-form-checkbox-group>
								</div>

								<b-alert variant="info" :show="selection.outputType === 'OutputHru'" class="mt-2">
									Note: compared scenarios must have printed the same HRUs otherwise they will not display in the chart.
								</b-alert>
							</div>

							<b-alert :show="selection.compareScenarios.length > page.maxToCompare" variant="warning">
								You may only compare to up to {{page.maxToCompare}} scenarios at a time. Please modify your selection.
							</b-alert>

						</b-col>
					</b-row>

					<div class="my-4" v-show="page.showChart">
						<div v-show="responseStatistic === 'boxplot'">
							<highcharts :options="boxChartOptions" :updateArgs="updateArgs"></highcharts>
						</div>
						<div v-show="responseStatistic !== 'boxplot'">
							<highcharts :options="chartOptions" :updateArgs="updateArgs"></highcharts>
						</div>

						<b-form inline class="mt-3">
							<label for="yAxisType" class="mr-2">Change y-axis scale</label>
							<b-form-select v-model="yAxisType" id="yAxisType">
								<option value="linear">linear</option>
								<option value="logarithmic">logarithmic</option>
							</b-form-select>
						</b-form>
					</div>
				</b-form>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, requiredIf } from 'vuelidate/lib/validators';
	import ProjectMap from '@/components/ProjectMap';

	export default {
		name: 'OutputCharts',
		components: {
			ProjectMap
		},
		data() {
			return {
				scenarioID: this.$route.params.scenarioID,
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					maxToCompare: 3,
					showChart: false
				},
				options: {
					outputTypes: {
						types: [],
						parameters: [],
						units: []
					},
					scenarios: [],
					statistics: [],
					map: {},
					noOutputProcessing: false
				},
				selection: {
					subbasin: null,
					statistic: null,
					outputType: null,
					parameters: [],
					compareScenarios: [],
					hru: null,
					units: 0
				},
				chartSelection: {
					types: {},
					yAxis: {}
				},
				responseData: {},
				chartOptions: {},
				boxChartOptions: {},
				responseStatistic: null,
				yAxisType: 'linear',
				updateArgs: [true, true, { duration: 1000 }]
			}
		},
		validations: {
			selection: {
				subbasin: { required },
				statistic: { required },
				outputType: { required },
				parameters: { required },
				units: { required },
				hru: { required: requiredIf(function(){ return this.selection.outputType === 'OutputHru'; }) }
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/output/chartoptions/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.options = response.data;
					this.selection.units = this.options.outputTypes.defaultUnits;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.page.showChart = false;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please complete the form.');
				} else {
					this.log(this.selection);

					var chartTypes = [];
					var yAxis = [];
					for (var i = 0; i < this.selection.parameters.length; i++) {
						var p = this.selection.parameters[i];
						chartTypes.push(this.chartSelection.types[p]);
						yAxis.push(this.chartSelection.yAxis[p]);
					}
					this.selection.chartTypes = chartTypes;
					this.selection.yAxis = yAxis;

					this.chartOptions = {};
					this.boxChartOptions = {};
					this.yAxisType = 'linear';

					try {
						const response = await this.$http.post(`scenarios/output/chart/${this.scenarioID}`, this.selection, this.getTokenHeader());
						this.log(response.data);
						this.responseData = response.data;
						this.responseStatistic = this.selection.statistic;
						if (this.responseStatistic === 'boxplot') this.drawBoxChart();
						else this.drawChart();

						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
				this.page.showChart = true;
			},
			async mapClick(selectedSubbasins) {
				if (selectedSubbasins.length > 0)
					this.selection.subbasin = selectedSubbasins[0];
				else
					this.selection.subbasin = null;
			},
			outputTypeChg() {
				this.selection.parameters = [];
				this.chartSelection.types = {};
				for (var i = 0; i < this.options.outputTypes.parameters[this.selection.outputType].length; i++) {
					var p = this.options.outputTypes.parameters[this.selection.outputType][i];
					this.chartSelection.types[p.variable] = "line";
					this.chartSelection.yAxis[p.variable] = false;
				}
			},
			checkYAxis(variable) {
				if (this.selection.parameters.length < 1) {
					this.chartSelection.yAxis[variable] = true;
				}
			},
			drawChart() {
				let xAxis = this.responseData.xAxis;
				let chartDef = { zoomType: 'x' };

				this.chartOptions = {
					chart: chartDef,
					title: { text: this.responseData.title },
					xAxis: xAxis,
					yAxis: this.responseData.yAxis,
					series: this.responseData.series,
					plotOptions: {
						series: { turboThreshold: 0 }
					},
					exporting: {
						csv: { dateFormat: '%Y-%m-%d' }
					}
				};
				this.log(this.chartOptions);
			},
			drawBoxChart() {
				let xAxis = [{ categories: this.responseData.xAxisCategories, type: 'category' }];
				let chartDef = { type: 'boxplot', zoomType: 'x' };

				this.boxChartOptions = {
					chart: chartDef,
					title: { text: this.responseData.title },
					xAxis: xAxis,
					yAxis: this.responseData.yAxis,
					series: this.responseData.series,
					plotOptions: {
						series: { turboThreshold: 0 }
					},
					exporting: {
						csv: { dateFormat: '%Y-%m-%d' }
					}
				};
				this.log(this.boxChartOptions);
			},
			getFilteredHrus() {
				if (this.selection.subbasin !== null) {
					var value = this.selection.subbasin.id;
					return this.options.printedHrus.filter(function (el) { return el.subbasinID === value; });
				}
				return [];
			}
		},
		watch: {
			yAxisType(newValue) {
				if (this.boxChartOptions && this.selection.statistic === 'boxplot') {
					let newYAxis = [];
					for (let i = 0; i < this.boxChartOptions.yAxis.length; i++) {
						newYAxis.push({
							type: newValue,
							allowNegativeLog: true,
							title: this.boxChartOptions.yAxis[i].title,
							labels: this.boxChartOptions.yAxis[i].labels,
							opposite: this.boxChartOptions.yAxis[i].opposite
						});
					}
					this.boxChartOptions.yAxis = newYAxis;
				}
				else if (this.chartOptions) {
					let newYAxis = [];
					for (let i = 0; i < this.chartOptions.yAxis.length; i++) {
						newYAxis.push({
							type: newValue,
							allowNegativeLog: true,
							title: this.chartOptions.yAxis[i].title,
							labels: this.chartOptions.yAxis[i].labels,
							opposite: this.chartOptions.yAxis[i].opposite
						});
					}
					this.chartOptions.yAxis = newYAxis;
				}
			}
		},
		computed: {
			filteredHrus() {
				var filtered = this.getFilteredHrus();
				if (filtered.length > 0) return filtered;
				return this.options.printedHrus;
			},
			subbasinHasHrus() {
				return this.getFilteredHrus().length > 0
			}
		}
	}
</script>

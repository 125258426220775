<template>
	<div>
		<div v-if="$route.name === 'ScenarioView'">
			<page-authorization-container :page="page">
				<h2 class="mb-3" v-if="!scenario.status.hasError">{{scenario.name}} Scenario</h2>
				<success-alert ref="reportSubmittedAlert" text="Error report submitted." />

				<div v-if="scenario.status.isRunning" class="max-w-400 py-5 mx-auto text-center">
					<b-progress :value="progress.percent" :max="100" animated></b-progress>
					<p>
						{{progress.message}}
					</p>

					<p class="text-muted mt-5">
						<small>
							<span v-if="!isNullOrEmpty(this.currentRunTime)">Time elapsed: {{this.currentRunTime}}<br /></span>
							Progress not updating? <a href="#" @click.prevent="get(false)">Refresh now.</a>
							<br /><a href="#" @click.prevent="page.abort.show = true" class="text-danger">Cancel scenario run.</a>
						</small>
					</p>
				</div>
				<div v-else-if="scenario.status.hasError">
					<h2 class="mb-3">
						<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon mr-1 text-warning" />
						An error occurred while running {{scenario.name}} scenario
					</h2>
					<p>
						Errors can be the result of a bug in the system or due to a change to your SWAT input data that caused a crash in the model.
						If you believe the error is a result of customizations to your input data, simply clear the error, modify the necessary inputs, and run the scenario again.
						If you need further assistance with the error, submit an error report.
						Please see the full exception message below.
					</p>

					<hr class="my-4" />

					<h5>{{scenario.status.statusMessage}}</h5>
					<p>
						<b-form-textarea v-model="scenario.status.exceptionMessage" rows="15"></b-form-textarea>
					</p>

					<fixed-action-bar>
						<b-button v-if="!isNullOrEmpty(scenario.status.zipFile)" :href="scenario.status.zipFile" class="mr-2">Download Files for Debug</b-button>
						<b-button @click="page.errorReport.show = true" class="mr-2">Submit Error Report</b-button>
						<b-button variant="secondary" @click="clearError" class="mr-2">Clear Error</b-button>
					</fixed-action-bar>

					<b-modal v-model="page.errorReport.show" title="Submit an error report">
						<error-list :errors="page.errorReport.errors"></error-list>

						<p>
							Complete the form below to notify developers about the error.
							If you have already submitted a report for this error or a similar error, there is no need to submit again.
						</p>

						<b-form-group label="Add any additional comments (optional)">
							<b-form-textarea v-model="errorForm.userComments" rows="7"></b-form-textarea>
						</b-form-group>

						<div class="mt-4">
							<b-form-checkbox v-model="errorForm.clearError">Clear error after submitting report</b-form-checkbox>
						</div>

						<div slot="modal-footer">
							<save-button type="button" :saving="page.errorReport.saving" @click.native="submitErrorReport" text="Submit Report" variant="success" />
							<b-button type="button" variant="secondary" @click="page.errorReport.show = false" class="ml-1">Cancel</b-button>
						</div>
					</b-modal>
				</div>
				<div v-else>
					<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
						<b-tab :title="tabs[0]">
							<b-alert variant="warning" :show="!$parent.$parent.withinTierLimits" class="my-3">
								<p class="mb-md-0">
									Your account only has access to run scenarios from limited datasets with {{$parent.$parent.tier.subbasinLimit | number(0)}} subbasins
									and {{$parent.$parent.tier.hruLimit | number(0)}} HRUs or fewer.
								</p>
								<p class="mb-0">
									Need access to a higher tier? <router-link to="/account">Go to your account</router-link> and click the tier tab.
								</p>
							</b-alert>

							<b-row>
								<b-col md>
									<table class="table table-inner-borders mb-4">
										<tr>
											<th class="min">Weather dataset</th>
											<td>{{weatherDatasetDescription}}</td>
										</tr>
										<tr>
											<th class="min">Starting simulation date</th>
											<td>{{scenario.startingSimulationDate | date('M/D/YYYY')}}</td>
										</tr>
										<tr>
											<th class="min">Ending simulation date</th>
											<td>{{scenario.endingSimulationDate | date('M/D/YYYY')}}</td>
										</tr>
										<tr>
											<th class="min">Set-up/warm-up years</th>
											<td>{{scenario.skipYears}}</td>
										</tr>
										<tr>
											<th class="min">SWAT output print setting</th>
											<td>{{scenario.printSetting}}</td>
										</tr>
										<tr>
											<th class="min">SWAT model version</th>
											<td>{{scenario.swatVersion}}</td>
										</tr>
									</table>
								</b-col>
								<b-col md="5" v-if="scenario.status.hasRun && !isNullOrEmpty(scenario.status.lastRunDate)">
									<table class="table table-inner-borders mb-4">
										<tr>
											<th class="min">SWAT editor tables</th>
											<td v-if="scenario.runSettings.writeSwatEditor">{{scenario.runSettings.writeSwatEditorAsSqlite ? 'SQLite database' : 'Access database'}}</td>
											<td v-else>Not included</td>
										</tr>
										<tr v-if="scenario.runSettings.noModelRun">
											<th class="text-danger" colspan="2">SWAT model run skipped</th>
										</tr>
										<tr v-if="scenario.runSettings.noOutputProcessing">
											<th class="text-danger" colspan="2">SWAT output processing skipped</th>
										</tr>
									</table>
								</b-col>
							</b-row>

							<div class="mt-3" v-if="scenario.status.hasRun && !isNullOrEmpty(scenario.status.lastRunDate)">
								<span class="foot-pill">Last ran on {{scenario.status.lastRunDate | date}}. The run took {{scenario.status.runtime}}.</span>
							</div>
							<div class="mt-3">
								<span class="foot-pill">Last modified on {{scenario.status.lastModifiedDate | date}}</span>
							</div>
							<div class="mt-3">
								<span class="foot-pill">Created on {{scenario.status.createdDate | date}}</span>
							</div>
						</b-tab>
						<b-tab :title="tabs[1]">
							<b-row>
								<b-col md>
									<b-card header-bg-variant="light" no-body class="border mb-3">
										<div slot="header" class="font-weight-bold mb-0">General watershed inputs and databases</div>
										<b-list-group flush>
											<b-list-group-item to="calibration" append class="d-flex justify-content-between align-items-center">
												<span>Calibration data</span>
											</b-list-group-item>
											<b-list-group-item to="bsn" append class="d-flex justify-content-between align-items-center">
												<span>Basin input data</span>
												<check-icon v-if="scenario.modTables.includes('Bsn')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="fert" append class="d-flex justify-content-between align-items-center">
												<span>Fertilizers</span>
												<check-icon v-if="scenario.modTables.includes('Fert')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="nutrient-efficiency" append class="d-flex justify-content-between align-items-center">
												<span>Nutrient efficiency</span>
												<check-icon v-if="scenario.modTables.includes('AutoEff')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="urban" append class="d-flex justify-content-between align-items-center">
												<span>Urban input data</span>
												<check-icon v-if="scenario.modTables.includes('Urban')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="lup" append class="d-flex justify-content-between align-items-center">
												<span>Land use update</span>
												<check-icon v-if="scenario.modTables.includes('Lup')"></check-icon>
											</b-list-group-item>
										</b-list-group>
									</b-card>

									<b-card header-bg-variant="light" no-body class="border mb-3">
										<div slot="header" class="font-weight-bold mb-0">Weather data</div>
										<b-list-group flush>
											<b-list-group-item to="climate-sensitivity" append class="d-flex justify-content-between align-items-center">
												<span>Climate sensitivity/variability analysis</span>
												<check-icon v-if="scenario.modTables.includes('ClimateSensitivity')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="weather-generator" append class="d-flex justify-content-between align-items-center">
												<span>Weather generator</span>
												<check-icon v-if="scenario.modTables.includes('Wgn')"></check-icon>
											</b-list-group-item>
										</b-list-group>
									</b-card>

									<b-card header-bg-variant="light" no-body class="border mb-3">
										<div slot="header" class="font-weight-bold mb-0">SWAT output to print</div>
										<b-list-group flush>
											<b-list-group-item to="hru-vars" append class="d-flex justify-content-between align-items-center">
												<span>HRU variables to print</span>
												<check-icon v-if="scenario.modTables.includes('HruVars')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="hru-print" append class="d-flex justify-content-between align-items-center">
												<span>HRUs to print</span>
												<check-icon v-if="scenario.modTables.includes('HruPrint')"></check-icon>
											</b-list-group-item>
										</b-list-group>
									</b-card>
								</b-col>
								<b-col md>
									<b-card header-bg-variant="light" no-body class="border mb-3">
										<div slot="header" class="font-weight-bold mb-0">Subbasin inputs</div>
										<b-list-group flush>
											<b-list-group-item to="curve-number" append class="d-flex justify-content-between align-items-center">
												<span>Curve number</span>
												<check-icon v-if="scenario.modTables.includes('Cn2Percent')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="potholes" append class="d-flex justify-content-between align-items-center">
												<span>Pothole variables</span>
												<check-icon v-if="scenario.modTables.includes('Potholes')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="sediment-routing" append class="d-flex justify-content-between align-items-center">
												<span>Sediment routing method</span>
												<check-icon v-if="scenario.modTables.includes('RteChEqn')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="point-source" append class="d-flex justify-content-between align-items-center">
												<span>Point source</span>
												<check-icon v-if="scenario.modTables.includes('Ptsrc')"></check-icon>
											</b-list-group-item>
										</b-list-group>
									</b-card>

									<b-card header-bg-variant="light" no-body class="border mb-3">
										<div slot="header" class="font-weight-bold mb-0">Ag management/BMPs/Conservation practices</div>
										<b-list-group flush>
											<b-list-group-item to="mgt-general" append class="d-flex justify-content-between align-items-center">
												<span>General parameters</span>
												<check-icon v-if="scenario.modTables.includes('Mgt1')"></check-icon>
											</b-list-group-item>
											<b-list-group-item to="mgt-operations" append class="d-flex justify-content-between align-items-center">
												<span>Operations management</span>
												<check-icon v-if="scenario.modTables.includes('Mgt2')"></check-icon>
											</b-list-group-item>
										</b-list-group>
									</b-card>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab :title="tabs[2]" v-if="scenario.status.hasRun">
							<p>
								This scenario last ran on {{scenario.status.lastRunDate | date}}. The run took {{scenario.status.runtime}}.
								<br />The scenario output will be kept on our system until <span class="text-danger">{{keepUntilDate | date('ll')}}</span>.
							</p>

							<b-card header-bg-variant="light" no-body class="border mb-3">
								<b-list-group flush>
									<b-list-group-item to="output/swat-check" append>Run SWAT Check to identify potential model problems</b-list-group-item>
									<b-list-group-item to="output/charts" append>Output charts</b-list-group-item>
									<b-list-group-item to="output/maps" append>Output maps</b-list-group-item>
									<b-list-group-item v-if="siteSettings.includeCeQual" to="output/ce-qual" append>CE-QUAL-W2</b-list-group-item>
									<b-list-group-item v-if="siteSettings.includeFfa" to="output/ffa" append>Flood Frequency Analysis</b-list-group-item>
									<b-list-group-item v-if="siteSettings.includeBaseflowSeparation" to="output/baseflowseparation" append>Baseflow Separation</b-list-group-item>
								</b-list-group>
							</b-card>
						</b-tab>
					</b-tabs>

					<fixed-action-bar>
						<span v-if="$parent.$parent.withinTierLimits">
							<b-dropdown v-if="scenario.status.hasRun && !isNullOrEmpty(scenario.status.zipFile)" variant="success" split :split-href="scenario.status.zipFile" text="Download Scenario" class="mr-2">
								<b-dropdown-item @click="page.run.show = true">Re-run Scenario</b-dropdown-item>
							</b-dropdown>
							<b-button v-else variant="success" @click="page.run.show = true" class="mr-2">Run Scenario</b-button>
						</span>
						<b-button v-else-if="scenario.status.hasRun && !isNullOrEmpty(scenario.status.zipFile)" variant="success" :href="scenario.status.zipFile" class="mr-2">Download Scenario</b-button>

						<b-dropdown variant="primary" text="Edit/Copy Settings" class="mr-2">
							<b-dropdown-item-button @click="openScenarioForm">Edit Settings</b-dropdown-item-button>
							<b-dropdown-item-button @click="page.copy.show = true">Copy Settings</b-dropdown-item-button>
						</b-dropdown>

						<back-button class="btn btn-secondary mr-2" />
						<b-button variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
					</fixed-action-bar>

					<b-modal v-model="show.scenarioForm" size="lg" title="Edit scenario" hide-footer>
						<scenario-form :data="scenarioForm" is-update @saved="saved" show-cancel-button></scenario-form>
					</b-modal>

					<b-modal v-model="page.run.show" title="Run scenario">
						<error-list :errors="page.run.errors"></error-list>

						<div v-if="scenario.status.canRun">
							<b-alert variant="warning" :show="scenario.status.hasRun">
								Please note any previous runs of this scenario will be overwritten.
								Be sure to download your files first if you wish to keep them.
							</b-alert>

							<p>
								Click the button below to run the scenario.
								This will write your SWAT input files and SWAT editor tables (if checked),
								run the model, and process model outputs.
								If you still want to change your SWAT input data, click the cancel button.
							</p>

							<p>
								Estimated run time: <span class="font-weight-bold mr-2">{{page.run.writeTables ? scenario.runtimeEstimateWithEditor : scenario.runtimeEstimate}}</span>
								<span v-b-tooltip.hover.bottom title="Estimated from your number of HRUs and simulation years. Subject to fluctuation based on number of concurrent users and projects running at any given time.">
									<font-awesome-icon icon="info-circle" class="text-info" />
								</span>
							</p>

							<div class="mt-4">
								<b-form-checkbox v-model="page.run.writeTables">Write SWAT editor tables</b-form-checkbox>
							</div>
							<div class="mt-2 pl-4" v-if="page.run.writeTables && scenario.canDownloadSqlite">
								<b-form-group label="Format:">
									<b-form-select v-model="page.run.writeTablesFormat">
										<b-form-select-option value="mdb">Access database</b-form-select-option>
										<b-form-select-option value="sqlite">SQLite database</b-form-select-option>
									</b-form-select>
								</b-form-group>
							</div>
							<div class="mt-2" v-if="scenario.canIncludeModflow">
								<b-form-checkbox v-model="page.run.includeModflow">Create MODFLOW files</b-form-checkbox>
							</div>
							<div class="mt-2">
								<b-form-checkbox v-model="page.run.skipModelRun" @change="setModelRunOption">Skip model run (write inputs only)</b-form-checkbox>
							</div>
							<div class="mt-2">
								<b-form-checkbox v-model="page.run.skipOutputProcessing" :disabled="page.run.skipModelRun">Skip output processing (charts, maps, csv will be unavailable)</b-form-checkbox>
							</div>
							<div class="mt-2">
								<b-form-checkbox v-model="page.run.emailMe">Email me when run completes</b-form-checkbox>
							</div>
						</div>
						<div v-else>
							<b-alert variant="warning" show>
								<p>
									Unable to run your scenario at this time. {{scenario.status.runLimit}}
								</p>
								<div>
									<router-link to="/account">Go to my account information</router-link><br />
									<a href="#" @click.prevent="get()">Refresh run status</a>
								</div>
							</b-alert>
						</div>

						<div slot="modal-footer">
							<save-button v-if="scenario.status.canRun" type="button" :saving="page.run.saving" @click.native="run" text="Run Scenario" variant="success" />
							<b-button type="button" variant="secondary" @click="page.run.show = false" class="ml-1">Cancel</b-button>
						</div>
					</b-modal>

					<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
						<error-list :errors="page.delete.errors"></error-list>

						<p>
							Are you sure you want to delete this scenario?
							This action is permanent and cannot be undone. All files related to this scenario will be removed.
						</p>

						<div slot="modal-footer">
							<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
							<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
						</div>
					</b-modal>

					<b-modal v-model="page.copy.show" title="Copy scenario settings">
						<error-list :errors="page.copy.errors"></error-list>

						<p>
							Copy this scenario's settings to a new scenario.
							All input customizations will be copied, but the model run will not.
						</p>

						<b-form-group label="Provide a name for the new scenario">
							<b-form-input v-model="page.copy.name" type="text" required></b-form-input>
						</b-form-group>

						<div slot="modal-footer">
							<save-button type="button" :saving="page.copy.saving" @click.native="copyScenario" text="Copy Scenario" variant="success" />
							<b-button type="button" variant="secondary" @click="page.copy.show = false" class="ml-1">Cancel</b-button>
						</div>
					</b-modal>
				</div>

				<b-modal v-model="page.abort.show" size="md" title="Confirm run cancellation" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to abort the current run of this scenario?
						All files related to the model run will be deleted.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.abort.saving" @click.native="abortRun" text="Yes, Abort" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.abort.show = false" class="ml-1">No</b-button>
					</div>
				</b-modal>
			</page-authorization-container>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import ScenarioForm from '@/views/projects/scenarios/ScenarioForm';
	import moment from 'moment';

	export default {
		name: 'ScenarioView',
		components: {
			ScenarioForm
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				currentRunTime: '',
				timer: '',
				page: {
					errors: [],
					loading: true,
					showLogin: false,
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					run: {
						show: false,
						errors: [],
						saving: false,
						writeTables: false,
						writeTablesFormat: 'mdb',
						emailMe: false,
						skipModelRun: false,
						skipOutputProcessing: false,
						includeModflow: false
					},
					errorReport: {
						show: false,
						errors: [],
						saving: false
					},
					copy: {
						show: false,
						errors: [],
						saving: false,
						name: ''
					},
					abort: {
						show: false,
						errors: [],
						saving: false
					}
				},
				scenario: {
					status: {},
					modTables: [],
					runSettings: {
						writeSwatEditor: false,
						writeSwatEditorAsSqlite: false,
						noModelRun: false,
						noOutputProcessing: false
					}
				},
				progress: {
					percent: 0,
					message: 'Please wait...'
				},
				tabIndex: 0,
				numTabs: 2,
				tabs: [
					'Overview',
					'Customize SWAT Inputs',
					'Output Data'
				],
				show: {
					scenarioForm: false
				},
				scenarioForm: undefined,
				errorForm: {
					projectID: this.$route.params.id,
					scenarioID: this.$route.params.scenarioID,
					title: '',
					message: '',
					exception: '',
					userComments: '',
					clearError: true
				}
			}
		},
		async created() {
			await this.get();
		},
		beforeDestroy() {
			this.$statusHub.scenarioClosed(this.scenarioID);
			this.$statusHub.$off('scenario-status-changed', this.onStatusChanged);
			this.cancelTimer();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get(initialize = true) {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;

				//if (this.$route.name === 'ScenarioView') {
					this.page.errors = [];
					this.page.loading = true;
					this.show.scenarioForm = false;

					try {
						const response = await this.$http.get(`scenarios/${this.scenarioID}`, this.getTokenHeader());
						this.log(response.data);
						this.scenario = response.data;

						this.page.run.writeTables = this.scenario.runSettings.writeSwatEditor;
						this.page.run.writeTablesFormat = this.scenario.runSettings.writeSwatEditorAsSqlite ? 'sqlite' : 'mdb';
						this.page.run.skipModelRun = this.scenario.runSettings.noModelRun;
						this.page.run.skipOutputProcessing = this.scenario.runSettings.noOutputProcessing;

						if (this.scenario.status.hasRun) {
							this.numTabs++;
						}

						if (this.scenario.status.isRunning) {
							this.progress.message = this.scenario.status.statusMessage;
							this.progress.percent = this.scenario.status.currentProgress;
						}

						if (this.scenario.projectID != this.projectID) {
							this.page.errors.push('Invalid scenario ID for this project. Please check the URL in your browser.')
						}
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				//}

				if (initialize) {
					this.$statusHub.scenarioOpened(this.scenarioID);
					this.$statusHub.$on('scenario-status-changed', this.onStatusChanged);

					this.setCurrentRunTime();
					this.timer = setInterval(this.setCurrentRunTime, 1000);
				}
			},
			openScenarioForm() {
				this.scenarioForm = {
					id: this.scenario.id,
					projectID: this.scenario.projectID,
					name: this.scenario.name,
					startingSimulationDate: this.scenario.startingSimulationDate,
					endingSimulationDate: this.scenario.endingSimulationDate,
					weatherDatasetID: this.scenario.weatherDatasetID,
					climateChangeScenarioID: this.scenario.climateChangeScenarioID,
					climateChangeTimePeriodID: this.scenario.climateChangeTimePeriodID,
					swatVersionID: this.scenario.swatVersionID,
					printSetting: this.scenario.printSettingID,
					skipYears: this.scenario.skipYears,
					pathName: this.scenario.pathName
				};

				this.show.scenarioForm = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/${this.scenarioID}`, this.getTokenHeader());
					this.$router.push({ name: 'ProjectView', params: { id: this.projectID }}).catch(err => {});
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async saved(response) {
				if (response == 'success') {
					await this.get(false);
				}
				this.show.scenarioForm = false;
			},
			async run() {
				try {
					var model = {
						createEditorTables: this.page.run.writeTables,
						sendEmailNotification: this.page.run.emailMe,
						skipOutputProcessing: this.page.run.skipOutputProcessing,
						includeModflow: this.page.run.includeModflow,
						skipModelRun: this.page.run.skipModelRun,
						writeEditorTablesAsSqlite: this.page.run.writeTablesFormat === 'sqlite',
					};
					const response = await this.$http.post(`scenarios/run/${this.scenarioID}`, model, this.getTokenHeader());
					this.log(response.data);
					this.scenario.status.isRunning = true;
					this.scenario.status.startRunDate = moment().toDate();
					this.progress.percent = 0;
					this.progress.message = "In the queue, preparing to run...";
					this.page.run.show = false;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async clearError() {
				try {
					await this.$http.get(`scenarios/clearerror/${this.scenarioID}`, this.getTokenHeader());
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async abortRun() {
				try {
					await this.$http.get(`scenarios/abort/${this.scenarioID}`, this.getTokenHeader());
					this.page.abort.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.abort.errors = this.logError(error);
				}
			},
			async onStatusChanged(data) {
				if (this.scenarioID != data.scenarioID) return;
				if (data.progress == 100) {
					await this.get(false);
				}

				this.progress.percent = data.progress;
				this.progress.message = data.statusMessage;
			},
			async submitErrorReport() {
				try {
					this.errorForm.exception = this.scenario.status.exceptionMessage;
					this.errorForm.title = this.scenario.status.statusMessage;
					this.errorForm.message = this.scenario.status.statusMessage;
					const response = await this.$http.post(`errorreports`, this.errorForm, this.getTokenHeader());
					await this.get();
					this.$refs.reportSubmittedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errorReport.errors = this.logError(error);
				}
			},
			async copyScenario() {
				if (this.isNullOrEmpty(this.page.copy.name)) {
					this.page.copy.errors.push('Please provide a name for the new scenario.');
				} else {
					try {
						var data = {
							value: this.page.copy.name
						};
						const response = await this.$http.post(`scenarios/copy/${this.scenarioID}`, data, this.getTokenHeader());
						this.$router.push({ name: 'ScenarioView', params: { id: this.projectID, scenarioID: response.data } }).catch(err => { });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.copy.errors = this.logError(error);
					}
				}
			},
			setCurrentRunTime() {
				this.currentRunTime = this.isNullOrEmpty(this.scenario.status.startRunDate) ? "" : moment(this.scenario.status.startRunDate).fromNow(true);
			},
			cancelTimer() {
				clearInterval(this.timer);
			},
			setModelRunOption() {
				if (this.page.run.skipModelRun) this.page.run.skipOutputProcessing = true;
			}
		},
		computed: {
			weatherDatasetDescription() {
				var d = this.scenario.weatherDataset;

				if (!this.isNullOrEmpty(this.scenario.climateChangeScenario))
					d += ', ' + this.scenario.climateChangeScenario;

				if (!this.isNullOrEmpty(this.scenario.climateChangeTimePeriod))
					d += ', ' + this.scenario.climateChangeTimePeriod;

				return d;
			},
			keepUntilDate() {
				return moment(this.scenario.status.lastRunDate).add(this.scenario.status.keepMonths, 'months');
			}
		}
	}
</script>

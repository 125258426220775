<template>
	<div>
		<page-authorization-container :page="page">
			<div v-if="$route.name == 'ApiBatchList'">
				<h2 class="mb-3">Batch project runs</h2>

				<grid-view api-url="api-requests/batch"
						   :fields="table.fields"
						   :default-sort="table.sort" :default-reverse="table.reverse"
						   collection-description="batch requests"
						   item-name="batch request"></grid-view>

				<fixed-action-bar :cols-lg="10" :offset-lg="2">
					<b-button to="create" append class="mr-2">Create a batch request</b-button>
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</div>
			<router-view></router-view>
		</page-authorization-container>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'ApiBatchList',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true, class: 'min-w-200' },
						{ key: 'status', sortable: true },
						{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
						{ key: 'createdDate', sortable: true, class: 'nowrap', formatter: (value) => { return moment(value).format('M/D/YYYY h:mm') } }
					],
					sort: 'lastModifiedDate',
					reverse: true,
					itemsPerPage: 50
				}
			}
		}
	}
</script>

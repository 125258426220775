<template>
	<div>
		<page-authorization-container :page="page" show-errors-with-object>
			<h2 class="mb-3">Create a new batch</h2>

			<error-list :errors="page.saveErrors"></error-list>

			<div class="mb-3">
				<p>
					Drag and drop a CSV file into the space below.
					Must contain a column named "Ending_Subbasin".
					If you want the project to start from somewhere other than the head, include a column "Starting_Subbasins".
					Values in this column should be comma-separated if more than one.
					Please include leading zeros in subbasin names.
				</p>

				<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
			</div>

			<b-form @submit.prevent="save">
				<b-form-group label="Batch name">
					<b-form-input v-model="form.name" type="text" :state="getValidState($v.form.name)"></b-form-input>
					<b-form-invalid-feedback v-if="!$v.form.name.required">Required</b-form-invalid-feedback>
					<b-form-invalid-feedback v-if="!$v.form.name.hasAlphaNumeric">Must contain at least one letter or number</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group :label="`${siteText.siteName} dataset`" :invalid-feedback="requiredFeedback($v.form.batch.datasetID)">
					<b-form-select v-model="form.batch.datasetID" @change="getDataset" :state="getValidState($v.form.batch.datasetID)">
						<b-form-select-option-group v-for="(o, i) in options.datasets" :key="i" :label="isNullOrEmpty(o.label) ? 'System datasets' : o.label" :options="o.options"></b-form-select-option-group>
						<b-form-select-option-group v-if="options.userDatasets.length > 0" label="User-submitted datasets" :options="options.userDatasets"></b-form-select-option-group>
					</b-form-select>
				</b-form-group>

				<page-loading :loading="page.dataset.loading"></page-loading>
				<div v-if="!isNullOrEmpty(form.batch.datasetID) && !page.dataset.loading">
					<h3 class="mt-4 mb-3 h5">Batch options</h3>
					<div class="my-3">
						<b-form-checkbox v-model="form.batch.setAsSingleSubbasinProjects">Create single subbasin projects (no routing to head of watershed; "Starting_Subbasins" column in CSV will be ignored)</b-form-checkbox>
					</div>
					<div class="my-3">
						<b-form-checkbox v-model="form.batch.useSubbasinAsNames">Use subbasin name as the project and scenario name</b-form-checkbox>
					</div>
					<div class="my-3" v-if="false">
						<b-form-checkbox v-model="form.batch.deleteProjectsWhenDone">Delete projects from {{siteText.siteName}} system when done (recommended unless you plan to use the project in the website GUI)</b-form-checkbox>
					</div>

					<h3 class="mt-4 mb-3 h5">HRU settings</h3>

					<b-row>
						<b-col md>
							<b-form-group label="Select a method for reducing HRUs" :invalid-feedback="requiredFeedback($v.form.batch.setHruMethod)">
								<b-form-select v-model="form.batch.setHruMethod" :options="options.hruMethods" :state="getValidState($v.form.batch.setHruMethod)"></b-form-select>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Amount/Target" :invalid-feedback="requiredFeedback($v.form.batch.targetThreshold)">
								<b-form-input v-model="form.batch.targetThreshold" type="number" step="any" :state="getValidState($v.form.batch.targetThreshold)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Units" :invalid-feedback="requiredFeedback($v.form.batch.setHruUnits)">
								<b-form-select v-model="form.batch.setHruUnits" :options="options.hruUnits" :state="getValidState($v.form.batch.setHruUnits)"></b-form-select>
							</b-form-group>
						</b-col>
					</b-row>

					<b-form-group label="Exempt land use">
						<b-form-input v-model="formattedForm.exemptLanduse" type="text"></b-form-input>
					</b-form-group>
					<b-form-group label="Land use to NOT redistribute area to">
						<b-form-input v-model="formattedForm.noAreaRedistribution" type="text"></b-form-input>
					</b-form-group>

					<h3 class="mt-4 mb-3 h5">Simulation settings</h3>
					<b-form-group label="Weather data" :invalid-feedback="requiredFeedback($v.form.batch.weatherDatasetID)">
						<b-form-select v-model="form.batch.weatherDatasetID" :state="getValidState($v.form.batch.weatherDatasetID)" @change="updateWeatherOptions">
							<optgroup v-for="(group, i) in datasetOptions.weatherDatasets" :key="i" :label="group.label">
								<option v-for="(opt, j) in group.options" :key="j" :value="opt.id">{{opt.name}}</option>
							</optgroup>
						</b-form-select>
					</b-form-group>

					<b-row v-if="weatherOptions.showClimateChange">
						<b-col md>
							<b-form-group label="Climate time period">
								<b-form-select v-model="form.batch.climateChangeTimePeriodID" @change="updateClimateTimePeriod">
									<option v-for="(opt, i) in climateTimePeriodOptions" :key="i" :value="opt.id">{{opt.name}}</option>
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Climate scenario">
								<b-form-select v-model="form.batch.climateChangeScenarioID">
									<option v-for="(opt, i) in climateScenarioOptions" :key="i" :value="opt.id">{{opt.name}}</option>
								</b-form-select>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="Simulation start date" :invalid-feedback="requiredFeedback($v.form.batch.startingSimulationDate)">
								<b-form-input v-model="form.batch.startingSimulationDate" type="date" :state="getValidState($v.form.batch.startingSimulationDate)"></b-form-input>
								<small class="form-text text-muted">{{weatherOptions.minDate | date('MM/DD/YYYY')}} or later</small>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Simulation end date" :invalid-feedback="requiredFeedback($v.form.batch.endingSimulationDate)">
								<b-form-input v-model="form.batch.endingSimulationDate" type="date" :state="getValidState($v.form.batch.endingSimulationDate)"></b-form-input>
								<small class="form-text text-muted">{{weatherOptions.maxDate | date('MM/DD/YYYY')}} or earlier</small>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="Set-up/warm-up years" :invalid-feedback="requiredFeedback($v.form.batch.skipYears)">
								<b-form-input v-model="form.batch.skipYears" type="number" :state="getValidState($v.form.batch.skipYears)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="SWAT output print setting" :invalid-feedback="requiredFeedback($v.form.batch.printSetting)">
								<b-form-select v-model="form.batch.printSetting" :options="options.printSettings" :state="getValidState($v.form.batch.printSetting)"></b-form-select>
							</b-form-group>
						</b-col>
					</b-row>

					<b-form-group label="SWAT model version to run" :invalid-feedback="requiredFeedback($v.form.batch.swatVersionID)">
						<b-form-select v-model="form.batch.swatVersionID" :options="datasetOptions.swatVersions" :state="getValidState($v.form.batch.swatVersionID)"></b-form-select>
					</b-form-group>

					<div class="my-3">
						<b-form-checkbox v-model="form.batch.writeSwatEditor">Write SWAT editor tables</b-form-checkbox>
					</div>
					<div class="my-3">
						<b-form-checkbox v-model="form.batch.noModelRun">Do NOT run the model (create input files only)</b-form-checkbox>
					</div>
					<div class="my-3">
						<b-form-checkbox v-model="form.batch.noOutputProcessing">Do NOT process model output files into SQLite</b-form-checkbox>
					</div>
				</div>

				<fixed-action-bar :cols-lg="10" :offset-lg="2">
					<save-button :saving="page.saving" class="mr-2" :disabled="form.batch.projectSubbasins.length < 1" text="Submit Batch" />
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import moment from 'moment';

	export default {
		name: 'ApiBatchCreate',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					uploadOk: false,
					dataset: {
						errors: [],
						loading: false
					},
					saving: false,
					saveErrors: []
				},
				dropzoneOptions: {
					url: '/api/api-requests/uploadbatch',
					headers: this.getTokenHeaderPart(),
					acceptedFiles: '.csv',
					timeout: 3600000,
					maxFilesize: 1000
				},
				options: {
					datasets: [],
					userDatasets: [],
					printSettings: [],
					hruMethods: [],
					hruUnits: [],
					defaultDataset: null,
					defaultWetlands: null,
					defaultSetHruMethod: null,
					defaultSetHruUnits: null
				},
				datasetOptions: {
					climateChangeScenarios: [],
					climateChangeTimePeriods: [],
					swatVersions: [],
					weatherDatasets: [],
					defaultSwatVersion: null,
					defaultWeatherDataset: null
				},
				weatherOptions: {
					selectedDataset: undefined,
					minDate: undefined,
					maxDate: undefined,
					showClimateChange: false
				},
				form: {
					name: null,
					batch: {
						datasetID: null,
						projectSubbasins: [],
						setAsSingleSubbasinProjects: false,
						useSubbasinAsNames: true,
						startYear: null,
						endYear: null,
						skipYears: 2,
						printSetting: 0,
						setHruMethod: 'area',
						setHruUnits: 'area',
						exemptLanduse: [],
						noAreaRedistribution: [],
						targetThreshold: 0.5,
						weatherDatasetID: null,
						climateChangeScenarioID: null,
						climateChangeTimePeriodID: null,
						swatVersionID: null,
						noOutputProcessing: true,
						noModelRun: false,
						writeSwatEditor: false,
						deleteProjectsWhenDone: false
					}
				},
				formattedForm: {
					exemptLanduse: null,
					noAreaRedistribution: null
				}
			}
		},
		validations: {
			form: {
				name: {
					required,
					hasAlphaNumeric(value) {
						return (
							/[a-z]/.test(value) || // checks for a-z
							/[0-9]/.test(value) // checks for 0-9
						);
					}
				},
				batch: {
					datasetID: { required },
					projectSubbasins: {},
					setAsSingleSubbasinProjects: {},
					useSubbasinAsNames: {},
					startingSimulationDate: { required },
					endingSimulationDate: { required },
					skipYears: { required },
					printSetting: { required },
					setHruMethod: {},
					setHruUnits: {},
					exemptLanduse: {},
					noAreaRedistribution: {},
					targetThreshold: {},
					weatherDatasetID: { required },
					climateChangeScenarioID: {},
					climateChangeTimePeriodID: {},
					swatVersionID: { required },
					noOutputProcessing: {},
					noModelRun: {},
					writeSwatEditor: {},
					deleteProjectsWhenDone: {}
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`api-requests/batchoptions`, this.getTokenHeader());
					this.log(response.data);
					this.options = response.data;

					this.form.batch.datasetID = response.data.defaultDataset;
					this.form.batch.setHruMethod = response.data.defaultSetHruMethod;
					this.form.batch.setHruUnits = response.data.defaultSetHruUnits;

					if (this.isNullOrEmpty(this.form.batch.datasetID) && this.options.datasets.length > 0 && this.options.datasets[0].options.length > 0) {
						this.form.batch.datasetID = this.options.datasets[0].options[0].value;
					}

					if (!this.isNullOrEmpty(this.form.batch.datasetID)) {
						await this.getDataset();
					}

					let wetlands = response.data.defaultWetlands;
					if (!this.isNullOrEmpty(wetlands) && wetlands.length > 0) {
						this.formattedForm.exemptLanduse = wetlands.join(', ');
						this.formattedForm.noAreaRedistribution = wetlands.join(', ');
					}
					
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getDataset() {
				this.page.dataset.errors = [];
				this.page.dataset.loading = true;

				if (this.isNullOrEmpty(this.form.batch.datasetID)) {
					this.page.dataset.errors.push('Please select a dataset.');
				} else {
					try {
						const response = await this.$http.get(`api-requests/batchdatasetoptions/${this.form.batch.datasetID}`, this.getTokenHeader());
						this.log(response.data);
						this.datasetOptions = response.data;
						this.form.batch.weatherDatasetID = response.data.defaultWeatherDataset;
						this.form.batch.swatVersionID = response.data.defaultSWATVersion;
						this.updateWeatherOptions(true);
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.dataset.errors = this.logError(error);
					}
				}

				this.page.dataset.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						this.form.batch.exemptLanduse = this.splitString(this.formattedForm.exemptLanduse);
						this.form.batch.noAreaRedistribution = this.splitString(this.formattedForm.noAreaRedistribution);

						const response = await this.$http.post('api-requests/createbatch', this.form, this.getTokenHeader());
						this.$router.push({ name: 'ApiBatchView', params: { id: response.data } }).catch(err => { });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			fileUploaded(file, response) {
				this.log(response);
				this.form.batch.projectSubbasins = response.projectSubbasins;
				this.form.name = response.name;
				this.page.uploadOk = true;
				this.page.saveErrors = [];
			},
			uploadError(file, message, xhr) {
				this.form.batch.projectSubbasins = [];
				this.page.uploadOk = false;
				this.log(file);
				this.log(xhr);
				this.log(message);
				this.page.saveErrors.push(message.errors.exception[0]);
			},
			updateWeatherOptions(setDefaults = true) {
				for (var i = 0; i < this.datasetOptions.weatherDatasets.length; i++) {
					var index = this.datasetOptions.weatherDatasets[i].options.findIndex(x => x.id == this.form.batch.weatherDatasetID);
					if (index > -1) {
						var dataset = this.datasetOptions.weatherDatasets[i].options[index];
						this.weatherOptions.selectedDataset = dataset;
						this.weatherOptions.minDate = moment(dataset.startDate).format('YYYY-MM-DD');
						this.weatherOptions.maxDate = moment(dataset.endDate).format('YYYY-MM-DD');
						this.weatherOptions.showClimateChange = !this.isNullOrEmpty(dataset.cmipVersion);

						if (setDefaults) {
							this.form.batch.startingSimulationDate = this.weatherOptions.minDate;
							this.form.batch.endingSimulationDate = this.weatherOptions.maxDate;

							if (this.weatherOptions.showClimateChange) {
								this.form.batch.climateChangeScenarioID = this.climateScenarioOptions[0].id;
								this.form.batch.climateChangeTimePeriodID = this.climateTimePeriodOptions[0].id;
							}
						} 
					}
				}
			},
			updateClimateTimePeriod() {
				var index = this.datasetOptions.climateChangeTimePeriods.findIndex(x => x.id == this.form.batch.climateChangeTimePeriodID);
				if (index > -1) {
					var dataset = this.datasetOptions.climateChangeTimePeriods[index];
					this.weatherOptions.minDate = moment(dataset.startDate).format('YYYY-MM-DD');
					this.weatherOptions.maxDate = moment(dataset.endDate).format('YYYY-MM-DD');
					this.form.batch.startingSimulationDate = this.weatherOptions.minDate;
					this.form.batch.endingSimulationDate = this.weatherOptions.maxDate;
				}
			}
		},
		computed: {
			climateScenarioOptions() {
				if (this.weatherOptions.showClimateChange) {
					var selectedCMIP = this.weatherOptions.selectedDataset.cmipVersion;
					return this.datasetOptions.climateChangeScenarios.filter(function (el) { return el.cmipVersion == selectedCMIP; });
				}

				return this.datasetOptions.climateChangeScenarios;
			},
			climateTimePeriodOptions() {
				if (this.weatherOptions.showClimateChange) {
					var selectedCMIP = this.weatherOptions.selectedDataset.cmipVersion;
					return this.datasetOptions.climateChangeTimePeriods.filter(function (el) { return el.cmipVersion == selectedCMIP; });
				}

				return this.datasetOptions.climateChangeTimePeriods;
			}
		}
	}
</script>

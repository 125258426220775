<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">Update feedback / error report</h2>

			<b-card bg-variant="light" class="mb-4">
				<b-card-text>
					Submitted on {{data.dateSubmitted | date}} by
					<router-link :to="`/admin/users/${data.userID}`">{{data.userName}}</router-link>
					<div v-if="!isNullOrEmpty(data.project)">
						In reference to project <router-link :to="`/projects/${data.projectID}`">{{data.project}}</router-link>
						<span v-if="!isNullOrEmpty(data.scenario)">
							scenario <router-link :to="`/projects/${data.projectID}/scenarios/${data.scenarioID}`">{{data.scenario}}</router-link>
						</span>
					</div>
				</b-card-text>				
			</b-card>

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<b-row>
					<b-col md>
						<b-form-group label="Report title">
							<b-form-input v-model="data.title" type="text"></b-form-input>
						</b-form-group>

						<b-form-group label="Exception message">
							<b-form-textarea v-model="data.exception" rows="10" readonly></b-form-textarea>
						</b-form-group>

						<b-form-group label="User comments">
							<b-form-textarea v-model="data.userComments" rows="10" readonly></b-form-textarea>
						</b-form-group>
					</b-col>
					<b-col md>
						<b-form-group label="Report status">
							<b-form-select v-model="data.status" :options="form.statusOptions"></b-form-select>
							<small v-if="!isNullOrEmpty(data.resolvedDate)">
								Resolved on {{data.resolvedDate | date}}
							</small>
						</b-form-group>

						<b-form-group label="Resolved by">
							<b-form-input v-model="data.resolvedBy" type="text"></b-form-input>
						</b-form-group>

						<b-form-group label="Developer comments">
							<b-form-textarea v-model="data.developerComments" rows="10"></b-form-textarea>
						</b-form-group>

						<b-form-group>
							<b-checkbox v-model="form.emailUser">Email developer comments to user?</b-checkbox>
						</b-form-group>

						<b-form-group label="Copy email to (optional)" v-if="form.emailUser">
							<b-form-input v-model="form.ccEmail" type="text"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>				

				<fixed-action-bar :cols-lg="10" :offset-lg="2">
					<save-button :saving="page.saving" class="mr-2" />
					<back-button class="btn btn-secondary mr-2" />
					<b-button variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
				</fixed-action-bar>
			</b-form>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this error report?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</page-authorization-container>
	</div>
</template>

<script>
	export default {
		name: 'AdminErrorView',
		data() {
			return {
				reportID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				data: {},
				form: {
					emailUser: false,
					ccEmail: null,
					statusOptions: [
						{ value: 'Submitted', text: 'Submitted' },
						{ value: 'Working', text: 'Working' },
						{ value: 'Resolved', text: 'Resolved' }
					]
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.reportID = this.$route.params.id;

				try {
					const response = await this.$http.get(`admin/errorreports/${this.reportID}`, this.getTokenHeader());
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					var data = {
						id: this.data.id,
						title: this.data.title,
						message: this.data.message,
						status: this.data.status,
						developerComments: this.data.developerComments,
						resolvedBy: this.data.resolvedBy,
						emailUser: this.form.emailUser,
						ccEmail: this.form.ccEmail
					};
					const response = await this.$http.put(`admin/errorreports/${this.reportID}`, data, this.getTokenHeader());
					this.log(response.data);
					await this.get();
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`errorreports/${this.reportID}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$router.push({ name: 'AdminErrorReports' }).catch(err => {});
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>

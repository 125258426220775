<template>
	<div>
		<page-authorization-container :page="page" show-errors-with-object>
			<h2 class="mb-4">Upload a new project dataset</h2>

			<p>
				Upload a SWAT project that has been modified offline.
				Once uploaded, you may create new projects based on your dataset for use in the {{siteText.siteName}} system.
				Your project dataset can be shared with your groups or be made public
				for use by all {{siteText.siteName}} users (administrator approval required).
			</p>

			<b-card no-body header-bg-variant="info" header-text-variant="white" class="mb-3">
				<template v-slot:header>
					<div><strong>Option 1:</strong> Projects based on an existing {{siteText.siteName}} project</div>
				</template>

				<b-card-body class="bg-light font-italic font-weight-light py-2">
					<b-card-text>
						If your project initiated from an exported project database file in the {{siteText.siteName}} system,
						please include the following in your zip file to upload:
					</b-card-text>
				</b-card-body>

				<b-list-group flush>
					<b-list-group-item>
						<ol class="m-0"><li>Project database (.mdb file from SWAT Editor)</li></ol>
					</b-list-group-item>
					<b-list-group-item>
						<ol class="m-0" start="2">
							<li>
								If you have customized fertilizer, pesticides, tillage, or soils,
								also include your SWAT2012.mdb file in the zip. <br />You may skip this file to just use the {{siteText.siteName}}
								data for these tables.
							</li>
						</ol>
					</b-list-group-item>
				</b-list-group>
			</b-card>

			<b-card no-body header-bg-variant="info" header-text-variant="white" class="mb-3">
				<template v-slot:header>
					<div><strong>Option 2:</strong> External SWAT models</div>
				</template>

				<b-card-body class="bg-light font-italic font-weight-light py-2">
					<b-card-text>
						If your project was set up using ArcSWAT or QSWAT instead of {{siteText.siteName}},
						please include the following in your zip file to upload. Please <strong>do not</strong> zip your entire project folder;
						instead copy only the files needed into a new directory as described below:
					</b-card-text>
				</b-card-body>

				<b-list-group flush>
					<b-list-group-item>
						<ol class="m-0"><li>Project database (.mdb file from SWAT Editor)</li></ol>
					</b-list-group-item>
					<b-list-group-item>
						<ol class="m-0" start="2"><li>SWAT2012.mdb file</li></ol>
					</b-list-group-item>
					<b-list-group-item>
						<ol class="m-0" start="3">
							<li>
								Subbasin and stream shapefile data in GeoJSON format with decimal degrees coordinate reference system
								<ul>
									<li>
										Inside your ArcSWAT/QSWAT project folder, browse to Watershed/Shapes.
										You'll see 6 files starting with <code>subs1</code>, and 6 files
										starting with <code>riv1</code>.
										We need you to convert these files into 2 GeoJSON files named
										<code>subs1.geojson</code> and <code>riv1.geojson</code> and copy them to the
										<strong>root</strong> of the upload folder.
									</li>
									<li>
										If you're using QGIS, right click on the subs1/riv1 layer in the Layers Panel.
										Choose 'Save As...' and select GeoJSON format and WGS 84 for CRS (not Conus Albers).
									</li>
									<li>
										You may alternatively use an online service to convert the files.
										However you will need to make sure your shape coordinates are in decimal lat/long degrees first,
										and not Conus Albers.
										Visit <a href="https://mygeodata.cloud/converter/shp-to-geojson" target="_blank">this website</a>
										and upload all 6 of your files starting with <code>subs1</code>. Download the resulting GeoJSON file, then
										repeat the process for the <code>riv1</code> shapefiles.
									</li>
								</ul>
							</li>
						</ol>
					</b-list-group-item>
					<b-list-group-item>
						<ol class="m-0" start="4">
							<li>
								Partial TxtInOut folder
								<ul>
									<li>
										Include all your weather files (pcp, tmp, etc),
										along with any point source (.dat) within a folder named TxtInOut at the <strong>root</strong>
										of the upload folder.
									</li>
									<li>
										Weather files must be named as: pcp1.pcp, hmd1.hmd, slr1.slr, tmp1.tmp, wnd1.wnd
									</li>
									<li>
										<strong style="text-decoration:underline">Do NOT include all files from TxtInOut.</strong>
										Only copy the files mentioned, and don't include all the .hru, .mgt, etc files.
										This will significantly speed up the upload process and keep your file size under the 1GB limit.
									</li>
								</ul>
							</li>
						</ol>
					</b-list-group-item>
					<b-list-group-item>
						<ol class="m-0" start="5">
							<li>
								Daily or monthly reservoir flow files
								<ul>
									<li>
										If your project uses daily or monthly reservoir outflow files, please include the .txt files you added via SWAT Editor
										and place them in the <strong>root</strong> of your zip file for upload.
									</li>
									<li>
										The files must be named the same as the entry for the <code>RESDAYO</code> or <code>RESMONO</code> column in the <code>res</code>
										table in your project database.
									</li>
									<li>
										<a href="/content/docs/upload-res-flow-files-sample.zip">Download a sample showing the required formats.</a>
									</li>
								</ul>
							</li>
						</ol>
					</b-list-group-item>
				</b-list-group>
			</b-card>

			<p>
				<strong>Limitations:</strong>
				atmospheric deposition data will not be included for external SWAT projects.
				For projects based on {{siteText.siteName}}, we will use default {{siteText.siteName}} atmospheric deposition data.
			</p>

			<hr class="my-4" />

			<div v-if="!data.hasAccess">
				<b-alert variant="warning" show>
					Your tier does not have access to project uploads.
					If this is a feature you need, visit your <router-link to="/account">account profile</router-link>
					to request access to a higher tier.
				</b-alert>
			</div>
			<div v-else-if="data.reachedLimit">
				<b-alert variant="warning" show>
					You have reached the maximum number of project uploads allowed in your tier.
					If you need more uploads, visit your <router-link to="/account">account profile</router-link>
					to request access to a higher tier.
				</b-alert>
			</div>
			<div v-else>
				<error-list :errors="page.saveErrors"></error-list>

				<div v-if="!page.showForm">
					<p class="font-weight-bold">
						Drag and drop a zip file into the space below.
						Your zip file must be no larger than 1 GB.
						<span class="text-primary text-underline pointer"
							v-b-tooltip title="If your Microsoft Access (.mdb) files are large, open the file, click the Database Tools menu, and select Compact and Repair Database to reduce its size">
							Tips</span>
					</p>

					<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
				</div>

				<b-form v-if="page.showForm" @submit.prevent="save">
					<b-alert variant="success" show class="my-3">
						<font-awesome-icon :icon="['fas', 'check']" class="mr-1" />
						Uploaded {{upload.uploadedFileName}}
					</b-alert>

					<b-form-group label="Name of your project (fewer than 50 characters)" :invalid-feedback="requiredFeedback($v.form.name)">
						<b-form-input v-model.trim="$v.form.name.$model" type="text" autofocus :state="getValidState($v.form.name)"></b-form-input>
					</b-form-group>

					<b-form-group label="Description (fewer than 200 characters)" :invalid-feedback="requiredFeedback($v.form.description)">
						<b-form-textarea v-model.trim="$v.form.description.$model" rows="4" :state="getValidState($v.form.description)"></b-form-textarea>
					</b-form-group>

					<b-form-group label="Agency, organization, or person to whom this project should be credited (optional)" :invalid-feedback="requiredFeedback($v.form.attribution)">
						<b-form-input v-model.trim="$v.form.attribution.$model" type="text" :state="getValidState($v.form.attribution)"></b-form-input>
					</b-form-group>

					<b-form-group :label="`If the project originated in ${siteText.siteName}, what dataset is it based on?`" :invalid-feedback="requiredFeedback($v.form.existingDatasetID)">
						<b-form-select v-model.trim="$v.form.existingDatasetID.$model" :state="getValidState($v.form.existingDatasetID)">
							<template v-slot:first>
								<b-form-select-option :value="null">None</b-form-select-option>
							</template>
							<b-form-select-option-group v-for="(o, i) in data.datasets" :key="i" :label="isNullOrEmpty(o.label) ? 'System datasets' : o.label" :options="o.options"></b-form-select-option-group>
							<b-form-select-option-group v-if="data.userDatasets.length > 0" label="User-submitted datasets" :options="data.userDatasets"></b-form-select-option-group>
						</b-form-select>
					</b-form-group>

					<b-form-group v-show="form.existingDatasetID !== null && (!upload.hasEndingSubKey || isNullOrEmpty(upload.endingSubbasinName))" label="Please provide the outlet subbasin name" :invalid-feedback="requiredFeedback($v.form.endingSubbasinName)">
						<b-form-input v-model.trim="$v.form.endingSubbasinName.$model" type="text" :state="getValidState($v.form.endingSubbasinName)"></b-form-input>
						<small class="text-muted">
							We were unable to detect this automatically from your <code>submapping</code> table.
							This may be because your project .mdb file was created using an old version of {{siteText.siteName}}.
						</small>
					</b-form-group>

					<b-card v-if="data.canDoHawqsBased" header="ADVANCED: This option is only available to full administrators">
						<b-card-text>
							<b-form-checkbox v-model.trim="$v.form.isHawqsBased.$model" :state="getValidState($v.form.isHawqsBased)">
								Is the data {{siteText.siteName}}-based? (Coming from another {{siteText.siteName}} dataset that is not loaded on this site.)
							</b-form-checkbox>
						</b-card-text>
					</b-card>

					<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
						<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
						<back-button class="btn btn-secondary mr-2" />
					</fixed-action-bar>
				</b-form>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, maxLength } from 'vuelidate/lib/validators';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'DatasetCreate',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					showForm: false
				},
				dropzoneOptions: {
					url: '/api/importdatasets/upload',
					headers: this.getTokenHeaderPart(),
					acceptedFiles: '.zip',
					timeout: 3600000,
					maxFilesize: 1000
				},
				data: {
					siteName: '',
					datasets: [],
					userDatasets: []
				},
				upload: {},
				form: {
					name: null,
					description: null,
					attribution: null,
					databaseFile: null,
					existingDatasetID: null,
					endingSubbasinName: null,
					hasSwatMdb: false,
					uploadDirectory: null,
					isHawqsBased: false,
					isSqlite: false
				}
			}
		},
		validations: {
			form: {
				name: { required, maxLength: maxLength(50) },
				description: { required, maxLength: maxLength(200) },
				existingDatasetID: { },
				endingSubbasinName: { },				
				attribution: { maxLength: maxLength(100) },
				isHawqsBased: {},
				isSqlite: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('/importdatasets/options', this.getTokenHeader());
					this.data = response.data;
					this.log(this.data);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post('importdatasets', this.form, this.getTokenHeader());
						this.$router.push({ name: 'DatasetView', params: { id: response.data } }).catch(err => {});
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			fileUploaded(file, response) {
				this.upload = response;
				this.log(this.upload);
				this.form.endingSubbasinName = this.upload.hasEndingSubKey ? this.upload.endingSubbasinName : null;
				this.form.hasSwatMdb = this.upload.hasSwatMdb;
				this.form.isSqlite = this.upload.isSqlite;
				this.form.uploadDirectory = this.upload.uploadDirectory;
				this.form.databaseFile = this.upload.projectMdbName;
				this.page.showForm = true;
				this.page.saveErrors = [];
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.log(message);
				this.page.saveErrors.push(message);
			}
		}
	}
</script>

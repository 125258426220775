<template>
	<div>
		<div v-if="$route.name == 'AdminProjects'">
			<h2 class="mb-3">Projects</h2>

			<p>
				Projects may be copied to a new user, or the same user. To copy a project,
				find it in the table below then 
				<span v-b-tooltip.hover.bottom title="Click anywhere on the row except one of the icons or links" class="ml-1 border-bottom text-info" style="cursor:help">
					click on the row
				</span> to select it.
				Once you have selected a row it will highlight in blue. Scroll to the top of the page if needed, then click the copy project button.
			</p>

			<div v-if="selectedProject !== null">
				<b-alert variant="info" show>
					<p>
						You have selected project <strong>{{selectedProject.name}}</strong>
						assigned to user <strong>{{selectedProject.userName}}</strong>.
					</p>
					<b-button variant="primary" @click="page.copy.show = true">Copy Project</b-button>
				</b-alert>
			</div>

			<grid-view api-url="admin/projects" use-filter allow-export export-url="admin/exports/projects"
					   selectable @selectionChanged="rowSelectionChanged"
					   delete-api-url="projects"
					   link-user-name
					   edit-route="/projects/" :edit-route-append="false"
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="projects"
					   item-name="project"
					   extra-delete-message="All scenarios and files for this project will be removed."></grid-view>

			<b-modal v-model="page.copy.show" title="Copy project">
				<error-list :errors="page.copy.errors"></error-list>

				<p>
					Copy this project to a new project. All scenarios and files will be copied.
				</p>

				<b-form-group label="Provide a name for the new project">
					<b-form-input v-model="page.copy.name" type="text" required></b-form-input>
				</b-form-group>

				<b-form-group label="Assign this project to a different user by typing a user name below. Leave blank to use the same user as the original project.">
					<b-typeahead ref="usersTypeahead"
								 :data="userNames"
								 v-model="usersSearch"
								 :max-matches="100">
					</b-typeahead>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.copy.saving" @click.native="copyProject" text="Copy Project" variant="success" />
					<b-button type="button" variant="secondary" @click="page.copy.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import _ from 'underscore';

	export default {
		name: 'AdminProjects',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					copy: {
						show: false,
						errors: [],
						saving: false,
						name: ''
					}
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true, class: 'min-w-200' },
						{ key: 'userName', sortable: true },
						{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
						{ key: 'dataset', sortable: false },
						{ key: 'endingSubbasin', label: 'Downstream ID', sortable: true },
						{ key: 'numSubbasins', label: 'Subbasins', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
						{ key: 'numHrus', label: 'HRUs', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
						{ key: 'totalArea', label: 'Area (sq. km.)', sortable: false, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
						{ key: 'numScenarios', label: 'Scenarios', sortable: false },
						{ key: 'delete', label: '', sortable: false, class: 'min' }
					],
					sort: 'lastModifiedDate',
					reverse: true,
					itemsPerPage: 50
				},
				selectedProject: null,
				usersSearch: '',
				userNames: []
			}
		},
		watch: {
			usersSearch: _.debounce(function (query) { this.findUsers(query) }, 500)
		},
		methods: {
			rowSelectionChanged(items) {
				this.log(items);
				if (items === null || items.length < 1) this.selectedProject = null;
				else {
					this.selectedProject = items[0];
					this.page.copy.name = this.selectedProject.name + ' (Copy)';
				}
			},
			async copyProject() {
				this.page.copy.errors = [];
				this.page.copy.saving = true;

				if (this.isNullOrEmpty(this.page.copy.name)) {
					this.page.copy.errors.push('Please provide a name for the new scenario.');
				} else {
					try {
						var data = {
							name: this.page.copy.name,
							userName: this.usersSearch
						};
						this.log(data);
						const response = await this.$http.post(`admin/projects/copy/${this.selectedProject.id}`, data, this.getTokenHeader());
						this.$router.push({ name: 'ProjectView', params: { id: response.data }}).catch(err => {});
						this.page.copy.show = false;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.copy.errors = this.logError(error);
					}
				}

				this.page.copy.saving = false;
			},
			async findUsers(query) {
				try {
					const response = await this.$http.get(`/admin/users/find/${query}`, this.getTokenHeader());
					this.userNames = response.data != '' ? response.data : [];
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.copy.errors = this.logError(error);
				}
			}
		}
	}
</script>

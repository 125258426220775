<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">
			<div id="main-panel">
				<div v-if="$route.name == 'FeedbackList'" class="container py-5">
					<page-authorization-container :page="page">
						<h2>Feedback and error reports</h2>

						<p>
							If you have any trouble using the system or want to provide us with any other feedback,
							please create a new ticket using the button below. Any error reports related to your projects and scenarios are
							shown below.
						</p>

						<grid-view api-url="errorreports"
								   :fields="table.fields"
								   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
								   collection-description=" feedback and error reports"
								   item-name="feedback / error report"
								   create-route="create" show-create></grid-view>

						<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
							<back-button class="btn btn-secondary mr-2" />
						</fixed-action-bar>
					</page-authorization-container>
				</div>
				<div v-else class="container">
					<b-breadcrumb :items="breadCrumbs" class="no-curves bg-white"></b-breadcrumb>
					<div class="container-fluid pb-4">
						<router-view></router-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DashboardHeader from '@/components/DashboardHeader';
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'FeedbackList',
		components: {
			DashboardHeader, GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				invites: [],
				table: {
					fields: [
						{ key: 'edit', label: '', class: 'min' },
						{ key: 'reportStatus', label: 'Status', sortable: true },
						{ key: 'title', sortable: true },
						{ key: 'project', sortable: true },
						{ key: 'scenario', sortable: true },
						{ key: 'dateSubmitted', sortable: true, formatter: (value) => { return moment(value).format('llll') } }
					],
					sort: 'dateSubmitted',
					reverse: true,
					itemsPerPage: 50
				}
			}
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (var i = 0; i < this.$route.matched.length; i++) {
					var item = this.$route.matched[i];
					var name = item.meta.title ? item.meta.title : item.name;

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Uploaded documents</h2>

			<p>
				Upload up to <strong>{{maxFiles}}</strong> documents to store with this project.
				Each file cannot exceed <strong>50 MB</strong> in size.
				Allowed file types include Microsoft Office (.docx, .xslx, .pptx), PDF (.pdf), images (.jpg, .png), or zip (.zip, .7z) files.
			</p>

			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<b-alert :show="files.length >= maxFiles" variant="info">
				You have already uploaded {{maxFiles}} for this project.
				You must delete one to upload more.
			</b-alert>
			<vue-dropzone v-if="files.length < maxFiles" ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

			<b-table responsive striped class="mt-4" :items="files" :fields="['fileName','modified',{key:'delete', label:'', class:'min'}]">
				<template v-slot:cell(fileName)="data">
					<a :href="data.item.fileUrl" target="_blank">{{data.item.fileName}}</a>
				</template>
				<template v-slot:cell(modified)="data">
					{{data.item.modified | date}}
				</template>
				<template v-slot:cell(delete)="data">
					<button class="plain text-danger" v-b-tooltip.hover.right="'Delete'" @click="askDelete(data.item.fileName)">
						<font-awesome-icon :icon="['fas', 'times']" />
					</button>
				</template>
			</b-table>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete <strong>{{page.delete.name}}</strong>?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<fixed-action-bar>
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</page-authorization-container>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'ProjectDocuments',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					delete: {
						show: false,
						name: '',
						errors: [],
						saving: false
					}
				},
				maxFiles: 10,
				dropzoneOptions: {
					url: `/api/projects/documents/${this.$route.params.id}`,
					headers: this.getTokenHeaderPart(),
					acceptedFiles: '.docx, .xslx, .pptx, .pdf, .jpg, .png, .zip, .7z',
					maxFilesize: 50
				},
				files: []
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`projects/documents/${this.projectID}`, this.getTokenHeader());
					this.files = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async fileUploaded(file, response) {
				this.log(response);
				this.page.saveErrors = [];
				await this.get();
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			askDelete(name) {
				this.page.delete.name = name;
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`projects/documents/${this.projectID}/${this.page.delete.name}`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">Edit weather dataset</h2>

			<admin-weather-form is-update :data="data"></admin-weather-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import AdminWeatherForm from '@/views/admin/datasets/weather/AdminWeatherForm';
	import moment from 'moment';

	export default {
		name: 'AdminWeatherView',
		components: {
			AdminWeatherForm
		},
		data() {
			return {
				weatherID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				data: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.weatherID = this.$route.params.id;

				try {
					const response = await this.$http.get(`admin/weather/${this.weatherID}`, this.getTokenHeader());
					this.data = response.data;
					this.data.startDate = moment(this.data.startDate).format('YYYY-MM-DD');
					this.data.endDate = moment(this.data.endDate).format('YYYY-MM-DD');
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>

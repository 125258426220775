<template>
	<div>
		<page-authorization-container :page="page">
			<b-row class="my-3">
				<b-col :md="subbasinOnly ? 12 : 6">
					<b-form-select v-model="selection.subbasin" class="mb-3">
						<template v-slot:first>
							<option :value="null" disabled>-- Select a subbasin from this list or click the map --</option>
						</template>
						<option v-for="(c, i) in projectMap.connections" :key="i" :value="{ id: c.fromID, name: c.fromName }">
							{{c.fromName}}
						</option>
					</b-form-select>

					<div v-if="!subbasinOnly">
						<error-list v-if="loading.errors.length > 0" :errors="loading.errors"></error-list>
						<div v-else>
							<page-loading :loading="loading.landuse"></page-loading>
							<b-form-select v-if="!loading.landuse && options.landuse.length > 0" v-model="selection.landuse" class="mb-3">
								<template v-slot:first>
									<option :value="null" disabled>-- Select land use --</option>
								</template>
								<option v-for="(o, i) in options.landuse" :key="i" :value="o.name">{{o.name}} - {{o.description}}</option>
							</b-form-select>

							<page-loading :loading="loading.soils"></page-loading>
							<b-form-select v-if="!loading.soils && options.soils.length > 0 && options.landuse.length > 0" v-model="selection.soil" class="mb-3">
								<template v-slot:first>
									<option :value="null" disabled>-- Select soil --</option>
								</template>
								<option v-for="(o, i) in options.soils" :key="i" :value="o">{{o}}</option>
							</b-form-select>

							<page-loading :loading="loading.slopes"></page-loading>
							<b-form-select v-if="!loading.slopes && options.slopes.length > 0 && options.soils.length > 0 && options.landuse.length > 0" v-model="selection.slope" class="mb-3">
								<template v-slot:first>
									<option :value="null" disabled>-- Select slope --</option>
								</template>
								<option v-for="(o, i) in options.slopes" :key="i" :value="o">{{o}}</option>
							</b-form-select>

							<slot></slot>
						</div>
					</div>
				</b-col>
				<b-col :md="subbasinOnly ? 12 : 6">
					<project-map :project-map="projectMap" height="300px"
								 highlight-selected
								 :selected-subbasins="selection.subbasin === null ? [] : [selection.subbasin]"
								 @subbasin-click="mapClick"></project-map>
				</b-col>
			</b-row>
		</page-authorization-container>
	</div>
</template>

<script>
	import ProjectMap from '@/components/ProjectMap';

	export default {
		name: 'HruSelector',
		components: {
			ProjectMap
		},
		props: {
			projectID: {
				type: Number,
				required: true
			},
			subbasinOnly: {
				type: Boolean,
				default: false
			},
			initSubID: {
				type: Number,
				required: false,
				default: null
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				projectMap: {},
				options: {
					landuse: [],
					soils: [],
					slopes: []
				},
				selection: {
					subbasin: null,
					landuse: null,
					soil: null,
					slope: null
				},
				loading: {
					errors: [],
					landuse: false,
					soils: false,
					slopes: false
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`projects/map/${this.projectID}`, this.getTokenHeader());
					this.projectMap = response.data;

					if (this.initSubID !== null && this.initSubID !== 0) {
						var value = this.initSubID;
						var filtered = this.projectMap.connections.filter(function (el) { return el.fromID === value; });
						if (filtered.length > 0) this.selection.subbasin = { id: filtered[0].fromID, name: filtered[0].fromName };
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getLanduse() {
				if (!this.subbasinOnly) {
					this.loading.errors = [];
					this.loading.landuse = true;

					try {
						const response = await this.$http.post(`hruselection/landuse/${this.projectID}`, [this.selection.subbasin], this.getTokenHeader());
						this.options.landuse = response.data;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.loading.errors = this.logError(error);
					}

					this.loading.landuse = false;
				}
			},
			async getSoils() {
				this.loading.errors = [];
				this.loading.soils = true;

				var model = {
					subbasins: [this.selection.subbasin],
					landuse: [this.selection.landuse]
				}

				try {
					const response = await this.$http.post(`hruselection/soils/${this.projectID}`, model, this.getTokenHeader());
					this.options.soils = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.loading.errors = this.logError(error);
				}

				this.loading.soils = false;
			},
			async getSlopes() {
				this.loading.errors = [];
				this.loading.slopes = true;

				var model = {
					subbasins: [this.selection.subbasin],
					landuse: [this.selection.landuse],
					soils: [this.selection.soil]
				}

				try {
					const response = await this.$http.post(`hruselection/slopes/${this.projectID}`, model, this.getTokenHeader());
					this.options.slopes = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.loading.errors = this.logError(error);
				}

				this.loading.slopes = false;
			},
			async mapClick(selectedSubbasins) {
				if (selectedSubbasins.length > 0)
					this.selection.subbasin = selectedSubbasins[0];
				else
					this.selection.subbasin = null;
			}
		},
		watch: {
			async selectedSubbasin() {
				await this.getLanduse();
				this.$emit('change', this.selection);
			},
			async selectedLanduse() {
				await this.getSoils();
				this.$emit('change', this.selection);
			},
			async selectedSoil() {
				await this.getSlopes();
				this.$emit('change', this.selection);
			},
			selectedSlope() {
				this.$emit('change', this.selection);
			},
			/*initSubID(newVal, oldVal) {
				this.log('In watch: ' + newVal);
				if (newVal !== null && newVal !== 0) {
					var value = newVal;
					this.selection.subbasin = this.projectMap.connections.filter(function (el) { return el.fromID === value; });
				}
			}*/
		},
		computed: {
			selectedSubbasin() {
				return this.selection.subbasin;
			},
			selectedLanduse() {
				return this.selection.landuse;
			},
			selectedSoil() {
				return this.selection.soil;
			},
			selectedSlope() {
				return this.selection.slope;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<h2 class="mb-4">Account information for {{profile.userName}}</h2>

			<success-alert ref="setApprovedAlert" text="User account approved. The user has been sent an email notification." />
			<div v-if="isPending">
				<error-list :errors="page.approve.errors"></error-list>
				<error-list :errors="page.deleteAccount.errors"></error-list>

				<b-alert variant="warning" show>
					<p>This account is pending approval.</p>

					<save-button :saving="page.approve.saving" text="Approve" @click.native="approveAccount" class="mr-2" />
					<save-button :saving="page.deleteAccount.saving" variant="danger" text="Deny/Delete" @click.native="confirmDelete(false)" />
				</b-alert>
			</div>

			<error-list :errors="page.lockout.errors"></error-list>
			<div v-if="profile.isLockedOut">
				<b-alert variant="warning" show>
					<p>This account is locked out until {{profile.lockoutEndDate | date}}</p>
					<save-button :saving="page.lockout.saving" text="Unlock account" @click.native="setLockout(-1)" class="mr-2" />
				</b-alert>
			</div>

			<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
				<b-tab title="Profile">
					<error-list :errors="page.profile.errors"></error-list>
					<success-alert ref="profileSavedAlert" text="Changes saved." />

					<error-list :errors="page.setRole.errors"></error-list>
					<success-alert ref="setRoleAlert" text="User role change saved." />

					<b-row>
						<b-col md="8">
							<b-form>
								<b-row>
									<b-col md>
										<b-form-group label="First name" :invalid-feedback="requiredFeedback($v.profile.firstName)">
											<b-form-input v-model="profile.firstName" type="text" autofocus :state="getValidState($v.profile.firstName)"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col md>
										<b-form-group label="Last name" :invalid-feedback="requiredFeedback($v.profile.lastName)">
											<b-form-input v-model="profile.lastName" type="text" :state="getValidState($v.profile.lastName)"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>

								<b-form-group label="Email">
									<b-form-input v-model="profile.email" type="email" :state="getValidState($v.profile.email)"></b-form-input>
									<b-form-invalid-feedback v-if="!$v.profile.email.required">Required</b-form-invalid-feedback>
									<b-form-invalid-feedback v-if="!$v.profile.email.email">Please enter a valid email address</b-form-invalid-feedback>
								</b-form-group>

								<b-form-group label="Company or organization" :invalid-feedback="requiredFeedback($v.profile.affiliation)">
									<b-form-input v-model="profile.affiliation" type="text" :state="getValidState($v.profile.affiliation)"></b-form-input>
								</b-form-group>

								<b-form-group label="Category" :invalid-feedback="requiredFeedback($v.profile.organizationCategory)">
									<b-form-select v-model="profile.organizationCategory" :options="location.organizationCategories" :state="getValidState($v.profile.organizationCategory)"></b-form-select>
								</b-form-group>

								<b-form-group :label="`What is your reason for trying ${siteText.siteName}?`" :invalid-feedback="requiredFeedback($v.profile.reasonForUse)">
									<b-form-input v-model="profile.reasonForUse" type="text" :state="getValidState($v.profile.reasonForUse)"></b-form-input>
								</b-form-group>

								<b-form-group label="In what country are you located?" :invalid-feedback="requiredFeedback($v.profile.country)">
									<b-form-select v-model="profile.country" :options="location.countries" :state="getValidState($v.profile.country)"></b-form-select>
								</b-form-group>

								<b-form-group v-if="profile.country === 'United States'" label="Select your state or territory" :invalid-feedback="requiredFeedback($v.profile.state)">
									<b-form-select v-model="profile.state" :options="location.states" :state="getValidState($v.profile.state)"></b-form-select>
								</b-form-group>

								<hr class="my-3" />

								<b-form-group label="Units preference" :invalid-feedback="requiredFeedback($v.profile.units)">
									<b-form-select v-model="profile.units" :options="options.units" :state="getValidState($v.profile.units)"></b-form-select>
								</b-form-group>
							</b-form>
						</b-col>
						<b-col>
							<b-card bg-variant="light" class="mb-3" v-if="!isPending && (isAuthorized(roleNames.admin) || (isAuthorized(roleNames.partialAdmin) && !userHasRole(roleNames.admin)))">
								<b-card-text>
									<p>Set roles</p>

									<div>
										<b-form-checkbox v-model="setRoles.isPartialAdmin" @change="setRole(roleNames.partialAdmin)">Site administrator</b-form-checkbox>
									</div>
									<div v-if="isAuthorized(roleNames.admin)">
										<b-form-checkbox v-model="setRoles.isAdmin" @change="setRole(roleNames.admin)">Full access administrator</b-form-checkbox>
									</div>
								</b-card-text>
							</b-card>

							<b-card bg-variant="light" class="mb-3" v-if="!isNullOrEmpty(options.defaultTier)">
								<b-card-text>
									<p>Set tier access</p>

									<b-form-select v-model="profile.tierID" :options="options.tiers"></b-form-select>

									<b-form-group label="Access expiration date (optional)" class="mt-3" v-if="profile.tierID !== options.defaultTier">
										<b-form-input v-model="profile.tierExpiration" type="date"></b-form-input>
										<small class="form-text text-muted">Leave blank for indefinite access to this tier</small>
									</b-form-group>
								</b-card-text>
							</b-card>

							<div class="text-muted text-small">
								<div>Last log-in on {{profile.lastLoginDate | date}}</div>
								<div>Registered on {{profile.registrationDate | date}}</div>
							</div>
						</b-col>
					</b-row>
				</b-tab>

				<b-tab title="Projects">
					<grid-view :api-url="`admin/users/projects/${userID}`"
							   :fields="table.fields" link-name
							   :default-sort="table.sort" :default-reverse="table.reverse"
							   collection-description="projects"
							   item-name="project"
							   edit-route="/projects/" :edit-route-append="false"></grid-view>
				</b-tab>

				<b-tab title="Usage statistics">
					<div class="mb-4">
						<highcharts :options="statisticsTimeChart"></highcharts>
					</div>

					<h2 class="h4 text-center">Totals</h2>
					<hr class="mt-2 mb-3" />
					<b-row align-h="center">
						<b-col cols="6" lg="3">
							<div class="stat-box bg-primary text-white mb-2">
								<div class="title">Projects</div>
								<div class="value">{{ stats.numProjects |  number(0) }}</div>
							</div>
						</b-col>
						<b-col cols="6" lg="3">
							<div class="stat-box bg-primary text-white mb-2">
								<div class="title">Scenarios</div>
								<div class="value">{{ stats.numScenarios |  number(0) }}</div>
							</div>
						</b-col>
						<b-col cols="6" lg="3">
							<div class="stat-box bg-primary text-white mb-2">
								<div class="title">Running Now</div>
								<div class="value">
									{{ stats.numScenariosRunning |  number(0) }}
								</div>
							</div>
						</b-col>
					</b-row>

					<h2 class="h4 text-center mt-4">Averages</h2>
					<hr class="mt-2 mb-3" />
					<b-row align-h="center">
						<b-col cols="6" lg="3">
							<div class="stat-box bg-info text-white mb-2">
								<div class="title">HRUs / Project</div>
								<div class="value">{{ stats.avgHrusPerProject |  number(1) }}</div>
							</div>
						</b-col>
						<b-col cols="6" lg="3">
							<div class="stat-box bg-info text-white mb-2">
								<div class="title">Scenarios / Project</div>
								<div class="value">{{ stats.avgScenariosPerProject |  number(1) }}</div>
							</div>
						</b-col>
						<b-col cols="6" lg="3">
							<div class="stat-box bg-info text-white mb-2">
								<div class="title">Scenario Run Time</div>
								<div class="value">
									{{ stats.avgScenarioRunTime |  number(1) }}
									<span title="minutes">min</span>
								</div>
							</div>
						</b-col>
					</b-row>
				</b-tab>

				<b-tab title="Reset password">
					<div v-if="isPending">
						<p><em>Not available for pending users.</em></p>
					</div>
					<div v-else>
						<div v-if="!isAuthorized(roleNames.admin) && userHasRole(roleNames.admin)">
							<b-alert variant="warning" show>
								You are not authorized to change this user's password because they are an administrator.
							</b-alert>
						</div>
						<div v-else>
							<p>
								In most cases you should instruct the user to use the forgot password link under the log-in form.
								They will enter their email and be mailed instructions for resetting.
								In cases where this doesn't work or is not an option, enter a new password below and send it to the user.
								Please instruct them to change it after successful log-in.
							</p>

							<error-list :errors="page.password.errors"></error-list>
							<success-alert ref="passwordSavedAlert" text="Changes saved." />

							<b-form>
								<b-form-group label="New password" :description="globals.passwordRequirements">
									<b-form-input v-model="password.new" type="password" :state="getValidState($v.password.new)"></b-form-input>
									<b-form-invalid-feedback v-if="!$v.password.new.required">Required</b-form-invalid-feedback>
									<b-form-invalid-feedback v-if="!$v.password.new.strongPassword">{{globals.passwordRequirements}}</b-form-invalid-feedback>
								</b-form-group>

								<b-form-group label="Confirm new password">
									<b-form-input v-model="password.confirm" type="password" :state="getValidState($v.password.confirm)"></b-form-input>
									<b-form-invalid-feedback v-if="!$v.password.confirm.required">Required</b-form-invalid-feedback>
									<b-form-invalid-feedback v-if="!$v.password.confirm.sameAsPassword">Passwords must match</b-form-invalid-feedback>
								</b-form-group>
							</b-form>
						</div>
					</div>
				</b-tab>

				<b-tab title="Access codes">
					<div v-if="isPending">
						<p><em>Not available for pending users.</em></p>
					</div>
					<div v-else>
						<p>
							Access codes are temporary codes sent to users confirming access to their accounts via email.
							If a user emails from their account's email address and claims to have not received the code, you may sent one from
							the table below. If the user does not have access to their account email, please change it for them, then have
							them log in as usual.
						</p>

						<b-alert :show="accessCodes.length < 1" variant="info">
							The user does not currently have any active temporary codes.
						</b-alert>

						<b-table-simple hover small responsive v-if="accessCodes.length > 0">
							<b-thead class="bg-light">
								<b-tr>
									<b-th>Code</b-th>
									<b-th>Expires</b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="(c, i) in accessCodes" :key="i">
									<b-td>{{c.code}}</b-td>
									<b-td>{{c.expiryDate | date}}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</div>
				</b-tab>

				<b-tab title="API access">
					<success-alert ref="apiClientAlert" text="Changes saved." />

					<div v-if="!siteSettings.apiConnectivityEnabled">
						<p><em>This feature is not enabled.</em></p>
					</div>
					<div v-else-if="isPending">
						<p><em>Not available for pending users.</em></p>
					</div>
					<div v-else-if="!hasApiAccess">
						<p>This user does not currently have API access set up. Use the button below to grant access.</p>
					</div>
					<div v-else>
						<b-table-simple class="border-bottom">
							<b-tr>
								<b-th>Shared Key</b-th>
								<b-td><code>{{apiClient.sharedKey}}</code></b-td>
							</b-tr>
							<b-tr>
								<b-th>Secret Key</b-th>
								<b-td><code>{{apiClient.secretKey}}</code></b-td>
							</b-tr>
						</b-table-simple>

						<h3 class="mt-4">API Requests</h3>
						<grid-view :api-url="`admin/users/apirequests/${userID}`"
								   :fields="apiTable.fields" link-name
								   :default-sort="apiTable.sort" :default-reverse="apiTable.reverse"
								   collection-description="requests"
								   item-name="request"
								   edit-route="/api/batch/" :edit-route-append="false"></grid-view>
					</div>
				</b-tab>

				<b-tab title="Delete account" title-link-class="text-danger" v-if="!isPending">
					<p>
						Are you sure you want to delete this account?
						This action is <strong>permanent and cannot be undone</strong>.
						All projects and files for this user will be removed.
					</p>

					<error-list :errors="page.deleteAccount.errors"></error-list>

					<b-form>
						<b-form-group label="Type DELETE to confirm">
							<b-form-input v-model="deleteAccount.value" type="text" :state="getValidState($v.deleteAccount.value)"></b-form-input>
							<b-form-invalid-feedback v-if="!$v.deleteAccount.value.required">Required</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="deleteAccount.value !== 'DELETE'">You must type DELETE</b-form-invalid-feedback>
						</b-form-group>
					</b-form>
				</b-tab>
			</b-tabs>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<save-button v-if="tabIndex == 0" :saving="page.profile.saving" @click.native="saveProfile" class="mr-2" />
				<save-button v-if="tabIndex == 3" :saving="page.password.saving" @click.native="savePassword" class="mr-2" />
				<save-button v-if="tabIndex == 6" :saving="page.deleteAccount.saving" @click.native="confirmDelete" class="mr-2"
							 variant="danger" text="Delete Account"
							 :disabled="deleteAccount.value !== 'DELETE'" />
				<save-button v-if="tabIndex == 5 && !hasApiAccess" :saving="page.apiClient.saving" @click.native="grantApiAccess" text="Grant API Access" class="mr-2" />
				<save-button v-if="tabIndex == 5 && hasApiAccess" :saving="page.apiClient.saving" @click.native="deleteApiAccess" variant="danger" text="Remove API Access" class="mr-2" />
				<back-button class="btn btn-secondary mr-2" />
				<save-button v-if="!profile.isLockedOut" :saving="page.lockout.saving"
							 variant="warning" text="Lockout User"
							 v-b-tooltip.hover title="Lockout user account from accessing this app for 30 days"
							 @click.native="setLockout(30)" class="ml-auto" />
			</fixed-action-bar>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators';
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'AdminUser',
		components: {
			GridView
		},
		data() {
			return {
				userID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					profile: {
						errors: [],
						saving: false
					},
					password: {
						errors: [],
						saving: false
					},
					deleteAccount: {
						errors: [],
						saving: false
					},
					approve: {
						errors: [],
						saving: false
					},
					lockout: {
						errors: [],
						saving: false
					},
					setRole: {
						errors: [],
						saving: false
					},
					apiClient: {
						errors: [],
						saving: false
					}
				},
				tabIndex: 0,
				options: {
					units: [
						{ value: 0, text: 'Metric' },
						{ value: 1, text: 'Imperial' }
					],
					tiers: [],
					defaultTier: ''
				},
				profile: {},
				stats: {
					statisticsTimeChart: {}
				},
				password: {
					new: '',
					confirm: ''
				},
				deleteAccount: {
					value: ''
				},
				setRoles: {
					isPartialAdmin: false,
					isAdmin: false
				},
				table: {
					fields: [
						{ key: 'name', sortable: true, class: 'min-w-200' },
						{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
						{ key: 'dataset', sortable: false },
						{ key: 'endingSubbasin', label: 'Downstream ID', sortable: true },
						{ key: 'numSubbasins', label: 'Subbasins', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
						{ key: 'numHrus', label: 'HRUs', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
						{ key: 'totalArea', label: 'Area (sq. km.)', sortable: false, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
						{ key: 'numScenarios', label: 'Scenarios', sortable: false }
					],
					sort: 'lastModifiedDate',
					reverse: true,
					itemsPerPage: 20
				},
				location: {
					countries: [],
					states: [],
					organizationCategories: []
				},
				accessCodes: [],
				apiClient: {},
				apiTable: {
					fields: [
						{ key: 'requestType', sortable: true },
						{ key: 'name', sortable: true, class: 'min-w-200' },
						{ key: 'status', sortable: true },
						{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
						{ key: 'createdDate', sortable: true, class: 'nowrap' }
					],
					sort: 'lastModifiedDate',
					reverse: true,
					itemsPerPage: 20
				}
			}
		},
		validations: {
			profile: {
				firstName: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				lastName: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				country: { required: requiredIf(function () { return this.tabIndex == 0; }) },
				state: { required: requiredIf(function () { return this.tabIndex == 0 && this.profile.country == 'United States'; }) },
				organizationCategory: { required: requiredIf(function () { return this.tabIndex == 0; }) },
				affiliation: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				reasonForUse: { required: requiredIf(function(){ return this.tabIndex == 0; }) },
				units: { required: requiredIf(function () { return this.tabIndex == 0; }) },
				email: {
					email,
					required: requiredIf(function(){ return this.tabIndex == 0; })
				}
			},
			password: {
				new: {
					strongPassword(password) {
						return (
							this.tabIndex != 3 || (
							/[a-z]/.test(password) && // checks for a-z
							/[0-9]/.test(password) && // checks for 0-9
							/\W|_/.test(password) && // checks for special char
							password.length >= 10)
						);
					},
					required: requiredIf(function(){ return this.tabIndex == 3; })
				},
				confirm: {
					sameAsPassword: sameAs('new'),
					required: requiredIf(function(){ return this.tabIndex == 3; })
				}
			},
			deleteAccount: {
				value: { required: requiredIf(function(){ return this.tabIndex == 6; }) }
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.userID = this.$route.params.id;

				try {
					const response = await this.$http.get(`admin/users/${this.userID}`, this.getTokenHeader());
					this.log(response.data);
					this.profile = response.data.profile;
					this.options.tiers = response.data.tiers;
					this.options.defaultTier = response.data.defaultTierID;
					this.stats = response.data.stats;
					this.location = response.data.location;
					this.accessCodes = response.data.accessCodes;
					this.apiClient = response.data.apiClient;

					if (!this.isNullOrEmpty(this.profile.tierExpiration)) {
						this.profile.tierExpiration = moment(this.profile.tierExpiration).format('YYYY-MM-DD');
					}
					this.log(this.profile);

					this.setRoles.isPartialAdmin = this.profile.roles.includes(this.roleNames.partialAdmin);
					this.setRoles.isAdmin = this.profile.roles.includes(this.roleNames.admin);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async saveProfile() {
				this.page.profile.errors = [];
				this.page.profile.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.profile.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post(`admin/users/${this.userID}`, this.profile, this.getTokenHeader());
						this.$refs.profileSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.profile.errors = this.logError(error);
					}
				}

				this.page.profile.saving = false;
			},
			async savePassword() {
				this.page.password.errors = [];
				this.page.password.saving = true;

				this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.password.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							value: this.password.new
						};
						const response = await this.$http.post(`admin/users/password/${this.userID}`, data, this.getTokenHeader());
						this.$refs.passwordSavedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.password.errors = this.logError(error);
					}
				}

				this.page.password.saving = false;
			},
			async setRole(role) {
				this.page.setRole.errors = [];
				this.page.setRole.saving = true;

				try {
					var data = {
						value: role
					};
					const response = await this.$http.post(`admin/users/setrole/${this.userID}`, data, this.getTokenHeader());
					this.$refs.setRoleAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.setRole.errors = this.logError(error);
				}

				this.page.setRole.saving = false;
			},
			async approveAccount() {
				this.page.approve.errors = [];
				this.page.approve.saving = true;

				try {
					const response = await this.$http.get(`admin/users/approve?id=${this.userID}`, this.getTokenHeader());
					await this.get();
					this.$refs.setApprovedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.approve.errors = this.logError(error);
				}

				this.page.approve.saving = false;
			},
			async confirmDelete(validate = true) {
				this.page.deleteAccount.errors = [];
				this.page.deleteAccount.saving = true;

				if (validate) this.$v.$touch();
				if (this.$v.$invalid) {
					this.page.deleteAccount.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.delete(`account/delete?userId=${this.userID}`, this.getTokenHeader());
						this.$router.push('/admin/users').catch(err => {});
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.deleteAccount.errors = this.logError(error);
					}
				}

				this.page.deleteAccount.saving = false;
			},
			async setLockout(days) {
				this.page.lockout.errors = [];
				this.page.lockout.saving = true;

				try {
					const response = await this.$http.get(`admin/users/setlock/${this.userID}/${days}`, this.getTokenHeader());
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.lockout.errors = this.logError(error);
				}

				this.page.lockout.saving = false;
			},
			userHasRole(role) {
				return this.profile.roles !== undefined && this.profile.roles.includes(role);
			},
			async grantApiAccess() {
				this.page.apiClient.errors = [];
				this.page.apiClient.saving = true;

				try {
					const response = await this.$http.post(`admin/users/apiAccess/${this.userID}`, {}, this.getTokenHeader());
					await this.get();
					this.$refs.apiClientAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.apiClient.errors = this.logError(error);
				}

				this.page.apiClient.saving = false;
			},
			async deleteApiAccess() {
				this.page.apiClient.errors = [];
				this.page.apiClient.saving = true;

				try {
					const response = await this.$http.delete(`admin/users/deleteApiAccess/${this.userID}`, this.getTokenHeader());
					await this.get();
					this.$refs.apiClientAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.apiClient.errors = this.logError(error);
				}

				this.page.apiClient.saving = false;
			}
		},
		computed: {
			isPending() {
				return this.profile.roles !== undefined && this.profile.roles.includes(this.roleNames.pending);
			},
			statisticsTimeChart() {
				return {
					chart: { type: 'bar' },
					plotOptions: { bar: { dataLabels: { enabled: true } } },
					title: { text: this.stats.statisticsTimeChart.title },
					xAxis: { categories: this.stats.statisticsTimeChart.xAxisCategories },
					yAxis: { title: { text: '' } },
					series: this.stats.statisticsTimeChart.series
				};
			},
			hasApiAccess() {
				return this.profile.roles !== undefined && this.profile.roles.includes(this.roleNames.apiUser);
			}
		}
	}
</script>

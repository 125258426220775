<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Feedback / error report details</h2>

			<table class="table table-striped">
				<tbody>
					<tr>
						<th class="min">Subject / title</th>
						<td>{{data.title}}</td>
					</tr>
					<tr>
						<th class="min">Report status</th>
						<td>
							<b-badge v-if="data.status === 'Submitted'" variant="danger">Submitted</b-badge>
							<b-badge v-if="data.status === 'Working'" variant="warning">Working</b-badge>
							<b-badge v-if="data.status === 'Resolved'" variant="success">Resolved</b-badge>
						</td>
					</tr>
					<tr>
						<th class="min">Date submitted</th>
						<td>{{data.dateSubmitted | date}}</td>
					</tr>
					<tr v-if="!isNullOrEmpty(data.resolvedDate)">
						<th class="min">Resolution</th>
						<td>
							{{data.resolvedDate | date}}
							<span v-if="!isNullOrEmpty(data.resolvedBy)">by {{data.resolvedBy}}</span>
						</td>
					</tr>
					<tr v-if="!isNullOrEmpty(data.developerComments)">
						<th class="min">Developer comments</th>
						<td><b-form-textarea readonly v-model="data.developerComments" rows="7"></b-form-textarea></td>
					</tr>
					<tr>
						<th class="min">User comments</th>
						<td><b-form-textarea readonly v-model="data.userComments" rows="7"></b-form-textarea></td>
					</tr>
					<tr v-if="!isNullOrEmpty(data.exception)">
						<th class="min">Error message received</th>
						<td><b-form-textarea readonly v-model="data.exception" rows="10"></b-form-textarea></td>
					</tr>
				</tbody>
			</table>

			<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</page-authorization-container>
	</div>
</template>

<script>
	export default {
		name: 'ErrorReportView',
		data() {
			return {
				reportID: this.$route.params.reportID,
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				data: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.reportID = this.$route.params.reportID;

				try {
					const response = await this.$http.get(`errorreports/view/${this.reportID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<div v-if="data.importID && data.importStatus.isRunning" class="max-w-400 py-5 mx-auto text-center">
				<b-progress :value="data.importStatus.progress.percent" :max="100" animated></b-progress>
				<p>
					{{data.importStatus.progress.message}}
				</p>
			</div>
			<div v-else-if="data.importID && data.importStatus.hasError">
				<h2 class="mb-4">There was an error importing this dataset</h2>
				<p>
					The user encountered the following error trying to import this dataset.
				</p>
				<p>
					<b-form-textarea v-model="data.importStatus.exceptionMessage" rows="8"></b-form-textarea>
				</p>
			</div>
			<div v-else>
				<h2 class="mb-4">Dataset details</h2>

				<b-alert variant="warning" :show="data.publicRequest && data.publicRequest.status === 'Submitted'">
					The creator of this dataset has requested it be made available to the public.
					Click the permissions tab below to review the request.
				</b-alert>

				<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
					<b-tab title="Overview">
						<div class="lead text-center mb-4">
							<span v-if="data.isUserSubmitted">
								The <strong>{{data.name}}</strong> dataset was uploaded {{data.createdOn | date}} by
								<router-link :to="`/admin/users/${data.userID}`">{{data.userName}}</router-link>.
								<span v-if="!data.isPublic && data.accessibility === 'User'">
									<br />This dataset is not shared. Its availability is limited to its creator.
								</span>
								<span v-if="!data.isPublic && data.accessibility === 'Group' && data.groups.length > 0">
									<br />This dataset is not available publicly, but is shared with the following groups:
									<span v-for="(g, i) in data.groups" :key="i">
										<router-link :to="`/admin/users/groups/${g.value}`">{{g.text}}</router-link><span v-if="i < data.groups.length - 1">, </span>
									</span>.
								</span>
							</span>
							<span v-else>
								<strong>{{data.name}}</strong> is a system-wide dataset
							</span>
							<span v-if="data.tiers.length > 0 && data.isPublic">
								<span v-if="data.isUserSubmitted"><br />It is </span>
								available to all users in
								<span v-for="(t, i) in data.tiers" :key="i">
									<router-link :to="`/admin/users/tiers/${t.value}`">{{t.text}}</router-link><span v-if="i < data.tiers.length - 1">, </span>
								</span>.
							</span>
						</div>

						<b-row align-h="center">
							<b-col cols="6" lg="3">
								<div :class="`stat-box ${activeClass} text-white mb-2`" v-b-tooltip.hover title="An active status means the dataset can be used for new projects">
									<div class="title">Status: {{ data.isActive ? 'Active' : 'Inactive' }}</div>
									<div class="value">
										<font-awesome-icon v-if="data.isActive" :icon="['fas', 'check']" />
										<font-awesome-icon v-else :icon="['fas', 'times']" />
									</div>
								</div>
							</b-col>
							<b-col cols="6" lg="3">
								<div class="stat-box bg-info text-white mb-2">
									<div class="title">Subbasins</div>
									<div class="value">{{ data.counts.subbasins |  number(0) }}</div>
								</div>
							</b-col>
							<b-col cols="6" lg="3">
								<div class="stat-box bg-primary text-white mb-2">
									<div class="title">HRUs</div>
									<div class="value">{{ data.counts.hrus |  number(0) }}</div>
								</div>
							</b-col>
							<b-col cols="6" lg="3">
								<div class="stat-box bg-dark text-white mb-2" v-b-tooltip.hover title="Number of projects using this dataset">
									<div class="title">Projects</div>
									<div class="value">{{ data.counts.projects |  number(0) }}</div>
								</div>
							</b-col>
						</b-row>
					</b-tab>
					<b-tab title="Settings">
						<b-form>
							<error-list :errors="page.saveErrors"></error-list>
							<success-alert ref="savedAlert" text="Changes saved." />

							<b-form-group class="bg-light py-3 px-2 mb-4 border">
								<b-form-checkbox v-model.trim="$v.data.isActive.$model" switch>
									{{ data.isActive ? 'Active &mdash; new projects using this dataset are allowed' : 'Inactive &mdash; no new projects can be created using this dataset, but old projects already using it are still functional' }}
								</b-form-checkbox>
							</b-form-group>

							<b-form-group label="Name of the dataset (fewer than 50 characters)" :invalid-feedback="requiredFeedback($v.data.name)">
								<b-form-input v-model.trim="$v.data.name.$model" type="text" autofocus :state="getValidState($v.data.name)"></b-form-input>
							</b-form-group>

							<b-form-group label="Description (fewer than 200 characters)" :invalid-feedback="requiredFeedback($v.data.description)">
								<b-form-textarea v-model.trim="$v.data.description.$model" rows="4" :state="getValidState($v.data.description)"></b-form-textarea>
							</b-form-group>

							<b-form-group label="Agency, organization, or person to whom this dataset should be credited (optional)" :invalid-feedback="requiredFeedback($v.data.attribution)">
								<b-form-input v-model.trim="$v.data.attribution.$model" type="text" :state="getValidState($v.data.attribution)"></b-form-input>
							</b-form-group>

							<b-form-group label="Version" :invalid-feedback="requiredFeedback($v.data.version)">
								<b-form-input v-model.trim="$v.data.version.$model" type="number" step="any" :state="getValidState($v.data.version)"></b-form-input>
							</b-form-group>

							<b-form-group label="Default SWAT version for new scenarios using this dataset" :invalid-feedback="requiredFeedback($v.data.defaultSWATVersionID)">
								<b-form-select v-model.trim="$v.data.defaultSWATVersionID.$model" :options="options.swatVersions" :state="getValidState($v.data.defaultSWATVersionID)">
									<template #first>
										<b-form-select-option :value="null">System default (latest version)</b-form-select-option>
									</template>
								</b-form-select>
							</b-form-group>

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.allowOnlyDefaultSWATVersion.$model">
									Allow projects to only use the SWAT version selected above?
								</b-form-checkbox>
							</b-form-group>

							<hr class="mt-5 mb-4" />

							<h4>Advanced</h4>

							<p class="text-danger">
								Warning: changing the fields below could break the system.
								Please use caution when changing values.
							</p>

							<b-row>
								<b-col>
									<b-form-group label="Grouping" :invalid-feedback="requiredFeedback($v.data.optionGroup)">
										<b-form-input v-model.trim="$v.data.optionGroup.$model" type="text" :state="getValidState($v.data.optionGroup)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Display order" :invalid-feedback="requiredFeedback($v.data.optionOrder)">
										<b-form-input v-model.trim="$v.data.optionOrder.$model" type="number" :state="getValidState($v.data.optionOrder)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col>
									<b-form-group label="Config. File Path (relative)" :invalid-feedback="requiredFeedback($v.data.configPath)">
										<b-form-input v-model.trim="$v.data.configPath.$model" type="text" :state="getValidState($v.data.configPath)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Weather File Path (relative)" :invalid-feedback="requiredFeedback($v.data.weatherPath)">
										<b-form-input v-model.trim="$v.data.weatherPath.$model" type="text" :state="getValidState($v.data.weatherPath)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.isUserSubmitted.$model">
									Show as user submitted? In the project creation page, this will list the dataset under user submitted grouping.
									(If left unchecked, dataset must be public.)
								</b-form-checkbox>
							</b-form-group>

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.loadByDefault.$model">
									Load map of entire dataset area when selected?
									Recommended for small, user-submitted datasets, but not large, country-sized system datasets.
								</b-form-checkbox>
							</b-form-group>

							<b-row>
								<b-col>
									<b-form-group label="Minimum Latitude" :invalid-feedback="requiredFeedback($v.data.minLat)">
										<b-form-input v-model.trim="$v.data.minLat.$model" type="number" step="any" :state="getValidState($v.data.minLat)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Maximum Latitude" :invalid-feedback="requiredFeedback($v.data.maxLat)">
										<b-form-input v-model.trim="$v.data.maxLat.$model" type="number" step="any" :state="getValidState($v.data.maxLat)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group label="Minimum Longitude" :invalid-feedback="requiredFeedback($v.data.minLon)">
										<b-form-input v-model.trim="$v.data.minLon.$model" type="number" step="any" :state="getValidState($v.data.minLon)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Maximum Longitude" :invalid-feedback="requiredFeedback($v.data.maxLon)">
										<b-form-input v-model.trim="$v.data.maxLon.$model" type="number" step="any" :state="getValidState($v.data.maxLon)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<b-form-group label="Boundary GeoJson" description="JSON format. Contact developer for instructions." class="my-3">
								<b-form-textarea v-model.trim="$v.data.outline.$model" rows="5" :state="getValidState($v.data.outline)"></b-form-textarea>
							</b-form-group>

							<hr class="my-4" />

							<p class="mt-4">
								Restrict curve number values so they never exceed the limits below, despite user changes.
								Leave blank to allow any curve number values.
							</p>

							<b-row>
								<b-col>
									<b-form-group label="Minimum curve number" :invalid-feedback="requiredFeedback($v.data.minCn2)">
										<b-form-input v-model.trim="$v.data.minCn2.$model" type="number" step="any" :state="getValidState($v.data.minCn2)"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group label="Maximum curve number" :invalid-feedback="requiredFeedback($v.data.maxCn2)">
										<b-form-input v-model.trim="$v.data.maxCn2.$model" type="number" step="any" :state="getValidState($v.data.maxCn2)"></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>

							<hr class="my-4" />

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.restrictSelection.$model">
									Enable subbasin restriction to a certain area? Note: developer will need to set the selectable subbasins.
								</b-form-checkbox>
							</b-form-group>

							<b-form-group label="Message to user when clicking outside selectable area" :invalid-feedback="requiredFeedback($v.data.restrictionMessage)">
								<b-form-textarea v-model.trim="$v.data.restrictionMessage.$model" rows="4" :state="getValidState($v.data.restrictionMessage)"></b-form-textarea>
							</b-form-group>

							<hr class="my-4" />

							<b-form-group label="Form's default HRU reduction area (km2) - uses 1km2 if not specified">
								<b-form-input v-model.trim="$v.data.defaultHruThreshold.$model" type="number" step="any" :state="getValidState($v.data.defaultHruThreshold)"></b-form-input>
							</b-form-group>

							<p class="mt-4">
								If the dataset has ICLUS (Integrated Climate and Land Use Scenarios) files, include the required HRU threshold below.
								Leave blank to disable ICLUS for the dataset.
							</p>

							<b-form-group label="ICLUS HRU reduction area (km2)">
								<b-form-input v-model.trim="$v.data.iclusHruThreshold.$model" type="number" step="any" :state="getValidState($v.data.iclusHruThreshold)"></b-form-input>
							</b-form-group>

							<hr class="my-4" />

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.includeModflow.$model">
									Include MODFLOW process when running simulations?
									Files will need to be configured under <code>{dataDirectory}/config/modflow/{datasetID}</code>.
								</b-form-checkbox>
							</b-form-group>

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.allowGisApiAccess.$model">
									Allow projects to download GIS files?
									This is only functional for USA HUC v2 data.
								</b-form-checkbox>
							</b-form-group>

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.allowApiAccess.$model">
									Allow API access to dataset?
								</b-form-checkbox>
							</b-form-group>

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.allowSqliteEditorTables.$model">
									Allow writing SWAT editor tables in SQLite format?
								</b-form-checkbox>
							</b-form-group>

							<b-form-group class="my-4">
								<b-form-checkbox v-model.trim="$v.data.hideFromHelpTable.$model">
									Hide from tier access table in help page?
								</b-form-checkbox>
							</b-form-group>

							<hr class="my-4" />

							<b-form-group label="Scenario calibration data text">
								<tinymce-editor :api-key="tinymce.api" v-model="data.calibrationPageText" :init="tinymce.init"></tinymce-editor>
							</b-form-group>
						</b-form>
					</b-tab>
					<b-tab title="Permissions">
						<error-list :errors="page.saveErrors"></error-list>
						<success-alert ref="sharedAlert" text="Changes saved." />

						<b-alert variant="warning" :show="!data.isUserSubmitted && data.isPublic && share.accessibility !== 'Public'">
							Warning: changing the share permissions
							means it will no longer be accessible to all users. This is not
							recommended for system-wide datasets.
						</b-alert>

						<div v-if="data.publicRequest && data.publicRequest.status === 'Submitted'">
							<p>
								The creator of this dataset would like it to made available to all users.
								To approve the request and make the dataset publicly
								available, select all users from the drop down below then check
								the tiers which should have access to the dataset.
								To deny the request, leave the share settings as is (creator or groups)
								and check deny request below.
								You may optionally send a message to the user regarding your decision.
								Click save changes when done.
							</p>
							<b-form-group :label="`Message from ${data.userName}`">
								<b-form-textarea v-model="data.publicRequest.userMessage" rows="3" readonly></b-form-textarea>
							</b-form-group>
							<b-form-group label="Message to user (optional)" :invalid-feedback="requiredFeedback($v.share.adminMessage)">
								<b-form-textarea v-model.trim="$v.share.adminMessage.$model" rows="3" :state="getValidState($v.share.adminMessage)"></b-form-textarea>
							</b-form-group>
							<b-form-group>
								<b-form-checkbox v-model.trim="$v.share.deny.$model">Deny this request?</b-form-checkbox>
							</b-form-group>
							<hr class="my-4" />
						</div>

						<b-form-group label="Share this dataset with:" class="mb-4">
							<b-form-select v-model.trim="$v.share.accessibility.$model" :options="options.share"></b-form-select>
						</b-form-group>

						<div v-if="share.accessibility === 'Group'">
							<p>
								Select groups that will have access to this dataset:
							</p>

							<div class="checklist">
								<b-form-checkbox-group v-model.trim="$v.share.groups.$model" stacked>
									<div class="item" v-for="(g, i) in options.groups" :key="i">
										<b-form-checkbox :value="g.value">{{g.text}}</b-form-checkbox>
									</div>
								</b-form-checkbox-group>
							</div>

							<p v-if="data.isUserSubmitted" class="mt-4">
								Note: we recommend letting the user who created the dataset manage the
								groups to which it is shared.
							</p>
						</div>

						<div v-if="share.accessibility === 'Public'">
							<p>
								Select user tiers that will have access to this dataset:
							</p>

							<div class="checklist">
								<b-form-checkbox-group v-model.trim="$v.share.tiers.$model" stacked>
									<div class="item" v-for="(g, i) in options.tiers" :key="i">
										<b-form-checkbox :value="g.value">{{g.text}}</b-form-checkbox>
									</div>
								</b-form-checkbox-group>
							</div>
						</div>

						<div v-if="data.publicRequest && data.publicRequest.status !== 'Submitted'">
							<hr class="my-4" />

							<div v-if="data.publicRequest.status === 'Approved'">
								<p>
									This dataset was <strong class="text-success">approved</strong>
									for public use by {{data.publicRequest.reviewedBy}}
									on {{data.publicRequest.reviewedOn | date}}.
								</p>
							</div>
							<div v-if="data.publicRequest.status === 'Denied'">
								<p>
									This dataset was <strong class="text-danger">denied</strong>
									public usage by {{data.publicRequest.reviewedBy}}
									on {{data.publicRequest.reviewedOn | date}}.
								</p>
							</div>
							<b-form-group label="User's request">
								<b-form-textarea v-model="data.publicRequest.userMessage" rows="3" readonly></b-form-textarea>
							</b-form-group>
							<b-form-group label="Administrator's response">
								<b-form-textarea v-model="data.publicRequest.adminMessage" rows="3" readonly></b-form-textarea>
							</b-form-group>
						</div>
					</b-tab>
					<b-tab title="Weather Options">
						<error-list :errors="page.saveErrors"></error-list>
						<success-alert ref="sharedAlert" text="Changes saved." />

						<p>
							Select weather datasets available for this dataset. CAREFUL: data must be named according to the dataset's subbasin names.
							Recommended developer change only.
						</p>

						<div class="checklist">
							<b-form-checkbox-group v-model.trim="$v.selectedWeather.$model" stacked>
								<div class="item" v-for="(g, i) in options.weather" :key="i">
									<b-form-checkbox :value="g.value">{{g.text}}</b-form-checkbox>
								</div>
							</b-form-checkbox-group>
						</div>
					</b-tab>
				</b-tabs>

				<fixed-action-bar :cols-lg="10" :offset-lg="2">
					<save-button v-if="tabIndex === settingsTab" @click.native="saveSettings" :saving="page.saving" class="mr-2" :disabled="!$v.data.$anyDirty" />
					<save-button v-if="tabIndex === permissionsTab" @click.native="savePermissions" :saving="page.saving" class="mr-2" :disabled="!$v.share.$anyDirty" />
					<save-button v-if="tabIndex === weatherTab" @click.native="saveWeather" :saving="page.saving" class="mr-2" :disabled="!$v.selectedWeather.$anyDirty" />
					<back-button class="btn btn-secondary mr-2" />

					<b-button variant="danger" class="ml-auto"
							  v-if="isAuthorized(roleNames.admin)"
							  @click="page.delete.show = true">Delete</b-button>
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete this dataset?
						This action is permanent and cannot be undone.
						ALL projects and data will be deleted.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import Editor from '@tinymce/tinymce-vue';
	import { required, requiredIf, maxLength, between } from 'vuelidate/lib/validators';
	import _ from 'lodash';

	export default {
		name: 'AdminDatasetView',
		components: {
			'tinymce-editor': Editor
		},
		data() {
			return {
				datasetID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				data: {
					isActive: false,
					name: null,
					description: null,
					attribution: null,
					version: 1,
					importStatus: {
						isRunning: false,
						hasError: false
					},
					counts: {},
					tiers: [],
					isUserSubmitted: false,
					optionOrder: 0,
					optionGroup: null,
					restrictSelection: false,
					restrictionMessage: null,
					defaultSWATVersionID: null,
					allowOnlyDefaultSWATVersion: false,
					allowGisApiAccess: false,
					allowApiAccess: false,
					includeModflow: false,
					outline: null,
					iclusHruThreshold: null,
					defaultHruThreshold: null,
					allowSqliteEditorTables: false,
					configPath: null,
					weatherPath: null,
					calibrationPageText: null,
					hideFromHelpTable: false
				},
				tabIndex: 0,
				settingsTab: 1,
				permissionsTab: 2,
				weatherTab: 3,
				options: {
					share: [
						{ value: 'User', text: 'Creator only' },
						{ value: 'Group', text: 'Selected groups' },
						{ value: 'Public', text: 'All users in selected tiers' }
					],
					groups: [],
					tiers: [],
					swatVersions: [],
					weather: []
				},
				share: {
					accessibility: null,
					groups: [],
					tiers: [],
					deny: false,
					adminMessage: null
				},
				selectedWeather: [],
				tinymce: {
					api: 'umc6fcf2lmc4vpzyrqb2jfcr2slzvqb94kk4ac6wl757gx5b',
					init: {
						height: 400,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount'
						],
						toolbar: 'undo redo | formatselect | bold italic underline | link unlink | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
					}
				}
			}
		},
		validations: {
			data: {
				isActive: {},
				name: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					maxLength: maxLength(50)
				},
				description: {
					maxLength: maxLength(200)
				},
				attribution: {
					maxLength: maxLength(100)
				},
				version: { required: requiredIf(function () { return this.tabIndex == this.settingsTab; }) },
				minLat: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					between: between(-180, 180)
				},
				maxLat: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					between: between(-180, 180)
				},
				minLon: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					between: between(-180, 180)
				},
				maxLon: {
					required: requiredIf(function () { return this.tabIndex == this.settingsTab; }),
					between: between(-180, 180)
				},
				minCn2: {},
				maxCn2: {},
				loadByDefault: {},
				isUserSubmitted: {},
				optionOrder: { required },
				optionGroup: {},
				restrictSelection: {},
				restrictionMessage: {},
				defaultSWATVersionID: {},
				allowOnlyDefaultSWATVersion: {},
				allowGisApiAccess: {},
				allowApiAccess: {},
				includeModflow: {},
				outline: {},
				iclusHruThreshold: {},
				defaultHruThreshold: {},
				allowSqliteEditorTables: {},
				configPath: {},
				weatherPath: {},
				calibrationPageText: {},
				hideFromHelpTable: {}
			},
			share: {
				accessibility: {},
				groups: {},
				tiers: {},
				deny: {},
				adminMessage: {}
			},
			selectedWeather: {}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;
				this.datasetID = this.$route.params.id;

				try {
					const response = await this.$http.get(`admin/datasets/${this.datasetID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data.dataset;
					this.options.groups = response.data.groups;
					this.options.tiers = response.data.tiers;
					this.options.swatVersions = response.data.swatVersions;
					
					this.share.accessibility = this.data.accessibility;
					this.share.groups = this.data.groupIDs;
					this.share.tiers = this.data.tierIDs;

					this.options.weather = response.data.weather.options;
					this.selectedWeather = response.data.weather.datasets;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async saveSettings() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						var data = {
							id: this.datasetID,
							isActive: this.data.isActive,
							name: this.data.name,
							description: this.data.description,
							attribution: this.data.attribution,
							version: this.data.version,
							loadByDefault: this.data.loadByDefault,
							maxLat: this.data.maxLat,
							minLat: this.data.minLat,
							maxLon: this.data.maxLon,
							minLon: this.data.minLon,
							maxCn2: this.data.maxCn2,
							minCn2: this.data.minCn2,
							isUserSubmitted: this.data.isUserSubmitted,
							optionOrder: this.data.optionOrder,
							optionGroup: this.data.optionGroup,
							restrictSelection: this.data.restrictSelection,
							restrictionMessage: this.data.restrictionMessage,
							defaultSWATVersionID: this.data.defaultSWATVersionID,
							allowOnlyDefaultSWATVersion: this.data.allowOnlyDefaultSWATVersion,
							allowGisApiAccess: this.data.allowGisApiAccess,
							allowApiAccess: this.data.allowApiAccess,
							includeModflow: this.data.includeModflow,
							outline: this.data.outline,
							iclusHruThreshold: this.data.iclusHruThreshold,
							defaultHruThreshold: this.data.defaultHruThreshold,
							allowSqliteEditorTables: this.data.allowSqliteEditorTables,
							configPath: this.data.configPath,
							weatherPath: this.data.weatherPath,
							calibrationPageText: this.data.calibrationPageText,
							hideFromHelpTable: this.data.hideFromHelpTable
						};
						await this.$http.put(`admin/datasets/${this.datasetID}`, data, this.getTokenHeader());
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async savePermissions() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.put(`admin/datasets/share/${this.datasetID}`, this.share, this.getTokenHeader());
						await this.get();
						this.$refs.sharedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async saveWeather() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						let data = {
							weatherDatasets: this.selectedWeather
						};
						const response = await this.$http.put(`admin/datasets/weather/${this.datasetID}`, data, this.getTokenHeader());
						await this.get();
						this.$refs.sharedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`admin/datasets/${this.datasetID}`, this.getTokenHeader());
					this.$router.push({ name: 'AdminDatasets' }).catch(err => { });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		},
		computed: {
			activeClass() {
				return this.data.isActive ? 'bg-success' : 'bg-danger';
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Project Metadata</h2>

			<p>
				This page lists your SWAT input data settings for each scenario in your project.
			</p>

			<b-row>
				<b-col md>
					<h3 class="mb-3">Watershed</h3>
					<table class="table table-striped border-bottom table-definitions">
						<tbody>
							<tr>
								<th>Project name</th>
								<td>{{project.name}}</td>
							</tr>
							<tr>
								<th>Dataset</th>
								<td>{{project.dataset}}</td>
							</tr>
							<tr>
								<th>Start of the watershed</th>
								<td v-if="project.startingSubbasins.length === 0">Head</td>
								<td v-else>{{project.startingSubbasins.join(', ')}}</td>
							</tr>
							<tr>
								<th>Ending subbasin</th>
								<td>{{project.endingSubbasin}}</td>
							</tr>
							<tr>
								<th>Number of subbasins</th>
								<td>{{project.numSubbasins | number(0)}}</td>
							</tr>
							<tr>
								<th>Total area</th>
								<td>{{project.totalArea | number(2)}} km<sup>2</sup></td>
							</tr>
						</tbody>
					</table>
				</b-col>
				<b-col md>
					<h3 class="mb-3">HRUs</h3>
					<table class="table table-striped border-bottom table-definitions">
						<tbody>
							<tr v-if="project.hasSetHrus">
								<th>Original number of HRUs</th>
								<td>{{project.numOriginalHrus | number(0)}}</td>
							</tr>
							<tr>
								<th>Number of HRUs</th>
								<td>{{project.numHrus | number(0)}}</td>
							</tr>
							<tr v-if="project.hasSetHrus">
								<th>Method for setting HRUs</th>
								<td v-if="project.hruMethod == 'Area'">
									Removed with area less than
									{{project.targetThreshold | number(2)}}
									<span v-if="project.hruUnits == 'Percent'">
										% of each subbasin
									</span>
									<span v-else>
										km<sup>2</sup>
									</span>
								</td>
								<td v-else-if="project.hruMethod == 'TargetHrus'">
									Aimed to retain {{project.targetThreshold | number(0)}} HRUs
								</td>
							</tr>
							<tr v-if="project.hasSetHrus">
								<th>Land use exempt</th>
								<td v-if="project.exemptLanduse.length === 0">None</td>
								<td v-else>{{project.exemptLanduse.join(', ')}}</td>
							</tr>
							<tr v-if="project.hasSetHrus">
								<th>Project area redistributed</th>
								<td>{{project.trimmedLanduseArea | number(2)}} km<sup>2</sup></td>
							</tr>
						</tbody>
					</table>
				</b-col>
			</b-row>

			<h3 class="mt-4 mb-3">Scenarios</h3>
			<b-table responsive striped :items="project.scenarios" :fields="tables.scenarios.fields" head-variant="light" show-empty>
				<template v-slot:empty>
					<p>This project does not have any scenarios.</p>
				</template>
			</b-table>

			<h3 class="mt-5 mb-3">Input Modifications</h3>
			<b-table responsive striped :items="project.modifications" :fields="tables.modifications.fields" head-variant="light" show-empty>
				<template v-slot:empty>
					<p>This project does not have any input modifications.</p>
				</template>
				<template v-slot:cell(values)="data">
					<div v-for="(k, i) in Object.keys(data.item.values)" :key="i">
						<span>{{k}}:</span>
						{{data.item.values[k]}}
					</div>
				</template>
				<template v-slot:cell(subbasins)="data">
					{{writeArray(data.item.subbasins.map(({ name }) => name))}}
				</template>
				<template v-slot:cell(landuses)="data">
					{{writeArray(data.item.landuses)}}
				</template>
				<template v-slot:cell(soils)="data">
					{{writeArray(data.item.soils)}}
				</template>
				<template v-slot:cell(slopes)="data">
					{{writeArray(data.item.slopes)}}
				</template>
			</b-table>

			<fixed-action-bar>
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</page-authorization-container>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		name: 'ProjectMetadata',
		data() {
			return {
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				project: {
					startingSubbasins: [],
					scenarios: [],
					exemptLanduse: [],
					modifications: []
				},
				tables: {
					scenarios: {
						fields: [
							{ key: 'name' },
							{ key: 'weatherDataset', label: 'Weather' },
							{ key: 'startingSimulationDate', label: 'Start', formatter: (value) => { return moment(value).format('M/D/YYYY') } },
							{ key: 'endingSimulationDate', label: 'End', formatter: (value) => { return moment(value).format('M/D/YYYY') } },
							{ key: 'skipYears', label: 'Skip' },
							{ key: 'printSetting', label: 'Print' },
							{ key: 'swatVersion', label: 'SWAT' }
						]
					},
					modifications: {
						fields: [
							{ key: 'scenario' },
							{ key: 'table' },
							{ key: 'subbasins' },
							{ key: 'landuses', label: 'Land use' },
							{ key: 'soils' },
							{ key: 'slopes' },
							{ key: 'values' }
						]
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`projects/metadata/${this.projectID}`, this.getTokenHeader());
					this.log(response.data);
					this.project = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			writeArray(items, limit = 5) {
				if (items === null || items.length === 0) return 'All';
				if (limit === 0) limit = items.length;
				var s = items.slice(0, limit).join(', ');

				if (items.length > limit) return s + ' (...)';
				return s;
			}
		}
	}
</script>

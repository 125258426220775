<template>
	<div>
		<div v-if="$route.name == 'AdminSwatVersionList'">
			<h2 class="mb-3">SWAT Executable Versions</h2>

			<grid-view api-url="admin/swatversions"
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="SWAT versions"
					   item-name="version"
					   show-create create-route="create"></grid-view>

			<hr class="my-4" />
			<h3 class="mb-3">Upload executable files</h3>
			<p>Note: existing files with the same name will be overwritten.</p>
			<error-list :errors="page.saveErrors"></error-list>
			<success-alert ref="savedAlert" text="Changes saved." />

			<vue-dropzone id="fileUpload" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'AdminSwatVersionList',
		components: {
			vueDropzone: vue2Dropzone, GridView
		},
		data() {
			return {
				page: {
					saveErrors: []
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true },
						{ key: 'releaseFileName', sortable: true },
						{ key: 'debugFileName', sortable: true },
						{ key: 'isDefault', label: 'Default?', sortable: true, formatter: (value) => { return value ? 'Yes' : 'No' } },
						{ key: 'isDisabled', label: 'Disabled?', sortable: true, formatter: (value) => { return value ? 'Yes' : 'No' } }
					],
					sort: 'name',
					reverse: true,
					itemsPerPage: 50
				},
				dropzoneOptions: {
					url: `/api/admin/swatversions/upload`,
					headers: this.getTokenHeaderPart(),
					acceptedFiles: '.exe',
					timeout: 300000
				}
			}
		},
		methods: {
			async fileUploaded(file, response) {
				this.log(response);
				this.page.saveErrors = [];
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			}
		}
	}
</script>

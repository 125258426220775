<template>
	<div class="table-responsive">
		<table class="table table-striped table-bordered">
			<thead>
				<tr class="thead">
					<th>Year</th>
					<th v-if="scheduleByHeatUnits">Op. #</th>
					<th v-if="!scheduleByHeatUnits">Month</th>
					<th v-if="!scheduleByHeatUnits">Day</th>
					<th>Operation</th>
					<th>Crop</th>
					<th v-if="scheduleByHeatUnits">Heat Units</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(m, i) in items" :key="i">
					<td>{{ m.YEAR }}</td>
					<td v-if="scheduleByHeatUnits">{{ m.OP_NUM }}</td>
					<td v-if="!scheduleByHeatUnits">{{ m.MONTH }}</td>
					<td v-if="!scheduleByHeatUnits">{{ m.DAY }}</td>
					<td>
						{{ getTextFromList(lists.opList, m.MGT_OP) }}
						<div style="margin-top:1em; font-size:0.8em;">
							<div v-if="m.MGT_OP == 1" class="row">
								<div class="col-md-4">
									CURYR_MAT: {{ m.CURYR_MAT }}
								</div>
								<div class="col-md-4">
									HEATUNITS: {{ m.HEATUNITS }}
								</div>
								<div class="col-md-4">
									LAI_INIT: {{ m.LAI_INIT }}
								</div>
								<div class="col-md-4">
									BIO_INIT: {{ m.BIO_INIT }} kg/ha
								</div>
								<div class="col-md-4">
									HI_TARG: {{ m.HI_TARG }} (kg/ha)/(kg/ha)
								</div>
								<div class="col-md-4">
									BIO_TARG: {{ m.BIO_TARG }} metric tons/ha
								</div>
								<div class="col-md-4">
									CNOP: {{ m.CNOP }}
								</div>
							</div>

							<div v-if="m.MGT_OP == 2" class="row">
								<div class="col-md-4">
									IRR_AMT: {{ m.IRR_AMT }} mm
								</div>
								<div class="col-md-4">
									IRR_SALT: {{ m.IRR_SALT }} mg/kg
								</div>
								<div class="col-md-4">
									IRR_EFM: {{ m.IRR_EFM }}
								</div>
								<div class="col-md-4">
									IRR_SQ: {{ m.IRR_SQ }}
								</div>
								<div class="col-md-4">
									IRR_SC: {{ m.IRR_SC }}
								</div>
								<div class="col-md-4">
									IRR_NO: {{ m.IRR_NO }}
								</div>
							</div>

							<div v-if="m.MGT_OP == 3" class="row">
								<div class="col-md-4">
									FERT_ID: {{ getTextFromList(lists.fertList, m.FERT_ID) }}
								</div>
								<div class="col-md-4">
									FRT_KG: {{ m.FRT_KG }} kg/ha
								</div>
								<div class="col-md-4">
									FRT_SURFACE: {{ m.FRT_SURFACE }}
								</div>
							</div>

							<div v-if="m.MGT_OP == 4" class="row">
								<div class="col-md-4">
									PEST_ID: {{ getTextFromList(lists.pestList, m.PEST_ID) }}
								</div>
								<div class="col-md-4">
									PST_KG: {{ m.PST_KG }} kg/ha
								</div>
								<div class="col-md-4">
									PST_DEP: {{ m.PST_DEP }} mm
								</div>
							</div>

							<div v-if="m.MGT_OP == 5" class="row">
								<div class="col-md-4">
									CNOP: {{ m.CNOP }}
								</div>
								<div class="col-md-4">
									HI_OVR: {{ m.HI_OVR }} (kg/ha)/(kg/ha)
								</div>
							</div>

							<div v-if="m.MGT_OP == 6" class="row">
								<div class="col-md-4">
									TILL_ID: {{ getTextFromList(lists.tillList, m.TILL_ID) }}
								</div>
								<div class="col-md-4">
									CNOP: {{ m.CNOP }}
								</div>
							</div>

							<div v-if="m.MGT_OP == 7" class="row">
								<div class="col-md-4">
									IHV_GBM: {{ m.IHV_GBM }}
								</div>
								<div class="col-md-4">
									HARVEFF: {{ m.HARVEFF }}
								</div>
								<div class="col-md-4">
									HI_OVR: {{ m.HI_OVR }} (kg/ha)/(kg/ha)
								</div>
							</div>

							<div v-if="m.MGT_OP == 9" class="row">
								<div class="col-md-4">
									MANURE_ID: {{ getTextFromList(lists.manureList, m.MANURE_ID) }}
								</div>
								<div class="col-md-4">
									GRZ_DAYS: {{ m.GRZ_DAYS }}
								</div>
								<div class="col-md-4">
									BIO_EAT: {{ m.BIO_EAT }} (kg/ha)/day
								</div>
								<div class="col-md-4">
									BIO_TRMP: {{ m.BIO_TRMP }} (kg/ha)/day
								</div>
								<div class="col-md-4">
									MANURE_KG: {{ m.MANURE_KG }} (kg/ha)/day
								</div>
							</div>

							<div v-if="m.MGT_OP == 10" class="row">
								<div class="col-md-4">
									WSTRS_ID: {{ m.WSTRS_ID }}
								</div>
								<div class="col-md-4">
									IRR_SCA: {{ m.IRR_SCA }}
								</div>
								<div class="col-md-4">
									IRR_NOA: {{ m.IRR_NOA }}
								</div>
								<div class="col-md-4">
									AUTO_WSTRS: {{ m.AUTO_WSTRS }}
								</div>
								<div class="col-md-4">
									IRR_EFF: {{ m.IRR_EFF }}
								</div>
								<div class="col-md-4">
									IRR_MX: {{ m.IRR_MX }} mm
								</div>
								<div class="col-md-4">
									IRR_ASQ: {{ m.IRR_ASQ }}
								</div>
							</div>

							<div v-if="m.MGT_OP == 11" class="row">
								<div class="col-md-4">
									AFERT_ID: {{ getTextFromList(lists.fertList, m.AFERT_ID) }}
								</div>
								<div class="col-md-4">
									AUTO_NSTRS: {{ m.AUTO_NSTRS }}
								</div>
								<div class="col-md-4">
									AUTO_NAPP: {{ m.AUTO_NAPP }} kg N/ha
								</div>
								<div class="col-md-4">
									AUTO_NYR: {{ m.AUTO_NYR }} kg N/ha
								</div>
								<div class="col-md-4">
									AUTO_EFF: {{ m.AUTO_EFF }}
								</div>
								<div class="col-md-4">
									AFRT_SURFACE: {{ m.AFRT_SURFACE }}
								</div>
							</div>

							<div v-if="m.MGT_OP == 12" class="row">
								<div class="col-md-4">
									SWEEPEFF: {{ m.SWEEPEFF }}
								</div>
								<div class="col-md-4">
									FR_CURB: {{ m.FR_CURB }}
								</div>
							</div>

							<div v-if="m.MGT_OP == 13" class="row">
								<div class="col-md-4">
									IMP_TRIG: {{ m.IMP_TRIG }}
								</div>
							</div>

							<div v-if="m.MGT_OP == 14" class="row">
								<div class="col-md-4">
									CFRT_ID: {{ getTextFromList(lists.fertList, m.CFRT_ID) }}
								</div>
								<div class="col-md-4">
									FERT_DAYS: {{ m.FERT_DAYS }}
								</div>
								<div class="col-md-4">
									IFRT_FREQ: {{ m.IFRT_FREQ }}
								</div>
								<div class="col-md-4">
									CFRT_KG: {{ m.CFRT_KG }} kg/ha
								</div>
							</div>

							<div v-if="m.MGT_OP == 15" class="row">
								<div class="col-md-4">
									CPST_ID: {{ getTextFromList(lists.pestList, m.CPST_ID) }}
								</div>
								<div class="col-md-4">
									PEST_DAYS: {{ m.FERT_DAYS }}
								</div>
								<div class="col-md-4">
									IPEST_FREQ: {{ m.IFRT_FREQ }}
								</div>
								<div class="col-md-4">
									CPST_KG: {{ m.CFRT_KG }} kg/ha
								</div>
							</div>

							<div v-if="m.MGT_OP == 16" class="row">
								<div class="col-md-4">
									BURN_FRLB: {{ m.BURN_FRLB }}
								</div>
							</div>
						</div>
					</td>
					<td>{{ getTextFromList(lists.plantList, m.PLANT_ID) }}</td>
					<td v-if="scheduleByHeatUnits">{{ m.HUSC }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: 'Mgt2Viewer',
		props: {
			scheduleByHeatUnits: {
				type: Boolean,
				default: true
			},
			items: {
				type: Array,
				default: () => []
			},
			lists: {
				type: Object,
				default: () => {
					return {
						plantList: [],
						fertList: [],
						pestList: [],
						tillList: [],
						manureList: [],
						scheduleOptions: [],
						opList: [],
						irrscList: [],
						ihvGbmList: [],
						wstrsList: [],
						impTrigList: []
					};
				}
			}
		},
		methods: {
			getTextFromList(list, value) {
				var filtered = list.filter(function (el) { return el.value === value; });
				if (filtered.length > 0) return filtered[0].text;
				return '';
			}
		}
	}
</script>

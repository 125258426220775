<template>
	<div>
		<page-authorization-container :page="page" show-errors-with-object no-bottom-marg>
			<b-container fluid class="p-0">
				<b-row no-gutters>
					<b-col md="3" class="fixed-height shadow d-flex align-items-stretch flex-column" style="z-index:500">
						<error-list :errors="page.saveErrors"></error-list>
						<error-list :errors="calibrationGroup.errors"></error-list>

						<div class="px-3 py-4 bg-light border-bottom">
							Select a dataset from the dropdown below, then click on the map to select your watershed.
							The map will display all subbasins upstream of your click location, as well as nearby subbasins.
						</div>
						<div class="p-3">
							<b-form-group label="Show the following calibrated watersheds on map" v-if="calibrationLayers.groups.length > 0">
								<b-form-select v-model="calibrationGroup.selected" :options="calibrationLayers.groups" @change="getCalibrationGroup">
									<template #first>
										<b-form-select-option :value="null">None</b-form-select-option>
									</template>
								</b-form-select>
							</b-form-group>

							<div v-show="calibrationGroup.selected !== null">
								<b-alert variant="light" show class="border mt-2 d-flex">
									<div>
										<font-awesome-icon :icon="['fas', 'info-circle']" class="text-info mr-2" />
									</div>
									<div>
										Click a calibration site for more information or to select it as a new project.
										<span v-if="project.endID === undefined">Turn the layer back off to return to selecting any downstream subbasin.</span>
										<span v-else>Your previously selected watershed is still on the map. Turn the layer back off to return to it.</span>
									</div>
								</b-alert>
							</div>
							<div v-show="calibrationGroup.selected === null">
								<b-form-group :label="`${siteText.siteName} Dataset`">
									<b-form-select v-model="project.datasetID" @change="datasetChange">
										<b-form-select-option-group v-for="(o, i) in options.datasets" :key="i" :label="isNullOrEmpty(o.label) ? 'System datasets' : o.label" :options="o.options"></b-form-select-option-group>
										<b-form-select-option-group v-if="options.userDatasets.length > 0" label="User-submitted datasets" :options="options.userDatasets"></b-form-select-option-group>
									</b-form-select>
									<div v-if="!isNullOrEmpty(selectedDataset) && (!isNullOrEmpty(selectedDataset.description) || !isNullOrEmpty(selectedDataset.attribution))">
										<b-alert variant="light" show class="border mt-2 d-flex">
											<div>
												<font-awesome-icon :icon="['fas', 'info-circle']" class="text-info mr-2" />
											</div>
											<div>
												{{selectedDataset.description}}
												<div v-if="!isNullOrEmpty(selectedDataset.attribution)">
													<i>Credit: {{selectedDataset.attribution}}</i>
												</div>
											</div>
										</b-alert>
									</div>
								</b-form-group>
								<b-form-group label="Downstream subbasin">
									<b-typeahead ref="subbasinsTypeahead"
												 :data="options.subbasins == null ? [] : options.subbasins"
												 v-model="subbasinsSearch"
												 :serializer="s => s.name"
												 :max-matches="100"
												 @hit="loadMapFromSub($event.name)">
										<template slot="append">
											<b-button @click="loadMapFromSub(subbasinsSearch)" variant="secondary" v-b-tooltip.hover="'Show watershed ending with this subbasin on map'">
												<font-awesome-icon :icon="['fas', 'map-marked-alt']" />
											</b-button>
										</template>
									</b-typeahead>
								</b-form-group>
								<div v-if="(hasWatershed() && map.route.length > 2) || startingSubs.length > 0 || options.selectStart">
									<b-button @click="options.showAdvanced = !options.showAdvanced" variant="light" size="sm" class="pr-3">
										<font-awesome-icon :icon="['fas', options.showAdvanced ? 'caret-down' : 'caret-right']" fixed-width />
										Advanced options
									</b-button>

									<b-collapse id="adv-collapse" v-model="options.showAdvanced">
										<p class="mt-3">
											By default your watershed is routed from the head to the downstream subbasin.
											If you want to start your project somewhere other than the head, toggle the button below
											and click the desired starting subbasins on the map. We recommend providing inlet data to your scenarios when utilizing this option.
										</p>
										<div class="mb-1">
											<b-button v-for="(s, i) in startingSubs" :key="i" variant="light" size="sm" class="mr-2 mb-2" v-b-tooltip.hover="options.selectStart ? 'Click to remove from list' : 'Toggle the select starting subbasins button to modify'" @click="removeStartingSub(s.id)">
												{{s.name}}
												<font-awesome-icon v-if="options.selectStart" :icon="['fas', 'times']" class="text-danger" />
											</b-button>
										</div>
										<p>
											<b-button :pressed.sync="options.selectStart" variant="primary" @click="loadMapFromIds">{{options.selectStart ? 'Done' : 'Begin'}} selecting starting subbasins</b-button>
										</p>
									</b-collapse>
								</div>
							</div>
						</div>
						<div v-if="hasWatershed() && !options.selectStart && calibrationGroup.selected === null" class="mt-4 p-3 border-top">
							<p>
								The selected watershed contains {{map.numSubs | number(0)}} subbasins and {{map.numHrus | number(0)}} HRUs.
								<a href="#" @click.prevent="modals.route.show=true">View routing table.</a>
							</p>

							<div v-if="isNumSubsValid">
								<b-form-checkbox v-if="map.route.length > 1" v-model="map.showRoute">Show routing on map</b-form-checkbox>

								<b-form-group label="Give your project a name" class="mt-4">
									<b-form-input v-model="project.name" type="text" :state="getValidState($v.project.name)"></b-form-input>
									<b-form-invalid-feedback v-if="!$v.project.name.required">Required</b-form-invalid-feedback>
									<b-form-invalid-feedback v-if="!$v.project.name.maxLength">Must be fewer than 100 characters</b-form-invalid-feedback>
									<b-form-invalid-feedback v-if="!$v.project.name.hasAlphaNumeric">Must contain at least one letter or number</b-form-invalid-feedback>
								</b-form-group>

								<b-alert variant="warning" :show="!isNumHrusValid">
									Warning: your account is limited to {{limits.hruLimit | number(0)}} HRUs per project.
									You will be required to set your HRUs under this limit before running scenarios.
									Go to the set HRUs page after the project is created.
								</b-alert>

								<b-button type="submit" variant="primary" @click="createProject">Create Project</b-button>
							</div>
							<div v-else>
								<b-alert variant="warning" show>
									Warning: your account is limited to {{limits.subbasinLimit | number(0)}} subbasins
									and {{limits.hruLimit | number(0)}} HRUs per project.
									Please select a smaller watershed.
								</b-alert>
							</div>
						</div>

						<div class="d-none d-md-flex align-items-center border-top bg-white position-relative mt-auto">
							<div class="p-3">
								<router-link to="/projects" class="stretched-link text-reset" title="Back to project list">
									<font-awesome-icon :icon="['fas', 'chevron-left']" fixed-width />
								</router-link>
							</div>
							<div class="p-3 border-left text-uppercase">
								Back to project list
							</div>
						</div>
					</b-col>
					<b-col md="9">
						<div class="map-container full-map">
							<l-map ref="leafmap" style="height: 100%; width:100%" :zoom="defaultMapZoom" :center="defaultMapBounds" :bounds="map.bounds" @click="mapClick" @mousemove="mapMove">
								<l-control-layers position="topright"></l-control-layers>
								<l-tile-layer v-for="tileProvider in defaultMapTileProviders"
											  :key="tileProvider.name"
											  :name="tileProvider.name"
											  :visible="tileProvider.visible"
											  :url="tileProvider.url"
											  :attribution="tileProvider.attribution"
											  layer-type="base" />

								<l-geo-json ref="subGeojson" v-if="map.subbasins.geojson !== undefined" :geojson="map.subbasins.geojson" :options="mapSubbasinsOptions" :options-style="mapSubbasinsStyle"></l-geo-json>
								<l-geo-json v-if="map.streams.geojson !== undefined" :geojson="map.streams.geojson" :options="map.streams.options"></l-geo-json>
								<l-geo-json v-if="!isNullOrEmpty(map.outline.geojson)" :geojson="map.outline.geojson" :options="map.outline.options"></l-geo-json>
								<l-control position="bottomleft">
									<div v-if="map.currentCoords !== undefined">Cursor position: {{map.currentCoords.lat | number(3)}}, {{map.currentCoords.lng | number(3)}}</div>
								</l-control>
								<div v-if="map.showRoute && map.route.length > 1">
									<l-polyline v-for="(c, i) in polylines" :key="i" :lat-lngs="c.lineCoordinates" color="green"></l-polyline>
									<v-polyline-decorator :paths="routeCoordinates" :patterns="map.patterns"></v-polyline-decorator>
								</div>
								<l-geo-json ref="calibratedWatershedsGeojson"
											v-if="calibrationGroup.selected !== null && hasCalibrations"
											:geojson="calibrationGroup.geojson"
											:options="mapCalibratedWatershedsOptions" :options-style="mapCalibratedWatershedsStyle"></l-geo-json>
								<l-layer-group v-if="hasCalibrationLocations" :visible="hasCalibrationLocations && map.showCalibrationLocations" ref="calLocationLayer" layer-type="overlay" :name="calibrationLayers.locationPoints.name">
									<l-geo-json :geojson="calibrationLayers.locationPoints.geojson" :options="mapCalibrationLocationOptions"></l-geo-json>
								</l-layer-group>

								<l-layer-group v-if="!isNullOrEmpty(map.regions.geojson)" :visible="false" ref="regionsLayer" layer-type="overlay" name="HUC2 Region Boundaries">
									<l-geo-json :geojson="map.regions.geojson" :options="mapRegionOptions"></l-geo-json>
								</l-layer-group>
								<l-control position="bottomright">
									<b-button variant="dark" size="sm" @click="toggleColors" style="font-size:0.8rem">Toggle Contrast</b-button>
								</l-control>
							</l-map>
							<div v-if="map.loading" class="map-loading-overlay">
								<font-awesome-icon :icon="['fas', 'spinner']" spin size="3x" />
							</div>
						</div>
					</b-col>
				</b-row>
			</b-container>

			<b-modal v-model="modals.route.show" scrollable size="md" title="Watershed Route">
				<b-table striped hover small no-sort-reset :items="map.route" :fields="['fromName', 'toName']"></b-table>

				<div slot="modal-footer">
					<b-button type="button" variant="primary" @click="modals.route.show = false">Close</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.showLogin" size="md" hide-footer title="Please log in to continue">
				<login-form no-redirect @success="loginSuccess"></login-form>
			</b-modal>

			<b-modal v-model="modals.calibration.show" scrollable size="xl" :title="modals.calibration.watershed.name" hide-footer>
				<div v-if="modals.calibration.loading" class="text-center p-5">
					<font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
					<div>Please wait while we load the watershed...</div>
				</div>
				<div v-else>
					<div v-html="getLocationSitesTable(modals.calibration.watershed.locationSites)"></div>
					<p>
						<small class="text-muted">See the <router-link to="/help" target="_blank">help page</router-link> for more information about the calibrated parameters for this watershed.</small>
					</p>

					<hr class="my-4" />

					<div class="form-inline my-4">
						<b-form-select v-model="modals.calibration.selectedDataset" :options="modals.calibration.watershed.datasets" class="d-inline mr-2">
							<template #first>
								<b-form-select-option :value="null">Select a dataset to set up a project</b-form-select-option>
							</template>
						</b-form-select>
						<b-button type="button" variant="primary" @click="setProjectToCalibration" :disabled="modals.calibration.selectedDataset === null" class="mr-2">Set Up Project</b-button>
						<b-button type="button" variant="secondary" @click="modals.calibration.show = false">Cancel</b-button>
					</div>
				</div>
			</b-modal>
		</page-authorization-container>
	</div>
</template>

<script>
	import L from 'leaflet';
	import { LMap, LTileLayer, LGeoJson, LControl, LPolyline, LControlLayers, LLayerGroup } from 'vue2-leaflet';
	import Vue2LeafletPolylineDecorator from '@/components/helpers/Vue2LeafletPolylineDecorator';
	import _ from 'underscore';
	import 'leaflet/dist/leaflet.css';
	import { required, maxLength } from 'vuelidate/lib/validators';
	var bbox = require('geojson-bbox');

	var __globalStartingSubs = []; //needed to sync with this.startingSubs because style function can't access 'this' and doesn't always touch line before return function in mapSubbasinsStyle
	var __globalColors = {};

	export default {
		name: 'ProjectCreate',
		components: {
			LMap, LTileLayer, LGeoJson, LControl, LPolyline, LControlLayers, LLayerGroup, 'v-polyline-decorator': Vue2LeafletPolylineDecorator
		},
		data() {
			return {
				page: {
					loading: false,
					errors: [],
					showLogin: false,
					saveErrors: [],
					saving: false
				},
				options: {
					datasets: [],
					userDatasets: [],
					subbasins: [],
					showAdvanced: false,
					selectStart: false
				},
				limits: {
					subbasinLimit: 0,
					hruLimit: 0
				},
				subbasinsSearch: '',
				project: {
					datasetID: 1,
					endID: undefined,
					startIDs: [],
					name: undefined,
					cacheID: undefined
				},
				startingSubs: [],
				map: {
					loading: false,
					bounds: undefined,
					subbasins: {
						geojson: undefined
					},
					streams: {
						geojson: undefined,
						options: {
							style: {
								weight: 1,
								opacity: 1,
								color: '#006da0',
								dashArray: '0'
							}
						}
					},
					currentCoords: undefined,
					selectedPoint: undefined,
					numSubs: 0,
					numHrus: 0,
					route: [],
					showRoute: false,
					patterns: [
						{ offset: '50%', repeat: 0, symbol: L.Symbol.arrowHead({ pixelSize: 15, polygon: false, pathOptions: { color: 'green', stroke: true } }) }
					],
					id: undefined,
					showCalibrationLayers: false,
					showCalibrationLocations: true,
					outline: {
						geojson: undefined,
						options: {
							style: {
								weight: 2,
								opacity: 0.8,
								color: '#7f0096',
								dashArray: '0',
								fillOpacity: 0
							}
						}
					},
					regions: {
						geojson: undefined,
						options: {
							style: {
								weight: 2,
								opacity: 0.8,
								color: '#5b5b5b',
								dashArray: '0',
								fillOpacity: 0
							}
						}
					}
				},
				modals: {
					route: {
						show: false
					},
					calibration: {
						show: false,
						errors: [],
						watershed: {
							id: 0,
							name: null,
							datasets: [],
							locationSites: []
						},
						selectedDataset: null
					}
				},
				selectedDataset: null,
				calibrationLayers: {
					groups: [],
					locationPoints: {
						name: null,
						geojson: { type: null, features: [] }
					}
				},
				calibrationGroup: {
					selected: null,
					geojson: { type: null, features: [] },
					loading: false,
					errors: []
				}
			}
		},
		validations: {
			project: {
				name: {
					required,
					maxLength: maxLength(100),
					hasAlphaNumeric(value) {
						return (
							/[A-Z]/.test(value) || // checks for a-z
							/[a-z]/.test(value) || // checks for a-z
							/[0-9]/.test(value) // checks for 0-9
						);
					}
				}
			}
		},
		async created() {
			await this.get();
			if (this.$refs.calLocationLayer.mapObject) this.$refs.calLocationLayer.mapObject.remove();
		},
		watch: {
			subbasinsSearch: _.debounce(function (query) { this.findSubbasins(query) }, 500)
		},
		methods: {
			async get() {
				this.page.loading = true;
				this.page.errors = [];
				this.loadMapColors();
				this.map.streams.options.style.color = this.mapColors.stream;

				try {
					const response = await this.$http.get('/datasets/user', this.getTokenHeader());
					console.log(response.data);
					this.options.datasets = response.data.datasets;
					this.options.userDatasets = response.data.userDatasets;
					this.limits = response.data.limits;

					if (this.options.datasets.length > 0 && this.options.datasets[0].options.length > 0) {
						this.map.bounds = L.latLngBounds(this.options.datasets[0].options[0].bounds);
						this.project.datasetID = this.options.datasets[0].options[0].value;
					}

					var dataset = this.getSelectedDataset();
					if (!this.isNullOrEmpty(dataset)) {
						await this.getDatasetOutline(dataset.value);
						if (dataset.loadByDefault && !this.isNullOrEmpty(dataset.endingSubbasin)) {
							await this.loadMapFromSub(dataset.endingSubbasin);
						}

						const response2 = await this.$http.get('/maps/calibrated-watersheds', this.getTokenHeader());
						console.log(response2.data);
						this.calibrationLayers = response2.data;
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
					console.log(error);
				}

				this.page.loading = false;
			},
			loginSuccess(wasSuccessful) {
				this.page.showLogin = !wasSuccessful;
			},
			async findSubbasins(query) {
				try {
					const response = await this.$http.get(`/subbasins/find/${this.project.datasetID}/${query}`, this.getTokenHeader());
					this.options.subbasins = response.data != '' && response.data != null ? response.data : [];
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async datasetChange() {
				var dataset = this.getSelectedDataset();
				await this.getDatasetOutline(dataset.value);
				if (dataset.loadByDefault && !this.isNullOrEmpty(dataset.endingSubbasin)) {
					await this.loadMapFromSub(dataset.endingSubbasin);
				} else if (this.map.selectedPoint != undefined) {
					await this.loadMap(this.map.selectedPoint[0], this.map.selectedPoint[1]);
				} else {
					this.setBounds();
				}
			},
			async getDatasetOutline(id) {
				try {
					const response = await this.$http.get(`/datasets/outlinegeojson/${id}`, this.getTokenHeader());
					this.log(response.data);
					this.map.outline.geojson = response.data.outline;
					this.map.regions.geojson = response.data.regions;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			getSelectedDataset() {
				let id = this.project.datasetID;
				let matches = [];
				for (let group of this.options.datasets) {
					matches = group.options.filter(function (el) { return el.value == id; });
					if (matches.length > 0) break;
				}
				if (matches.length == 0) {
					matches = this.options.userDatasets.filter(function (el) { return el.value == id; });
				}
				this.selectedDataset = matches[0];
				return matches[0];
				//return this.options.datasets.filter(function (el) { return el.value == id; })[0]; 
			},
			setBounds() {
				var dataset = this.getSelectedDataset();
				if (dataset) {
					this.map.bounds = L.latLngBounds(dataset.bounds);
				}
			},
			async mapClick(e) {
				if (!this.options.selectStart && this.calibrationGroup.selected === null) {
					await this.loadMap(e.latlng.lat, e.latlng.lng);
				}			
			},
			async loadMap(lat, lon) {
				this.page.errors = [];
				this.map.loading = true;

				try {
					const response = await this.$http.get(`/maps/coords/${this.project.datasetID}/${lat}/${lon}`, this.getTokenHeader());
					this.log(response.data);

					this.map.selectedPoint = [lat, lon];
					this.setMapData(response.data);
					this.startingSubs = [];

					this.page.loading = false;

				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.map.loading = false;
			},
			async loadMapFromSub(name) {
				this.page.errors = [];
				this.map.loading = true;

				if (!this.isNullOrEmpty(name)) {
					try {
						const response = await this.$http.get(`/maps/subbasin/name/${this.project.datasetID}/${name}`, this.getTokenHeader());
						//console.log(response);

						this.map.selectedPoint = undefined;
						this.setMapData(response.data);
						this.startingSubs = [];

						this.page.loading = false;

					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.map.loading = false;
			},
			getMapFromIdsDb() {
				var starting = '';
				for (var i = 0; i < this.startingSubs.length; i++) {
					starting += this.startingSubs[i].id.toString();
					if (i < this.startingSubs.length - 1)
						starting += ',';
				}
				return this.$http.get(`/maps/subbasin/id/${this.project.endID}/${starting}`, this.getTokenHeader());
			},
			async loadMapFromIds() {
				if (!this.options.selectStart) {
					this.map.loading = true;

					try {
						const response = await this.getMapFromIdsDb();

						this.map.selectedPoint = undefined;
						this.setMapData(response.data);

						this.page.loading = false;

					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.map.loading = false;
				}
			},
			setMapData(map) {
				this.map.subbasins.geojson = map.subbasinsGeoJson;
				this.map.streams.geojson = map.streamsGeoJson;
				this.setEndingSubbasin(map.downstreamSubbasin);
				this.project.endID = map.downstreamID;

				this.map.bounds = L.latLngBounds(map.bounds);

				this.map.numSubs = map.numSubbasins;
				this.map.numHrus = map.numHrus;
				this.map.route = map.connections;
				this.map.id = map.id;

				this.page.errors = [];
				if (!this.hasWatershed()) {
					this.page.errors.push('Please select a subbasin within the study area.');
					this.setBounds();
				} else {
					var dataset = this.getSelectedDataset();
					if (dataset) {
						this.project.name = dataset.text + ' - ' + map.downstreamSubbasin;
					}
				}
			},
			clearMapData() {
				this.map.subbasins.geojson = undefined;
				this.map.streams.geojson = undefined;
				this.subbasinsSearch = '';
				try { this.$refs.subbasinsTypeahead.$data.inputValue = ''; } catch (e) { this.log(e); }
				this.project.endID = undefined;
				this.project.name = undefined;
				this.project.startIDs = [];
				this.project.cacheID = undefined;

				this.map.numSubs = 0;
				this.map.numHrus = 0;
				this.map.route = [];
				this.map.showRoute = false;
				this.map.id = undefined;
			},
			mapMove(e) {
				this.map.currentCoords = e.latlng;
			},
			setEndingSubbasin(name) {
				this.subbasinsSearch = name;
				this.log(this.$refs.subbasinsTypeahead);
				try { this.$refs.subbasinsTypeahead.$data.inputValue = name; } catch (e) { this.log(e); }
			},
			hasWatershed() {
				return !this.map.subbasins.geojson ?  false : this.map.subbasins.geojson.features.length > 0;
			},
			async createProject() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					this.project.startIDs = [];
					for (var i = 0; i < this.startingSubs.length; i++) {
						this.project.startIDs.push(this.startingSubs[i].id);
					}

					this.project.cacheID = this.map.id;

					try {
						const response = await this.$http.post('projects/', this.project, this.getTokenHeader());
						this.$router.push({ name: 'ProjectView', params: { id: response.data } }).catch(err => { });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			mapSubbasinsClick(e) {
				if (this.options.selectStart && !e.target.feature.properties.isNearbyOnly) {
					var sub = e.target.feature.properties;
					var index = this.startingSubs.findIndex(x => x.id == sub.id);
					if (index === -1) {
						this.startingSubs.push({
							id: sub.id,
							name: sub.name
						});
					} else {
						this.removeStartingSub(sub.id);
					}
					__globalStartingSubs = this.startingSubs;
				}
			},
			removeStartingSub(id) {
				if (this.options.selectStart) {
					this.startingSubs = this.startingSubs.filter(function (el) { return el.id != id; });
					__globalStartingSubs = this.startingSubs;
				}
			},
			toggleColors() {
				this.setMapColors(!this.mapColors.highContrast);
				this.map.streams.options.style.color = this.mapColors.stream;
			},
			mapCalibratedWatershedsClick(e) {
				let watershed = e.target.feature.properties;
				this.log(watershed);
				this.modals.calibration.selectedDataset = null;
				this.modals.calibration.watershed = watershed;
				this.modals.calibration.show = true;
				this.modals.calibration.loading = false;
			},
			async getCalibrationGroup() {
				this.map.loading = true;
				this.calibrationGroup.errors = [];

				if (this.isNullOrEmpty(this.calibrationGroup.selected)) {
					this.calibrationGroup.geojson = { type: null, features: [] };
				} else {
					try {
						const response = await this.$http.get(`/maps/calibrated-watersheds/${this.calibrationGroup.selected}`, this.getTokenHeader());
						console.log(response.data);
						this.calibrationGroup.geojson = response.data.geojson;

						if (this.hasCalibrationLocations) {
							this.$nextTick(() => {
								this.$refs.calLocationLayer.mapObject.remove();
								this.$refs.calLocationLayer.mapObject.addTo(this.$refs.leafmap.mapObject);
							});
						}
						let bounds = bbox(this.calibrationGroup.geojson);
						this.map.bounds = L.latLngBounds([[bounds[3], bounds[2]], [bounds[1], bounds[0]]]);
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.calibrationGroup.errors = this.logError(error);
						console.log(error);
					}
				}

				this.map.loading = false;
			},
			/*toggleCalibrationLayers(checked) {
				if (checked) {
					if (this.hasCalibrationLocations) {
						this.$nextTick(() => {
							this.$refs.calLocationLayer.mapObject.remove();
							this.$refs.calLocationLayer.mapObject.addTo(this.$refs.leafmap.mapObject);
						});
					}
					let bounds = bbox(this.calibrationGroup.geojson);
					this.map.bounds = L.latLngBounds([[bounds[3], bounds[2]], [bounds[1], bounds[0]]]);
				}
			},*/
			async setProjectToCalibration() {
				this.modals.calibration.loading = true;
				this.project.datasetID = this.modals.calibration.selectedDataset.id;
				this.getSelectedDataset();
				this.subbasinsSearch = this.modals.calibration.selectedDataset.endingSubbasin;

				await this.loadMapFromSub(this.modals.calibration.selectedDataset.endingSubbasin);

				this.modals.calibration.loading = false;
				this.calibrationGroup.selected = null
				this.modals.calibration.show = false;
			},
			getLocationSitesTable(sites) {
				let text = '';
				if (sites.length > 0 && sites[0].data.length > 0) {	
					text += `<table class="table table-sm table-striped"><thead><tr>`;
					if (sites[0].isUsgsSite) text += `<th>USGS Site #</th>`;
					else text += `<th>Site #</th>`;
					text += `<th>Name</th>`;

					for (let k of Object.keys(sites[0].data[0])) {
						let name = k == 'CalibrationYears' ? 'Calibration years' : k;
						text += `<th>${name}</th>`;
					}
					text += `</tr></thead><tbody>`;

					for (let site of sites) {
						let rowSpan = site.data.length;
						text += `<tr>`;

						text += `<td rowspan="${rowSpan}">${site.number}</td>`;
						text += `<td rowspan="${rowSpan}">${site.name}</td>`;

						let i = 0;
						for (let row of site.data) {
							if (i > 0) text += `<tr>`;

							for (let k of Object.keys(row)) {
								text += `<td>${row[k]}</td>`;
							}

							text += `</tr>`;
							i++;
						}
					}

					text += `</tbody></table>`;
				}
				return text;
			}
		},
		computed: {
			polylines() {
				return this.map.route.filter(function (el) { return el.lineCoordinates[1].length > 0; })
			},
			isNumSubsValid() {
				return this.limits.subbasinLimit === 0 || this.limits.subbasinLimit >= this.map.numSubs;
			},
			isNumHrusValid() {
				return this.limits.hruLimit === 0 || this.limits.hruLimit >= this.map.numHrus;
			},
			mapSubbasinsOptions() {
				return {
					onEachFeature: this.mapSubbasinsOnEachFeature
				};
			},
			mapSubbasinsStyle() {
				__globalStartingSubs = this.startingSubs;
				__globalColors = this.mapColors;
				return (feature) => {
					var fillColor = feature.properties.isDownstream ? __globalColors.downstream : (feature.properties.isNearbyOnly ? __globalColors.nearby : __globalColors.subbasin);
					var index = __globalStartingSubs.findIndex(x => x.id == feature.properties.id);
					if (index > -1)
						fillColor = __globalColors.selected;

					return {
						fillColor: fillColor,
						weight: 1,
						opacity: 0.8,
						color: 'black',
						dashArray: '0',
						fillOpacity: feature.properties.isDownstream ? 0.9 : 0.5
					};
				};
			},
			mapSubbasinsOnEachFeature() {
				return (feature, layer) => {
					let streamName = feature.properties.name === feature.properties.streams ? '' : feature.properties.streams;
					let landArea = feature.properties.landArea;
					let landAreaStr = this.isNullOrEmpty(landArea) || landArea === 0 ? '' : `<br />${this.numberWithCommas(landArea.toFixed(2))} km<sup>2</sup> land-only area`;
					let cArea = feature.properties.cumulativeArea;
					let cAreaStr = this.isNullOrEmpty(cArea) || cArea === 0 ? '' : `<br />${this.numberWithCommas(cArea.toFixed(2))} km<sup>2</sup> cumulative area`;
					layer.bindTooltip(
						'<div>' + feature.properties.name + '</div>' +
						'<div>' + streamName + '</div>' +
						'<div class="mt-2">' + this.numberWithCommas(feature.properties.area.toFixed(2)) + 'km<sup>2</sup> area' + landAreaStr + cAreaStr + '<br />' +
						this.numberWithCommas(feature.properties.elevation.toFixed(2)) + 'm elevation</div>',
						{ permanent: false, sticky: true });

					layer.on({
						click: this.mapSubbasinsClick
					})
				}
			},
			routeCoordinates() {
				var plArray = [];
				for (var i = 0; i < this.map.route.length; i++) {
					if (this.map.route[i].lineCoordinates[1].length > 0)
						plArray.push(this.map.route[i].lineCoordinates);
				}
				return plArray;
			},
			mapCalibratedWatershedsOptions() {
				return {
					onEachFeature: this.mapCalibratedWatershedsOnEachFeature
				};
			},
			mapCalibratedWatershedsStyle() {
				__globalColors = this.mapColors;
				return (feature) => {
					let fillColor = __globalColors.subbasin;

					return {
						fillColor: '#ce29e8',
						weight: 2,
						opacity: 0.8,
						color: '#7f0096',
						dashArray: '0',
						fillOpacity: 0.5
					};
				};
			},
			mapCalibratedWatershedsOnEachFeature() {
				return (feature, layer) => {
					layer.bindTooltip(
						`<div>${feature.properties.name}</div>`,
						{ permanent: false, sticky: true });

					layer.on({
						click: this.mapCalibratedWatershedsClick
					})
				}
			},
			hasCalibrations() {
				return this.calibrationGroup.geojson.features.length > 0;
			},
			hasCalibrationLocations() {
				return this.calibrationLayers.locationPoints.geojson.features.length > 0;
			},
			mapCalibratedUsgsSitesOptions() {
				return {
					onEachFeature: this.mapCalibratedUsgsSitesOnEachFeature,
					pointToLayer: this.mapCalibratedUsgsSitesPointToLayer
				};
			},
			mapCalibrationLocationOptions() {
				return {
					onEachFeature: this.mapCalibrationLocationOnEachFeature,
					pointToLayer: this.mapCalibrationLocationPointToLayer
				};
			},
			mapCalibrationLocationPointToLayer() {
				return (feature, latlng) => {
					let pointColor = '#f7ff1e';
					let name = feature.properties.calibrations[0].name;
					if (name.includes("HAWQS v1.2")) pointColor = '#a4f24b';
					if (name.includes("WOTUS")) pointColor = '#ff194f';

					return L.circleMarker(latlng, {
						radius: 5,
						fillColor: pointColor,
						color: '#000',
						weight: 1,
						opacity: 1,
						fillOpacity: 0.9
					});
				}
			},
			mapCalibrationLocationOnEachFeature() {
				return (feature, layer) => {
					let popText = '<div><b>Calibrated Sites:</b></div>';

					for (let cal of feature.properties.calibrations) {
						if (cal.isUsgsSite) {
							popText += `<div>USGS Site: ${cal.number}</div>`;
						}

						popText += `<div>${cal.name}</div><div class="mt-2"><table class="table table-sm">`;

						let i = 0;
						for (let d of cal.data) {
							let calKeys = Object.keys(d);
							if (i === 0) {
								popText += `<tr>`;
								for (let k of calKeys) {
									let name = k == 'CalibrationYears' ? 'Calibration years' : k;
									popText += `<th>${name}</th>`;
								}
								popText += `</tr>`;
							}

							popText += `<tr>`;
							for (let k of calKeys) {
								popText += `<td>${d[k]}</td>`;
							}
							popText += `</tr>`;
							i++;
						}

						popText += `</table></div>`;
					}

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			},
			mapRegionOptions() {
				return {
					onEachFeature: this.mapRegionOnEachFeature,
					style: this.map.regions.options.style
				};
			},
			mapRegionOnEachFeature() {
				return (feature, layer) => {
					let popText = `<div><b>Region ${feature.properties.region}</b></div>`;

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true });
				};
			}
		}
	}
</script>

<style scoped>
	.map-container {
		position: relative;
	}

	.map-loading-overlay {
		z-index: 1000;
		position: absolute;
		top: 45%;
		left: 0;
		text-align: center;
		width: 100%;
	}
</style>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Flood Frequency Analysis</h2>

			<div v-if="$parent.scenario.status.isRunning">
				<page-running />
			</div>
			<div v-else-if="!siteSettings.includeFfa">
				<b-alert variant="warning" show>This feature is not enabled. Please contact an administrator if you are expecting it to be available.</b-alert>
			</div>
			<div v-else-if="data.noOutputProcessing">
				<b-alert variant="warning" show>Output processing was skipped for this scenario run. Please re-run your scenario without the "skip output processing" box checked to use this feature.</b-alert>
			</div>
			<div v-else-if="data.numYears <= 3">
				<b-alert variant="warning" show>Your simulation must be run for more than 3 years to use this feature (simulation date minus skip years). At least 10 years is recommended.</b-alert>
			</div>
			<div v-else>
				<b-alert variant="info" :show="data.printSetting !== 'daily'">
					The flood frequency analysis feature requires that your scenario is run with <strong>daily</strong> output selected.
					Your scenario was run with <strong>{{data.printSetting}}</strong> output. Please edit your scenario settings and re-run
					the scenario to use this feature.
				</b-alert>
				<div v-if="data.printSetting === 'daily'">
					<success-alert ref="savedAlert" text="Your request has been submitted. Please wait while we create your files." />

					<p>
						The flood frequency analysis takes your output reach data and calculates return periods using annual maximum flow at 2-year, 10-year, 25-year, 50-year, 100-year and 200-year recurrence intervals.
						The return periods and the frequency distribution plot can be used for flood frequency studies under the <a href="https://www.usgs.gov/publications/guidelines-determining-flood-flow-frequency-bulletin-17c" target="_blank">USGS Bulletin 17c guidelines</a>.
						The number of years of analysis can have a significant impact on the results. It is recommended to have the longest available period of simulation for flood frequency analysis.
						A minimum of three complete water years (October 1 of one year and September 30th of next) is required for the analysis.
						To avoid substantial bias, it is suggested to have at least 10 years of simulation data.
					</p>

					<div class="my-4" v-if="data.requests.length > 0">
						<b-table responsive striped small class="nowrap-headers border-bottom mb-1" :items="data.requests" :fields="table.fields">
							<template v-slot:cell(status)="data">
								<font-awesome-icon v-if="data.item.status == 'running'" :icon="['fas', 'spinner']" spin />
								<font-awesome-icon v-else-if="data.item.status == 'error'" :icon="['fas', 'exclamation-triangle']" class="text-danger" />
								<span v-else></span>
							</template>
							<template v-slot:cell(lastModifiedDate)="data">
								{{data.item.lastModifiedDate | date}}
							</template>
							<template v-slot:cell(downloadLink)="data">
								<a v-if="data.item.status == 'complete'" :href="data.item.downloadLink">Download</a>
								<em v-else>{{data.item.status == 'error' ? `Error: ${data.item.statusMessage}` : data.item.statusMessage}}</em>
							</template>
							<template v-slot:cell(subbasins)="data">
								{{writeArray(data.item.subbasins)}}
							</template>
							<template v-slot:cell(delete)="data">
								<button class="plain text-danger" v-b-tooltip.hover.right="'Delete'" @click="askDelete(data.item.id)" v-if="data.item.status !== 'running'">
									<font-awesome-icon :icon="['fas', 'times']" />
								</button>
								<span v-else></span>
							</template>
						</b-table>
						<div v-if="anyRunning"><small><a href="#" @click.prevent="get" class="text-secondary"><font-awesome-icon :icon="['fas', 'redo']" class="mr-1" /> Refresh progress</a></small></div>
					</div>

					<h3>Request flood frequency analysis files</h3>

					<b-form @submit.prevent="save">
						<error-list :errors="page.saveErrors"></error-list>

						<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange" subbasin-only drop-down-label="Request for"></hru-selector>

						<fixed-action-bar :cols="10" :offset="2" :cols-lg="10" :offset-lg="2">
							<save-button :saving="page.saving" :disabled="!validHruSelection" text="Request Files" class="mr-2" />
							<back-button class="btn btn-secondary mr-2" />
						</fixed-action-bar>
					</b-form>

					<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
						<error-list :errors="page.delete.errors"></error-list>

						<p>
							Are you sure you want to delete this request?
							This action is permanent and cannot be undone.
						</p>

						<div slot="modal-footer">
							<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
							<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
						</div>
					</b-modal>
				</div>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import HruSelector from '@/components/HruSelector';

	export default {
		name: 'OutputFfa',
		components: {
			HruSelector
		},
		data() {
			return {
				scenarioID: this.$route.params.scenarioID,
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						id: null,
						errors: [],
						saving: false
					},
					showMonthlyAlgae: false
				},
				data: {
					printSetting: null,
					numYears: 0,
					noOutputProcessing: false,
					requests: []
				},
				form: {
					applyToAll: true,
					subbasins: []
				},
				options: {
					outputTypes: [
						{ value: 'RCH', text: 'Reach output' },
						{ value: 'SUB', text: 'Subbasin output' }
					]
				},
				table: {
					fields: [
						{ key: 'status', label: '', class: 'min' },
						{ key: 'downloadLink', label: 'Request', sortable: true },
						{ key: 'subbasins', sortable: true },
						{ key: 'lastModifiedDate', label: 'Date', sortable: true },
						{ key: 'delete', label: '', sortable: false, class: 'min' }
					],
					filter: null
				}
			}
		},
		async created() {
			await this.get();
			this.$statusHub.customOutputRequestOpened(this.$route.params.scenarioID, 'FFA');
			this.$statusHub.$on('custom-output-request-status-changed', this.onStatusChanged);
		},
		beforeDestroy() {
			this.$statusHub.customOutputRequestClosed(this.$route.params.scenarioID, 'FFA');
			this.$statusHub.$off('custom-output-request-status-changed', this.onStatusChanged);
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				await this.getData();

				this.page.loading = false;
			},
			async getData() {
				try {
					const response = await this.$http.get(`scenarios/output/ffa/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`scenarios/output/ffa/${this.scenarioID}`, this.form, this.getTokenHeader());
					this.log(response.data);
					await this.get();
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			askDelete(id) {
				this.page.delete.id = id;
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`scenarios/output/deletecustomoutputrequest/${this.page.delete.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			hruSelectionChange(selection) {
				this.form.applyToAll = selection.applyToAll;
				this.form.subbasins = selection.subbasins;
			},
			async onStatusChanged(data) {
				await this.getData();
			},
			writeArray(items, limit = 5) {
				if (items === null || items.length === 0) return 'All';
				if (limit === 0) limit = items.length;
				var s = items.slice(0, limit).join(', ');

				if (items.length > limit) return s + ' (...)';
				return s;
			}
		},
		watch: {
			'$route': 'get'
		},
		computed: {
			validHruSelection() {
				return this.form.applyToAll ||
					(this.form.subbasins.length > 0);
			},
			anyRunning() {
				let matches = this.data.requests.filter(function (el) { return el.status === 'running'; });
				return matches.length > 0;
			}
		}
	}
</script>

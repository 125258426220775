<template>
	<div>
		<page-authorization-container :page="page">
			<div v-if="!hideApplySelector">
				<b-form-select v-model="selection.applyToAll" @change="$emit('change', selection)">
					<b-form-select-option :value="false">{{dropDownLabel}} selected {{subbasinAndMonths ? 'subbasins and months' : (subbasinOnly ? 'subbasins' : 'HRUs')}}</b-form-select-option>
					<b-form-select-option :value="true">{{dropDownLabel}} all {{subbasinAndMonths ? 'subbasins and months' : (subbasinOnly  ? 'subbasins' : 'HRUs')}}</b-form-select-option>
				</b-form-select>
			</div>
			<div v-if="!selection.applyToAll">
				<b-row class="my-3">
					<b-col md="8">
						<project-map :project-map="projectMap" height="307px"
									 highlight-selected select-multiple
									 :selected-subbasins="selection.subbasins"
									 @subbasin-click="mapClick"></project-map>
					</b-col>
					<b-col md>
						<div class="checklist-header">
							<b-form-checkbox v-model="all.subbasins" @change="toggleAllSubbasins">
								Select subbasins
								<span v-b-tooltip.hover.bottom title="Click a subbasin on the map, or from the list below" class="ml-1">
									<font-awesome-icon icon="info-circle" class="text-info" />
								</span>
							</b-form-checkbox>
						</div>
						<div class="checklist">
							<b-form-checkbox-group v-model="selection.subbasins" stacked>
								<div class="item" v-for="(c, i) in projectMap.connections" :key="i">
									<b-form-checkbox :value="{ id: c.fromID, name: c.fromName }">{{c.fromName}}</b-form-checkbox>
								</div>
							</b-form-checkbox-group>
						</div>
					</b-col>
				</b-row>

				<error-list v-if="loading.errors.length > 0" :errors="loading.errors"></error-list>
				<b-row v-else>
					<b-col md v-if="subbasinAndMonths">
						<div v-if="selection.subbasins.length > 0">
							<div class="checklist-header">
								<b-form-checkbox v-model="all.months" @change="toggleAllMonths">
									Select Months
								</b-form-checkbox>
							</div>
							<div class="checklist">
								<b-form-checkbox-group v-model="selection.months" stacked>
									<div class="item" v-for="(o, i) in options.months" :key="i">
										<b-form-checkbox :value="o.value">{{o.text}}</b-form-checkbox>
									</div>
								</b-form-checkbox-group>
							</div>
						</div>
					</b-col>

					<b-col md>
						<page-loading :loading="loading.landuse"></page-loading>
						<div v-if="!loading.landuse && options.landuse.length > 0 ">
							<div class="checklist-header">
								<b-form-checkbox v-model="all.landuse" @change="toggleAllLanduse">
									Select land use
								</b-form-checkbox>
							</div>
							<div class="checklist">
								<b-form-checkbox-group v-model="selection.landuse" stacked>
									<div class="item" v-for="(o, i) in options.landuse" :key="i">
										<b-form-checkbox :value="o.name">{{o.name}} - {{o.description}}</b-form-checkbox>
									</div>
								</b-form-checkbox-group>
							</div>
						</div>
					</b-col>
					<b-col md>
						<page-loading :loading="loading.soils"></page-loading>
						<div v-if="!loading.soils && options.soils.length > 0 && options.landuse.length > 0">
							<div class="checklist-header">
								<b-form-checkbox v-model="all.soils" @change="toggleAllSoils">
									Select soils
								</b-form-checkbox>
							</div>
							<div class="checklist">
								<b-form-checkbox-group v-model="selection.soils" stacked>
									<div class="item" v-for="(o, i) in options.soils" :key="i">
										<b-form-checkbox :value="o">{{o}}</b-form-checkbox>
									</div>
								</b-form-checkbox-group>
							</div>
						</div>
					</b-col>
					<b-col md>
						<page-loading :loading="loading.slopes"></page-loading>
						<div v-if="!loading.slopes && options.slopes.length > 0 && options.soils.length > 0 && options.landuse.length > 0">
							<div class="checklist-header">
								<b-form-checkbox v-model="all.slopes" @change="toggleAllSlopes">
									Select slopes
								</b-form-checkbox>
							</div>
							<div class="checklist">
								<b-form-checkbox-group v-model="selection.slopes" stacked>
									<div class="item" v-for="(o, i) in options.slopes" :key="i">
										<b-form-checkbox :value="o">{{o}}</b-form-checkbox>
									</div>
								</b-form-checkbox-group>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import ProjectMap from '@/components/ProjectMap';

	export default {
		name: 'HruSelector',
		components: {
			ProjectMap
		},
		props: {
			projectID: {
				type: Number,
				required: true
			},
			subbasinOnly: {
				type: Boolean,
				default: false
			},
			subbasinAndMonths: {
				type: Boolean,
				default: false
			},
			initialSelection: {
				type: Object,
				default: () => {
					return {
						subbasins: [],
						landuse: [],
						soils: [],
						slopes: [],
						months: [],
					}
				}
			},
			hideApplySelector: {
				type: Boolean,
				default: false
			},
			dropDownLabel: {
				type: String,
				default: 'Apply to'
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				projectMap: {},
				options: {
					landuse: [],
					soils: [],
					slopes: [],
					months: [
						{ value: 1, text: 'January' },
						{ value: 2, text: 'February' },
						{ value: 3, text: 'March' },
						{ value: 4, text: 'April' },
						{ value: 5, text: 'May' },
						{ value: 6, text: 'June' },
						{ value: 7, text: 'July' },
						{ value: 8, text: 'August' },
						{ value: 9, text: 'September' },
						{ value: 10, text: 'October' },
						{ value: 11, text: 'November' },
						{ value: 12, text: 'December' }
					]
				},
				selection: {
					applyToAll: false,
					subbasins: [],
					landuse: [],
					soils: [],
					slopes: [],
					months: []
				},
				all: {
					subbasins: false,
					landuse: false,
					soils: false,
					slopes: false,
					months: false
				},
				loading: {
					errors: [],
					landuse: false,
					soils: false,
					slopes: false,
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`projects/map/${this.projectID}`, this.getTokenHeader());
					this.projectMap = response.data;

					if (this.hideApplySelector) this.selection.applyToAll = false;

					await this.initializeSelection(this.initialSelection);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getLanduse() {
				this.loading.errors = [];
				this.loading.landuse = true;

				try {
					const response = await this.$http.post(`hruselection/landuse/${this.projectID}`, this.selection.subbasins, this.getTokenHeader());
					this.options.landuse = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.loading.errors = this.logError(error);
				}

				this.loading.landuse = false;
			},
			async getSoils() {
				this.loading.errors = [];
				this.loading.soils = true;

				try {
					const response = await this.$http.post(`hruselection/soils/${this.projectID}`, this.selection, this.getTokenHeader());
					this.options.soils = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.loading.errors = this.logError(error);
				}

				this.loading.soils = false;
			},
			async getSlopes() {
				this.loading.errors = [];
				this.loading.slopes = true;

				try {
					const response = await this.$http.post(`hruselection/slopes/${this.projectID}`, this.selection, this.getTokenHeader());
					this.options.slopes = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.loading.errors = this.logError(error);
				}

				this.loading.slopes = false;
			},
			async mapClick(selectedSubbasins) {
				this.selection.subbasins = selectedSubbasins;
			},
			async toggleAllSubbasins(checked) {
				this.selection.subbasins = checked ? this.projectMap.connections.map(function (i) { return { id: i.fromID, name: i.fromName } }) : [];
			},
			async toggleAllLanduse(checked) {
				this.selection.landuse = checked ? this.options.landuse.map(function (i) { return i.name }) : [];
			},
			async toggleAllSoils(checked) {
				this.selection.soils = checked ? this.options.soils.slice() : [];
			},
			async toggleAllSlopes(checked) {
				this.selection.slopes = checked ? this.options.slopes.slice() : [];
			},
			async toggleAllMonths(checked) {
				this.selection.months = checked ? this.options.months.map(function (i) { return i.value }) : [];
			},
			async initializeSelection(newVal) {
				this.selection.subbasins = newVal.subbasins;
				await this.getLanduse();
				this.selection.landuse = newVal.landuse;
				await this.getSoils();
				this.selection.soils = newVal.soils;
				await this.getSlopes();
				this.selection.slopes = newVal.slopes;
			}
		},
		watch: {
			async selectedSubbasins() {
				if (!this.subbasinOnly && !this.subbasinAndMonths)
					await this.getLanduse();
				this.$emit('change', this.selection);
			},
			async selectedLanduse() {
				await this.getSoils();
				this.$emit('change', this.selection);
			},
			async selectedSoils() {
				await this.getSlopes();
				this.$emit('change', this.selection);
			},
			selectedSlopes() {
				this.$emit('change', this.selection);
			},
			selectedMonths() {
				this.$emit('change', this.selection);
			},
			async initialSelection(newVal, oldVal) {
				await this.initializeSelection(newVal);
			}
		},
		computed: {
			selectedSubbasins() {
				return this.selection.subbasins;
			},
			selectedLanduse() {
				return this.selection.landuse;
			},
			selectedSoils() {
				return this.selection.soils;
			},
			selectedSlopes() {
				return this.selection.slopes;
			},
			selectedMonths() {
				return this.selection.months;
			},
		}
	}
</script>

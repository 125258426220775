<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Operations management</h2>

			<p>
				Read the <a href="/content/docs/io/ch20_input_mgt.pdf" target="_blank">SWAT2012 IO documentation chapter on MGT inputs</a> for more information about management variables.
			</p>

			<div v-if="!page.showHruSelector" class="mb-4">
				<p class="mb-2">
					<strong>Selected HRU:</strong> {{initialHruName}}
				</p>
				<b-button @click="page.showHruSelector = !page.showHruSelector">Select another HRU</b-button>
			</div>
			<b-collapse v-model="page.showHruSelector" class="mb-3">
				<p>
					<strong>Select an HRU to view management data and make changes. Changes may be extended to other HRUs in your project.</strong>
				</p>

				<single-hru-selector :projectID="Number($route.params.id)" @change="initialHruChange">
					<p v-if="validInitialHru">
						<save-button type="button" :saving="page.loadingData" @click.native="getMgt" text="Get data" class="mr-2" />
						<b-button @click="page.showHruSelector = !page.showHruSelector" v-if="page.showData" variant="secondary">Hide form</b-button>
					</p>
				</single-hru-selector>
			</b-collapse>

			<error-list :errors="page.loadingErrors"></error-list>

			<b-form @submit.prevent="save">
				<div v-if="page.showData">
					<error-list :errors="page.saveErrors"></error-list>
					<success-alert ref="savedAlert" text="Changes saved." />

					<h3 class="mb-4">Scheduling</h3>
					<p>
						Operations may be scheduled by date or heat units. Heat unit scheduling is explained in
						<a href="/content/docs/theory/Land-Cover-and-Plant.pdf" target="_blank">Chapter 5:1 of the theoretical documentation</a>.
						Use the buttons below to toggle scheduling for all operations in this HRU.
					</p>

					<b-form-group>
						<b-form-radio-group buttons button-variant="outline-primary"
											v-model="options.scheduleByHeatUnits"
											:options="options.scheduleOptions"></b-form-radio-group>
					</b-form-group>

					<h3 class="mb-4">Operations</h3>

					<b-alert variant="info" :show="form.item.length < 1">
						This HRU does not have any operations.
					</b-alert>
					<div class="table-responsive mb-4" v-if="form.item.length > 0">
						<table class="table table-striped table-bordered table-valign-m table-form">
							<thead>
								<tr class="thead">
									<th class="text-center">Edit</th>
									<th>Year</th>
									<th v-if="options.scheduleByHeatUnits">Op. #</th>
									<th v-if="options.scheduleByHeatUnits">Heat Units</th>
									<th v-if="!options.scheduleByHeatUnits">Month</th>
									<th v-if="!options.scheduleByHeatUnits">Day</th>
									<th>Operation</th>
									<th>Crop</th>
									<th class="text-center">Remove</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(m, i) in orderedItems" :key="i">
									<td class="text-center">
										<button type="button" class="plain text-primary" v-b-tooltip.hover.right="'Modify this operation'" @click="showOp(m)">
											<font-awesome-icon :icon="['fas', 'edit']" />
										</button>
									</td>
									<td>
										<span v-if="options.isSorting">
											<b-form-input v-model.trim="$v.form.item.$each[i].YEAR.$model" class="number" type="number" :state="getValidState($v.form.item.$each[i].YEAR)"></b-form-input>
										</span>
										<span v-if="!options.isSorting">
											{{ m.YEAR }}
										</span>
									</td>
									<td v-if="options.scheduleByHeatUnits">
										<span v-if="options.isSorting">
											<b-form-input v-model.trim="$v.form.item.$each[i].OP_NUM.$model" class="number" type="number" :state="getValidState($v.form.item.$each[i].OP_NUM)"></b-form-input>
										</span>
										<span v-if="!options.isSorting">
											{{ m.OP_NUM }}
										</span>
									</td>
									<td v-if="options.scheduleByHeatUnits">
										<span v-if="options.isSorting">
											<b-form-input v-model.trim="$v.form.item.$each[i].HUSC.$model" class="number" type="number" step="any" :state="getValidState($v.form.item.$each[i].HUSC)"></b-form-input>
										</span>
										<span v-if="!options.isSorting">
											{{ m.HUSC }}
										</span>
									</td>
									<td v-if="!options.scheduleByHeatUnits">
										<span v-if="options.isSorting">
											<b-form-input v-model.trim="$v.form.item.$each[i].MONTH.$model" class="number" type="number" :state="getValidState($v.form.item.$each[i].MONTH)" min="1" max="12"></b-form-input>
										</span>
										<span v-if="!options.isSorting">
											{{ m.MONTH }}
										</span>
									</td>
									<td v-if="!options.scheduleByHeatUnits">
										<span v-if="options.isSorting">
											<b-form-input v-model.trim="$v.form.item.$each[i].DAY.$model" class="number" type="number" :state="getValidState($v.form.item.$each[i].DAY)" min="1" max="31"></b-form-input>
										</span>
										<span v-if="!options.isSorting">
											{{ m.DAY }}
										</span>
									</td>
									<td>{{getTextFromList(form.opList, m.MGT_OP)}}</td>
									<td>{{getTextFromList(form.plantList, m.PLANT_ID)}}</td>
									<td class="text-center">
										<button type="button" class="plain text-danger" v-b-tooltip.hover.right="'Delete'" @click="askDeleteOp(m)">
											<font-awesome-icon :icon="['fas', 'times']" />
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<b-modal v-model="page.op.view.show" size="xl" title="Edit operation" scrollable no-close-on-backdrop no-close-on-esc hide-header-close>
						<b-row>
							<b-col md="4">
								<b-form-group label="Year of rotation">
									<b-form-input v-model.trim="$v.editItem.YEAR.$model" class="number" type="number" :state="getValidState($v.editItem.YEAR)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md>
								<b-row v-if="options.scheduleByHeatUnits">
									<b-col md>
										<b-form-group label="Operation number">
											<b-form-input v-model.trim="$v.editItem.OP_NUM.$model" class="number" type="number" :state="getValidState($v.editItem.OP_NUM)"></b-form-input>
										</b-form-group>
									</b-col>
									<b-col md>
										<b-form-group label="Heat unit scheduling">
											<b-form-input v-model.trim="$v.editItem.HUSC.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.HUSC)"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row v-else>
									<b-col md>
										<b-form-group label="Month">
											<b-form-select v-model.trim="$v.editItem.MONTH.$model" :options="options.monthsList" :state="getValidState($v.editItem.MONTH)"></b-form-select>
										</b-form-group>
									</b-col>
									<b-col md>
										<b-form-group label="Day">
											<b-form-input v-model.trim="$v.editItem.DAY.$model" class="number" type="number" min="1" max="31" :state="getValidState($v.editItem.DAY)"></b-form-input>
										</b-form-group>
									</b-col>
								</b-row>
							</b-col>
						</b-row>

						<b-form-group label="Operation">
							<b-form-select v-model.trim="$v.editItem.MGT_OP.$model" :options="form.opList" :state="getValidState($v.editItem.MGT_OP)"></b-form-select>
						</b-form-group>

						<b-row v-if="editItem.MGT_OP === 1">
							<b-col cols="12">
								<b-form-group label="Crop">
									<b-form-select v-model.trim="$v.editItem.PLANT_ID.$model" :options="form.plantList" :state="getValidState($v.editItem.PLANT_ID)">
										<template #first>
											<b-form-select-option :value="0">-- Select crop --</b-form-select-option>
										</template>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="CURYR_MAT: Current age of trees (years)">
									<b-form-input v-model.trim="$v.editItem.CURYR_MAT.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.CURYR_MAT)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="HEATUNITS: Total heat units for cover/plant to reach maturity">
									<b-form-input v-model.trim="$v.editItem.HEATUNITS.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.HEATUNITS)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="LAI_INIT: Initial leaf area index">
									<b-form-input v-model.trim="$v.editItem.LAI_INIT.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.LAI_INIT)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="BIO_INIT: Initial dry weight biomass (kg/ha)">
									<b-form-input v-model.trim="$v.editItem.BIO_INIT.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.BIO_INIT)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="HI_TARG: Harvest index target ((kg/ha)/(kg/ha))">
									<b-form-input v-model.trim="$v.editItem.HI_TARG.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.HI_TARG)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="BIO_TARG: Biomass (dry weight) target (metric tons/ha)">
									<b-form-input v-model.trim="$v.editItem.BIO_TARG.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.BIO_TARG)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="CNOP: SCS runoff curve number for moisture condition II">
									<b-form-input v-model.trim="$v.editItem.CNOP.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.CNOP)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 2">
							<b-col lg="6">
								<b-form-group label="IRR_AMT: Depth of irrigation water applied on HRU (mm)">
									<b-form-input v-model.trim="$v.editItem.IRR_AMT.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.IRR_AMT)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="IRR_SALT: Concentration of salt in irrigation (mg/kg)">
									<b-form-input v-model.trim="$v.editItem.IRR_SALT.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.IRR_SALT)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="IRR_EFM: Irrigation efficiency (0-1)">
									<b-form-input v-model.trim="$v.editItem.IRR_EFM.$model" class="number" type="number" step="any" min="0" max="1" :state="getValidState($v.editItem.IRR_EFM)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="IRR_SQ: Surface runoff ratio (0-1)">
									<b-form-input v-model.trim="$v.editItem.IRR_SQ.$model" class="number" type="number" step="any" min="0" max="1" :state="getValidState($v.editItem.IRR_SQ)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="IRR_SC: Irrigation source code">
									<b-form-select v-model.trim="$v.editItem.IRR_SC.$model" :options="form.irrscList" :state="getValidState($v.editItem.IRR_SC)"></b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<p>IRR_NO: Irrigation source location</p>
								<single-hru-selector :projectID="Number($route.params.id)" @change="setIRRNO" subbasin-only :initSubID="getSubbasinId(editItem.IRR_NO)"></single-hru-selector>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 3">
							<b-col cols="12">
								<b-form-group label="Fertilizer">
									<b-form-select v-model.trim="$v.editItem.FERT_ID.$model" :options="form.fertList" :state="getValidState($v.editItem.FERT_ID)">
										<template v-slot:first>
											<option :value="0">-- Select fertilizer --</option>
										</template>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="FRT_KG: Amount of fertilizer applied to HRU (kg/ha)">
									<b-form-input v-model.trim="$v.editItem.FRT_KG.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.FRT_KG)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="FRT_SURFACE: Fraction of fertilizer applied to top 10mm of soil">
									<b-form-input v-model.trim="$v.editItem.FRT_SURFACE.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.FRT_SURFACE)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 4">
							<b-col cols="12">
								<b-form-group label="Pesticide">
									<b-form-select v-model.trim="$v.editItem.PEST_ID.$model" :options="form.pestList" :state="getValidState($v.editItem.PEST_ID)">
										<template v-slot:first>
											<option :value="0">-- Select pesticide --</option>
										</template>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="PST_KG: Amount of pesticide applied to HRU (kg/ha)">
									<b-form-input v-model.trim="$v.editItem.PST_KG.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.PST_KG)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="PST_DEP: Depth of pesticide incorporation in the soil (mm)">
									<b-form-input v-model.trim="$v.editItem.PST_DEP.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.PST_DEP)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 5">
							<b-col lg="6">
								<b-form-group label="CNOP: SCS runoff curve number for moisture condition II">
									<b-form-input v-model.trim="$v.editItem.CNOP.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.CNOP)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="HI_OVR: Harvest index override ((kg/ha)/(kg/ha))">
									<b-form-input v-model.trim="$v.editItem.HI_OVR.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.HI_OVR)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 6">
							<b-col lg="6">
								<b-form-group label="Tillage implement code">
									<b-form-select v-model.trim="$v.editItem.TILLAGE_ID.$model" :options="form.tillList" :state="getValidState($v.editItem.TILLAGE_ID)">
										<template v-slot:first>
											<option :value="0">-- Select tillage --</option>
										</template>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="CNOP: SCS runoff curve number for moisture condition II">
									<b-form-input v-model.trim="$v.editItem.CNOP.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.CNOP)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 7">
							<b-col cols="12">
								<b-form-group label="IHV_GBM: Grain or biomass harvest code">
									<b-form-select v-model.trim="$v.editItem.IHV_GBM.$model" :options="form.ihvGbmList" :state="getValidState($v.editItem.IHV_GBM)"></b-form-select>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="HARVEFF: Harvest efficiency">
									<b-form-input v-model.trim="$v.editItem.HARVEFF.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.HARVEFF)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="HI_OVR: Harvest index override ((kg/ha)/(kg/ha))">
									<b-form-input v-model.trim="$v.editItem.HI_OVR.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.HI_OVR)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 9">
							<b-col cols="12">
								<b-form-group label="Manure">
									<b-form-select v-model.trim="$v.editItem.MANURE_ID.$model" :options="form.manureList" :state="getValidState($v.editItem.MANURE_ID)">
										<template v-slot:first>
											<option :value="0">-- Select manure --</option>
										</template>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="GRZ_DAYS: Number of consecutive days grazing takes place in the HRU">
									<b-form-input v-model.trim="$v.editItem.GRZ_DAYS.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.GRZ_DAYS)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="BIO_EAT: Dry weight of biomass consumed daily ((kg/ha)/day)">
									<b-form-input v-model.trim="$v.editItem.BIO_EAT.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.BIO_EAT)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="BIO_TRMP: Dry weight of biomass trampled daily ((kg/ha)/day)">
									<b-form-input v-model.trim="$v.editItem.BIO_TRMP.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.BIO_TRMP)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="MANURE_KG: Dry weight of manure deposited daily ((kg/ha)/day)">
									<b-form-input v-model.trim="$v.editItem.MANURE_KG.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.MANURE_KG)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 10">
							<b-col cols="12">
								<b-form-group label="WSTRS_ID: Water stress identifier">
									<b-form-select v-model.trim="$v.editItem.WSTRS_ID.$model" :options="form.wstrsList" :state="getValidState($v.editItem.WSTRS_ID)">
										<template v-slot:first>
											<option :value="0">-- Select identifier --</option>
										</template>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="IRR_SCA: Auto irrigation source code">
									<b-form-select v-model.trim="$v.editItem.IRR_SCA.$model" :options="form.irrscList" :state="getValidState($v.editItem.IRR_SCA)"></b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<p>IRR_NOA: Auto irrigation source location</p>
								<single-hru-selector :projectID="Number($route.params.id)" @change="setIRR_NOA" subbasin-only :initSubID="getSubbasinId(editItem.IRR_NOA)"></single-hru-selector>
							</b-col>
							<b-col cols="12">
								<b-form-group label="AUTO_WSTRS: Water stress threshold that triggers irrigation">
									<b-form-input v-model.trim="$v.editItem.AUTO_WSTRS.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.AUTO_WSTRS)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="IRR_EFF: Irrigation efficiency">
									<b-form-input v-model.trim="$v.editItem.IRR_EFF.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.IRR_EFF)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="IRR_MX: Amount of irrigation water applied each time auto irrigation is triggered (mm)">
									<b-form-input v-model.trim="$v.editItem.IRR_MX.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.IRR_MX)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="IRR_ASQ: Surface runoff ratio (0-1)">
									<b-form-input v-model.trim="$v.editItem.IRR_ASQ.$model" class="number" type="number" step="any" min="0" max="1" :state="getValidState($v.editItem.IRR_ASQ)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 11">
							<b-col cols="12">
								<b-form-group label="AFERT_ID: Fertilizer">
									<b-form-select v-model.trim="$v.editItem.AFERT_ID.$model" :options="form.fertList" :state="getValidState($v.editItem.AFERT_ID)">
										<template v-slot:first>
											<option :value="0">-- Select fertilizer --</option>
										</template>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="AUTO_NSTRS: Nitrogen stress factor of cover/plant that triggers fertilization (0-1)">
									<b-form-input v-model.trim="$v.editItem.AUTO_NSTRS.$model" class="number" type="number" step="any" min="0" max="1" :state="getValidState($v.editItem.AUTO_NSTRS)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="AUTO_NAPP: Maximum amount of mineral N allowed in any one application (kg N/ha)">
									<b-form-input v-model.trim="$v.editItem.AUTO_NAPP.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.AUTO_NAPP)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="AUTO_NYR: Maximum amount of mineral N allowed to be applied in any one year (kg N/ha)">
									<b-form-input v-model.trim="$v.editItem.AUTO_NYR.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.AUTO_NYR)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="AUTO_EFF: Application efficiency">
									<b-form-input v-model.trim="$v.editItem.AUTO_EFF.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.AUTO_EFF)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="AFRT_SURFACE: Fraction of fertilizer applied to top 10mm of soil">
									<b-form-input v-model.trim="$v.editItem.AFRT_SURFACE.$model" class="number" type="number" step="any" min="0" max="1" :state="getValidState($v.editItem.AFRT_SURFACE)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 12">
							<b-col lg="6">
								<b-form-group label="SWEEPEFF: Removal efficiency of sweeping operation">
									<b-form-input v-model.trim="$v.editItem.SWEEPEFF.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.SWEEPEFF)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col lg="6">
								<b-form-group label="FR_CURB: Fraction of curb length available for sweeping">
									<b-form-input v-model.trim="$v.editItem.FR_CURB.$model" class="number" type="number" step="any" min="0" max="1" :state="getValidState($v.editItem.FR_CURB)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 13">
							<b-col cols="12">
								<b-form-group label="IMP_TRIG: Release/impound action code">
									<b-form-select v-model.trim="$v.editItem.IMP_TRIG.$model" :options="form.impTrigList" :state="getValidState($v.editItem.IMP_TRIG)"></b-form-select>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 14">
							<b-col cols="12">
								<b-form-group label="CFRT_ID: Fertilizer">
									<b-form-select v-model.trim="$v.editItem.CFRT_ID.$model" :options="form.fertList" :state="getValidState($v.editItem.CFRT_ID)">
										<template v-slot:first>
											<option :value="0">-- Select fertilizer --</option>
										</template>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="FERT_DAYS: Duration or length of period (days) the continuous fertilizer operation takes place in the HRU">
									<b-form-input v-model.trim="$v.editItem.FERT_DAYS.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.FERT_DAYS)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="IFRT_FREQ: Application frequency (days)">
									<b-form-input v-model.trim="$v.editItem.IFRT_FREQ.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.IFRT_FREQ)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="CFRT_KG: Amount of fertilizer/manure applied to ground in each application (kg/ha)">
									<b-form-input v-model.trim="$v.editItem.CFRT_KG.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.CFRT_KG)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 15">
							<b-col cols="12">
								<b-form-group label="CPST_ID: Pesticide">
									<b-form-select v-model.trim="$v.editItem.CPST_ID.$model" :options="form.pestList" :state="getValidState($v.editItem.CPST_ID)">
										<template v-slot:first>
											<option :value="0">-- Select pesticide --</option>
										</template>
									</b-form-select>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="PEST_DAYS: Number of days continuous pesticide will be simulated">
									<b-form-input v-model.trim="$v.editItem.PEST_DAYS.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.PEST_DAYS)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="IPEST_FREQ: Number of days between applications">
									<b-form-input v-model.trim="$v.editItem.IPEST_FREQ.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.IPEST_FREQ)"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<b-form-group label="CPST_KG: Amount of pesticide applied to HRU on a given day (kg/ha)">
									<b-form-input v-model.trim="$v.editItem.CPST_KG.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.CPST_KG)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row v-if="editItem.MGT_OP === 16">
							<b-col cols="12">
								<b-form-group label="BURN_FRLB: Fraction of biomass, plant nitrogen and phosphorus, surface residue, soil surface organic nitrogen and phosphorus that remains after a burn">
									<b-form-input v-model.trim="$v.editItem.BURN_FRLB.$model" class="number" type="number" step="any" :state="getValidState($v.editItem.BURN_FRLB)"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-alert variant="warning" class="my-3" :show="$v.editItem.$anyError">
							Please make sure no fields are left blank before continuing.
						</b-alert>

						<div slot="modal-footer">
							<b-button type="button" variant="secondary" @click="page.op.view.show = false" class="ml-1" :disabled="$v.editItem.$anyError">Ok</b-button>
						</div>
					</b-modal>

					<b-modal v-model="page.op.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
						<p>
							Are you sure you want to remove the operation from the schedule?
						</p>

						<div slot="modal-footer">
							<save-button type="button" :saving="false" @click.native="confirmDeleteOp" text="Delete" variant="danger" />
							<b-button type="button" variant="secondary" @click="page.op.delete.show = false" class="ml-1">Cancel</b-button>
						</div>
					</b-modal>

					<div v-if="!options.isSorting">
						<b-button type="button" variant="primary" class="mr-2" @click="addOp">Add an operation</b-button>
						<b-button v-if="form.item.length > 0" type="button" variant="light" class="mr-2 border" @click="options.isSorting = true">Sort operations</b-button>
						<b-button v-if="form.item.length > 0" type="button" variant="light" class="mr-2 border" @click="page.showDefaults = true">View default operations</b-button>
						<b-button type="button" variant="light" class="mr-2 border" @click="page.importExport.show = true">Import/export operations</b-button>
					</div>

					<div v-if="form.item.length > 0">
						<p v-if="options.isSorting && options.scheduleByHeatUnits">
							Operations are sorted by year, then operation number, then heat units. Enter new values as needed then click the button below when done and the table will re-sort.
						</p>

						<p v-if="options.isSorting && !options.scheduleByHeatUnits">
							Operations are sorted by year, then month and day. Enter new values as needed then click the button below when done and the table will re-sort.
						</p>

						<div v-if="options.isSorting">
							<b-button type="button" variant="primary" class="mr-2" @click="options.isSorting = false">Done sorting operations</b-button>
						</div>

						<b-alert variant="warning" class="mt-3" :show="options.scheduleByHeatUnits && hasEmptyHeatUnits()">
							Heat units cannot be zero if scheduling by heat units. Please edit the value for all rows before continuing.
						</b-alert>

						<b-alert variant="warning" class="mt-3" :show="!options.scheduleByHeatUnits && hasEmptyDate()">
							Month/day cannot be zero if scheduling by date. Please edit the value for all rows before continuing.
						</b-alert>
					</div>

					<h3 class="mt-5 mb-4">Apply your changes</h3>
					<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange" :initial-selection="initialSelection"></hru-selector>
				</div>

				<scenario-mod-viewer v-if="mods.length > 0" @deleted="modDeleted" :data="mods" value-label="Values" value-is-object
									 is-mgt2 :mgt2Lists="listsObject"></scenario-mod-viewer>

				<fixed-action-bar>
					<span id="save-button-tooltip">
						<save-button :saving="page.saving" :disabled="!validHruSelection || !page.showData" class="mr-2" />
					</span>
					<b-tooltip :disabled="validHruSelection" target="save-button-tooltip">
						Select at least one subbasin, land use, soil, and slope.
					</b-tooltip>
					<back-button class="btn btn-secondary mr-2" />

					<save-button v-if="mods.length > 0" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete all management operations changes?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>

				<b-modal v-model="page.showDefaults" size="xl" :title="`Default management operations for ${initialHruName}`" scrollable>
					<mgt2-viewer :schedule-by-heat-units="options.scheduleByHeatUnits" :items="form.defaults" :lists="listsObject"></mgt2-viewer>

					<div slot="modal-footer">
						<b-button type="button" variant="secondary" @click="page.showDefaults = false" class="ml-1">Ok</b-button>
					</div>
				</b-modal>

				<b-modal v-model="page.importExport.show" size="lg" title="Import/export operation schedule" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.importExport.errors"></error-list>

					<b-tabs content-class="mt-3" v-model="page.importExport.tabIndex" class="page-nav-tabs">
						<b-tab title="Export">
							<p>
								Export your management operation schedule to a CSV file, edit offline, then import for use in any HRUs as needed.
								Before exporting, be sure to save any changes first.
							</p>
							<p>
								The CSV file will contain columns for every variable in the management operations table.
								If the variable is not relevant for your operation, please leave it with a 0 value.
								Refer to the <a href="/content/docs/io/ch20_input_mgt.pdf" target="_blank">SWAT2012 IO documentation chapter on MGT inputs</a> for documentation on management variables.
							</p>
						</b-tab>
						<b-tab title="Import">
							<b-alert variant="info" :show="!page.importExport.uploaded">
								Note: uploading a CSV file will replace your schedule of operations listed on this page.
								However, changes won't be saved automatically. After uploading, close this window and save.
							</b-alert>

							<p>Choose your file (.csv)</p>
							<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>

							<b-alert variant="success" :show="page.importExport.uploaded" class="mt-3">
								Your file has been loaded. Remember to apply and save your changes after closing this window.
							</b-alert>
						</b-tab>
					</b-tabs>
					<div v-if="page.importExport.isExport">
						
					</div>

					<div slot="modal-footer">
						<span v-if="page.importExport.tabIndex === 0 && !page.importExport.uploaded">
							<a :href="`/api/exports/mgtoperations/${scenarioID}/${initialHruUrl}?access_token=${localStorageToken}`" class="btn btn-success">Export to CSV</a>
							<b-button type="button" variant="secondary" @click="page.importExport.tabIndex = 1" class="ml-1">Import</b-button>
						</span>
						<b-button type="button" variant="secondary" @click="page.importExport.show = false" class="ml-1">{{page.importExport.uploaded ? 'Close' : 'Cancel'}}</b-button>
					</div>
				</b-modal>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, between } from 'vuelidate/lib/validators';
	import SingleHruSelector from '@/components/SingleHruSelector';
	import HruSelector from '@/components/HruSelector';
	import ScenarioModViewer from '@/components/ScenarioModViewer';
	import Mgt2Viewer from '@/components/Mgt2Viewer';
	import _ from 'lodash';
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'EditMgtOperations',
		components: {
			SingleHruSelector, HruSelector, ScenarioModViewer, Mgt2Viewer, vueDropzone: vue2Dropzone
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					loadingErrors: [],
					loadingData: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					showData: false,
					showHruSelector: true,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					importExport: {
						show: false,
						errors: [],
						saving: false,
						uploaded: false,
						tabIndex: 0
					},
					op: {
						delete: {
							show: false,
							item: {}
						},
						view: {
							show: false
						}
					},
					showDefaults: false
				},
				dropzoneOptions: {
					url: '/api/scenarios/mgt/operationsupload',
					headers: this.getTokenHeaderPart(),
					acceptedFiles: '.csv',
					timeout: 300000
				},
				editItem: {},
				form: {
					defaults: [],
					item: [],
					plantList: [],
					fertList: [],
					pestList: [],
					tillList: [],
					manureList: [],
					scheduleOptions: [],
					opList: [],
					irrscList: [],
					ihvGbmList: [],
					wstrsList: [],
					impTrigList: []
				},
				options: {
					scheduleByHeatUnits: true,
					scheduleOptions: [
						{ text: 'Schedule by heat units', value: true },
						{ text: 'Schedule by date', value: false }
					],
					isSorting: false,
					monthsList: [
						{ value: 1, text: 'January' },
						{ value: 2, text: 'February' },
						{ value: 3, text: 'March' },
						{ value: 4, text: 'April' },
						{ value: 5, text: 'May' },
						{ value: 6, text: 'June' },
						{ value: 7, text: 'July' },
						{ value: 8, text: 'August' },
						{ value: 9, text: 'September' },
						{ value: 10, text: 'October' },
						{ value: 11, text: 'November' },
						{ value: 12, text: 'December' }
					]
				},
				initialHru: {
					subbasin: null,
					landuse: null,
					soil: null,
					slope: null
				},
				hruSelection: {
					applyToAll: true,
					subbasins: [],
					landuse: [],
					soils: [],
					slopes: []
				},
				mods: [],
				projectConnections: []
			}
		},
		validations: {
			form: {
				item: {
					required,
					$each: {
						YEAR: { required },
						MONTH: { required, between: between(0, 12) },
						DAY: { required, between: between(0, 31) },
						HUSC: { required },
						OP_NUM: { required }
					}
				}
			},
			editItem: {
				YEAR: { required },
				MONTH: { required, between: between(0, 12) },
				DAY: { required, between: between(0, 31) },
				HUSC: { required },
				MGT_OP: { required },
				HEATUNITS: { required },
				PLANT_ID: { required },
				CURYR_MAT: { required },
				LAI_INIT: { required },
				BIO_INIT: { required },
				HI_TARG: { required },
				BIO_TARG: { required },
				CNOP: { required },
				IRR_AMT: { required },
				FERT_ID: { required },
				FRT_KG: { required },
				FRT_SURFACE: { required },
				PEST_ID: { required },
				PST_KG: { required },
				TILLAGE_ID: { required },
				HARVEFF: { required },
				HI_OVR: { required },
				GRZ_DAYS: { required },
				MANURE_ID: { required },
				BIO_EAT: { required },
				BIO_TRMP: { required },
				MANURE_KG: { required },
				WSTRS_ID: { required },
				AUTO_WSTRS: { required },
				AFERT_ID: { required },
				AUTO_NSTRS: { required },
				AUTO_NAPP: { required },
				AUTO_NYR: { required },
				AUTO_EFF: { required },
				AFRT_SURFACE: { required },
				SWEEPEFF: { required },
				FR_CURB: { required },
				IMP_TRIG: { required },
				FERT_DAYS: { required },
				CFRT_ID: { required },
				IFRT_FREQ: { required },
				CFRT_KG: { required },
				PST_DEP: { required },
				IHV_GBM: { required },
				IRR_SALT: { required },
				IRR_EFM: { required },
				IRR_SQ: { required },
				IRR_EFF: { required },
				IRR_MX: { required },
				IRR_ASQ: { required },
				CPST_ID: { required },
				PEST_DAYS: { required },
				IPEST_FREQ: { required },
				CPST_KG: { required },
				BURN_FRLB: { required },
				OP_NUM: { required },
				IRR_SC: { required },
				IRR_NO: { required },
				IRR_SCA: { required },
				IRR_NOA: { required }
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/mgt/operationslists/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.mods = response.data.mods;
					this.form = {
						defaults: this.form.defaults,
						item: this.form.item,
						plantList: response.data.plantList,
						fertList:  response.data.fertList,
						pestList:  response.data.pestList,
						tillList:  response.data.tillList,
						manureList:  response.data.manureList,
						scheduleOptions:  response.data.scheduleOptions,
						opList:  response.data.opList,
						irrscList:  response.data.irrscList,
						ihvGbmList:  response.data.ihvGbmList,
						wstrsList:  response.data.wstrsList,
						impTrigList:  response.data.impTrigList
					}

					const response2 = await this.$http.get(`projects/map/${this.projectID}`, this.getTokenHeader());
					this.projectConnections = response2.data.connections;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getMgt() {
				this.page.loadingErrors = [];
				this.page.loadingData = true;
				this.page.showData = false;

				if (!this.validInitialHru) {
					this.page.loadingErrors.push('Please select an HRU and try again.');
				} else {
					try {
						const response = await this.$http.get(`scenarios/mgt/operations/${this.scenarioID}/${this.initialHru.subbasin.name}/${this.initialHru.landuse}/${this.initialHru.soil}/${this.initialHru.slope}`, this.getTokenHeader());
						this.log(response.data);
						this.form.defaults = response.data.defaults;
						this.form.item = response.data.item;

						if (this.form.item.length > 0) {
							this.options.scheduleByHeatUnits = this.form.item[0].DAY === 0;
						}

						this.page.showData = true;
						this.page.showHruSelector = false;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.loadingErrors = this.logError(error);
					}
				}

				this.page.loadingData = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				/*if (this.$v.$invalid) {
					this.page.saveErrors.push('Please make sure all fields are completed for each operation and try again.');
				}*/
				if (this.options.scheduleByHeatUnits && this.hasEmptyHeatUnits()) {
					this.page.saveErrors.push('Heat units cannot be zero if scheduling by heat units. Please edit the value for all rows before continuing.');
				} else if (!this.options.scheduleByHeatUnits && this.hasEmptyDate()) {
					this.page.saveErrors.push('Month/day cannot be zero if scheduling by date. Please edit the value for all rows before continuing.');
				} else {
					for (var i = 0; i < this.orderedItems.length; i++) {
						var item = this.form.item[i];
						if (this.options.scheduleByHeatUnits) {
							item.DAY = 0;
							item.MONTH = 0;
						} else {
							item.HUSC = 0;
						}
						item.OP_NUM = i + 1;
					}

					var data = {
						applyToAll: this.hruSelection.applyToAll,
						subbasins: this.hruSelection.subbasins,
						landuses: this.hruSelection.landuse,
						soils: this.hruSelection.soils,
						slopes: this.hruSelection.slopes,
						modificationObject: this.form.item
					};
					this.log(data);

					try {
						const response = await this.$http.post(`scenarios/savemods/${this.scenarioID}/Mgt2`, data, this.getTokenHeader());
						this.log(response.data);
						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/Mgt2`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			initialHruChange(selection) {
				this.initialHru = selection;
			},
			hruSelectionChange(selection) {
				this.hruSelection = selection;
			},
			getTextFromList(list, value) {
				var filtered = list.filter(function (el) { return el.value === value; });
				if (filtered.length > 0) return filtered[0].text;
				return '';
			},
			addOp() {
				var item = {
					YEAR: this.form.item.length > 0 ? this.form.item[this.form.item.length-1].YEAR : 1,
					MONTH: this.options.scheduleByHeatUnits ? 0 : 1,
					DAY: this.options.scheduleByHeatUnits ? 0 : 1,
					HUSC: this.options.scheduleByHeatUnits ? 0.15 : 0,
					OP_NUM: this.form.item.length + 1,
					MGT_OP: 1,
					HEATUNITS: 0,
					PLANT_ID: 0,
					CURYR_MAT: 0,
					LAI_INIT: 0,
					BIO_INIT: 0,
					HI_TARG: 0,
					BIO_TARG: 0,
					CNOP: 0,
					IRR_AMT: 0,
					FERT_ID: 0,
					FRT_KG: 0,
					FRT_SURFACE: 0,
					PEST_ID: 0,
					PST_KG: 0,
					TILLAGE_ID: 0,
					HARVEFF: 0,
					HI_OVR: 0,
					GRZ_DAYS: 0,
					MANURE_ID: 0,
					BIO_EAT: 0,
					BIO_TRMP: 0,
					MANURE_KG: 0,
					WSTRS_ID: 0,
					AUTO_WSTRS: 0,
					AFERT_ID: 0,
					AUTO_NSTRS: 0,
					AUTO_NAPP: 0,
					AUTO_NYR: 0,
					AUTO_EFF: 0,
					AFRT_SURFACE: 0,
					SWEEPEFF: 0,
					FR_CURB: 0,
					IMP_TRIG: 0,
					FERT_DAYS: 0,
					CFRT_ID: 0,
					IFRT_FREQ: 0,
					CFRT_KG: 0,
					PST_DEP: 0,
					IHV_GBM: 0,
					IRR_SALT: 0,
					IRR_EFM: 0,
					IRR_SQ: 0,
					IRR_EFF: 0,
					IRR_MX: 0,
					IRR_ASQ: 0,
					CPST_ID: 0,
					PEST_DAYS: 0,
					IPEST_FREQ: 0,
					CPST_KG: 0,
					BURN_FRLB: 0,
					IRR_SC: 0,
					IRR_NO: 0,
					IRR_SCA: 0,
					IRR_NOA: 0
				};
				this.form.item.push(item);
				this.page.op.view.show = true;
				this.editItem = item;
			},
			showOp(item) {
				this.page.op.view.show = true;
				this.editItem = item;
			},
			askDeleteOp(item) {
				this.page.op.delete.show = true;
				this.page.op.delete.item = item;
			},
			confirmDeleteOp() {
				var item = this.page.op.delete.item;
				this.form.item = this.form.item.filter(function (el) { return el != item; });
				this.page.op.delete.show = false;
				this.page.op.delete.item = {};
			},
			hasEmptyHeatUnits() {
				var nohu = this.form.item.filter(function (el) { return el.HUSC === 0; });
				return nohu.length > 0;
			},
			hasEmptyDate() {
				var nodate = this.form.item.filter(function (el) { return el.MONTH === 0 || el.DAY === 0; });
				return nodate.length > 0;
			},
			setIRRNO(selection) {
				if (selection.subbasin !== null)
					this.editItem.IRR_NO = this.getSubbasinIndex(selection.subbasin.id);
			},
			setIRR_NOA(selection) {
				if (selection.subbasin !== null)
					this.editItem.IRR_NOA = this.getSubbasinIndex(selection.subbasin.id);
			},
			getSubbasinIndex(id) {
				let matches = this.projectConnections.filter(function (el) { return el.fromID === id });
				if (matches.length > 0) {
					return matches[0].fromIndex;
				}
				return 1;
			},
			getSubbasinId(index) {
				let matches = this.projectConnections.filter(function (el) { return el.fromIndex === index });
				if (matches.length > 0) {
					return matches[0].fromID;
				}
				return null;
			},
			fileUploaded(file, response) {
				this.log(response);
				this.form.item = response;
				if (this.form.item.length > 0) {
					this.options.scheduleByHeatUnits = this.form.item[0].DAY === 0;
				}
				this.$v.$touch();
				this.page.importExport.uploaded = true;
				this.page.importExport.errors = [];
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.importExport.errors = [];
				this.page.importExport.errors.push(message);
			}
		},
		computed: {
			validInitialHru() {
				return this.initialHru.subbasin !== null &&
						this.initialHru.landuse !== null &&
						this.initialHru.soil !== null &&
						this.initialHru.slope !== null;
			},
			validHruSelection() {
				return this.hruSelection.applyToAll ||
					(this.hruSelection.subbasins.length > 0 &&
						this.hruSelection.landuse.length > 0 &&
						this.hruSelection.soils.length > 0 &&
						this.hruSelection.slopes.length > 0);
			},
			initialSelection() {
				return {
					subbasins: [this.initialHru.subbasin],
					landuse: [this.initialHru.landuse],
					soils: [this.initialHru.soil],
					slopes: [this.initialHru.slope]
				}
			},
			orderedItems() {
				var sortArray = ['YEAR', 'MONTH', 'DAY', 'OP_NUM', 'HUSC'];
				if (this.options.scheduleByHeatUnits) {
					sortArray = ['YEAR', 'HUSC', 'OP_NUM', 'MONTH', 'DAY'];
				}

				return _.orderBy(this.form.item, sortArray);
			},
			initialHruName() {
				if (this.initialHru.subbasin !== null)
					return `${this.initialHru.subbasin.name} / ${this.initialHru.landuse} / ${this.initialHru.soil} / ${this.initialHru.slope}`;
				return '';
			},
			initialHruUrl() {
				if (this.initialHru.subbasin !== null)
					return `${this.initialHru.subbasin.name}/${this.initialHru.landuse}/${this.initialHru.soil}/${this.initialHru.slope}`;
				return '';
			},
			listsObject() {
				return {
					plantList: this.form.plantList,
					fertList:  this.form.fertList,
					pestList:  this.form.pestList,
					tillList:  this.form.tillList,
					manureList:  this.form.manureList,
					scheduleOptions:  this.form.scheduleOptions,
					opList:  this.form.opList,
					irrscList:  this.form.irrscList,
					ihvGbmList:  this.form.ihvGbmList,
					wstrsList:  this.form.wstrsList,
					impTrigList:  this.form.impTrigList
				}
			}
		}
	}
</script>

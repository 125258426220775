<template>
	<div>
		<page-authorization-container :page="page" require-admin>
			<div v-for="(d, i) in data" :key="i" class="mb-4">
				<h2>{{d.name}}</h2>
				<hr class="mt-2 mb-3" />
				<b-row align-h="center">
					<b-col cols="4" lg="3">
						<div class="stat-box bg-info text-white mb-2">
							<div class="title">Subbasins</div>
							<div class="value">{{ d.subbasins |  number(0) }}</div>
						</div>
					</b-col>
					<b-col cols="4" lg="3">
						<div class="stat-box bg-primary text-white mb-2">
							<div class="title">HRUs</div>
							<div class="value">{{ d.hrus |  number(0) }}</div>
						</div>
					</b-col>
					<b-col cols="4" lg="3">
						<div class="stat-box bg-dark text-white mb-2">
							<div class="title">Projects</div>
							<div class="value">{{ d.projects |  number(0) }}</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	export default {
		name: 'AdminDatabase',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				data: {}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/database', this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>

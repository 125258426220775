<template>
	<b-alert :show="dismissCountDown"
			 dismissible
			 variant="success"
			 @dismiss-count-down="countDownChanged"
			 @dismissed="dismissCountDown=0">
		<font-awesome-icon :icon="['fas', 'check']" />
		{{text}}
	</b-alert>
</template>

<script>
	export default {
		name: 'SuccessAlert',
		props: {
			text: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				dismissCountDown: 0,
				dismissSecs: 3
			}
		},
		methods: {
			countDownChanged (dismissCountDown) {
				this.dismissCountDown = dismissCountDown;
			},
			startAlert() {
				this.dismissCountDown = this.dismissSecs;
			}
		}
	}
</script>

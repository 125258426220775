<template>
	<div>
		<b-tabs v-model="tabIndex" content-class="mt-4" class="page-nav-tabs">
			<b-tab title="Settings">
				<success-alert ref="savedAlert" text="Changes saved." />
				<error-list :errors="page.errors"></error-list>

				<b-form>
					<b-form-group label="Name" :invalid-feedback="requiredFeedback($v.form.name)">
						<b-form-input v-model="form.name" type="text" autofocus :state="getValidState($v.form.name)"></b-form-input>
					</b-form-group>

					<b-form-group label="Description (optional)">
						<b-form-input v-model="form.description" type="text"></b-form-input>
					</b-form-group>

					<b-form-group>
						<b-checkbox v-model="form.isDefault">Make this the default tier for users</b-checkbox>
					</b-form-group>

					<b-form-group class="mb-5">
						<b-checkbox v-model="form.hideFromDisplay">Hide this tier from the help display (tier for internal/dev. use)</b-checkbox>
					</b-form-group>

					<p>
						<strong>For the fields below, enter 0 for unlimited.</strong>
					</p>

					<b-row>
						<b-col md>
							<b-form-group label="Maximum number of subbasins per project" :invalid-feedback="requiredFeedback($v.form.subbasinLimit)">
								<b-form-input v-model="form.subbasinLimit" type="number" :state="getValidState($v.form.subbasinLimit)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Maximum number of HRUs per project" :invalid-feedback="requiredFeedback($v.form.hruLimit)">
								<b-form-input v-model="form.hruLimit" type="number" :state="getValidState($v.form.hruLimit)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="Maximum number of runs" :invalid-feedback="requiredFeedback($v.form.taskLimit)">
								<b-input-group>
									<b-form-input v-model="form.taskLimit" type="number" :state="getValidState($v.form.taskLimit)"></b-form-input>
									<b-input-group-append>
										<b-form-select v-model="form.taskLimitInterval" :options="options.taskLimitIntervals"></b-form-select>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Maximum number of concurrent runs" :invalid-feedback="requiredFeedback($v.form.concurrencyLimit)">
								<b-form-input v-model="form.concurrencyLimit" type="number" :state="getValidState($v.form.concurrencyLimit)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md="6">
							<b-form-group label="Priority in queue">
								<b-form-select v-model="form.queuePriority" :options="options.queuePriorities"></b-form-select>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row class="mt-4">
						<b-col>
							<b-form-group label="Maximum number of dataset uploads (offline project .mdb files). No unlimited value; a zero means no uploads allowed." label-class="font-weight-bold" :invalid-feedback="requiredFeedback($v.form.datasetUploadLimit)">
								<b-form-input v-model="form.datasetUploadLimit" type="number" :state="getValidState($v.form.datasetUploadLimit)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<page-loading :loading="page.loading"></page-loading>
					<div v-if="!page.loading" class="mt-4">
						<p>
							<strong>Select datasets accessible to users in this tier:</strong>
						</p>
						<div class="checklist">
							<b-form-checkbox-group v-model="form.datasets" stacked>
								<div class="item" v-for="(d, i) in options.datasets" :key="i">
									<b-form-checkbox :value="d.value">{{d.text}}</b-form-checkbox>
								</div>
							</b-form-checkbox-group>
						</div>
					</div>

					<div class="mt-4">
						<p>
							<strong>Select advanced features accessible to users in this tier:</strong>
						</p>
						<div class="checklist">
							<b-form-checkbox-group v-model="form.featureList" stacked>
								<div class="item">
									<b-form-checkbox :value="featureNames.batchProjects">Batch Projects (be sure to enable API key for each user)</b-form-checkbox>
									<b-form-checkbox :value="featureNames.regionalProjects">Regional Projects (regions must be set up in the database)</b-form-checkbox>
								</div>
							</b-form-checkbox-group>
						</div>
					</div>
				</b-form>
			</b-tab>
			<b-tab title="Users" v-if="isUpdate && !data.isDefault">
				<grid-view :api-url="`admin/tiers/users/${data.id}`"
					:fields="table.fields"
					:default-sort="table.sort" :default-reverse="table.reverse"
					collection-description="users"
					item-name="user"
					edit-route="/admin/users/" :edit-route-append="false"></grid-view>
			</b-tab>
		</b-tabs>

		<fixed-action-bar :cols-lg="10" :offset-lg="2">
			<save-button v-if="tabIndex === 0" :saving="page.saving" class="mr-2" @click.native="save" />
			<back-button class="btn btn-secondary mr-2" />
			<b-button v-if="isUpdate && isAuthorized(roleNames.admin)" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
		</fixed-action-bar>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete this tier?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'AdminTierForm',
		components: {
			GridView
		},
		props: {
			isUpdate: {
				type: Boolean,
				default: false
			},
			data: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				options: {
					taskLimitIntervals: [
						{ value: 1, text: 'per hour' },
						{ value: 24, text: 'per day' }
					],
					queuePriorities: [
						{ value: 'default', text: 'Normal' },
						{ value: 'middle', text: 'Higher' },
						{ value: 'highest', text: 'Highest' }
					],
					datasets: []
				},
				form: this.data,
				tabIndex: 0,
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'userName', sortable: true },
						{ key: 'email', sortable: true },
						{ key: 'firstName', sortable: true },
						{ key: 'lastName', sortable: true },
						{ key: 'affiliation', sortable: true },
						{ key: 'location', sortable: true },
						{ key: 'expiration', label: 'Access Expiration', sortable: true, formatter: (value) => { return this.isNullOrEmpty(value) ? '' : moment(value).format('M/D/YYYY') } }
					],
					sort: 'userName',
					reverse: true,
					itemsPerPage: 50
				}
			}
		},
		validations: {
			form: {
				name: { required },
				subbasinLimit: { required },
				hruLimit: { required },
				taskLimit: { required },
				concurrencyLimit: { required },
				datasetUploadLimit: { required },
				hideFromDisplay: {}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('/datasets', this.getTokenHeader());
					this.log(response.data);
					this.options.datasets = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.errors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.errors.push('Please fix the errors below and try again.');
				} else {
					try {
						if (!this.isUpdate) {
							const response = await this.$http.post('admin/tiers', this.form, this.getTokenHeader());
							this.$router.push({ name: 'AdminTiers' }).catch(err => {});
						} else {
							const response = await this.$http.put(`admin/tiers/${this.form.id}`, this.form, this.getTokenHeader());
							this.$refs.savedAlert.startAlert();
							this.$v.$reset();
						}
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`admin/tiers/${this.form.id}`, this.getTokenHeader());
					this.$router.push({ name: 'AdminTiers' }).catch(err => {});
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">
			<div id="main-panel">
				<page-authorization-container :page="page">
					<b-container fluid class="p-0">
						<b-row no-gutters>
							<b-col md="3" lg="2" order="2" order-md="1" class="fixed-height bg-light">
								<div class="px-3 pt-md-4">
									<b-nav vertical class="dash-items-nav nav-lg">
										<b-nav-item to="/api/batch" v-if="hasFeatureAccess(featureNames.batchProjects) && isAuthorized(roleNames.apiUser)"><font-awesome-icon :icon="['fas', 'tasks']" fixed-width class="mr-3" /> Batch project runs</b-nav-item>
										<b-nav-item to="/api/regional" v-if="hasFeatureAccess(featureNames.regionalProjects)"><font-awesome-icon :icon="['fas', 'map-location']" fixed-width class="mr-3" /> Regional project runs</b-nav-item>
									</b-nav>
								</div>
							</b-col>
							<b-col md="9" lg="10" order="1" order-md="2" class="fixed-height shadow-sm no-shadow-sm" style="z-index:500">
								<b-breadcrumb :items="breadCrumbs" class="no-curves bg-white px-md-4"></b-breadcrumb>
								<div class="container-fluid px-md-4 pb-4">
									<div v-if="$route.name == 'ApiDashboard'">
										
									</div>
									<router-view></router-view>
								</div>
							</b-col>
						</b-row>
					</b-container>
				</page-authorization-container>
			</div>
		</div>
	</div>
</template>

<script>
	import DashboardHeader from '@/components/DashboardHeader';

	export default {
		name: 'ApiDashboard',
		components: {
			DashboardHeader
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				data: {
					
				}
			}
		},
		async created() {
			//await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('admin/dashboard', this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (var i = 0; i < this.$route.matched.length; i++) {
					var item = this.$route.matched[i];
					var name = item.meta.title ? item.meta.title : item.name;

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Land use update</h2>

			<p>
				Land use update files allow HRU fraction updating during a simulation run.
				This is particularly useful to initialize conservation measures mid-simulation. After their initialization, the practices remain in effect for the remainder of the simulation.
			</p>

			<p>
				Read the <a href="/content/docs/io/ch37_input_lup.pdf" target="_blank">SWAT2012 IO documentation chapter on land use update</a>.
				Please note we accept CSV files instead of the .dat files described in the documentation.
			</p>

			<div v-if="data.iclus.isAvailable">
				<h3 class="mb-3">Integrated Climate and Land-Use Scenarios (ICLUS)</h3>

				<p>
					ICLUS (Integrated Climate and Land-Use Scenarios) Version 2.1.1 is a raster dataset of land use and population projections.
					To integrate this data into HAWQS projects, ICLUS data was reprojected and spatially resampled, and land use change between ICLUS
					and the land use rasters used in the development of HAWQS was calculated. Because HAWQS uses land use (among other datasets) to define
					HRUs (Hydrologic Resource Units) as fractions of each subbasin, land use changes could not be directly applied to the HRUs.
					Instead, land use change proportions were calculated and used to alter the existing fractions of land use classes in HAWQS.
				</p>

				<error-list :errors="page.iclus.errors"></error-list>
				<b-form-group>
					<b-checkbox v-model="data.iclus.isUsing" @change="saveIclusSetting">
						Use ICLUS files with your scenario? Uncheck to upload your own files or not use any land use update.
						<font-awesome-icon :icon="['fas', 'spinner']" spin v-if="page.iclus.saving" />
					</b-checkbox>
				</b-form-group>
			</div>

			<div v-if="!data.iclus.isUsing">
				<h3 class="mb-3">Data formatting and uploading instructions</h3>
				<ol>
					<li>
						<a :href="data.hrusCsvUrl">Download a CSV file of your project HRUs.</a>
						<ul v-if="data.iclus.isAvailable">
							<li>
								Note: The CSV above is the HAWQS default project HRUs consistent with the user selected HRU aggregation. To download the ICLUS HRU table, check the box above to use ICLUS, run your scenario with SWAT editor tables checked, and download. Then you can open and view the HRU table in the Access database associated with the scenario.
							</li>
						</ul>
					</li>
					<li>Edit the <strong>fraction</strong> column values as needed. Remember, the <strong>fraction must add up to 1 for each subbasin</strong>.</li>
					<li>
						Save your changes to the CSV, and rename it to the data you want to start the update. Please name the file in the format yyyy-mm-dd.csv, e.g. if your date is January 3, 1965, name the file 1965-01-03.csv.
						<ul>
							<li>Warning: be careful when saving the CSV to make sure the subbasin column stays intact. Sometimes Microsoft Excel trims and rounds the value resulting in an error.</li>
						</ul>
					</li>
					<li>Copy the HRU CSV file and repeat this process for <strong>up to 25 dates/files</strong>.</li>
					<li>Add your CSV files to a zip file and upload below.</li>
				</ol>

				<div v-if="data.hasMods">
					<hr class="my-4" />

					<h3 class="my-3">Existing uploaded data</h3>
					<p>
						You have already uploaded land use update data for this scenario. Download it below.
					</p>
					<p>
						<a :href="data.userDataUrl" class="btn btn-primary">Download</a>
					</p>
				</div>

				<hr class="my-4" />

				<b-form @submit.prevent="save">
					<h3 class="mb-3">Upload data</h3>
					<p>
						Drag a zip file of CSV files into the space below. <strong v-if="data.hasMods">Adding a new file below will replace all existing data.</strong>
					</p>

					<error-list :errors="page.saveErrors"></error-list>
					<success-alert ref="savedAlert" text="Changes saved." />

					<vue-dropzone ref="addFile" id="addFile" :options="dropzoneOptions" @vdropzone-success="fileUploaded" @vdropzone-error="uploadError"></vue-dropzone>
				</b-form>
			</div>

			<fixed-action-bar>
				<back-button class="btn btn-secondary mr-2" />

				<save-button v-if="data.hasMods && !data.iclus.isUsing" :saving="page.delete.saving" type="button"
							 variant="danger" text="Remove Changes"
							 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
							 @click.native="askDelete" class="ml-auto" />
			</fixed-action-bar>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete your uploaded data?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</page-authorization-container>
	</div>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'EditLup',
		components: {
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				dropzoneOptions: {
					url: `/api/scenarios/general/lup/${this.$route.params.scenarioID}`,
					headers: this.getTokenHeaderPart(),
					acceptedFiles: '.zip'
				},
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					iclus: {
						saving: false,
						errors: []
					}
				},
				data: {
					hasMods: false,
					userDataUrl: '',
					hrusCsvUrl: '',
					iclus: {
						isAvailable: false,
						isUsing: false
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/general/lup/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/Lup`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async fileUploaded(file, response) {
				this.log(response);
				this.page.saveErrors = [];
				await this.get();
				this.$refs.savedAlert.startAlert();
			},
			uploadError(file, message, xhr) {
				this.log(file);
				this.log(xhr);
				this.page.saveErrors.push(message);
			},
			async saveIclusSetting() {
				this.page.iclus.errors = [];
				this.page.iclus.saving = true;

				try {
					await this.$http.put(`scenarios/general/lupiclus/${this.scenarioID}`, { value: this.data.iclus.isUsing }, this.getTokenHeader());
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.iclus.errors = this.logError(error);
				}

				this.page.iclus.saving = false;
			}
		}
	}
</script>

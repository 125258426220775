<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Weather generator</h2>

			<p>
				Adjust weather generator (WGN) input data using the form below.
				Read the <a href="/content/docs/io/ch12_input_wgn.pdf" target="_blank">SWAT2012 IO documentation chapter on weather generator inputs</a>.
			</p>

			<p>
				<strong>Increase or decrease the variables below by up to 100%.</strong>
			</p>

			<hr class="my-4" />

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<div v-for="(o, i) in Object.keys(item)" :key="i">
					<div>
						<label :for="o">{{o}}: {{descriptions[i]}}</label>
					</div>
					<b-row class="align-items-center mb-3">
						<b-col lg="5" md="9" sm="10" class="d-flex align-items-center">
							<b-form-input v-model.trim="$v.item[o].$model" type="range" :min="options.min" :max="options.max"></b-form-input>
						</b-col>
						<b-col lg="2" md="3" sm="2">
							<b-input-group append="%">
								<b-form-input :id="o" v-model.trim="$v.item[o].$model" type="number" step="any" :min="options.min" :max="options.max" :state="getValidState($v.item[o])"></b-form-input>
							</b-input-group>
							<b-form-invalid-feedback v-if="!$v.item[o].required">Required</b-form-invalid-feedback>
							<b-form-invalid-feedback v-if="!$v.item[o].between">Must be between {{options.min}} and {{options.max}}</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</div>

				<hr class="my-4" />

				<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange" subbasin-and-months></hru-selector>

				<scenario-mod-viewer v-if="mods.length > 0" @deleted="modDeleted" :data="mods" value-label="Values" value-is-object></scenario-mod-viewer>

				<fixed-action-bar>
					<span id="save-button-tooltip">
						<save-button :saving="page.saving" :disabled="!validHruSelection || !$v.$anyDirty" class="mr-2" />
					</span>
					<b-tooltip :disabled="validHruSelection" target="save-button-tooltip">
						Select at least one subbasin, land use, soil, and slope.
					</b-tooltip>
					<back-button class="btn btn-secondary mr-2" />
					<save-button v-if="mods.length > 0" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>
			</b-form>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete all weather generator changes?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

		</page-authorization-container>
	</div>
</template>

<script>
	import { required, between } from 'vuelidate/lib/validators';
	import HruSelector from '@/components/HruSelector';
	import ScenarioModViewer from '@/components/ScenarioModViewer';

	export default {
		name: 'EditWgn',
		components: {
			HruSelector, ScenarioModViewer
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				options: {
					min: -100,
					max: 100
				},
				hruSelection: {
					applyToAll: true,
					subbasins: [],
					months: []
				},
				descriptions: [
					'Maximum 0.5 hour rainfall in entire period of record for month (mm H2O)',
					'Average daily maximum air temperature (ºC)',
					'Average daily minimum air temperature for month (ºC)',
					'Standard deviation for daily maximum air temperature in month (ºC)',
					'Standard deviation for daily minimum air temperature in month (ºC)',
					'Average total monthly precipitation (mm H2O)',
					'Standard deviation for daily precipitation in month (mm H2O/day)',
					'Skew coefficient for daily precipitation in month',
					'Probability of a wet day following a dry day in the month',
					'Probability of a wet day following a wet day in the month',
					'Average number of days of precipitation in month',
					'Average daily solar radiation for month (MJ/m2/day)',
					'Average daily dew point temperature for each month (ºC)',
					'Average daily wind speed in month (m/s)'
				],
				item: {
					RAINHHMX: 0,
					TMPMX: 0,
					TMPMN: 0,
					TMPSTDMX: 0,
					TMPSTDMN: 0,
					PCPMM: 0,
					PCPSTD: 0,
					PCPSKW: 0,
					PR_W1: 0,
					PR_W2: 0,
					PCPD: 0,
					SOLARAV: 0,
					DEWPT: 0,
					WNDAV: 0
				},
				mods: []
			}
		},
		validations() {
			var item = {};
			var keys = Object.keys(this.item);
			for (var i = 0; i < keys.length; i++) {
				item[keys[i]] = { required, between: between(-100, 100) };
			}
			return { item: item };
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/getmods/${this.scenarioID}/Wgn`, this.getTokenHeader());
					this.mods = response.data.mods;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				}
				else {
					try {
						var item = {};
						var keys = Object.keys(this.item);

						for (var i = 0; i < keys.length; i++) {
							if (this.$v.item[keys[i]] && (this.$v.item[keys[i]].$dirty)) {
								item[keys[i]] = Number(this.item[keys[i]]);
							}
						}

						item.Months = this.hruSelection.months;

						var data = {
							applyToAll: this.hruSelection.applyToAll,
							subbasins: this.hruSelection.subbasins,
							modificationObject: item
						};
						this.log(data);
						const response = await this.$http.post(`scenarios/savemods/${this.scenarioID}/Wgn`, data, this.getTokenHeader());
						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();


					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/Wgn`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			hruSelectionChange(selection) {
				this.hruSelection = selection;
			}
		},
		computed: {
			validHruSelection() {
				return this.hruSelection.applyToAll ||
					(this.hruSelection.subbasins.length > 0 &&
						this.hruSelection.months.length > 0);
			}
		}

	}
</script>

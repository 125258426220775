<template>
	<div>
		<page-authorization-container v-if="$route.name === 'EditFerts'" :page="page">
			<h2 class="mb-4">Fertilizers</h2>

			<div v-if="$parent.scenario.status.isRunning">
				<page-running />
			</div>
			<div v-else>
				<p>
					The fertilizer database summarizes the relative fractions of nitrogen and phosphorus pools in the different fertilizers.
					Read the <a href="/content/docs/io/ch17_input_fertdb.pdf" target="_blank">SWAT2012 IO documentation chapter on fertilizer inputs</a>.
				</p>

				<p v-if="hasChanges">
					Fertilizers you have modified are <span class="table-info">highlighted</span>.
				</p>

				<div class="table-responsive mb-3">
					<b-table responsive class="mb-0" :items="items" :fields="fields" striped hover :per-page="perPage" :current-page="currentPage" :tbody-tr-class="rowClass">
						<template v-slot:cell(edit)="data">
							<b-link :to="editUrl + data.item.id">
								<font-awesome-icon :icon="['fas', 'edit']" />
							</b-link>
						</template>
					</b-table>
				</div>

				<b-pagination v-model="currentPage"
							  :total-rows="rows"
							  :per-page="perPage"
							  aria-controls="fert-table">
				</b-pagination>

				<fixed-action-bar>
					<back-button class="btn btn-secondary mr-2" />

					<save-button v-if="hasChanges" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete your fertilizer input data changes?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</div>			
		</page-authorization-container>
		<router-view></router-view>
	</div>
</template>

<script>

	export default {
		name: 'EditFerts',
		data() {
			return {
				scenarioID: this.$route.params.scenarioID,
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				fields: [
					{ key: 'edit', label: '' },
					{ key: 'id', label: 'ID' },
					{ key: 'name', label: 'Name' },
					{ key: 'description', label: 'Description' },
					{ key: 'FMINN', label: 'Mineral N', formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(3)) } },
					{ key: 'FMINP', label: 'Mineral P', formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(3)) } },
					{ key: 'FORGN', label: 'Organic N', formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(3)) } },
					{ key: 'FORGP', label: 'Organic P', formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(3)) } },
					{ key: 'FNH3N', label: 'Mineral N applied as NH3 N', formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(3)) } },
				],
				editUrl: '',
				perPage: 20,
				currentPage: 1,
				items: [],
				fieldsData: [],
				hasChanges: false
			}
		},
		async created() {
			await this.get();
		},
		computed: {
			rows() {
				return this.items.length
			},
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/general/ferts/${this.scenarioID}`, this.getTokenHeader());
					this.items = response.data.items;
					this.hasChanges = response.data.hasChanges;
					this.editUrl = '/projects/' + this.projectID + '/scenarios/' + this.scenarioID + '/fert/';

				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/Fert`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			rowClass(item, type) {
				if (!item) return
				if (item.isModified) return 'table-info'
			}
		}
	}
</script>

<template>
	<div class="bg-dark pt-4 pb-5 text-trans text-center">
		<b-container>
			<b-row>
				<b-col>
					<div v-html="siteText.footer"></div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
	export default {
		name: 'FooterData',
		data() {
			return {
				publicPath: process.env.BASE_URL,
				email: 'eco.web@tamu.edu'
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Project Downloads</h2>

			<b-row>
				<b-col md>
					<b-card header-bg-variant="light" no-body class="border mb-3">
						<div slot="header" class="font-weight-bold mb-0">Zip and download project</div>
						<b-card-body>
							<error-list :errors="page.zip.errors"></error-list>

							<div v-if="data.status.isZipping">
								Your project is currently zipping files. Please wait.
							</div>
							<div v-else-if="data.status.isRunningScenarios">
								<b-alert variant="warning" show>
									Your project is busy running scenarios.
									Please wait until all scenario runs have completed to zip your project files.
								</b-alert>
							</div>
							<div v-else>
								<p>
									Create a zip of all your project files and download them to your computer for offline use.
									We recommend waiting until you are done running all scenarios for this project before creating
									this file. It may take several hours if you have multiple scenarios and your project is
									large (>10,000 HRUs).
								</p>
								<p v-if="data.zipFile.canGetGisData">
									<b-form-checkbox v-model="page.zip.includeGisData">Include GIS project data in your download?</b-form-checkbox>
								</p>
							</div>

							<b-alert variant="info" :show="data.zipFile.exists" class="text-center">
								<p>A zip file of your project was created on<br /> {{data.zipFile.createdOn | date}}.</p>
								<a :href="data.zipFile.url" class="btn btn-primary mr-1">Download Zip File</a>
							</b-alert>
						</b-card-body>
					</b-card>
				</b-col>

				<b-col md>
					<b-card header-bg-variant="light" no-body class="border mb-3">
						<div slot="header" class="font-weight-bold mb-0">GIS data</div>
						<b-list-group flush>
							<b-list-group-item :href="data.geoJsonUrls.subbasins">Subbasins geojson</b-list-group-item>
							<b-list-group-item :href="data.geoJsonUrls.streams">Streams geojson</b-list-group-item>
							<b-list-group-item v-for="layer in data.geoJsonLayers" :key="layer" :href="`/api/exports/geojson/${projectID}/${layer}?access_token=${localStorageToken}`">{{layer}} geojson</b-list-group-item>
						</b-list-group>
					</b-card>

					<b-card header-bg-variant="light" no-body class="border mb-3">
						<div slot="header" class="font-weight-bold mb-0">Watershed data</div>
						<b-list-group flush>
							<b-list-group-item :href="data.subbasinsCsv">Subbasins from/to connectivity CSV</b-list-group-item>
							<b-list-group-item v-if="data.hasSetHrus" :href="data.hrusOriginalCsv">Default HRUs CSV</b-list-group-item>
							<b-list-group-item v-if="data.hasSetHrus" :href="data.hrusTrimmedCsv">Trimmed HRUs CSV</b-list-group-item>
							<b-list-group-item v-else :href="data.hrusOriginalCsv">HRUs CSV</b-list-group-item>
							<b-list-group-item :href="data.pointSourceConstant">{{siteText.siteName}} constant point source values CSV</b-list-group-item>
						</b-list-group>
					</b-card>
				</b-col>
			</b-row>

			

			<fixed-action-bar>
				<save-button type="button" :saving="page.zip.saving" :disabled="data.status.isZipping || data.status.isRunningScenarios" @click.native="zipProject" :text="data.zipFile.exists ? 'Create a New Zip File' : 'Zip Project Files'" variant="success" class="mr-2" />
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</page-authorization-container>
	</div>
</template>

<script>
	//import moment from 'moment';

	export default {
		name: 'ProjectDownloads',
		data() {
			return {
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					zip: {
						errors: [],
						saving: false,
						includeGisData: true
					}
				},
				data: {
					geoJsonUrls: {},
					geoJsonLayers: [],
					zipFile: {},
					status: {}
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`projects/downloads/${this.projectID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async zipProject() {
				this.page.zip.errors = [];
				this.page.zip.saving = true;

				if (!this.data.status.isZipping && !this.data.status.isRunningScenarios) {
					try {
						const response = await this.$http.put(`projects/zip/${this.projectID}`, { value: this.page.zip.includeGisData && this.data.zipFile.canGetGisData }, this.getTokenHeader());
						this.log(response.data);
						this.$router.push({ name: 'ProjectView', params: { id: this.projectID } }).catch(err => { this.log(err) });
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.zip.errors = this.logError(error);
					}
				}

				this.page.zip.saving = false;
			}
		}
	}
</script>

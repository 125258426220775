<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">HRUs to print</h2>			

			<div v-if="hasMods">
				<p>
					Your selected HRUs for this scenario are shown in the table below.
					To modify the HRUs printed in this scenario, click the button below to remove the current selections.
				</p>
				<p>
					<save-button v-if="hasMods" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Selection"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</p>

				<b-table ref="hruTable" responsive striped hover :items="mods" :fields="table.fields.slice(1)"></b-table>
			</div>

			<b-form @submit.prevent="save" v-if="!hasMods">
				<p>
					Select up to <strong>20</strong> HRUs print in your model output. Due to the potential size of HRU output, only 20 may be selected per scenario run.
					By default only the first HRU is printed. To print all HRUs in your project, download your scenario, modify the file.cio, and run offline.
				</p>

				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<div v-if="!page.showHruSelector" class="mb-4">
					<b-button @click="page.showHruSelector = true; page.showResults = false">Change HRU Filter</b-button>
				</div>
				<b-collapse v-model="page.showHruSelector" class="mb-3">
					<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange" hide-apply-selector></hru-selector>
				</b-collapse>

				<div v-show="page.showResults">
					<h3 class="mb-4">Matching HRUs</h3>
					<p>
						Check a row in the table below to toggle its selection. You may select up to 20 HRUs.
					</p>

					<b-table ref="hruTable" responsive striped hover :items="hrus" :fields="table.fields" :tbody-tr-class="rowClass">
						<template v-slot:cell(isChecked)="data">
							<b-form-checkbox v-model="selected" :value="data.item.id"></b-form-checkbox>
						</template>
					</b-table>
				</div>

				<fixed-action-bar>
					<div v-if="!page.showResults">
						<span id="save-button-tooltip">
							<save-button :saving="page.saving" :disabled="!validHruSelection" class="mr-2" text="Get Matching HRUs" type="button" @click.native="getHrus" />
						</span>
						<b-tooltip :disabled="validHruSelection" target="save-button-tooltip">
							Select at least one subbasin.
						</b-tooltip>

						<b-button v-if="hrus.length > 0" @click="page.showHruSelector = false; page.showResults = true" variant="secondary" class="mr-2">Hide form</b-button>
					</div>

					<save-button v-if="page.showResults" :saving="page.saving" :disabled="selected.length < 1 || selected.length > 20" class="mr-2" />
					<back-button class="btn btn-secondary mr-2" />

					<div v-if="page.showResults" class="ml-3">({{selected.length}}/20 selected)</div>

					<div v-if="selected.length > 20" class="ml-3 text-danger">
						<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon mr-1 text-warning" />
						Please select only 20 HRUs at most.
					</div>
				</fixed-action-bar>
			</b-form>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to remove your changes and print only the first HRU (default)?
					This action is permanent and cannot be undone.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</page-authorization-container>
	</div>
</template>

<script>
	import HruSelector from '@/components/HruSelector';

	export default {
		name: 'EditHruPrint',
		components: {
			HruSelector
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					showResults: false,
					showHruSelector: true
				},
				hruSelection: {
					applyToAll: true,
					subbasins: [],
					landuse: [],
					soils: [],
					slopes: []
				},
				hasMods: false,
				mods: [],
				hrus: [],
				selected: [],
				table: {
					fields: [
						{ key: 'isChecked', label: '' },
						{ key: 'subbasin', sortable: true },
						{ key: 'landuse', sortable: true },
						{ key: 'soil', sortable: true },
						{ key: 'slopeClass', sortable: true },
						{ key: 'slope', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(5)) } },
						{ key: 'slopeLength', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
						{ key: 'area', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
						{ key: 'fraction', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(5)) } }
					],
					perPage: 50,
					currentPage: 1
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				this.hrus = [];
				this.selected = [];
				this.page.showResults = false;
				this.page.showHruSelector = true;
				this.hruSelection = {
					applyToAll: true,
					subbasins: [],
					landuse: [],
					soils: [],
					slopes: []
				};

				try {
					const response = await this.$http.get(`scenarios/print/hruprint/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.hasMods = response.data.hasMods;
					this.mods = response.data.selected;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getHrus() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					const response = await this.$http.post(`hruselection/matching/${this.projectID}`, this.hruSelection, this.getTokenHeader());
					this.log(response.data);
					this.hrus = response.data;
					this.selectRows(this.hrus);
					this.page.showResults = true;
					this.page.showHruSelector = false;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					var data = {
						applyToAll: true,
						modificationObject: this.selected
					};
					const response = await this.$http.post(`scenarios/savemods/${this.scenarioID}/HruPrint`, data, this.getTokenHeader());
					this.log(response.data);
					await this.get();
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/HruPrint`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			hruSelectionChange(selection) {
				this.hruSelection = selection;
			},
			selectRows(items) {
				this.selected = [];
				for (var i = 0; i < items.slice(0, 20).length; i++) {
					this.selected.push(items[i].id);
				}
				this.log(this.selected);
			},
			rowClass(item, type) {
				if (!item) return
				if (this.selected.includes(item.id)) return 'table-info'
			}
		},
		computed: {
			validHruSelection() {
				return this.hruSelection.applyToAll ||
					(this.hruSelection.subbasins.length > 0);
			}
		}
	}
</script>

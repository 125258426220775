<template>
	<div>
		<div class="bg-gradient-two-blues">
			<b-navbar toggleable="lg" type="dark">
				<b-container>
					<div class="navbar-brand d-flex py-3">
						<div class="acronym mr-3">{{siteText.siteName}}</div>
						<div class="d-none d-md-block">
							<div class="name">{{siteText.siteLongName}}</div>
							<div class="description">{{siteText.siteNameDescription}}</div>
						</div>
					</div>

					<b-navbar-nav class="ml-auto">
						<b-nav-item to="/projects" v-if="isAuthorized(roleNames.registered)">My Projects</b-nav-item>
						<b-nav-item id="login-popover" v-else>Log in</b-nav-item>
						<b-nav-item to="/help">
							<span class="d-none d-md-block">Documentation &amp; Support</span>
							<span class="d-md-none">Support</span>
						</b-nav-item>
					</b-navbar-nav>
				</b-container>
			</b-navbar>

			<b-popover v-if="!isAuthorized(roleNames.registered)" :show.sync="show.login" target="login-popover" placement="bottom" title="Log in">
				<login-form explicit-redirect="/projects"></login-form>

				<p>
					Don't have an account? <a @click.prevent="showRegister" href="#">Register now.</a>
				</p>
			</b-popover>

			<b-modal v-model="show.register" size="lg" title="Register for an account">
				<p v-if="!registration.submitted">
					Accounts are subject to administrator approval. Complete the form below to get started.
				</p>

				<error-list :errors="registration.errors"></error-list>

				<div v-if="registration.submitted">
					<p>
						Your account has been created. Please check your email to confirm your account.
					</p>
					<p>
						Once confirmed, a {{siteText.siteName}} administrator will be notified to approve or deny access.
					</p>
					<p>
						If you do not receive the email after a few minutes, log into {{siteText.siteName}} using the user name and password you just created.
						You will be presented with the option to re-send the confirmation email.
					</p>
				</div>

				<b-form @submit.prevent="register" class="my-4" v-if="!registration.submitted">
					<b-row>
						<b-col md>
							<b-form-group label="First name" :invalid-feedback="requiredFeedback($v.registration.form.firstName)">
								<b-form-input v-model="registration.form.firstName" type="text" autofocus :state="getValidState($v.registration.form.firstName)"></b-form-input>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Last name" :invalid-feedback="requiredFeedback($v.registration.form.lastName)">
								<b-form-input v-model="registration.form.lastName" type="text" :state="getValidState($v.registration.form.lastName)"></b-form-input>
							</b-form-group>
						</b-col>
					</b-row>

					<b-row>
						<b-col md>
							<b-form-group label="Email address">
								<b-form-input v-model="registration.form.email" type="email" :state="getValidState($v.registration.form.email)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.registration.form.email.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.registration.form.email.email">Please enter a valid email address</b-form-invalid-feedback>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Confirm your email">
								<b-form-input v-model="registration.form.compareEmail" type="email" :state="getValidState($v.registration.form.compareEmail)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.registration.form.compareEmail.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.registration.form.compareEmail.email">Please enter a valid email address</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.registration.form.compareEmail.sameAsEmail">Email addresses must match</b-form-invalid-feedback>
							</b-form-group>
						</b-col>
					</b-row>

					<b-form-group label="Company or organization" :invalid-feedback="requiredFeedback($v.registration.form.affiliation)">
						<b-form-input v-model="registration.form.affiliation" type="text" :state="getValidState($v.registration.form.affiliation)"></b-form-input>
					</b-form-group>

					<b-form-group label="Category" :invalid-feedback="requiredFeedback($v.registration.form.organizationCategory)">
						<b-form-select v-model="registration.form.organizationCategory" :options="location.organizationCategories" :state="getValidState($v.registration.form.organizationCategory)">
							<template v-slot:first>
								<option :value="null" disabled>-- Select a category --</option>
							</template>
						</b-form-select>
					</b-form-group>

					<b-form-group :label="`What is your reason for trying ${siteText.siteName}?`" :invalid-feedback="requiredFeedback($v.registration.form.reasonForUse)">
						<b-form-input v-model="registration.form.reasonForUse" type="text" :state="getValidState($v.registration.form.reasonForUse)"></b-form-input>
					</b-form-group>

					<b-form-group label="In what country are you located?" :invalid-feedback="requiredFeedback($v.registration.form.country)">
						<b-form-select v-model="registration.form.country" :options="location.countries" :state="getValidState($v.registration.form.country)">
							<template v-slot:first>
								<option :value="null" disabled>-- Select a country --</option>
							</template>
						</b-form-select>
					</b-form-group>

					<b-form-group v-if="registration.form.country === 'United States'" label="Select your state or territory" :invalid-feedback="requiredFeedback($v.registration.form.state)">
						<b-form-select v-model="registration.form.state" :options="location.states" :state="getValidState($v.registration.form.state)">
							<template v-slot:first>
								<option :value="null" disabled>-- Select a state or territory --</option>
							</template>
						</b-form-select>
					</b-form-group>

					<hr class="my-3" />

					<b-form-group label="Enter a user name" description="Letters, numbers, period, and underscore only. No spaces." :invalid-feedback="requiredFeedback($v.registration.form.userName)">
						<b-form-input v-model="registration.form.userName" type="text" :state="getValidState($v.registration.form.userName)"></b-form-input>
					</b-form-group>
					<b-row>
						<b-col md>
							<b-form-group label="Password" :description="globals.passwordRequirements">
								<b-form-input v-model="registration.form.password" type="password" :state="getValidState($v.registration.form.password)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.registration.form.password.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.registration.form.password.strongPassword">{{globals.passwordRequirements}}</b-form-invalid-feedback>
							</b-form-group>
						</b-col>
						<b-col md>
							<b-form-group label="Confirm your password">
								<b-form-input v-model="registration.form.comparePassword" type="password" :state="getValidState($v.registration.form.comparePassword)"></b-form-input>
								<b-form-invalid-feedback v-if="!$v.registration.form.comparePassword.required">Required</b-form-invalid-feedback>
								<b-form-invalid-feedback v-if="!$v.registration.form.comparePassword.sameAsPassword">Passwords must match</b-form-invalid-feedback>
							</b-form-group>
						</b-col>
					</b-row>
					<vue-recaptcha sitekey="6LfjtrQUAAAAAE4QWk_7aVBpuqZ4LvdCzLETUL6J"
								   ref="recaptcha"
								   @verify="submitCaptcha"
								   @expired="onCaptchaExpired"
								   :loadRecaptchaScript="true">
					</vue-recaptcha>

					<error-list :errors="registration.errors"></error-list>
					<div class="mt-3">
						<save-button :saving="registration.saving" text="Create account" />
					</div>
				</b-form>
				<div slot="modal-footer" class="w-100">
					<p>
						Already have an account? <a @click.prevent="showLogin" href="#">Log in now.</a>
					</p>
				</div>
			</b-modal>
			<b-container>
				<b-row class="pb-5 pt-4">
					<b-col>
						<div class="text-trans lead mb-0" v-html="siteText.homePageIntro"></div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<div class="py-5">
			<b-container>
				<div v-html="siteText.homePageText"></div>
			</b-container>
		</div>
		<div class="py-5 bg-gradient-light-blue">
			<b-container>
				<b-row>
					<b-col class="text-center">
						<h1>
							How does {{siteText.siteName}} work?
						</h1>
						<p class="text-muted" v-if="!isAuthorized(roleNames.registered)">
							<a @click.prevent="showLogin" href="#">Log in</a> or <a @click.prevent="showRegister" href="#">register</a> for an account to get started.
						</p>
						<p class="text-muted" v-else>
							<router-link to="/projects">Visit your projects dashboard</router-link> to get started.
						</p>
					</b-col>
				</b-row>
				<div v-html="siteText.homePageWorkLists"></div>
				
			</b-container>
		</div>
		<footer-data></footer-data>
	</div>
</template>

<script>
	import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators';
	import VueRecaptcha from 'vue-recaptcha';
	import FooterData from '@/components/FooterData';

	//const userNameValid = helpers.regex(/([A-Za-z0-9._]+)/);

	export default {
		name: 'Home',
		components: {
			VueRecaptcha, FooterData
		},
		data() {
			return {
				publicPath: process.env.BASE_URL,
				email: 'eco.web@tamu.edu',
				page: {
					errors: [],
					loading: false
				},
				show: {
					login: false,
					register: false
				},
				registration: {
					form: {
						firstName: '',
						lastName: '',
						email: '',
						compareEmail: '',
						userName: '',
						password: '',
						comparePassword: '',
						country: 'United States',
						state: null,
						affiliation: '',
						organizationCategory: null,
						reasonForUse: ''
					},
					errors: [],
					saving: false,
					submitted: false,
					recaptchaToken: ''
				},
				location: {
					countries: [],
					states: [],
					organizationCategories: []
				}
			}
		},
		validations: {
			registration: {
				form: {
					firstName: { required },
					lastName: { required },
					email: { required, email },
					compareEmail: { required, email, sameAsEmail: sameAs('email') },
					userName: { required },
					password: {
						required,
						strongPassword(password) {
							return (
								/[a-z]/.test(password) && // checks for a-z
								/[0-9]/.test(password) && // checks for 0-9
								/\W|_/.test(password) && // checks for special char
								password.length >= 10
							);
						}
					},
					comparePassword: { required, sameAsPassword: sameAs('password') },
					country: { required },
					state: { required: requiredIf(function () { return this.registration.form.country == 'United States'; }) },
					organizationCategory: { required },
					affiliation: { required },
					reasonForUse: { required }
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('account/locationdata', this.getTokenHeader());
					this.location = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async register() {
				this.registration.errors = [];
				this.registration.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.registration.errors.push('Please fix the errors below and try again.');
				} else {
					var data = {
						firstName: this.registration.form.firstName,
						lastName: this.registration.form.lastName,
						email: this.registration.form.email,
						userName: this.registration.form.userName,
						password: this.registration.form.password,
						country: this.registration.form.country,
						state: this.registration.form.state,
						organizationCategory: this.registration.form.organizationCategory,
						affiliation: this.registration.form.affiliation,
						reasonForUse: this.registration.form.reasonForUse,
						recaptchaToken: this.registration.recaptchaToken
					};

					try {
						await this.$http.post('account/register', data);
						this.registration.submitted = true;
					} catch (error) {
						this.registration.errors = this.logError(error);
					}
				}

				this.registration.saving = false;
			},
			submitCaptcha(recaptchaToken) {
				this.registration.recaptchaToken = recaptchaToken;
			},
			onCaptchaExpired() {
				this.$refs.recaptcha.reset();
			},
			showRegister() {
				this.show.register = true;
				this.show.login = false;
			},
			showLogin() {
				this.show.register = false;
				this.show.login = true;
				this.$scrollTo('#login-popover');
			}
		}
	}
</script>

<template>
	<div>
		<h2>Create a new scenario</h2>
		<scenario-form :data="scenario" show-back-button></scenario-form>
	</div>
</template>

<script>
	import ScenarioForm from '@/views/projects/scenarios/ScenarioForm';

	export default {
		name: 'ScenarioCreate',
		components: {
			ScenarioForm
		},
		data() {
			return {
				scenario: {
					projectID: this.$route.params.id,
					name: 'Default',
					startingSimulationDate: undefined,
					endingSimulationDate: undefined,
					weatherDatasetID: undefined,
					climateChangeScenarioID: undefined,
					climateChangeTimePeriodID: undefined,
					swatVersionID: undefined,
					printSetting: 1,
					skipYears: 2
				}
			}
		}
	}
</script>

<template>
	<b-navbar toggleable="lg" type="dark" variant="primary" fixed="top" id="dash-nav">
		<div class="navbar-brand d-flex">
			<div class="acronym mr-3"><router-link to="/">{{siteText.siteName}}</router-link></div>
			<div class="d-none d-md-block">
				<div class="name">{{siteText.siteLongName}}</div>
				<div class="description">{{siteText.siteNameDescription}}</div>
			</div>
		</div>

		<b-navbar-toggle target="nav_collapse" />
		<b-collapse is-nav id="nav_collapse">
			<b-navbar-nav class="ml-auto">
				<b-nav-item-dropdown text="Advanced" right class="mr-2" v-if="hasFeatureAccess()">
					<b-dropdown-item to="/api/batch" v-if="hasFeatureAccess(featureNames.batchProjects) && isAuthorized(roleNames.apiUser)">Batch project runs</b-dropdown-item>
					<b-dropdown-item to="/api/regional" v-if="hasFeatureAccess(featureNames.regionalProjects)">Regional project runs</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-item-dropdown text="Projects" right class="mr-2" v-if="isAuthorized(roleNames.registered)">
					<b-dropdown-item v-for="(p, i) in recentProjects" :key="i" :to="`/projects/${p.id}`">{{p.name}}</b-dropdown-item>
					<b-dropdown-divider v-if="recentProjects.length > 0"></b-dropdown-divider>
					<b-dropdown-item to="/projects">All projects</b-dropdown-item>
					<b-dropdown-item to="/projects/create">Create a new project</b-dropdown-item>
					<b-dropdown-divider v-if="hasAccessToUploads"></b-dropdown-divider>
					<b-dropdown-item v-if="hasAccessToUploads" to="/datasets">Upload a project</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-item to="/projects" v-if="isAuthorized(roleNames.registered)" v-b-tooltip.hover title="Project dashboard">
					<font-awesome-icon :icon="['fas', 'home']" fixed-width size="lg" />
				</b-nav-item>
				<b-nav-item to="/admin" v-if="isAuthorized(roleNames.admin) || isAuthorized(roleNames.partialAdmin)" v-b-tooltip.hover :title="`${siteText.siteName} administration`">
					<font-awesome-icon :icon="['fas', 'lock']" fixed-width size="lg" />
				</b-nav-item>
				<b-nav-item-dropdown right no-caret class="mr-2" v-if="isAuthorized(roleNames.registered)">
					<template slot="button-content">
						<font-awesome-layers full-width v-b-tooltip.hover title="Notifications">
							<font-awesome-icon :icon="['fas', 'bell']" fixed-width size="lg" />
							<font-awesome-layers-text v-if="notifications.length > 0" class="text-white badge badge-danger" transform="up-8 right-16 shrink-3" :value="notifications.length" />
						</font-awesome-layers>
					</template>
					<b-dropdown-text class="dropdown-max-width" v-if="page.loading"><font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" /></b-dropdown-text>
					<b-dropdown-text class="dropdown-max-width" v-else-if="notifications.length === 0">You have no notifications at this time.</b-dropdown-text>
					<div v-else class="dropdown-max-width">
						<div style="max-height:500px; overflow: auto">
							<div v-for="(n, i) in notifications" :key="i" class="py-2">
								<b-dropdown-form>
									<div class="d-flex justify-content-between align-items-start">
										<div class="px-2">
											<b-form-checkbox v-b-tooltip.hover title="Clear this notification" @change="clearNotification(n.id)"></b-form-checkbox>
										</div>
										<div class="pr-3">
											<h6 class="mb-1">{{n.title}}</h6>
											<div>{{n.details}}</div>
											<div><a href="#" @click.prevent="clearAndGo(n.id, n.url)">{{n.urlText}}</a></div>
											<div class="text-muted"><small>{{n.notificationDate | dateFromNow}}</small></div>
										</div>
									</div>
								</b-dropdown-form>
								<b-dropdown-divider></b-dropdown-divider>
							</div>
						</div>
						<b-dropdown-item-button @click="clearNotifications">Clear all notifications</b-dropdown-item-button>
					</div>
				</b-nav-item-dropdown>
				<b-nav-item-dropdown right no-caret v-if="isAuthorized(roleNames.registered)" v-b-tooltip.hover title="Account and group settings">
					<template slot="button-content">
						<font-awesome-icon :icon="['fas', 'user-circle']" fixed-width size="lg" />
					</template>
					<b-dropdown-text style="width:300px">Welcome, <strong>{{this.user.userName}}</strong>!</b-dropdown-text>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item to="/account">My account</b-dropdown-item>
					<b-dropdown-item to="/groups" v-if="isAuthorized(roleNames.registered)">My groups</b-dropdown-item>
					<b-dropdown-item to="/feedback" v-if="isAuthorized(roleNames.registered)">Feedback and error reports</b-dropdown-item>
					<b-dropdown-item-button @click="logout">Log out</b-dropdown-item-button>
				</b-nav-item-dropdown>
				<b-nav-item to="/help" target="_blank" v-b-tooltip.hover title="Help"><font-awesome-icon :icon="['fas', 'question-circle']" fixed-width size="lg" /></b-nav-item>
			</b-navbar-nav>
		</b-collapse>
	</b-navbar>
</template>

<script>
	export default {
		name: 'DashboardHeader',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				recentProjects: [],
				notifications: [],
				hasAccessToUploads: false
			}
		},
		async created() {
			await this.get();
			this.$statusHub.userOpened(this.user.userName);
			this.$statusHub.$on('user-notification-status-changed', this.onStatusChanged);
		},
		beforeDestroy() {
			this.$statusHub.userClosed(this.user.userName);
			this.$statusHub.$off('user-notification-status-changed', this.onStatusChanged);
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('projects/dashboard', this.getTokenHeader());
					this.recentProjects = response.data.projects;
					this.notifications = response.data.notifications;
					this.hasAccessToUploads = response.data.hasAccessToUploads;
				} catch (error) {
					this.page.errors = this.logError(error);
					this.recentProjects = [];
				}

				this.page.loading = false;
			},
			async clearNotifications() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.delete('account/notifications', this.getTokenHeader());
					await this.get();
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async clearNotification(id) {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.delete(`account/notification/${id}`, this.getTokenHeader());
					await this.get();
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async clearAndGo(id, url) {
				await this.clearNotification(id);
				this.$router.push(url);
			},
			async getNotification(id) {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`account/notification/${id}`, this.getTokenHeader());
					if (response.data !== null) this.notifications.unshift(response.data);
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async onStatusChanged(data) {
				if (this.user.userName != data.userName) return;
				if (data.notificationID) {
					await this.getNotification(data.notificationID);
				}
			}
		}
	}
</script>

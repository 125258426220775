<template>
	<div id="app">
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<router-view />
		</div>
	</div>
</template>

<style lang="scss">
	@import 'app.scss';
</style>

<script>
	export default {
		name: 'app',
		data() {
			return {
				page: {
					loading: true,
					errors: []
				}
			}
		},
		async created() {
			await this.getWebsiteText();
			await this.checkAuth();
		},
		methods: {
			async checkAuth() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('auth/check', this.getTokenHeader());
					this.$store.commit('login', {
						token: localStorage.getItem('auth_token'),
						user: response.data
					});
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getWebsiteText() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get('datasets/websitetext');
					this.$store.commit('setupSite', {
						siteText: response.data.siteText,
						siteSettings: response.data.siteSettings
					});

					if (!this.isNullOrEmpty(response.data.siteText.siteName)) {
						document.title = `${response.data.siteText.siteName} | ${response.data.siteText.siteLongName}`;
					}
				} catch (error) {
					this.page.errors = this.logError(error);
				}

				//this.page.loading = false;
			}
		}
	}
</script>

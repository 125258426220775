<template>
	<div>
		<dashboard-header></dashboard-header>

		<div id="content-frame">
			<div id="main-panel">
				<page-loading :loading="page.loading"></page-loading>
				<div v-if="!page.loading" class="max-w-400 mx-auto py-4">
					<error-list :errors="page.errors"></error-list>

					<div v-if="page.success">
						<b-alert show variant="info">
							We verified your account from version 1 and sent an email to the address on file.
							The email will contain a link with instructions on how to proceed.
							If you do not receive an email within a few hours, check your junk folders, then please contact support if you cannot find it. 
						</b-alert>
					</div>
					<div v-else>
						<p>
							If you were a user from version 1 of HAWQS, enter either your user name or
							email address for your account below. If we find your account, we'll send an email to
							the address on file. 
						</p>
						<p>
							After your email is confirmed, you will set up your account in the new version of HAWQS
							and import your projects from version 1.
						</p>

						<b-form @submit.prevent="checkUser" class="my-4">
							<b-form-group label="HAWQS version 1 user name">
								<b-form-input v-model="form.userName" type="text"></b-form-input>
							</b-form-group>

							<b-form-group label="Or, enter your email address">
								<b-form-input v-model="form.email" type="email"></b-form-input>
							</b-form-group>

							<div class="mt-4">
								<save-button :saving="page.saving" text="Find my account" size="lg" />
							</div>
						</b-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DashboardHeader from '@/components/DashboardHeader';
	export default {
		name: 'Version1CheckUser',
		components: {
			DashboardHeader
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					saving: false,
					success: false
				},
				form: {
					userName: null,
					email: null
				}
			}
		},
		methods: {
			async checkUser() {
				this.page.errors = [];
				this.page.saving = true;
				this.page.success = false;

				if (this.isNullOrEmpty(this.form.userName) && this.isNullOrEmpty(this.form.email)) {
					this.page.errors.push('Please enter either your user name or email address from HAWQS version 1.');
				} else {
					try {
						await this.$http.post('version1/checkuser', this.form);
						this.page.success = true;
					} catch (error) {
						this.page.errors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">General management</h2>

			<p>
				Read the <a href="/content/docs/io/ch20_input_mgt.pdf" target="_blank">SWAT2012 IO documentation chapter on MGT inputs</a> for more information about management variables.
			</p>

			<div v-if="!page.showHruSelector" class="mb-4">
				<p class="mb-2">
					<strong>Selected HRU:</strong> {{initialHru.subbasin.name}} / {{initialHru.landuse}} / {{initialHru.soil}} / {{initialHru.slope}}
				</p>
				<b-button @click="page.showHruSelector = !page.showHruSelector">Select another HRU</b-button>
			</div>
			<b-collapse v-model="page.showHruSelector" class="mb-3">
				<p>
					<strong>Select an HRU to view management data and make changes. Changes may be extended to other HRUs in your project.</strong>
				</p>

				<single-hru-selector :projectID="Number($route.params.id)" @change="initialHruChange">
					<p v-if="validInitialHru">
						<save-button type="button" :saving="page.loadingData" @click.native="getMgt" text="Get data" class="mr-2" />
						<b-button @click="page.showHruSelector = !page.showHruSelector" v-if="page.showData" variant="secondary">Hide form</b-button>
					</p>
				</single-hru-selector>
			</b-collapse>

			<error-list :errors="page.loadingErrors"></error-list>

			<b-form @submit.prevent="save">
				<div v-if="page.showData">
					<error-list :errors="page.saveErrors"></error-list>
					<success-alert ref="savedAlert" text="Changes saved." />

					<div class="table-responsive mb-4">
						<table class="table table-valign-m table-form border-bottom">
							<thead>
								<tr class="thead">
									<th>Input Variable</th>
									<th>Value</th>
									<th>Default</th>
								</tr>
							</thead>
							<tbody>
								<tr class="bg-light">
									<th colspan="3">Initial Plant Growth</th>
								</tr>
								<tr>
									<td>
										<label for="PLANT_ID">Initial land cover</label>
									</td>
									<td>
										<b-form-select id="PLANT_ID" v-model.trim="$v.form.item.PLANT_ID.$model" :options="form.plantList" :state="getValidState($v.form.item.PLANT_ID)">
											<template v-slot:first>
												<option :value="0">No crop growing</option>
											</template>
										</b-form-select>
									</td>
									<td>{{ defaultPlantName }}</td>
								</tr>
								<tr v-if="form.item.PLANT_ID > 0">
									<td>
										<label for="LAI_INIT">LAI_INIT: Initial leaf area index</label>
									</td>
									<td>
										<b-form-input id="LAI_INIT" v-model.trim="$v.form.item.LAI_INIT.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.LAI_INIT)"></b-form-input>
									</td>
									<td>{{ form.defaults.LAI_INIT }}</td>
								</tr>
								<tr v-if="form.item.PLANT_ID > 0">
									<td>
										<label for="BIO_INIT">BIO_INIT: Initial dry weight biomass</label>
									</td>
									<td>
										<b-form-input id="BIO_INIT" v-model.trim="$v.form.item.BIO_INIT.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.BIO_INIT)"></b-form-input>
										<span>kg/ha</span>
									</td>
									<td>{{ form.defaults.BIO_INIT }}</td>
								</tr>
								<tr v-if="form.item.PLANT_ID > 0">
									<td>
										<label for="PHU_PLT">PHU_PLT: Number of heat units to bring plant to maturity</label>
									</td>
									<td>
										<b-form-input id="PHU_PLT" v-model.trim="$v.form.item.PHU_PLT.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.PHU_PLT)"></b-form-input>
									</td>
									<td>{{ form.defaults.PHU_PLT }}</td>
								</tr>

								<tr class="bg-light">
									<th colspan="3">General Management</th>
								</tr>
								<tr>
									<td>
										<label for="BIOMIX">BIOMIX: Biological mixing efficiency</label>
									</td>
									<td>
										<b-form-input id="BIOMIX" v-model.trim="$v.form.item.BIOMIX.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.BIOMIX)"></b-form-input>
									</td>
									<td>{{ form.defaults.BIOMIX }}</td>
								</tr>
								<tr>
									<td>
										<span v-b-tooltip.hover.bottom title="Note: any adjustments to CN2 here will take precedence over changes made through the edit subbasin inputs -> curve numbers section." class="mr-1">
											<font-awesome-icon icon="info-circle" class="text-info" />
										</span>
										<label for="CN2">CN2: Initial SCS runoff curve number for moisture condition II</label>
									</td>
									<td>
										<b-form-select id="CurveNumberEditMethod" v-model.trim="$v.form.item.CurveNumberEditMethod.$model" :options="form.cnEditOptions" :state="getValidState($v.form.item.CurveNumberEditMethod)"></b-form-select>

										<div v-if="form.item.CurveNumberEditMethod === 1" class="mt-2">
											<b-form-input id="CN2" v-model.trim="$v.form.item.CN2.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.CN2)"></b-form-input>
										</div>
										<div v-if="form.item.CurveNumberEditMethod === 2" class="my-4 d-flex align-items-center">
											<b-form-input v-model="cn2PercentAdjust" type="range" style="width:200px" :min="-10" :max="10"></b-form-input>
											<span>{{cn2PercentAdjust}}%</span>
										</div>
									</td>
									<td>
										<span v-b-tooltip.hover.bottom title="Note: the default CN2 shown here is adjusted based on the selected initial or urban land cover, if any" class="ml-1">
											<font-awesome-icon icon="info-circle" class="text-info" />
										</span>
										{{ selectedDefaultCn2 | number(3) }}
									</td>
								</tr>
								<tr>
									<td>
										<label for="USLE_P">USLE_P: USLE equation support practice factor</label>
									</td>
									<td>
										<b-form-input id="USLE_P" v-model.trim="$v.form.item.USLE_P.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.USLE_P)"></b-form-input>
									</td>
									<td>{{ form.defaults.USLE_P }}</td>
								</tr>
								<tr>
									<td>
										<label for="BIO_MIN">BIO_MIN: Minimum biomass for grazing</label>
									</td>
									<td>
										<b-form-input id="BIO_MIN" v-model.trim="$v.form.item.BIO_MIN.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.BIO_MIN)"></b-form-input>
										<span>kg/ha</span>
									</td>
									<td>{{ form.defaults.BIO_MIN }}</td>
								</tr>
								<tr>
									<td>
										<label for="FILTERW">FILTERW: Width of edge-of-field filter strip</label>
									</td>
									<td>
										<b-form-input id="FILTERW" v-model.trim="$v.form.item.FILTERW.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.FILTERW)"></b-form-input>
										<span>m</span>
									</td>
									<td>{{ form.defaults.FILTERW }}</td>
								</tr>

								<tr class="bg-light">
									<th colspan="3">Urban Management</th>
								</tr>
								<tr>
									<td>
										<label for="URBLU">Urban land cover</label>
									</td>
									<td>
										<b-form-select id="URBLU" v-model.trim="$v.form.item.URBLU.$model" :options="form.urbanList" :state="getValidState($v.form.item.URBLU)">
											<template v-slot:first>
												<option :value="0">No urban land use</option>
											</template>
										</b-form-select>
									</td>
									<td>{{ defaultUrbanName }}</td>
								</tr>
								<tr v-if="form.item.URBLU > 0">
									<td>
										<label for="IURBAN">Urban simulation method</label>
									</td>
									<td>
										<b-form-select id="IURBAN" v-model.trim="$v.form.item.IURBAN.$model" :options="form.iurbanList" :state="getValidState($v.form.item.IURBAN)"></b-form-select>
									</td>
									<td>{{ form.defaults.IURBAN }}</td>
								</tr>

								<tr class="bg-light">
									<th colspan="3">Irrigation Management</th>
								</tr>
								<tr>
									<td>
										<label for="IRRSC">Irrigation source</label>
									</td>
									<td>
										<b-form-select id="IRRSC" v-model.trim="$v.form.item.IRRSC.$model" :options="form.irrscList" :state="getValidState($v.form.item.IRRSC)"></b-form-select>
									</td>
									<td>{{ form.defaults.IRRSC }}</td>
								</tr>
								<tr v-if="form.item.IRRSC != 5 && form.item.IRRSC != 0">
									<td colspan="2">
										<single-hru-selector :projectID="Number($route.params.id)" @change="setIRRNO" subbasin-only :initSubID="form.item.IRRNO"></single-hru-selector>
									</td>
									<td></td>
								</tr>
								<tr v-if="form.item.IRRSC != 5 && form.item.IRRSC > 0">
									<td>
										<label for="FLOWMIN">FLOWMIN: Minimum in-stream flow for irr diversions</label>
									</td>
									<td>
										<b-form-input id="FLOWMIN" v-model.trim="$v.form.item.FLOWMIN.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.FLOWMIN)"></b-form-input>
										<span>m<sup>3</sup>/s</span>
									</td>
									<td>{{ form.defaults.FLOWMIN }}</td>
								</tr>
								<tr v-if="form.item.IRRSC != 5 && form.item.IRRSC > 0">
									<td>
										<label for="DIVMAX">DIVMAX: Maximum irrigation diversion from reach</label>
									</td>
									<td>
										<b-form-input id="DIVMAX" v-model.trim="$v.form.item.DIVMAX.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.DIVMAX)"></b-form-input>
										<span>+mm/-10<sup>4</sup>m<sup>3</sup></span>
									</td>
									<td>{{ form.defaults.DIVMAX }}</td>
								</tr>
								<tr v-if="form.item.IRRSC != 5 && form.item.IRRSC > 0">
									<td>
										<label for="FLOWFR">FLOWFR: Fraction of flow allowed to be pulled for irrigation</label>
									</td>
									<td>
										<b-form-input id="FLOWFR" v-model.trim="$v.form.item.FLOWFR.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.FLOWFR)"></b-form-input>
									</td>
									<td>{{ form.defaults.FLOWFR }}</td>
								</tr>

								<tr class="bg-light">
									<th colspan="3">Tile Drain Management</th>
								</tr>
								<tr>
									<td>
										<label for="DDRAIN">DDRAIN: Depth to subsurface tile drain</label>
									</td>
									<td>
										<b-form-input id="DDRAIN" v-model.trim="$v.form.item.DDRAIN.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.DDRAIN)"></b-form-input>
										<span>mm</span>
									</td>
									<td>{{ form.defaults.DDRAIN }}</td>
								</tr>
								<tr>
									<td>
										<label for="TDRAIN">TDRAIN: Time to drain soil to field capacity</label>
									</td>
									<td>
										<b-form-input id="TDRAIN" v-model.trim="$v.form.item.TDRAIN.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.TDRAIN)"></b-form-input>
										<span>hr</span>
									</td>
									<td>{{ form.defaults.TDRAIN }}</td>
								</tr>
								<tr>
									<td>
										<label for="GDRAIN">GDRAIN: Drain tile lag time</label>
									</td>
									<td>
										<b-form-input id="GDRAIN" v-model.trim="$v.form.item.GDRAIN.$model" class="max-w-150" type="number" step="any" :state="getValidState($v.form.item.GDRAIN)"></b-form-input>
										<span>hr</span>
									</td>
									<td>{{ form.defaults.GDRAIN }}</td>
								</tr>
							</tbody>
						</table>
					</div>

					<h3 class="mt-5 mb-4">Apply your changes</h3>
					<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange" :initial-selection="initialSelection"></hru-selector>
				</div>

				<scenario-mod-viewer v-if="mods.length > 0" @deleted="modDeleted" :data="mods" value-label="Values" value-is-object></scenario-mod-viewer>

				<fixed-action-bar>
					<span id="save-button-tooltip">
						<save-button :saving="page.saving" :disabled="!validHruSelection || !page.showData" class="mr-2" />
					</span>
					<b-tooltip :disabled="validHruSelection" target="save-button-tooltip">
						Select at least one subbasin, land use, soil, and slope.
					</b-tooltip>
					<back-button class="btn btn-secondary mr-2" />

					<save-button v-if="mods.length > 0" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete all general management data changes?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, requiredIf } from 'vuelidate/lib/validators';
	import SingleHruSelector from '@/components/SingleHruSelector';
	import HruSelector from '@/components/HruSelector';
	import ScenarioModViewer from '@/components/ScenarioModViewer';

	export default {
		name: 'EditMgtGeneral',
		components: {
			SingleHruSelector, HruSelector, ScenarioModViewer
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					loadingErrors: [],
					loadingData: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					showData: false,
					showHruSelector: true,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				form: {
					cnEditOptions: [],
					irrscList: [],
					iurbanList: [],
					plantList: [],
					urbanList: [],
					defaults: {},
					item: {}
				},
				cn2PercentAdjust: 0,
				initialHru: {
					subbasin: null,
					landuse: null,
					soil: null,
					slope: null
				},
				hruSelection: {
					applyToAll: true,
					subbasins: [],
					landuse: [],
					soils: [],
					slopes: []
				},
				mods: []
			}
		},
		validations: {
			form: {
				item: {
					IGRO: { required },
					PLANT_ID: { required },
					LAI_INIT: { required: requiredIf(function(){ return this.form.item.PLANT_ID > 0; }) },
					BIO_INIT: { required: requiredIf(function(){ return this.form.item.PLANT_ID > 0; }) },
					PHU_PLT: { required: requiredIf(function(){ return this.form.item.PLANT_ID > 0; }) },
					BIOMIX: { required },
					CN2: { required },
					USLE_P: { required },
					BIO_MIN: { required },
					FILTERW: { required },
					IURBAN: { required },
					URBLU: { required },
					IRRSC: { required },
					IRRNO: { required },
					FLOWMIN: { required },
					DIVMAX: { required },
					FLOWFR: { required },
					DDRAIN: { required },
					TDRAIN: { required },
					GDRAIN: { required },
					NROT: { required },
					HUSC: { required },
					ISCROP: { required },
					CN_SLOPE: { required },
					HYD_GRP: { },
					CurveNumberEditMethod: { required }
				}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/getmods/${this.scenarioID}/Mgt1`, this.getTokenHeader());
					this.log(response.data);
					this.mods = response.data.mods;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async getMgt() {
				this.page.loadingErrors = [];
				this.page.loadingData = true;
				this.page.showData = false;

				if (!this.validInitialHru) {
					this.page.loadingErrors.push('Please select an HRU and try again.');
				} else {
					try {
						const response = await this.$http.get(`scenarios/mgt/general/${this.scenarioID}/${this.initialHru.subbasin.name}/${this.initialHru.landuse}/${this.initialHru.soil}/${this.initialHru.slope}`, this.getTokenHeader());
						this.log(response.data);
						this.form = response.data;
						this.page.showData = true;
						this.page.showHruSelector = false;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.loadingErrors = this.logError(error);
					}
				}

				this.page.loadingData = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
					this.log(this.$v);
				} else {
					try {
						var item = {};
						var keys = Object.keys(this.form.item);
						for (var i = 0; i < keys.length; i++) {
							if (this.$v.form.item[keys[i]] && (this.$v.form.item[keys[i]].$dirty || this.form.item[keys[i]] != this.form.defaults[keys[i]])) {
								if (keys[i] === 'CN2') {
									if (this.form.item.CurveNumberEditMethod === 1)
										item[keys[i]] = Number(this.form.item[keys[i]]);
									else if (this.form.item.CurveNumberEditMethod === 2)
										item['CN2Adjust'] = Number(this.cn2PercentAdjust);
								} else {
									item[keys[i]] = Number(this.form.item[keys[i]]);
								}
							}
						}

						var data = {
							applyToAll: this.hruSelection.applyToAll,
							subbasins: this.hruSelection.subbasins,
							landuses: this.hruSelection.landuse,
							soils: this.hruSelection.soils,
							slopes: this.hruSelection.slopes,
							modificationObject: item
						};
						this.log(data);
						const response = await this.$http.post(`scenarios/savemods/${this.scenarioID}/Mgt1`, data, this.getTokenHeader());
						this.log(response.data);
						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/Mgt1`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			initialHruChange(selection) {
				this.initialHru = selection;
			},
			hruSelectionChange(selection) {
				this.hruSelection = selection;
			},
			setIRRNO(selection) {
				if (selection.subbasin !== null)
					this.form.item.IRRNO = selection.subbasin.id;
			},
			findInList(list, value, propToReturn='text') {
				if (value === 0) return 'None';
				var filtered = list.filter(function (el) { return el.value === value; });
				if (filtered.length > 0) {
					return filtered[0][propToReturn];
				}
				return value;
			}
		},
		computed: {
			validInitialHru() {
				return this.initialHru.subbasin !== null &&
						this.initialHru.landuse !== null &&
						this.initialHru.soil !== null &&
						this.initialHru.slope !== null;
			},
			validHruSelection() {
				return this.hruSelection.applyToAll ||
					(this.hruSelection.subbasins.length > 0 &&
						this.hruSelection.landuse.length > 0 &&
						this.hruSelection.soils.length > 0 &&
						this.hruSelection.slopes.length > 0);
			},
			defaultPlantName() {
				return this.findInList(this.form.plantList, this.form.defaults.PLANT_ID);
			},
			defaultUrbanName() {
				return this.findInList(this.form.urbanList, this.form.defaults.URBLU);
			},
			selectedDefaultCn2() {
				if (this.form.item.PLANT_ID > 0) {
					return this.findInList(this.form.plantList, this.form.item.PLANT_ID, 'cn2');
				} else if (this.form.item.URBLU > 0) {
					return this.findInList(this.form.urbanList, this.form.item.URBLU, 'cn2');
				}

				return this.form.defaults.CN2;
			},
			initialSelection() {
				return {
					subbasins: [this.initialHru.subbasin],
					landuse: [this.initialHru.landuse],
					soils: [this.initialHru.soil],
					slopes: [this.initialHru.slope]
				}
			}
		}
	}
</script>

const signalR = require("@microsoft/signalr");

export default {
	install(Vue) {
		const connection = new signalR.HubConnectionBuilder()
			.withUrl('/status-hub')
			.configureLogging(signalR.LogLevel.Debug)
			.build();

		const statusHub = new Vue();

		// Forward hub events through the event, so we can listen for them in the Vue components
		connection.on('CreateProjectStatusChange', (projectID, progress, statusMessage) => {
			statusHub.$emit('create-project-status-changed', { projectID, progress, statusMessage })
		});
		connection.on('SetHrusStatusChange', (projectID, progress, statusMessage) => {
			statusHub.$emit('set-hrus-status-changed', { projectID, progress, statusMessage })
		});
		connection.on('ZipProjectStatusChange', (projectID, progress, statusMessage) => {
			statusHub.$emit('zip-project-status-changed', { projectID, progress, statusMessage })
		});
		connection.on('CopyProjectStatusChange', (projectID, progress, statusMessage) => {
			statusHub.$emit('copy-project-status-changed', { projectID, progress, statusMessage })
		});
		connection.on('ScenarioStatusChange', (scenarioID, progress, statusMessage) => {
			statusHub.$emit('scenario-status-changed', { scenarioID, progress, statusMessage })
		});
		connection.on('UserNotificationStatusChange', (userName, notificationID) => {
			statusHub.$emit('user-notification-status-changed', { userName, notificationID })
		});
		connection.on('AdminReportStatusChange', (reportIDs) => {
			statusHub.$emit('admin-report-status-change', { reportIDs })
		});
		connection.on('ImportDatasetStatusChange', (importID, progress, statusMessage) => {
			statusHub.$emit('import-dataset-status-changed', { importID, progress, statusMessage })
		});
		connection.on('ImportHucVersion2StatusChange', (importID, progress, statusMessage) => {
			statusHub.$emit('import-huc-version2-status-changed', { importID, progress, statusMessage })
		});
		connection.on('CustomOutputRequestStatusChange', (requestIDs) => {
			statusHub.$emit('custom-output-request-status-changed', { requestIDs })
		});
		connection.on('BatchStatusChange', (requestID, progress, statusMessage) => {
			statusHub.$emit('batch-status-changed', { requestID, progress, statusMessage })
		});
		connection.on('ApiRequestStatusChange', (requestID, progress, statusMessage) => {
			statusHub.$emit('api-request-status-changed', { requestID, progress, statusMessage })
		});

		// Provide methods for components to send messages back to server
		// Make sure no invocation happens until the connection is established
		statusHub.projectOpened = (projectID) => {
			return startedPromise
				.then(() => connection.invoke('JoinProjectGroup', Number(projectID)))
				.catch(console.error);
		}
		statusHub.projectClosed = (projectID) => {
			return startedPromise
				.then(() => connection.invoke('LeaveProjectGroup', Number(projectID)))
				.catch(console.error);
		}

		statusHub.scenarioOpened = (scenarioID) => {
			return startedPromise
				.then(() => connection.invoke('JoinScenarioGroup', Number(scenarioID)))
				.catch(console.error);
		}
		statusHub.scenarioClosed = (scenarioID) => {
			return startedPromise
				.then(() => connection.invoke('LeaveScenarioGroup', Number(scenarioID)))
				.catch(console.error);
		}

		statusHub.adminReportOpened = () => {
			return startedPromise
				.then(() => connection.invoke('JoinAdminReportGroup'))
				.catch(console.error);
		}
		statusHub.adminReportClosed = () => {
			return startedPromise
				.then(() => connection.invoke('LeaveAdminReportGroup'))
				.catch(console.error);
		}

		statusHub.importDatasetOpened = (importID) => {
			return startedPromise
				.then(() => connection.invoke('JoinImportDatasetGroup', Number(importID)))
				.catch(console.error);
		}
		statusHub.importDatasetClosed = (importID) => {
			return startedPromise
				.then(() => connection.invoke('LeaveImportDatasetGroup', Number(importID)))
				.catch(console.error);
		}

		statusHub.userOpened = (userName) => {
			return startedPromise
				.then(() => connection.invoke('JoinUserGroup', userName))
				.catch(console.error);
		}
		statusHub.userClosed = (userName) => {
			return startedPromise
				.then(() => connection.invoke('LeaveUserGroup', userName))
				.catch(console.error);
		}

		statusHub.importHucVersion2Opened = () => {
			return startedPromise
				.then(() => connection.invoke('JoinHucVersion2ImportGroup'))
				.catch(console.error);
		}
		statusHub.importHucVersion2Closed = () => {
			return startedPromise
				.then(() => connection.invoke('LeaveHucVersion2ImportGroup'))
				.catch(console.error);
		}

		statusHub.customOutputRequestOpened = (scenarioID, requestType) => {
			return startedPromise
				.then(() => connection.invoke('JoinCustomOutputRequestGroup', Number(scenarioID), requestType))
				.catch(console.error);
		}
		statusHub.customOutputRequestClosed = (scenarioID, requestType) => {
			return startedPromise
				.then(() => connection.invoke('LeaveCustomOutputRequestGroup', Number(scenarioID), requestType))
				.catch(console.error);
		}

		statusHub.batchOpened = (requestID) => {
			return startedPromise
				.then(() => connection.invoke('JoinBatchGroup', Number(requestID)))
				.catch(console.error);
		}
		statusHub.batchClosed = (requestID) => {
			return startedPromise
				.then(() => connection.invoke('LeaveBatchGroup', Number(requestID)))
				.catch(console.error);
		}

		statusHub.apiRequestOpened = (requestID) => {
			return startedPromise
				.then(() => connection.invoke('JoinApiRequestGroup', Number(requestID)))
				.catch(console.error);
		}
		statusHub.apiRequestClosed = (requestID) => {
			return startedPromise
				.then(() => connection.invoke('LeaveApiRequestGroup', Number(requestID)))
				.catch(console.error);
		}

		Vue.prototype.$statusHub = statusHub;

		// You need to call connection.start() to establish the connection
		// the client wont handle reconnecting for you! Docs recommend listening onclose
		// and handling it there. This is the simplest of the strategies
		let startedPromise = null;
		function start() {
			startedPromise = connection.start()
				.catch(err => {
					console.error('Failed to connect with hub', err)
					return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
				});
			return startedPromise;
		}
		connection.onclose(() => start());

		start();
	}
}

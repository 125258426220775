<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">{{group.name}}</h2>

			<p>
				{{group.description}}
			</p>

			<h3 class="mt-4 mb-3">Group Projects</h3>

			<grid-view :api-url="`groups/projects/${groupID}`"
					   :fields="tables.projects.fields"
					   :default-sort="tables.projects.sort" :default-reverse="tables.projects.reverse" :default-per-page="tables.itemsPerPage"
					   collection-description="group projects"
					   item-name="project"></grid-view>

			<h3 class="mt-4 mb-3" id="membersTop">Group Members</h3>

			<div v-if="selectedMember !== null && group.isAdmin">
				<b-alert variant="info" show>
					<p>
						You have selected member
						<strong>{{selectedMember.firstName}} {{selectedMember.lastName}} ({{selectedMember.userName}})</strong>.
					</p>
					<div v-if="selectedMember.isOwner">
						<em>This user is the owner of the group and cannot be removed.</em>
					</div>
					<div v-else>
						<save-button type="button" variant="primary" class="mr-2" :saving="page.setAdmin.saving"
									 @click.native="setAdmin(selectedMember.userName, !selectedMember.isAdmin)"
									 :text="selectedMember.isAdmin ? 'Revoke Admin Rights' : 'Make Group Admin'" />
						<b-button variant="danger" type="button" @click="askRevokeAccess(selectedMember.userName)">
							Revoke Group Membership
						</b-button>
					</div>
				</b-alert>
			</div>

			<p>
				Click a row on the table below to set administrative rights for the user or remove them from the group.
				Users with administrative rights to the group can edit group settings and members.
			</p>

			<grid-view :api-url="`groups/members/${groupID}`"
					   :fields="tables.members.fields"
					   :selectable="group.isAdmin" @selectionChanged="memberSelectionChanged"
					   :default-sort="tables.members.sort" :default-reverse="tables.members.reverse" :default-per-page="tables.itemsPerPage"
					   collection-description="group members"
					   item-name="member"></grid-view>

			<div v-if="group.invitedUsers.length > 0">
				<h3 class="mt-4 mb-3">Pending Invitations</h3>

				<b-table striped responsive class="nowrap-headers mb-3 border-bottom"
						 :items="group.invitedUsers" :fields="tables.invites.fields">
					<template v-slot:cell(delete)="data">
						<div v-if="group.isAdmin">
							<button class="plain text-danger" v-b-tooltip.hover.right="'Delete'" @click="askDeleteInvite(data.item.id)">
								<font-awesome-icon :icon="['fas', 'times']" />
							</button>
						</div>
						<div v-else></div>
					</template>
				</b-table>
			</div>

			<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
				<b-button v-if="group.isAdmin" type="button" variant="success" @click="page.invite.show = true" class="mr-2">Invite Members</b-button>
				<b-button v-if="group.isAdmin" type="button" variant="primary" @click="page.edit.show = true" class="mr-2">Edit Settings</b-button>
				<back-button class="btn btn-secondary mr-2" />
				<b-button v-if="group.isOwner" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
			</fixed-action-bar>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this group?
					No projects or files will be deleted. Access will return to just the creator.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.deleteInvite.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.deleteInvite.errors"></error-list>

				<p>
					Are you sure you want to delete this invitation?
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.deleteInvite.saving" @click.native="confirmDeleteInvite" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.deleteInvite.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.revokeAccess.show" size="md" title="Confirm removal from group" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.revokeAccess.errors"></error-list>

				<p>
					Are you sure you want to revoke group membership for <strong>{{page.revokeAccess.userName}}</strong>?
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.revokeAccess.saving" @click.native="confirmRevokeAccess" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.revokeAccess.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.invite.show" title="Invite a member to this group">
				<p>
					Invite a new member to this group.
					All users in the group may view, edit*, and run* projects shared with the group.
					(*Note: tier limits still apply.)
				</p>
				<p>
					For security reasons, in order to invite a user you must have the email address associated with
					their {{group.siteName}} account.
				</p>

				<error-list :errors="page.invite.errors"></error-list>

				<b-alert :show="page.invite.success" variant="success">
					Invitation sent!
					Enter another email address to send another invitation or
					<a href="#" @click.prevent="page.invite.show = false">close this window</a>.
				</b-alert>

				<b-form-group :label="`What is the user's ${group.siteName} email address?`">
					<b-form-input v-model="page.invite.data.name" type="email" required></b-form-input>
				</b-form-group>

				<b-form-group label="Message to user (optional)">
					<b-form-textarea v-model="page.invite.data.description" rows="4"></b-form-textarea>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.invite.saving" @click.native="sendInvite" text="Invite User" variant="success" />
					<b-button type="button" variant="secondary" @click="page.invite.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.edit.show" title="Edit group settings">
				<error-list :errors="page.edit.errors"></error-list>

				<b-form-group label="Group name">
					<b-form-input v-model="page.edit.data.name" type="email" required></b-form-input>
				</b-form-group>

				<b-form-group label="Describe the purpose of this group">
					<b-form-textarea v-model="page.edit.data.description" rows="4"></b-form-textarea>
				</b-form-group>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.edit.saving" @click.native="edit" />
					<b-button type="button" variant="secondary" @click="page.edit.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</page-authorization-container>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'GroupView',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					deleteInvite: {
						show: false,
						id: undefined,
						errors: [],
						saving: false
					},
					setAdmin: {
						errors: [],
						saving: false
					},
					revokeAccess: {
						show: false,
						errors: [],
						saving: false,
						userName: null
					},
					invite: {
						show: false,
						errors: [],
						saving: false,
						data: {
							name: null,
							description: null
						}
					},
					edit: {
						show: false,
						errors: [],
						saving: false,
						data: {
							name: null,
							description: null
						}
					}
				},
				groupID: this.$route.params.id,
				group: {
					invitedUsers: []
				},
				tables: {
					projects: {
						fields: [
							{ key: 'project', sortable: true, class: 'min-w-200' },
							{ key: 'userName', label: 'Belongs to', sortable: true },
							{ key: 'lastModifiedDate', sortable: true, class: 'nowrap' },
							{ key: 'dataset', sortable: true },
							{ key: 'endingSubbasin', label: 'Downstream ID', sortable: true },
							{ key: 'numSubbasins', label: 'Subbasins', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
							{ key: 'numHrus', label: 'HRUs', sortable: false, formatter: (value) => { return this.numberWithCommas(value) } },
							{ key: 'totalArea', label: 'Area (sq. km.)', sortable: false, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
							{ key: 'numScenarios', label: 'Scenarios', sortable: false }
						],
						sort: 'lastModifiedDate',
						reverse: true
					},
					members: {
						fields: [
							{ key: 'userName', sortable: true },
							{ key: 'email', sortable: true },
							{ key: 'firstName', sortable: true },
							{ key: 'lastName', sortable: true },
							{ key: 'affiliation', sortable: true },
							{ key: 'isAdmin', label: 'Group Admin?', sortable: false, formatter: (value) => { return value === true ? 'Yes' : 'No' } }
						],
						sort: 'userName',
						reverse: false
					},
					invites: {
						fields: [
							{ key: 'userName', sortable: true },
							{ key: 'email', sortable: true },
							{ key: 'firstName', sortable: true },
							{ key: 'lastName', sortable: true },
							{ key: 'affiliation', sortable: true },
							{ key: 'delete', label: '' }
						],
						sort: 'userName',
						reverse: false
					},
					itemsPerPage: 10
				},
				selectedMember: null
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.groupID = this.$route.params.id;
				this.page.errors = [];
				this.page.loading = true;
				this.selectedMember = null;

				try {
					const response = await this.$http.get(`groups/${this.groupID}`, this.getTokenHeader());
					this.group = response.data;
					this.page.edit.data.name = this.group.name;
					this.page.edit.data.description = this.group.description;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			askDeleteInvite(id) {
				this.page.deleteInvite.id = id;
				this.page.deleteInvite.show = true;
			},
			async confirmDeleteInvite() {
				this.page.deleteInvite.errors = [];
				this.page.deleteInvite.saving = true;

				try {
					const response = await this.$http.delete(`groups/invite/${this.page.deleteInvite.id}`, this.getTokenHeader());
					this.page.deleteInvite.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.deleteInvite.errors = this.logError(error);
				}

				this.page.deleteInvite.saving = false;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`groups/${this.groupID}`, this.getTokenHeader());
					this.$router.push({ name: 'GroupList' }).catch(err => {});
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			askRevokeAccess(userName) {
				this.page.revokeAccess.userName = userName;
				this.page.revokeAccess.show = true;
			},
			async confirmRevokeAccess() {
				this.page.revokeAccess.errors = [];
				this.page.revokeAccess.saving = true;

				try {
					var data = {
						value: this.page.revokeAccess.userName
					};
					const response = await this.$http.patch(`groups/removemember/${this.groupID}`, data, this.getTokenHeader());
					this.page.revokeAccess.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.revokeAccess.errors = this.logError(error);
				}

				this.page.revokeAccess.saving = false;
			},
			async setAdmin(name, value) {
				this.page.setAdmin.errors = [];
				this.page.setAdmin.saving = true;

				try {
					var data = {
						name: name,
						value: value
					};
					const response = await this.$http.patch(`groups/setadmin/${this.groupID}`, data, this.getTokenHeader());
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.setAdmin.errors = this.logError(error);
				}

				this.page.setAdmin.saving = false;
			},
			memberSelectionChanged(items) {
				this.log(items);
				if (items === null || items.length < 1) this.selectedMember = null;
				else {
					this.selectedMember = items[0];
					this.$scrollTo('#membersTop');
				}
			},
			async sendInvite() {
				this.page.invite.errors = [];
				this.page.invite.saving = true;

				try {
					const response = await this.$http.put(`groups/invite/${this.groupID}`, this.page.invite.data, this.getTokenHeader());
					await this.get();
					this.page.invite.success = true;
					this.page.invite.data.name = null;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.invite.errors = this.logError(error);
				}

				this.page.invite.saving = false;
			},
			async edit() {
				this.page.edit.errors = [];
				this.page.edit.saving = true;

				try {
					const response = await this.$http.put(`groups/${this.groupID}`, this.page.edit.data, this.getTokenHeader());
					this.page.edit.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.edit.errors = this.logError(error);
				}

				this.page.edit.saving = false;
			}
		}
	}
</script>

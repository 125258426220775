<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">HRU variables to print</h2>

			<p>
				Select up to <strong>20</strong> HRU variables to print in your model output. Due to the potential size of HRU output, only 20 may be selected per scenario run.
			</p>

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<b-alert variant="warning" :show="selected.length > 20">
					Please select only 20 variables at most.
				</b-alert>
				<b-alert variant="warning" :show="selected.length < 1">
					Please select at least one variable.
				</b-alert>

				<div class="checklist-expanded">
					<b-form-checkbox-group v-model="selected" stacked>
						<div class="item" v-for="(o, i) in options" :key="i">
							<b-form-checkbox :value="o.name">{{o.name}} {{isNullOrEmpty(o.description) ? '' : `- ${o.description}`}}</b-form-checkbox>
						</div>
					</b-form-checkbox-group>
				</div>

				<fixed-action-bar>
					<save-button :saving="page.saving" :disabled="selected.length < 1 || selected.length > 20" class="mr-2" />
					<back-button class="btn btn-secondary mr-2" />

					<div class="ml-3">({{selected.length}}/20 selected)</div>

					<div v-if="selected.length > 20" class="ml-3 text-danger">
						<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon mr-1 text-warning" />
						Please select only 20 variables at most.
					</div>

					<save-button v-if="hasMods" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to revert to the default printed HRU variables?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	export default {
		name: 'EditHruVars',
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				options: [],
				hasMods: false,
				selected: [],
				defaultSelection: []
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/print/hruvars/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.hasMods = response.data.hasMods;
					this.options = response.data.options;
					this.selected = response.data.selected;
					this.defaultSelection = response.data.defaultSelection;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				try {
					var data = {
						applyToAll: true,
						modificationObject: this.selected
					};
					const response = await this.$http.post(`scenarios/savemods/${this.scenarioID}/HruVars`, data, this.getTokenHeader());
					this.log(response.data);
					await this.get();
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/HruVars`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			}
		}
	}
</script>

<template>
	<div class="container-fluid py-3">
		<page-authorization-container :page="page">
			<div class="d-flex align-items-center mb-3">
				<h1 class="h4 mb-0">{{project.name}} HRUs</h1>
				<div class="ml-auto" v-if="project.numHrus < project.numOriginalHrus">
					<a :href="project.hrusTrimmedCsv" class="btn btn-primary btn-sm">Download <font-awesome-icon icon="file-csv" /></a>
				</div>
				<div class="ml-auto" v-else>
					<a :href="project.hrusOriginalCsv" class="btn btn-primary btn-sm">Download <font-awesome-icon icon="file-csv" /></a>
				</div>
			</div>

			<p>
			Please note this HRU table is generated per project, and does not reflect scenario modifications such as CN2 adjustments or calibration data.
			</p>			

			<grid-view :api-url="apiUrl" small paging-fixed
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   :default-per-page="table.itemsPerPage"
					   collection-description="HRUs" item-name="HRU"></grid-view>
		</page-authorization-container>		
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';

	export default {
		name: 'HruTable',
		components: {
			GridView
		},
		data() {
			return {
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				apiUrl: `hruselection/hrus/${this.$route.params.id}`,
				table: {
					fields: [
						//{ key: 'sortOrder', sortable: true },
						{ key: 'subbasin', sortable: true },
						{ key: 'landuse', sortable: true },
						{ key: 'soil', sortable: true },
						{ key: 'slopeClass', sortable: true },
						{ key: 'slope', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(5)) } },
						{ key: 'slopeLength', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
						{ key: 'area', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
						{ key: 'fraction', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(5)) } },
						{ key: 'cn2', label: 'CN2', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(2)) } },
						{ key: 'ov_n', label: 'OVN', sortable: true, formatter: (value) => { return this.numberWithCommas(Number(value).toFixed(3)) } }
					],
					sort: 'sortOrder',
					reverse: false,
					itemsPerPage: 100
				},
				project: {}
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.apiUrl = `hruselection/hrus/${this.projectID}`;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`projects/basic-info/${this.projectID}`, this.getTokenHeader());
					this.project = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-loading :loading="page.loading"></page-loading>
		<div v-if="!page.loading">
			<login-form v-if="!isAuthenticated || page.showLogin" show-header></login-form>
			<div v-else-if="!isAuthorized(roleNames.registered)">
				<b-alert variant="warning" show>
					Your account is pending approval. You will receive a notification by email when it is approved.
				</b-alert>
			</div>
			<error-list v-else-if="page.errors.length > 0" :errors="page.errors"></error-list>
			<div v-else-if="status.isCreating || status.isSettingHrus || status.isZipping" class="max-w-400 py-5 mx-auto text-center">
				<b-progress :value="progress.percent" :max="100" animated></b-progress>
				<p>
					{{progress.message}}
				</p>

				<p class="text-muted mt-5">
					<small>
						Progress not updating? <a href="#" @click.prevent="get(false)">Refresh now.</a>
						<br /><a v-if="status.isZipping" href="#" @click.prevent="page.abort.show = true" class="text-danger">Cancel zip file creation.</a>
					</small>
				</p>
			</div>
			<div v-else-if="status.hasError" class="container">
				<h2 class="mb-3 mt-3">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon mr-1 text-warning" />
					An error occurred in the project {{project.name}}
				</h2>
				<p>
					Errors can be the result of a bug in the system.
					Please see the full exception message below.
				</p>

				<hr class="my-4" />

				<h5>{{status.statusMessage}}</h5>
				<p>
					<b-form-textarea v-model="status.exceptionMessage" rows="15"></b-form-textarea>
				</p>

				<fixed-action-bar :cols="12" :cols-lg="12" :offset="0" :offset-lg="0">
					<b-button @click="page.errorReport.show = true" class="mr-2">Submit Error Report</b-button>
					<b-button variant="secondary" @click="clearError" class="mr-2">Clear Error</b-button>
				</fixed-action-bar>

				<b-modal v-model="page.errorReport.show" title="Submit an error report">
					<error-list :errors="page.errorReport.errors"></error-list>

					<p>
						Complete the form below to notify developers about the error.
						If you have already submitted a report for this error or a similar error, there is no need to submit again.
					</p>

					<b-form-group label="Add any additional comments (optional)">
						<b-form-textarea v-model="errorForm.userComments" rows="7"></b-form-textarea>
					</b-form-group>

					<div class="mt-4">
						<b-form-checkbox v-model="errorForm.clearError">Clear error after submitting report</b-form-checkbox>
					</div>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.errorReport.saving" @click.native="submitErrorReport" text="Submit Report" variant="success" />
						<b-button type="button" variant="secondary" @click="page.errorReport.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</div>
			<div v-else>
				<b-container fluid class="p-0">
					<b-row no-gutters v-if="$route.name == 'ProjectView'">
						<b-col md="3" class="fixed-height shadow d-flex align-items-stretch flex-column" style="z-index:500">
							<b-breadcrumb :items="breadCrumbs" class="no-curves bg-white px-3 mb-0"></b-breadcrumb>
							<div class="px-3 py-4 bg-gradient-light-blue">
								<h2 class="h6">Summary</h2>
								<table class="table table-sm table-borderless table-summary mt-2 mb-0">
									<tbody>
										<tr>
											<th>Name</th>
											<td>{{project.name}}</td>
										</tr>
										<tr>
											<th><a :href="project.subbasinsCsv" v-b-tooltip.hover.bottom title="Download SWAT->Subbasin CSV file">Subbasins</a></th>
											<td>{{project.numSubbasins | number(0)}}</td>
										</tr>
										<tr>
											<th>
												<router-link to="hrus" append v-b-tooltip.hover.bottom :title="status.hasSetHrus ? 'Review or download your project HRUs' : 'Set your HRUs to eliminate minor land uses'">HRUs</router-link>
											</th>
											<td>{{project.numHrus | number(0)}}</td>
										</tr>
										<tr>
											<th>Total area</th>
											<td>{{project.totalArea | number(2)}} km<sup>2</sup></td>
										</tr>
										<tr v-if="project.totalLandArea > 0">
											<th>Land-only area</th>
											<td>{{project.totalLandArea | number(2)}} km<sup>2</sup></td>
										</tr>
										<tr>
											<th>Watershed</th>
											<td>{{project.dataset}}, {{project.endingSubbasin}}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="px-3 py-4">
								<h2 class="h6">Set-up</h2>
								<b-nav vertical class="dash-items-nav">
									<b-nav-item to="hrus" append><font-awesome-icon :icon="['fas', 'sliders-h']" fixed-width class="mr-3" /> {{status.hasSetHrus ? 'Review' : 'Set'}} HRUs</b-nav-item>
									<b-nav-item v-if="project.scenarios.length < 1 && withinTierLimits" @click="openNewScenario"><font-awesome-icon :icon="['fas', 'cog']" fixed-width class="mr-3" /> Create scenario</b-nav-item>
									<b-nav-item v-else-if="project.scenarios.length > 0" v-b-toggle.collapse-scenarios>
										<font-awesome-icon :icon="['fas', 'cog']" fixed-width class="mr-3" /> Scenarios
										<font-awesome-icon :icon="['fas', 'angle-down']" fixed-width class="ml-3 mr-1 mt-1 float-right" />
									</b-nav-item>
									<b-collapse id="collapse-scenarios">
										<ul>
											<li v-for="(s, i) in project.scenarios" :key="i">
												<router-link :to="`scenarios/${s.id}`" append>{{s.name}}</router-link>
											</li>
										</ul>
										<ul class="font-weight-bold">
											<li><router-link :to="`/projects/${projectID}/scenarios`">All scenarios</router-link></li>
											<li v-if="withinTierLimits"><a href="#" @click.prevent="openNewScenario">Create a new scenario</a></li>
										</ul>
									</b-collapse>
								</b-nav>

								<b-alert variant="warning" :show="!withinTierLimits" class="mt-3">
									<p>
										Your account tier only has access to run scenarios from limited datasets with {{tier.subbasinLimit | number(0)}} subbasins
										and {{tier.hruLimit | number(0)}} HRUs or fewer.
										Use the set HRUs link above to reduce the number of HRUs below the limit if possible.
									</p>
									<p class="mb-0">
										Need access to a higher tier? <router-link to="/account">Go to your account</router-link> and click the tier tab.
									</p>
								</b-alert>

								<h2 class="h6 mt-4">Data</h2>
								<b-nav vertical class="dash-items-nav">
									<b-nav-item to="metadata" append><font-awesome-icon :icon="['far', 'clipboard']" fixed-width class="mr-3" /> Metadata</b-nav-item>
									<b-nav-item to="downloads" append><font-awesome-icon :icon="['fas', 'archive']" fixed-width class="mr-3" /> Project downloads</b-nav-item>
									<b-nav-item to="documents" append><font-awesome-icon :icon="['fas', 'upload']" fixed-width class="mr-3" /> Uploaded documents</b-nav-item>
									<b-nav-item to="errors" append><font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width class="mr-3" /> Feedback and error reports</b-nav-item>
								</b-nav>

								<h2 class="h6 mt-4">Actions</h2>
								<b-nav vertical class="dash-items-nav">
									<b-nav-item @click="page.share.show = true"><font-awesome-icon :icon="['fas', 'user-friends']" fixed-width class="mr-3" /> Share project</b-nav-item>
									<b-nav-item @click="page.copy.show = true"><font-awesome-icon :icon="['fas', 'copy']" fixed-width class="mr-3" /> Copy project</b-nav-item>
									<b-nav-item @click="page.edit.show = true"><font-awesome-icon :icon="['fas', 'edit']" fixed-width class="mr-3" /> Change project name</b-nav-item>
									<b-nav-item @click="page.delete.show = true"><font-awesome-icon :icon="['fas', 'trash']" fixed-width class="mr-3" /> Delete project</b-nav-item>
								</b-nav>
							</div>
							<div class="d-none d-md-flex align-items-center border-top bg-white position-relative mt-auto">
								<div class="p-3">
									<router-link to="/projects" class="stretched-link text-reset" title="Back to project list">
										<font-awesome-icon :icon="['fas', 'chevron-left']" fixed-width />
									</router-link>
								</div>
								<div class="p-3 border-left text-uppercase">
									Back to project list
								</div>
							</div>
						</b-col>
						<b-col md="9">
							<project-map :project-map="project.map" show-layer-control show-extra-layers load-only-on-view="ProjectView"></project-map>
						</b-col>
					</b-row>
					<b-row no-gutters v-else-if="$route.name.startsWith('Output')">
						<b-col lg="2" order="2" order-lg="1" class="fixed-height bg-light">
							<div class="d-flex align-items-center border-bottom bg-white position-relative">
								<div class="p-3">
									<router-link :to="baseRoute" class="stretched-link text-reset" title="Back to scenario">
										<font-awesome-icon :icon="['fas', 'chevron-left']" fixed-width />
									</router-link>
								</div>
								<div class="p-3 border-left text-uppercase">
									Back to scenario
								</div>
							</div>
							<div class="px-3 pt-4">
								<h2 class="h6">Output</h2>
								<b-nav vertical class="dash-items-nav nav-sm mb-4">
									<b-nav-item :href="project.currentScenarioZipFile">Download scenario files</b-nav-item>
									<b-nav-item :to="`${baseRoute}/output/swat-check`">SWAT check</b-nav-item>
									<b-nav-item :to="`${baseRoute}/output/charts`">Charts</b-nav-item>
									<b-nav-item :to="`${baseRoute}/output/maps`">Maps</b-nav-item>
									<b-nav-item v-if="siteSettings.includeCeQual" :to="`${baseRoute}/output/ce-qual`">CE-QUAL-W2</b-nav-item>
									<b-nav-item v-if="siteSettings.includeFfa" :to="`${baseRoute}/output/ffa`">Flood Frequency Analysis</b-nav-item>
									<b-nav-item v-if="siteSettings.includeBaseflowSeparation" :to="`${baseRoute}/output/baseflowseparation`">Baseflow Separation</b-nav-item>
								</b-nav>
							</div>

							<div style="height:70px"></div>
						</b-col>
						<b-col lg="10" order="1" order-lg="2" class="fixed-height shadow no-shadow-md" style="z-index:500">
							<b-breadcrumb :items="breadCrumbs" class="no-curves bg-white px-md-4"></b-breadcrumb>
							<div class="container-fluid px-md-4 pb-4">
								<router-view></router-view>
							</div>
						</b-col>
					</b-row>
					<b-row no-gutters v-else-if="$route.name.startsWith('Edit')">
						<b-col md="3" order="2" order-md="1" class="fixed-height bg-light">
							<div class="d-flex align-items-center border-bottom bg-white position-relative">
								<div class="p-3">
									<router-link :to="baseRoute" class="stretched-link text-reset" title="Back to scenario">
										<font-awesome-icon :icon="['fas', 'chevron-left']" fixed-width />
									</router-link>
								</div>
								<div class="p-3 border-left text-uppercase">
									Back to scenario
								</div>
							</div>
							<div class="px-3 pt-4">
								<h2 class="h6">Data</h2>
								<b-nav vertical class="dash-items-nav nav-sm mb-4">
									<b-nav-item @click="openHrus"
												v-b-tooltip.hover title="Opens in a separate window"
												link-classes="d-flex justify-content-between align-items-center">
										<span>Project HRU table</span>
										<font-awesome-icon :icon="['fas', 'external-link-alt']" fixed-width class="icon ml-2" />
									</b-nav-item>
								</b-nav>

								<h2 class="h6">General</h2>
								<b-nav vertical class="dash-items-nav nav-sm mb-4">
									<b-nav-item :to="`${baseRoute}/calibration`" link-classes="d-flex justify-content-between align-items-center">
										<span>Calibration data</span>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/bsn`" link-classes="d-flex justify-content-between align-items-center">
										<span>Basin input data</span>
										<check-icon v-if="project.modTables.includes('Bsn')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/fert`" link-classes="d-flex justify-content-between align-items-center">
										<span>Fertilizers</span>
										<check-icon v-if="project.modTables.includes('Fert')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/nutrient-efficiency`" link-classes="d-flex justify-content-between align-items-center">
										<span>Nutrient efficiency</span>
										<check-icon v-if="project.modTables.includes('AutoEff')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/urban`" link-classes="d-flex justify-content-between align-items-center">
										<span>Urban input data</span>
										<check-icon v-if="project.modTables.includes('Urban')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/lup`" link-classes="d-flex justify-content-between align-items-center">
										<span>Land use update</span>
										<check-icon v-if="project.modTables.includes('Lup')"></check-icon>
									</b-nav-item>
								</b-nav>

								<h2 class="h6">Weather data</h2>
								<b-nav vertical class="dash-items-nav nav-sm mb-4">
									<b-nav-item :to="`${baseRoute}/climate-sensitivity`" link-classes="d-flex justify-content-between align-items-center">
										<span>Climate sensitivity/variability analysis</span>
										<check-icon v-if="project.modTables.includes('ClimateSensitivity')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/weather-generator`" link-classes="d-flex justify-content-between align-items-center">
										<span>Weather generator</span>
										<check-icon v-if="project.modTables.includes('Wgn')"></check-icon>
									</b-nav-item>
								</b-nav>

								<h2 class="h6">Subbasin inputs</h2>
								<b-nav vertical class="dash-items-nav nav-sm mb-4">
									<b-nav-item :to="`${baseRoute}/curve-number`" link-classes="d-flex justify-content-between align-items-center">
										<span>Curve number</span>
										<check-icon v-if="project.modTables.includes('Cn2Percent')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/potholes`" link-classes="d-flex justify-content-between align-items-center">
										<span>Pothole variables</span>
										<check-icon v-if="project.modTables.includes('Potholes')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/sediment-routing`" link-classes="d-flex justify-content-between align-items-center">
										<span>Sediment routing method</span>
										<check-icon v-if="project.modTables.includes('RteChEqn')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/point-source`" link-classes="d-flex justify-content-between align-items-center">
										<span>Point source</span>
										<check-icon v-if="project.modTables.includes('Ptsrc')"></check-icon>
									</b-nav-item>
								</b-nav>

								<h2 class="h6">Ag management</h2>
								<b-nav vertical class="dash-items-nav nav-sm mb-4">
									<b-nav-item :to="`${baseRoute}/mgt-general`" link-classes="d-flex justify-content-between align-items-center">
										<span>General parameters</span>
										<check-icon v-if="project.modTables.includes('Mgt1')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/mgt-operations`" link-classes="d-flex justify-content-between align-items-center">
										<span>Operations management</span>
										<check-icon v-if="project.modTables.includes('Mgt2')"></check-icon>
									</b-nav-item>
								</b-nav>

								<h2 class="h6">Print</h2>
								<b-nav vertical class="dash-items-nav nav-sm mb-4">
									<b-nav-item :to="`${baseRoute}/hru-vars`" link-classes="d-flex justify-content-between align-items-center">
										<span>HRU variables</span>
										<check-icon v-if="project.modTables.includes('HruVars')"></check-icon>
									</b-nav-item>
									<b-nav-item :to="`${baseRoute}/hru-print`" link-classes="d-flex justify-content-between align-items-center">
										<span>HRUs to print</span>
										<check-icon v-if="project.modTables.includes('HruPrint')"></check-icon>
									</b-nav-item>
								</b-nav>
							</div>

							<div style="height:70px"></div>
						</b-col>
						<b-col md="9" order="1" order-md="2" class="fixed-height shadow no-shadow-sm" style="z-index:500">
							<b-breadcrumb :items="breadCrumbs" class="no-curves bg-white px-md-4"></b-breadcrumb>
							<div class="container-fluid px-md-4 pb-4">
								<router-view></router-view>
							</div>
						</b-col>
					</b-row>
					<b-row no-gutters v-else>
						<b-col md="3" order="2" order-md="1" class="fixed-height bg-light d-none d-md-block">
							<div class="border-bottom" v-if="project.numSubbasins < globals.maxSubbasinsForMap">
								<project-map :project-map="project.map" height="350px"></project-map>
							</div>
							<div class="px-3 py-4">
								<table class="table table-sm table-borderless table-summary my-0">
									<tbody>
										<tr>
											<th>Project</th>
											<td>{{project.name}}</td>
										</tr>
										<tr>
											<th>Subbasins</th>
											<td>{{project.numSubbasins | number(0)}}</td>
										</tr>
										<tr>
											<th>HRUs</th>
											<td>{{project.numHrus | number(0)}}</td>
										</tr>
										<tr>
											<th>Total area</th>
											<td>{{project.totalArea | number(2)}} km<sup>2</sup></td>
										</tr>
										<tr>
											<th>Land-only area</th>
											<td>{{project.totalLandArea | number(2)}} km<sup>2</sup></td>
										</tr>
										<tr>
											<th>Watershed</th>
											<td>{{project.dataset}}, {{project.endingSubbasin}}</td>
										</tr>
									</tbody>
								</table>

								<b-dropdown text="Scenarios" size="sm" variant="secondary" class="mt-3" v-if="(project.scenarios.length > 0) && $route.name !== 'ScenarioList'">
									<b-dropdown-item v-for="(s, i) in project.scenarios" :key="i" :to="`/projects/${projectID}/scenarios/${s.id}`">{{s.name}}</b-dropdown-item>
									<b-dropdown-divider></b-dropdown-divider>
									<b-dropdown-item :to="`/projects/${projectID}/scenarios`">All scenarios</b-dropdown-item>
									<b-dropdown-item href="#" @click.prevent="openNewScenario">Create a new scenario</b-dropdown-item>
								</b-dropdown>
							</div>

							<div style="height:70px"></div>
						</b-col>
						<b-col md="9" order="1" order-md="2" class="fixed-height shadow no-shadow-sm border-bottom-0" style="z-index:500">
							<b-breadcrumb :items="breadCrumbs" class="no-curves bg-white px-md-4"></b-breadcrumb>
							<div class="container-fluid px-md-4">
								<router-view></router-view>
							</div>
						</b-col>
					</b-row>
				</b-container>

				<b-modal v-model="show.scenarioForm" size="lg" title="Create a new scenario" hide-footer>
					<scenario-form :data="newScenario" @saved="cancelOpenScenario" show-cancel-button></scenario-form>
				</b-modal>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete this project?
						This action is permanent and cannot be undone. All files and scenarios related to this project will be removed.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>

				<b-modal v-model="page.copy.show" title="Copy project">
					<error-list :errors="page.copy.errors"></error-list>

					<p>
						Copy this project to a new project. All scenarios and files will be copied.
					</p>

					<b-form-group label="Provide a name for the new project">
						<b-form-input v-model="page.copy.name" type="text" required></b-form-input>
					</b-form-group>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.copy.saving" @click.native="copyProject" text="Copy Project" variant="success" />
						<b-button type="button" variant="secondary" @click="page.copy.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>

				<b-modal v-model="page.edit.show" title="Edit project name">
					<error-list :errors="page.edit.errors"></error-list>

					<b-form-group label="Provide a new name for the project">
						<b-form-input v-model="page.edit.name" type="text" required></b-form-input>
					</b-form-group>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.edit.saving" @click.native="editProject" />
						<b-button type="button" variant="secondary" @click="page.edit.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>

				<b-modal v-model="page.share.show" title="Share project">
					<p>
						Groups are a way to share your projects and scenarios with other users.
						All users in the group may view, edit, and run* projects shared with the group.
						(*Note: tier limits still apply. If the project is outside the tier of other members in the group, they may view and edit the project but not run it.)
					</p>
					<p>
						Tip: use the copy project feature to retain a copy of this project that
						is not available for other group members to modify.
					</p>

					<error-list :errors="page.share.errors"></error-list>

					<b-alert :show="page.share.sharedSuccess" variant="success">
						Your project has been shared with
						<router-link :to="`/groups/${page.share.currentItem.value}`">{{page.share.currentItem.text}}</router-link>.
						Done sharing? <a href="#" @click.prevent="closeShare">Close this window.</a>
						<span v-if="groupOptions.length > 0">
							Otherwise, select another group below.
						</span>
					</b-alert>
					<b-alert :show="page.share.unsharedSuccess" variant="success">
						Your project has been removed from
						<router-link :to="`/groups/${page.share.currentItem.value}`">{{page.share.currentItem.text}}</router-link>.
						<a href="#" @click.prevent="closeShare">Close this window if done.</a>
					</b-alert>

					<b-form-group v-if="groupOptions.length > 0">
						<b-form-select v-model="page.share.groupID" :options="groupOptions">
							<template v-slot:first>
								<option :value="null" disabled>-- Select a group for sharing --</option>
							</template>
						</b-form-select>
					</b-form-group>

					<b-alert variant="info" :show="groupOptions.length <= 0 && project.groups.length <= 0">
						You don't belong to any groups.
						<router-link to="/groups/create">Create one now.</router-link>
					</b-alert>

					<b-alert variant="info" :show="groupOptions.length <= 0 && project.groups.length > 0">
						This project is already shared with all of your groups.
					</b-alert>

					<div v-if="project.groups.length > 0" class="my-4">
						<div>Currently shared with (click to remove):</div>
						<b-button v-for="(g, i) in project.groups" :key="i" variant="light" size="sm" class="mr-2 mb-2" @click="unshareProject(g.value)">
							{{g.text}}
							<font-awesome-icon :icon="['fas', 'times']" class="text-danger" />
						</b-button>
					</div>

					<div slot="modal-footer">
						<save-button v-if="groupOptions.length > 0" type="button" :saving="page.share.saving" @click.native="shareProject" text="Share Project" :disabled="page.share.groupID === null" />
						<b-button type="button" variant="secondary" @click="closeShare" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</div>

			<b-modal v-model="page.abort.show" size="md" title="Confirm cancellation" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to cancel creating a zip file of this project?
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.abort.saving" @click.native="abortZip" text="Yes, Abort" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.abort.show = false" class="ml-1">No</b-button>
				</div>
			</b-modal>
		</div>
	</div>
</template>

<script>
	import ProjectMap from '@/components/ProjectMap';
	import ScenarioForm from '@/views/projects/scenarios/ScenarioForm';
	import _ from 'lodash';

	export default {
		name: 'ProjectView',
		components: {
			ProjectMap, ScenarioForm
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					copy: {
						show: false,
						errors: [],
						saving: false,
						name: ''
					},
					edit: {
						show: false,
						errors: [],
						saving: false,
						name: ''
					},
					share: {
						show: false,
						errors: [],
						saving: false,
						groupID: null,
						currentItem: {},
						sharedSuccess: false,
						unsharedSuccess: false
					},
					errorReport: {
						show: false,
						errors: [],
						saving: false
					},
					abort: {
						show: false,
						errors: [],
						saving: false
					}
				},
				project: {},
				tier: {
					subbasinLimit: 0,
					hruLimit: 0
				},
				status: {
					hasError: false,
					isCreating: false,
					isCreated: false,
					isSettingHrus: false,
					hasSetHrus: false,
					isZipping: false
				},
				groupOptions: [],
				progress: {
					percent: 0,
					message: 'Please wait...'
				},
				show: {
					scenarioForm: false,
					zip: false
				},
				newScenario: undefined,
				baseRoute: '',
				hruWindow: null,
				errorForm: {
					projectID: this.$route.params.id,
					scenarioID: null,
					title: '',
					message: '',
					exception: '',
					userComments: '',
					clearError: true
				}
			}
		},
		async created() {
			await this.get();
		},
		beforeDestroy() {
			this.$statusHub.projectClosed(this.projectID);
			this.$statusHub.$off('create-project-status-changed', this.onCreatingStatusChanged);
			this.$statusHub.$off('set-hrus-status-changed', this.onSetHrusStatusChanged);
			this.$statusHub.$off('zip-project-status-changed', this.onZippingStatusChanged);
			this.$statusHub.$off('copy-project-status-changed', this.onCopyingStatusChanged);
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get(initialize=true) {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.baseRoute = `/projects/${this.projectID}/scenarios/${this.scenarioID}`;

				this.show.scenarioForm = false;
				this.page.errors = [];
				this.page.loading = true;

				try {
					var url = `projects/${this.projectID}`;
					if (this.scenarioID)
						url += `/${this.scenarioID}`;
					const response = await this.$http.get(url, this.getTokenHeader());
					this.log(response.data);
					this.project = response.data.project;
					this.tier = response.data.tier;
					this.status = response.data.status;
					this.groupOptions = response.data.groupOptions;
					this.progress = response.data.progress;
					this.page.copy.name = this.project.name + ' (Copy)';
					this.page.edit.name = this.project.name;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
				if (initialize) {
					this.$statusHub.projectOpened(this.projectID);
					this.$statusHub.$on('create-project-status-changed', this.onCreatingStatusChanged);
					this.$statusHub.$on('set-hrus-status-changed', this.onSetHrusStatusChanged);
					this.$statusHub.$on('zip-project-status-changed', this.onZippingStatusChanged);
					this.$statusHub.$on('copy-project-status-changed', this.onCopyingStatusChanged);
				}
			},
			async onCreatingStatusChanged(data) {
				if (this.projectID != data.projectID) return;
				if (data.progress == 100) {
					await this.get(false);
				}

				this.progress.percent = data.progress;
				this.progress.message = data.statusMessage;
			},
			onSetHrusStatusChanged(data) {
				if (this.projectID != data.projectID) return;
				if (data.progress == 100) {
					this.status.isSettingHrus = false;
					this.status.hasSetHrus = false;
					this.$router.push({ name: 'ProjectHrus', params: { id: this.projectID }}).catch(err => {});
				}

				this.progress.percent = data.progress;
				this.progress.message = data.statusMessage;
			},
			async onZippingStatusChanged(data) {
				if (this.projectID != data.projectID) return;
				if (data.progress == 100) {
					//await this.get(false);
					this.status.isZipping = false;
					this.$router.push({ name: 'ProjectDownloads', params: { id: this.projectID } }).catch(err => { });
				}

				this.progress.percent = data.progress;
				this.progress.message = data.statusMessage;
			},
			async onCopyingStatusChanged(data) {
				if (this.projectID != data.projectID) return;
				if (data.progress == 100) {
					await this.get(false);
				}

				this.progress.percent = data.progress;
				this.progress.message = data.statusMessage;
			},
			openNewScenario() {
				this.newScenario = {
					projectID: this.projectID,
					name: this.project.scenarios.length > 0 ? '' : 'Default',
					startingSimulationDate: undefined,
					endingSimulationDate: undefined,
					weatherDatasetID: undefined,
					climateChangeScenarioID: undefined,
					climateChangeTimePeriodID: undefined,
					swatVersionID: undefined,
					printSetting: 1,
					skipYears: 2
				};

				this.show.scenarioForm = true;
			},
			cancelOpenScenario() {
				this.show.scenarioForm = false;
			},
			openHrus() {
				var routeData = this.$router.resolve({ name: 'HruTable', params: { id: this.projectID } });
				var features = "resizable=yes,scrollbars=yes,status=yes,width=800,height=600";
				if (this.hruWindow === null || this.hruWindow.closed) {
					this.hruWindow = window.open(routeData.href, '_blank', features);
					this.log(this.hruWindow);
				} else {
					if (this.hruWindow.location.href != routeData.href)
						this.hruWindow.location.assign(routeData.href);
					this.hruWindow.focus();
				}
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`projects/${this.projectID}`, this.getTokenHeader());
					this.$router.push({ name: 'ProjectList' }).catch(err => {});
					this.page.delete.show = false;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			async copyProject() {
				this.page.copy.errors = [];
				this.page.copy.saving = true;

				if (this.isNullOrEmpty(this.page.copy.name)) {
					this.page.copy.errors.push('Please provide a name for the new project.');
				} else {
					try {
						var data = {
							value: this.page.copy.name
						};
						const response = await this.$http.post(`projects/copy/${this.projectID}`, data, this.getTokenHeader());
						this.$router.push({ name: 'ProjectView', params: { id: response.data }}).catch(err => {});
						this.page.copy.show = false;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.copy.errors = this.logError(error);
					}
				}

				this.page.copy.saving = false;
			},
			async editProject() {
				this.page.edit.errors = [];
				this.page.edit.saving = true;

				if (this.isNullOrEmpty(this.page.edit.name)) {
					this.page.copy.errors.push('Please provide a name for the project.');
				} else {
					try {
						var data = {
							value: this.page.edit.name
						};
						const response = await this.$http.put(`projects/${this.projectID}`, data, this.getTokenHeader());
						this.page.edit.show = false;
						await this.get(false);
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.edit.errors = this.logError(error);
					}
				}

				this.page.edit.saving = false;
			},
			async shareProject() {
				this.page.share.errors = [];
				this.page.share.saving = true;

				if (this.isNullOrEmpty(this.page.share.groupID)) {
					this.page.share.errors.push('Please select a group.');
				} else {
					try {
						const response = await this.$http.patch(`groups/share/${this.page.share.groupID}/${this.projectID}`, {}, this.getTokenHeader());
						this.page.share.currentItem = response.data;
						this.groupOptions = _.remove(this.groupOptions, function (n) { return n.value !== response.data.value; });
						this.project.groups.push(response.data);
						this.page.share.groupID = null;
						this.page.share.sharedSuccess = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.share.errors = this.logError(error);
					}
				}

				this.page.share.saving = false;
			},
			async unshareProject(id) {
				this.page.share.errors = [];
				this.page.share.saving = true;

				if (!this.isNullOrEmpty(id)) {
					try {
						const response = await this.$http.patch(`groups/unshare/${id}/${this.projectID}`, {}, this.getTokenHeader());
						this.page.share.currentItem = response.data;
						this.project.groups = _.remove(this.project.groups, function (n) { return n.value !== response.data.value; });
						this.groupOptions.push(response.data);
						this.page.share.unsharedSuccess = true;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.share.errors = this.logError(error);
					}
				}

				this.page.share.saving = false;
			},
			closeShare() {
				this.page.share.unsharedSuccess = false;
				this.page.share.sharedSuccess = false;
				this.page.share.show = false;
			},
			async clearError() {
				try {
					await this.$http.get(`projects/clearerror/${this.projectID}`, this.getTokenHeader());
					await this.get(false);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}
			},
			async submitErrorReport() {
				try {
					this.errorForm.exception = this.status.exceptionMessage;
					this.errorForm.title = this.status.statusMessage;
					this.errorForm.message = this.status.statusMessage;
					await this.$http.post(`errorreports`, this.errorForm, this.getTokenHeader());
					await this.get(false);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errorReport.errors = this.logError(error);
				}
			},
			async abortZip() {
				try {
					await this.$http.get(`projects/abortzip/${this.projectID}`, this.getTokenHeader());
					this.page.abort.show = false;
					await this.get(false);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.abort.errors = this.logError(error);
				}
			}
		},
		computed: {
			breadCrumbs() {
				var crumbs = [];
				for (var i = 0; i < this.$route.matched.length; i++) {
					var item = this.$route.matched[i];
					var name = item.meta.title ? item.meta.title : item.name;

					if (item.name == "ProjectView") {
						name = this.project.name;
					}
					else if (item.name == "ScenarioView") {
						name = this.project.currentScenarioName;
					}

					crumbs.push({
						text: name,
						to: { name: item.name }
					});
				}
				return crumbs;
			},
			withinTierLimits() {
				var subsValid = this.tier.subbasinLimit === 0 || this.tier.subbasinLimit >= this.project.numSubbasins;
				var hrusValid = this.tier.hruLimit === 0 || this.tier.hruLimit >= this.project.numHrus;
				var datasetValid = this.tier.datasets.includes(this.project.datasetID) || this.isAuthorized(this.roleNames.admin) || this.isAuthorized(this.roleNames.partialAdmin);
				return subsValid && hrusValid && datasetValid;
			}
		}
	}
</script>

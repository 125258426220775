<template>
	<div>
		<page-authorization-container :page="page">
			<div v-if="data.status.hasError">
				<h2 class="mb-3">
					<font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon mr-1 text-warning" />
					An error occurred while running {{data.name}}
				</h2>
				<p>
					Please see the full exception message below and contact support.
				</p>

				<hr class="my-4" />

				<h5>{{data.status.statusMessage}}</h5>
				<p>
					<b-form-textarea v-model="data.status.exceptionMessage" rows="15"></b-form-textarea>
				</p>
			</div>
			<div v-else>
				<h2 class="mb-3">Batch {{data.name}}</h2>

				<div v-if="data.status.isRunning" class="max-w-400 py-5 mx-auto text-center">
					<b-progress :value="progress.percent" :max="100" animated></b-progress>
					<p>
						{{progress.message}}
					</p>

					<p class="text-muted mt-5">
						<small>
							<span v-if="!isNullOrEmpty(this.currentRunTime)">Time elapsed: {{this.currentRunTime}}<br /></span>
							Progress not updating? <a href="#" @click.prevent="get(false)">Refresh now.</a>
							<br /><a href="#" @click.prevent="page.abort.show = true" class="text-danger">Cancel batch.</a>
						</small>
					</p>
				</div>

				<b-alert variant="success" :show="data.status.hasRun">Batch run completed in {{data.status.runtime}}</b-alert>
				<b-alert variant="danger" :show="data.status.isAborting">Batch run aborted.</b-alert>

				<hr class="my-4" />

				<h3 class="mt-4 mb-3 h5">Batch settings</h3>

				<b-row>
					<b-col md>
						<table class="table table-inner-borders">
							<tr>
								<th class="min">{{siteText.siteName}} dataset</th>
								<td>{{data.batch.dataset}}</td>
							</tr>
							<tr>
								<th class="min">Single subbasin projects</th>
								<td>{{data.batch.setAsSingleSubbasinProjects ? 'Yes' : 'No'}}</td>
							</tr>
							<tr>
								<th class="min">HRU reduction method</th>
								<td>{{data.batch.setHruMethod}}</td>
							</tr>
							<tr>
								<th class="min">HRU reduction threshold</th>
								<td>
									{{data.batch.targetThreshold | number(2)}}
									<span v-if="data.batch.setHruUnits == 'Percent'">
										% of each subbasin
									</span>
									<span v-else>
										km<sup>2</sup>
									</span>
								</td>
							</tr>
							<tr>
								<th class="min">HRU land use exemptions</th>
								<td>{{joinArray(data.batch.ExemptLanduse)}}</td>
							</tr>
							<tr>
								<th class="min">HRU land use exempt from area redistribution</th>
								<td>{{joinArray(data.batch.NoAreaRedistribution)}}</td>
							</tr>
						</table>
					</b-col>
					<b-col md>
						<table class="table table-inner-borders">
							<tr>
								<th class="min">Weather dataset</th>
								<td>{{weatherDatasetDescription}}</td>
							</tr>
							<tr>
								<th class="min">Simulation dates</th>
								<td>{{data.batch.startingSimulationDate | date('M/D/YYYY')}} - {{data.batch.endingSimulationDate | date('M/D/YYYY')}}</td>
							</tr>
							<tr>
								<th class="min">Set-up/warm-up years</th>
								<td>{{data.batch.skipYears}}</td>
							</tr>
							<tr>
								<th class="min">SWAT output print setting</th>
								<td>{{data.batch.printSetting}}</td>
							</tr>
							<tr>
								<th class="min">SWAT model version</th>
								<td>{{data.batch.swatVersion}}</td>
							</tr>
							<tr>
								<th class="min">Run model</th>
								<td>{{data.batch.noModelRun ? 'No' : 'Yes'}}</td>
							</tr>
							<tr>
								<th class="min">Process output database</th>
								<td>{{data.batch.noOutputProcessing ? 'No' : 'Yes'}}</td>
							</tr>
							<tr>
								<th class="min">Write to SWAT editor tables</th>
								<td>{{data.batch.writeSwatEditor ? 'Yes' : 'No'}}</td>
							</tr>
						</table>
					</b-col>
				</b-row>

				<hr class="my-4" />

				<h3 class="mt-4 mb-3 h5">Batch projects</h3>

				<p>
					You can download files individually below, or log into the server and grab from this directory:
					<code>{{data.batch.serverPath}}</code>
				</p>

				<p v-if="data.status.isRunning">
					Individual project status is shown below, but the page does not refresh the status automatically.
					<a href="#" @click.prevent="get(false)">Refresh now.</a>
				</p>
				<p v-else-if="data.status.hasRun">
					<a :href="data.batch.geojsonDownload" class="btn btn-primary mr-2">Download GeoJson</a>
					<a :href="data.batch.cumulativeAreasDownload" class="btn btn-primary mr-2">Download Cumulative Areas</a>
				</p>

				<b-table-simple small responsive hover>
					<b-thead>
						<b-tr>
							<b-th></b-th>
							<b-th>Downstream Subbasin</b-th>
							<b-th>Starting Subbasins</b-th>
							<b-th>Status</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr v-for="p in data.projects" :key="p.id">
							<b-td>
								<router-link :to="`/projects/${p.id}`" title="Edit/View" target="_blank">
									<font-awesome-icon :icon="['fas', 'edit']" />
								</router-link>
							</b-td>
							<b-td>{{p.endingSubbasin}}</b-td>
							<b-td>{{p.startingSubbasins.join(', ')}}</b-td>
							<b-td>
								<div v-if="p.status.isRunning">{{p.status.statusMessage}}</div>
								<div v-else>
									<div v-for="s in p.scenarios" :key="s.id">
										<div v-if="s.hasRun"><a :href="s.download">Download</a> (completed in {{s.runtime}})</div>
										<div v-else>
											<router-link :to="`/projects/${p.id}/scenarios/${s.id}`" title="Edit/View" target="_blank">
												<font-awesome-icon :icon="['fas', 'edit']" />
											</router-link>
											{{s.statusMessage}}
										</div>
									</div>
								</div>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>

			<fixed-action-bar :cols-lg="10" :offset-lg="2">
				<back-button class="btn btn-secondary mr-2" />
				<b-button v-if="!data.status.isRunning" variant="danger" @click="page.delete.show = true" class="ml-auto">Delete</b-button>
			</fixed-action-bar>

			<b-modal v-model="page.abort.show" size="md" title="Confirm run cancellation" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to abort the batch?
					We'll keep files of progress so far and no projects will be deleted.
					But you will not be able to restart the batch; a new batch will need to be submitted.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.abort.saving" @click.native="abortRun" text="Yes, Abort" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.abort.show = false" class="ml-1">No</b-button>
				</div>
			</b-modal>

			<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
				<error-list :errors="page.delete.errors"></error-list>

				<p>
					Are you sure you want to delete this batch?
					This action is permanent and cannot be undone. All files and projects related to this batch will be removed.
				</p>

				<div slot="modal-footer">
					<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
					<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
				</div>
			</b-modal>
		</page-authorization-container>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		name: 'ApiBatchView',
		data() {
			return {
				batchID: this.$route.params.id,
				currentRunTime: '',
				timer: '',
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					},
					abort: {
						show: false,
						errors: [],
						saving: false
					}
				},
				progress: {
					percent: 0,
					message: 'Please wait...'
				},
				data: {
					name: null,
					status: {},
					projects: [],
					batch: {}
				}
			}
		},
		async created() {
			await this.get();
		},
		beforeDestroy() {
			this.$statusHub.batchClosed(this.batchID);
			this.$statusHub.$off('batch-status-changed', this.onStatusChanged);
			this.cancelTimer();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get(initialize = true) {
				this.batchID = this.$route.params.id;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`api-requests/batch/${this.batchID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;

					if (this.data.status.isRunning) {
						this.progress.message = this.data.status.statusMessage;
						this.progress.percent = this.data.status.currentProgress;
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;

				if (initialize) {
					this.$statusHub.batchOpened(this.batchID);
					this.$statusHub.$on('batch-status-changed', this.onStatusChanged);

					this.setCurrentRunTime();
					this.timer = setInterval(this.setCurrentRunTime, 1000);
				}
			},
			async onStatusChanged(data) {
				if (this.batchID != data.requestID) return;
				if (data.progress == 100) {
					await this.get(false);
				}

				this.progress.percent = data.progress;
				this.progress.message = data.statusMessage;
			},
			async abortRun() {
				try {
					await this.$http.get(`api-requests/abortbatch/${this.batchID}`, this.getTokenHeader());
					this.page.abort.show = false;
					await this.get(false);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.abort.errors = this.logError(error);
				}
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					await this.$http.delete(`api-requests/deletebatch/${this.batchID}`, this.getTokenHeader());
					this.$router.push({ name: 'ApiBatchList' }).catch(err => { this.log(err) });
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			setCurrentRunTime() {
				this.currentRunTime = this.isNullOrEmpty(this.data.status.startRunDate) ? '' : moment(this.data.status.startRunDate).fromNow(true);
			},
			cancelTimer() {
				clearInterval(this.timer);
			}
		},
		computed: {
			weatherDatasetDescription() {
				var d = this.data.batch.weatherDataset;

				if (!this.isNullOrEmpty(this.data.batch.climateChangeScenario))
					d += ', ' + this.data.batch.climateChangeScenario;

				if (!this.isNullOrEmpty(this.data.batch.climateChangeTimePeriod))
					d += ', ' + this.data.batch.climateChangeTimePeriod;

				return d;
			}
		}
	}
</script>

<template>
	<div>
		<hr class="my-4" />
		<h3 class="mb-3">Saved modifications</h3>

		<p v-if="data.length > 1">
			Each row below is applied in order, starting from the top. Bottom settings may overwrite rows above.
		</p>

		<div class="table-responsive mb-3">
			<b-table responsive class="mb-0" :items="data" :fields="table.fields">
				<template v-slot:cell(lastModifiedDate)="data">
					{{data.item.lastModifiedDate | date}}
				</template>
				<template v-slot:cell(value)="data">
					<div v-if="!valueIsObject">{{data.item.value}}{{appendToValue}}</div>
					<div v-else><a href="#" @click.prevent="showView(data.item)">View</a></div>
				</template>
				<template v-slot:cell(subbasins)="data">
					{{writeArray(data.item.subbasins.map(({ name }) => name))}}
				</template>
				<template v-slot:cell(landuses)="data">
					{{writeArray(data.item.landuses)}}
				</template>
				<template v-slot:cell(soils)="data">
					{{writeArray(data.item.soils)}}
				</template>
				<template v-slot:cell(slopes)="data">
					{{writeArray(data.item.slopes)}}
				</template>
				<template v-slot:cell(delete)="data">
					<button type="button" class="plain text-danger" v-b-tooltip.hover.right="'Delete'" @click="askDelete(data.item.id, 'this modification')">
						<font-awesome-icon :icon="['fas', 'times']" />
					</button>
				</template>
				<template v-slot:cell(view)="data">
					<button type="button" class="plain text-primary" v-b-tooltip.hover.right="'View'" @click="showView(data.item)">
						<font-awesome-icon :icon="['fas', 'edit']" />
					</button>
				</template>
			</b-table>
		</div>

		<b-modal v-model="page.view.show" :size="isMgt2 ? 'xl' : 'lg'" title="Modification details" scrollable>
			<p v-if="!valueIsObject">
				<strong>{{valueLabel}}</strong>: {{page.view.item.value}}{{appendToValue}}
			</p>
			<div v-else-if="isMgt2">
				<mgt2-viewer :schedule-by-heat-units="scheduleByHeatUnits" :items="page.view.item.value" :lists="mgt2Lists"></mgt2-viewer>
			</div>
			<div v-else>
				<p v-for="(k, i) in Object.keys(page.view.item.value)" :key="i">
					<strong>{{k}}: </strong>
					<span v-if="k === 'Months'">
						<br />
						<span v-if="page.view.item.value[k] < 1">All</span>
						<span v-else>{{toMonthNames(page.view.item.value[k])}}</span>
					</span>
					<span v-else>{{page.view.item.value[k]}}</span>
				</p>
			</div>

			<p>
				<strong>Subbasins:</strong><br />
				{{writeArray(page.view.item.subbasins.map(({ name }) => name), 0)}}
			</p>
			<p>
				<strong>Land use:</strong><br />
				{{writeArray(page.view.item.landuses, 0)}}
			</p>
			<p>
				<strong>Soils:</strong><br />
				{{writeArray(page.view.item.soils, 0)}}
			</p>
			<p>
				<strong>Slopes:</strong><br />
				{{writeArray(page.view.item.slopes, 0)}}
			</p>

			<div slot="modal-footer">
				<b-button type="button" variant="secondary" @click="page.view.show = false">Ok</b-button>
			</div>
		</b-modal>

		<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
			<error-list :errors="page.delete.errors"></error-list>

			<p>
				Are you sure you want to delete <strong>{{page.delete.name}}</strong>?
				This action is permanent and cannot be undone.
			</p>

			<div slot="modal-footer">
				<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
				<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import Mgt2Viewer from '@/components/Mgt2Viewer';

	export default {
		name: 'ScenarioModViewer',
		components: {
			Mgt2Viewer
		},
		props: {
			data: {
				type: Array,
				required: true
			},
			valueLabel: {
				type: String,
				required: true
			},
			valueIsObject: {
				type: Boolean,
				default: false
			},
			appendToValue: {
				type: String,
				default: ''
			},
			isMgt2: {
				type: Boolean,
				default: false
			},
			mgt2Lists: {
				type: Object,
				default: () => {
					return {
						plantList: [],
						fertList: [],
						pestList: [],
						tillList: [],
						manureList: [],
						scheduleOptions: [],
						opList: [],
						irrscList: [],
						ihvGbmList: [],
						wstrsList: [],
						impTrigList: []
					};
				}
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					delete: {
						show: false,
						id: undefined,
						name: '',
						errors: [],
						saving: false
					},
					view: {
						show: false,
						item: {
							subbasins: [],
							landuses: [],
							soils: [],
							slopes: [],
							value: {}
						}
					}
				},
				table: {
					fields: [
						{ key: 'view', label: '', class: 'min' },
						{ key: 'value', label: this.valueLabel, class: 'min' },
						{ key: 'subbasins' },
						{ key: 'landuses', label: 'Land use' },
						{ key: 'soils' },
						{ key: 'slopes' },
						{ key: 'lastModifiedDate', label: 'Added' },
						{ key: 'delete', label: '', class: 'min' }
					]
				},
				months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
			}
		},
		methods: {
			writeArray(items, limit = 5) {
				if (items === null || items.length === 0) return 'All';
				if (limit === 0) limit = items.length;
				var s = items.slice(0, limit).join(', ');

				if (items.length > limit) return s + ' (...)';
				return s;
			},
			askDelete(id, name) {
				this.page.delete.id = id;
				this.page.delete.name = name;
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemod/${this.page.delete.id}`, this.getTokenHeader());
					this.page.delete.show = false;
					this.$emit('deleted', this.page.delete.id);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			showView(item) {
				this.page.view.show = true;
				this.page.view.item = item;
			},
			toMonthNames(item) {
				var names = [];
				for (var i = 0; i < item.length; i++) {
					names.push(this.months[item[i] - 1]);
				}
				return names.join(', ');
			}
		},
		computed: {
			scheduleByHeatUnits() {
				if (this.page.view.item.value.length > 0) {
					return this.page.view.item.value[0].DAY === 0;
				}
				return false;
			}
		}
	}
</script>

<template>
	<div>
		<div v-if="$route.name == 'AdminUsers'">
			<page-authorization-container :page="page" require-admin>
				<h2 class="mb-3">Users</h2>

				<b-alert :show="data.numPending > 0" variant="warning" class="mb-4 py-3">
					<p>
						There {{data.numPending === 1 ? 'is' : 'are'}} currently <strong>{{data.numPending}}</strong> {{data.numPending === 1 ? 'user' : 'users'}} pending approval.
						Click the edit icon next to a user's name to approve/deny, or click the approve all button below.
					</p>

					<b-button :pressed.sync="data.showPending" variant="warning">{{data.showPending ? 'Show all users' : 'Show pending user' + (data.numPending > 1 ? 's' : '')}}</b-button>
				</b-alert>

				<grid-view api-url="admin/users" use-filter allow-export export-url="admin/exports/users" :filter-flag="data.showPending ? 'Pending' : ''"
						   :fields="table.fields"
						   :default-sort="table.sort" :default-reverse="table.reverse"
						   collection-description="users"
						   item-name="user"
						   show-create create-route="create"
						   extra-delete-message="All projects, scenarios, and files for this user will be removed."></grid-view>
			</page-authorization-container>
		</div>
		<router-view></router-view>
</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'AdminUsers',
		components: {
			GridView
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false
				},
				data: {
					showPending: false,
					numPending: 0
				},
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'roles', label: '' },
						{ key: 'lastLoginDate', label: 'Last Log-in', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY h:mm a') } },
						{ key: 'userName', sortable: true },
						{ key: 'email', sortable: true },
						{ key: 'firstName', sortable: true },
						{ key: 'lastName', sortable: true },
						{ key: 'affiliation', sortable: true },
						{ key: 'organizationCategory', label: 'Category', sortable: true },
						{ key: 'country', sortable: true },
						{ key: 'state', sortable: true },
						{ key: 'registrationDate', label: 'Registered', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'isLockedOut', label: 'Locked?', sortable: true }
					],
					sort: 'lastLoginDate',
					reverse: true,
					itemsPerPage: 50
				}
			}
		},
		watch: {
			'$route': 'get'
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				if (this.$route.name == 'AdminUsers') {
					this.page.errors = [];
					this.page.loading = true;

					try {
						const response = await this.$http.get('admin/users/pending', this.getTokenHeader());
						this.log(response.data);
						this.data.numPending = response.data.numPending;
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.errors = this.logError(error);
					}

					this.page.loading = false;
				}
			}
		}
	}
</script>

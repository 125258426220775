<template>
	<b-button :type="type" :variant="variant" :size="size" :disabled="saving || disabled">
		<font-awesome-icon v-if="saving" :icon="['fas', 'spinner']" spin />
		{{ text }}
	</b-button>
</template>

<script>
export default {
	name: 'SaveButton',
	props: {
		saving: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		text: {
			type: String,
			default: 'Save Changes'
		},
		type: {
			type: String,
			default: 'submit'
        },
        variant: {
            type: String,
            default: 'success'
        },
        size: {
            type: String,
            default: 'md'
        }
	}
}
</script>

<template>
	<div>
		<div v-if="$route.name == 'AdminGroups'">
			<h2 class="mb-3">Groups</h2>

			<p>
				Groups are a way for users to share projects and scenarios with each other.
				Any user can create a group then invite other users to join by entering their email address.
				Users choose which projects to share with the group by clicking the share button from a project's page.
				All users in the group may view, edit*, and run* projects shared with the group.
				(*Note: tier limits still apply.)
			</p>

			<grid-view api-url="admin/users/groups" delete-api-url="groups"
					   :fields="table.fields" link-name
					   :default-sort="table.sort" :default-reverse="table.reverse" :default-per-page="table.itemsPerPage"
					   collection-description="groups"
					   item-name="group"
					   extra-delete-message="It is recommended to let users manage their own groups. No projects or files will be deleted. Access will return to just the creator."></grid-view>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'AdminGroups',
		components: {
			GridView
		},
		data() {
			return {
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true, class: 'min-w-200' },
						{ key: 'numProjects', label: 'Projects', sortable: true },
						{ key: 'numMembers', label: 'Members', sortable: true },
						{ key: 'numInvitedUsers', label: 'Invitations', sortable: true },						
						{ key: 'createdOn', sortable: true, formatter: (value) => { return moment(value).format('llll') } },
						{ key: 'createdBy', sortable: true },
						{ key: 'delete', label: '', sortable: false, class: 'min' }
					],
					sort: 'name',
					reverse: true,
					itemsPerPage: 50
				}
			}
		}
	}
</script>

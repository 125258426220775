<template>
	<div :class="'map-container ' + (page.customWidth? '' : (page.customHeight ? 'w-100' : 'full-map'))" :style="(page.customHeight ? `height: ${height};` : '') + (page.customWidth ? `width: ${width};` : '')">
		<l-map :ref="refName" style="height: 100%; width:100%" :zoom="defaultMapZoom" :center="defaultMapBounds" :bounds="map.bounds" @mousemove="mapMove">
			<l-control-layers v-if="showLayerControl" position="topright"></l-control-layers>
			<l-tile-layer v-for="tileProvider in defaultMapTileProviders"
						  :key="tileProvider.name"
						  :name="tileProvider.name"
						  :visible="tileProvider.visible"
						  :url="tileProvider.url"
						  :attribution="tileProvider.attribution"
						  layer-type="base" />
			<l-layer-group layer-type="overlay" name="Subbasins">
				<l-geo-json v-if="map.subbasins.geojson !== undefined" :geojson="map.subbasins.geojson" :options="mapSubbasinsOptions" :options-style="mapSubbasinsStyle"></l-geo-json>
			</l-layer-group>
			<l-layer-group v-if="map.streams.geojson !== undefined" layer-type="overlay" name="Streams">
				<l-geo-json :geojson="map.streams.geojson" :options="map.streams.options"></l-geo-json>
			</l-layer-group>
			<l-layer-group layer-type="overlay" v-for="(extraLayer, i) in allowedExtraLayers" :key="i" :name="extraLayer.name" :ref="`extraLayer${i}`" :visible="!['Ponds', 'Reservoirs', 'Point Source Discharge'].includes(extraLayer.name)">
				<l-geo-json :geojson="extraLayer.geojson" :options="mapExtraLayersOptions(i)"></l-geo-json>
			</l-layer-group>
			<l-control position="bottomleft" v-if="!page.customHeight">
				<div v-if="map.currentCoords !== undefined">Cursor position: {{map.currentCoords.lat | number(3)}}, {{map.currentCoords.lng | number(3)}}</div>
			</l-control>
			<l-layer-group ref="routeLayer" :visible="!page.loading" v-if="projectMap.connections.length > 1" layer-type="overlay" name="Route">
				<l-polyline v-for="(c, i) in polylines" :key="i" :lat-lngs="c.lineCoordinates" color="green"></l-polyline>
				<v-polyline-decorator :paths="routeCoordinates" :patterns="map.patterns"></v-polyline-decorator>
			</l-layer-group>
			<l-control v-if="showLayerControl" position="bottomright">
				<b-button variant="dark" size="sm" @click="toggleColors" style="font-size:0.8rem">Toggle Contrast</b-button>
			</l-control>
		</l-map>
		<div v-if="page.loading" class="map-loading-overlay">
			<font-awesome-icon :icon="['fas', 'spinner']" spin size="3x" />
		</div>

		<b-modal v-model="usgsSite.show" :title="`USGS Site ` + (usgsSite.item !== null ? `${usgsSite.item.number}, ${usgsSite.item.name}` : '')" scrollable size="xl">
			<p class="font-italic">
				Daily streamflow data is retrieved from <a href="https://waterservices.usgs.gov/" target="_blank">waterservices.usgs.gov</a>.
			</p>

			<error-list :errors="usgsSite.errors"></error-list>
			<page-loading :loading="usgsSite.loading"></page-loading>

			<div v-if="!usgsSite.loading && usgsSite.errors.length < 1">
				<b-form inline @submit.prevent="queryWaterServices">
					<label class="mr-sm-2" for="usgsStartDate">Start date</label>
					<b-form-input id="usgsStartDate" class="mb-2 mr-sm-3 mb-sm-0" v-model="usgsSite.options.startDT" type="date"></b-form-input>

					<div class="d-lg-none"></div>
					<label class="mr-sm-2" for="usgsEndDate">End date</label>
					<b-form-input id="usgsEndDate" class="mb-2 mr-sm-3 mb-sm-0" v-model="usgsSite.options.endDT" type="date" :max="todaysDate"></b-form-input>

					<b-form-select class="mb-2 mr-sm-3 mb-sm-0" v-model="usgsSite.options.units" :options="usgsSite.options.unitOptions"></b-form-select>

					<save-button :saving="usgsSite.saving" text="Update Chart" class="mr-2" />
				</b-form>

				<hr class="my-3" />

				<div>
					<highcharts :options="usgsSite.display.chartOptions" :updateArgs="usgsSite.display.updateArgs"></highcharts>
				</div>
			</div>

			<div slot="modal-footer">
				<b-button type="button" variant="primary" @click="usgsSite.show = false" class="ml-1">Close</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import L from 'leaflet';
	import { LMap, LTileLayer, LGeoJson, LControl, LPolyline, LControlLayers, LLayerGroup } from 'vue2-leaflet';
	import Vue2LeafletPolylineDecorator from '@/components/helpers/Vue2LeafletPolylineDecorator';
	import _ from 'underscore';
	import moment from 'moment';
	import 'leaflet/dist/leaflet.css';
	var bbox = require('geojson-bbox');

	var __globalStartingSubs = [];
	var __globalColors = {};

	export default {
		name: 'ProjectMap',
		components: {
			LMap, LTileLayer, LGeoJson, LControl, LPolyline, LControlLayers, LLayerGroup, 'v-polyline-decorator': Vue2LeafletPolylineDecorator
		},
		props: {
			projectMap: {
				type: Object,
				required: true
			},
			showLayerControl: {
				type: Boolean,
				default: false
			},
			height: {
				type: String,
				default: '100%'
			},
			width: {
				type: String,
				default: '100%'
			},
			highlightSelected: {
				type: Boolean,
				default: false
			},
			selectMultiple: {
				type: Boolean,
				default: false
			},
			selectedSubbasins: {
				type: Array,
				default: () => []
			},
			refName: {
				type: String,
				default: 'leafmap'
			},
			showExtraLayers: {
				type: Boolean,
				default: false
			},
			loadOnlyOnView: {
				type: String,
				default: ''
			},
			isRegional: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					customHeight: this.height != '100%',
					customWidth: this.width != '100%'
				},
				map: {
					bounds: undefined,
					subbasins: {
						geojson: undefined
					},
					streams: {
						geojson: undefined,
						options: {
							style: {
								weight: 1,
								opacity: 1,
								color: '#006da0',
								dashArray: '0'
							}
						}
					},
					currentCoords: undefined,
					route: [],
					patterns: [
						{ offset: '50%', repeat: 0, symbol: L.Symbol.arrowHead({ pixelSize: 15, polygon: false, pathOptions: { color: 'green', stroke: true } }) }
					]
				},
				startingSubs: this.selectedSubbasins,
				layerColors: [
					'#a4f24b',
					'#ff194f',
					'#ce29e8',
					'#0274cc',
					'#f7ff1e'
				],
				usgsSite: {
					show: false,
					errors: [],
					loading: false,
					saving: false,
					item: {
						number: null,
						name: null,
						isUsgsSite: false,
						data: {}
					},
					data: {},
					display: {
						name: null,
						latitude: null,
						longitude: null,
						variable: null,
						variableDescription: null,
						valueType: null,
						units: null,
						chartOptions: {
							chart: { zoomType: 'x' },
							title: { text: null },
							xAxis: [{
								title: null,
								type: 'datetime',
								labels: {
									format: '{value:%b %e}'
								},
								opposite: false
							}],
							yAxis: [{
								title: { text: 'Streamflow' },
								labels: { format: '{value} ft3/s' },
								gridLineWidth: 1,
								opposite: false
							}],
							series: [{
								data: [],
								name: 'Streamflow',
								type: 'line',
								xAxis: 0,
								yAxis: 0,
								tooltip: {
									valueSuffix: ' ft3/s',
									valueDecimals: 2
								}
							}],
							plotOptions: {
								series: { turboThreshold: 0 }
							},
							exporting: {
								csv: { dateFormat: '%Y-%m-%d' }
							}
						},
						updateArgs: [true, true, { duration: 1000 }]
					},
					options: {
						usePeriod: false,
						period: 'P7D',
						startDT: null,
						endDT: null,
						unitOptions: [
							{ value: 0, text: 'Metric units' },
							{ value: 1, text: 'English units' }
						],
						units: 0
					},
					baseUrl: 'https://waterservices.usgs.gov/nwis/dv/?format=json&parameterCd=00060&siteStatus=all'
				}
			}
		},
		async created() {
		},
		async mounted() {
			await this.get();
			if (this.projectMap.connections.length > 1)
				this.$refs.routeLayer.mapObject.remove();
			/*if (this.showExtraLayers && this.projectMap.extraLayers.length > 0) {
				for (let i = 0; i < this.projectMap.extraLayers.length; i++) {
					if (this.projectMap.extraLayers[i].name in ['Ponds', 'Reservoirs']) {
						this.$refs[`extraLayer${i}`].mapObject.remove();
					}
				}
			}*/
			this.renderMap();
		},
		watch: {
			selectedSubbasins(newVal, oldVal) {
				this.startingSubs = newVal;
				__globalStartingSubs = this.startingSubs;
			}
		},
		methods: {
			async get() {
				this.log(this.startingSubs);
				this.log("Map");
				this.log(this.projectMap);
				this.page.errors = [];
				this.page.loading = true;

				this.loadMapColors();
				this.map.streams.options.style.color = this.mapColors.stream;

				this.usgsSite.options.endDT = this.todaysDate;
				this.usgsSite.options.startDT = moment().subtract(1, 'months').format('YYYY-MM-DD');
				this.usgsSite.options.units = this.projectMap.defaultUnits;

				if (this.isNullOrEmpty(this.loadOnlyOnView) || this.$route.name == this.loadOnlyOnView) {
					try {
						/*
						Nice idea, but there is a hard limit of 5MB local storage, so this isn't very useful.
	
						let storedSubbasinsKey = `subs_geojson:${this.projectMap.geoJsonUrls.subbasins}`;
						let storedSubbasinsGeojson = localStorage.getItem(storedSubbasinsKey);
						if (!this.isNullOrEmpty(storedSubbasinsGeojson)) {
							this.map.subbasins.geojson = JSON.parse(storedSubbasinsGeojson);
							this.log(`Retrieved map from local storage ${storedSubbasinsKey}`);
						} else {
							const subsResponse = await this.$httpRoot.get(this.projectMap.geoJsonUrls.subbasins);
							this.map.subbasins.geojson = subsResponse.data;
							localStorage.setItem(storedSubbasinsKey, JSON.stringify(subsResponse.data));
						}
	
						let storedStreamsKey = `streams_geojson:${this.projectMap.geoJsonUrls.streams}`;
						let storedStreamsGeojson = localStorage.getItem(storedStreamsKey);
						if (!this.isNullOrEmpty(storedStreamsGeojson)) {
							this.map.streams.geojson = JSON.parse(storedStreamsGeojson);
						} else {
							const streamsResponse = await this.$httpRoot.get(this.projectMap.geoJsonUrls.streams);
							this.map.streams.geojson = streamsResponse.data;
							localStorage.setItem(storedStreamsKey, JSON.stringify(streamsResponse.data));
						}*/

						const subsResponse = await this.$httpRoot.get(this.projectMap.geoJsonUrls.subbasins);
						this.map.subbasins.geojson = subsResponse.data;

						if (!this.isRegional && (this.projectMap.connections.length < this.globals.maxSubbasinsForMap)) {
							const streamsResponse = await this.$httpRoot.get(this.projectMap.geoJsonUrls.streams);
							this.map.streams.geojson = streamsResponse.data;
						}

						this.map.route = this.projectMap.connections;
					} catch (error) {
						this.page.errors = this.logError(error);
					}
				}

				this.page.loading = false;
			},
			mapMove(e) {
				this.map.currentCoords = e.latlng;
			},
			mapSubbasinsClick(e) {
				var sub = e.target.feature.properties;
				var item = { id: sub.id, name: sub.name };

				if (this.highlightSelected) {
					if (this.selectMultiple) {
						var index = this.startingSubs.findIndex(x => x.id == sub.id);
						if (index === -1) {
							this.startingSubs.push(item);
						} else {
							this.removeStartingSub(sub.id);
						}
					} else {
						this.startingSubs = [item];
					}
					__globalStartingSubs = this.startingSubs;
				}

				this.$emit('subbasin-click', this.startingSubs);
			},
			removeStartingSub(id) {
				if (this.highlightSelected) {
					this.startingSubs = this.startingSubs.filter(function (el) { return el.id != id; });
					__globalStartingSubs = this.startingSubs;
				}
			},
			renderMap() {
				window.dispatchEvent(new Event('resize'));
				var ref = this.$refs[this.refName];
				var self = this;
				this.$nextTick(function () {
					ref.mapObject.invalidateSize(true);
					self.setMapBounds();
				});
			},
			setMapBounds() {
				this.map.bounds = L.latLngBounds(this.projectMap.watershedBounds);
				if (this.map.subbasins.geojson) {
					var bounds = bbox(this.map.subbasins.geojson);
					this.map.bounds = L.latLngBounds([[bounds[3], bounds[2]], [bounds[1], bounds[0]]]);
				} else {
					this.map.bounds = L.latLngBounds(this.projectMap.watershedBounds);
				}
			},
			toggleColors() {
				this.setMapColors(!this.mapColors.highContrast);
				this.map.streams.options.style.color = this.mapColors.stream;
			},
			mapExtraLayersOptions(i) {
				return {
					onEachFeature: this.mapExtraLayersOnEachFeature,
					pointToLayer: this.mapExtraLayersPointToLayer(i)
				};
			},
			mapExtraLayersPointToLayer(i) {
				return (feature, latlng) => {
					let color = this.layerColors[0];
					if (i < this.layerColors.length) {
						color = this.layerColors[i];
					}

					return L.circleMarker(latlng, {
						radius: 5,
						fillColor: color,
						color: '#000',
						weight: 1,
						opacity: 1,
						fillOpacity: 0.9
					});
				}
			},
			async usgsSiteClick(e) {
				let props = e.target.feature.properties;
				this.log(props);

				let keys = Object.keys(props);
				let hasCalibrations = keys.includes('calibrations');
				if (hasCalibrations) {
					for (let cal of props.calibrations) {
						if (cal.isUsgsSite) {
							this.usgsSite.item = cal;
							this.usgsSite.data = {};
							this.usgsSite.show = true;
							await this.queryWaterServices();
							break;
						}
					}
				}			
			},
			async queryWaterServices() {
				this.usgsSite.loading = true;
				this.usgsSite.errors = [];

				try {
					let url = `${this.usgsSite.baseUrl}&sites=${this.usgsSite.item.number}`;

					if (this.usgsSite.options.usePeriod) {
						url += `&period=${this.usgsSite.options.period}`;
					} else {
						url += `&startDT=${this.usgsSite.options.startDT}&endDT=${this.usgsSite.options.endDT}`;
					}

					const response = await this.$httpRoot.get(url);
					this.log(response);

					this.usgsSite.data = response.data;
				} catch (error) {
					this.usgsSite.errors = this.logError(error);
				}

				if (this.usgsSite.errors.length < 1) {
					try {
						let series = this.usgsSite.data.value.timeSeries[0];
						this.usgsSite.display.name = series.sourceInfo.siteName;
						this.usgsSite.display.latitude = series.sourceInfo.geoLocation.geogLocation.latitude;
						this.usgsSite.display.longitude = series.sourceInfo.geoLocation.geogLocation.longitude;
						this.usgsSite.display.variable = series.variable.variableName;
						this.usgsSite.display.variableDescription = series.variable.variableDescription;
						this.usgsSite.display.valueType = series.variable.valueType;
						this.usgsSite.display.units = series.variable.unit.unitCode;

						let multiplier = 1;
						if (this.usgsSite.options.units === 0) {
							this.usgsSite.display.chartOptions.yAxis[0].labels.format = '{value} m3/s';
							this.usgsSite.display.chartOptions.series[0].tooltip.valueSuffix = ' m3/s';
							this.usgsSite.display.units = 'm3/s';
							multiplier = 0.0283168;
						} else {
							this.usgsSite.display.chartOptions.yAxis[0].labels.format = '{value} ft3/s';
							this.usgsSite.display.chartOptions.series[0].tooltip.valueSuffix = ' ft3/s';
							this.usgsSite.display.units = 'ft3/s';
						}

						this.usgsSite.display.chartOptions.title.text = `USGS Site ${this.usgsSite.item.number} Daily Average Streamflow (${this.usgsSite.display.units})`;

						let zone = series.sourceInfo.timeZoneInfo.defaultTimeZone.zoneOffset;
						if (series.sourceInfo.timeZoneInfo.siteUsesDaylightSavingsTime) {
							zone = series.sourceInfo.timeZoneInfo.daylightSavingsTimeZone.zoneOffset;
						}

						let timeSeries = series.values[0].value;
						this.usgsSite.display.chartOptions.series[0].data = [];
						let chartData = [];

						for (let point of timeSeries) {
							chartData.push({
								x: moment(point.dateTime).utcOffset(zone).valueOf(),
								y: Number(point.value) * multiplier
							});
						}

						this.usgsSite.display.chartOptions.series[0].data = chartData;
					} catch (error) {
						let errorMessage = 'Error parsing response from waterservices.usgs.gov web API. Data may be incomplete or the format was unexpected.';
						if (this.usgsSite.data.value.timeSeries.length < 1) {
							errorMessage = `No time series data available for USGS site #${this.usgsSite.item.number}.`;
						}

						this.usgsSite.errors.push(errorMessage);
						console.log(this.usgsSite.data);
						console.log(this.usgsSite.display);
						console.log(error);
					}
				}

				this.usgsSite.loading = false;
			}
		},
		computed: {
			todaysDate() {
				return moment().format('YYYY-MM-DD');
			},
			allowedExtraLayers() {
				if (!this.showExtraLayers) return [];
				return this.projectMap.extraLayers;
			},
			polylines() {
				return this.projectMap.connections.filter(function (el) { return el.lineCoordinates[1].length > 0; })
			},
			mapExtraLayersOnEachFeature() {
				return (feature, layer) => {
					let popText = '';
					let keys = Object.keys(feature.properties);
					let hasCalibrations = keys.includes('calibrations');

					let isUsgsSite = false;
					if (hasCalibrations) {
						for (let cal of feature.properties.calibrations) {
							if (cal.isUsgsSite) {
								popText += `<div>USGS Site: ${cal.number}</div>`;
							}

							popText += `<div>${cal.name}</div><div class="mt-2"><table class="table table-sm">`;

							/*let calKeys = Object.keys(cal.data);
							for (let k of calKeys) {
								let name = k == 'CalibrationYears' ? 'Calibration years' : k.toUpperCase();
								popText += `<div>${name}: ${cal.data[k]}</div>`;
							}*/
							//Change to array
							let i = 0;
							for (let d of cal.data) {
								let calKeys = Object.keys(d);
								if (i === 0) {
									popText += `<tr>`;
									for (let k of calKeys) {
										let name = k == 'CalibrationYears' ? 'Calibration years' : k;
										popText += `<th>${name}</th>`;
									}
									popText += `</tr>`;
								}

								popText += `<tr>`;
								for (let k of calKeys) {
									popText += `<td>${d[k]}</td>`;
								}
								popText += `</tr>`;
								i++;
							}

							popText += `</table></div>`;
							if (cal.isUsgsSite) {
								popText += `<div class="mt-2 font-italic">Click the dot for streamflow data</div>`;
							}

							isUsgsSite = isUsgsSite || cal.isUsgsSite;
						}

						layer.on({
							click: this.usgsSiteClick
						});
					} else {
						for (let k of keys) {
							popText += `<div>${k}: ${feature.properties[k]}</div>`;
						}
					}					

					layer.bindTooltip(
						popText,
						{ permanent: false, sticky: true, interactive: isUsgsSite });
				};
			},
			mapSubbasinsOptions() {
				return {
					onEachFeature: this.mapSubbasinsOnEachFeature
				};
			},
			mapSubbasinsStyle() {
				__globalStartingSubs = this.startingSubs;
				__globalColors = this.mapColors;
				return (feature) => {
					var fillColor = feature.properties.isDownstream ? __globalColors.downstream : (feature.properties.isNearbyOnly ? __globalColors.nearby : __globalColors.subbasin);
					var index = __globalStartingSubs.findIndex(x => x.id == feature.properties.id);
					if (index > -1)
						fillColor = __globalColors.selected;

					return {
						fillColor: fillColor,
						weight: 1,
						opacity: 0.8,
						color: 'black',
						dashArray: '0',
						fillOpacity: feature.properties.isDownstream ? 0.9 : 0.5
					};
				};
			},
			mapSubbasinsOnEachFeature() {
				return (feature, layer) => {
					let streamName = feature.properties.name === feature.properties.streams ? '' : feature.properties.streams;
					let landArea = feature.properties.landArea;
					let landAreaStr = this.isNullOrEmpty(landArea) || landArea === 0 ? '' : `<br />${this.numberWithCommas(landArea.toFixed(2))} km<sup>2</sup> land-only area`;
					let cArea = feature.properties.cumulativeArea;
					let cAreaStr = this.isNullOrEmpty(cArea) || cArea === 0 ? '' : `<br />${this.numberWithCommas(cArea.toFixed(2))} km<sup>2</sup> cumulative area`;
					layer.bindTooltip(
						'<div>' + feature.properties.name + '</div>' +
						'<div>' + streamName + '</div>' +
						'<div class="mt-2">' + this.numberWithCommas(feature.properties.area.toFixed(2)) + 'km<sup>2</sup> area' + landAreaStr + cAreaStr + '<br />' +
						this.numberWithCommas(feature.properties.elevation.toFixed(2)) + 'm elevation</div>',
						{ permanent: false, sticky: true });

					layer.on({
						click: this.mapSubbasinsClick
					})
				}
			},
			routeCoordinates() {
				var plArray = [];
				for (var i = 0; i < this.projectMap.connections.length; i++) {
					if (this.projectMap.connections[i].lineCoordinates[1].length > 0)
						plArray.push(this.projectMap.connections[i].lineCoordinates);
				}
				return plArray;
			}
		}
	}
</script>

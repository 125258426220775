<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Submit feedback / error report</h2>

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>

				<b-form-group label="Report title" :invalid-feedback="requiredFeedback($v.form.title)">
					<b-form-input v-model.trim="$v.form.title.$model" type="text" autofocus :state="getValidState($v.form.title)"></b-form-input>
				</b-form-group>

				<b-form-group label="If the report is related to a scenario, select it below">
					<b-form-select v-model="form.scenarioID" :options="scenarios">
						<template v-slot:first>
							<option :value="null">None</option>
						</template>
					</b-form-select>
				</b-form-group>

				<b-form-group label="Please provide a description of the error" :invalid-feedback="requiredFeedback($v.form.userComments)">
					<b-form-textarea v-model.trim="$v.form.userComments.$model" rows="10" :state="getValidState($v.form.userComments)"></b-form-textarea>
				</b-form-group>

				<fixed-action-bar>
					<save-button :saving="page.saving" class="mr-2" />
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'ErrorReportCreate',
		data() {
			return {
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false
				},
				form: {
					projectID: this.$route.params.id,
					scenarioID: null,
					title: '',
					userComments: ''
				},
				scenarios: []
			}
		},
		validations: {
			form: {
				title: { required },
				userComments: { required }
			}
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`projects/scenarios/listall/${this.projectID}`, this.getTokenHeader());
					this.scenarios = response.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post(`errorreports`, this.form, this.getTokenHeader());
						this.log(response.data);
						this.$router.push({ name: 'ErrorReportList' }).catch(err => {});
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2>Pothole Variables</h2>

			<p>
				Read the <a href="/content/docs/io/ch19_input_hru.pdf" target="_blank">SWAT2012 IO documentation chapter on HRU inputs</a>, see pages 5, 9, and 10 for pothole variables.
			</p>

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<div class="table-responsive mb-3">
					<b-table responsive class="table-valign-m mb-0" :items="pothole" :fields="fieldsHeading">
						<template v-slot:cell(value)="data">
							<b-form-input v-model.trim="$v.item[data.item.variable].$model" type="number" step="any" :state="getValidState($v.item[data.item.variable])"></b-form-input>
						</template>
					</b-table>
				</div>

				<hru-selector :projectID="Number($route.params.id)" @change="hruSelectionChange"></hru-selector>
				<scenario-mod-viewer v-if="mods.length > 0" @deleted="modDeleted" :data="mods" value-label="Values" value-is-object></scenario-mod-viewer>

				<fixed-action-bar>
					<span id="save-button-tooltip">
						<save-button :saving="page.saving" :disabled="!validHruSelection || !$v.$anyDirty" class="mr-2" />
					</span>
					<b-tooltip :disabled="validHruSelection" target="save-button-tooltip">
						Select at least one subbasin, land use, soil, and slope.
					</b-tooltip>
					<back-button class="btn btn-secondary mr-2" />

					<save-button v-if="mods.length > 0" :saving="page.delete.saving" type="button"
								 variant="danger" text="Remove Changes"
								 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
								 @click.native="askDelete" class="ml-auto" />
				</fixed-action-bar>

				<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
					<error-list :errors="page.delete.errors"></error-list>

					<p>
						Are you sure you want to delete all pothole variable changes?
						This action is permanent and cannot be undone.
					</p>

					<div slot="modal-footer">
						<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
						<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</b-form>			
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, between } from 'vuelidate/lib/validators';
	import HruSelector from '@/components/HruSelector';
	import ScenarioModViewer from '@/components/ScenarioModViewer';

	export default {
		name: 'EditPotholes',
		components: {
			HruSelector, ScenarioModViewer
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				options: {
					min: -10,
					max: 10
				},
				hruSelection: {
					applyToAll: true,
					subbasins: [],
					landuse: [],
					soils: [],
					slopes: []
				},
				fieldsHeading: [
					{ key: 'value', class: 'min-w-100 table-input-col' },
					{ key: 'units', class: 'min' },
					{ key: 'description', class: 'wrap' },
					'variable', 'valid_range'
				],
				potholesFields: ['POT_FR', 'POT_TILE', 'POT_VOLX', 'POT_VOL', 'SED_CON', 'ORGN_CON', 'ORGP_CON', 'SOLN_CON', 'SOLP_CON'],
				description: [
					'Fraction of HRU area that drains into pothole',
					'Average daily outflow to main channel from tile flow if drainage tiles are installed in the pothole',
					'Maximum volume of water stored in the pothole',
					'Initial volume of water stored in the pothole',
					'Sediment concentration in runoff, after urban BMP is applied',
					'Organic nitrogen concentration in runoff, after urban BMP is applied',
					'Organic phosphorus concentration in runoff, after urban BMP is applied',
					'Soluble nitrogen concentration in runoff, after urban BMP is applied',
					'Soluble phosphorus concentration in runoff, after urban BMP is applied'
				],
				units: ['', 'mm', 'mm', 'mm', 'ppm', 'ppm', 'ppm', 'ppm', 'ppm'],
				valid_range: ['0 to 1', '0 to 100', '0 to 100', '0 to 100', '0 to 5000', '0 to 100', '0 to 50', '0 to 10', '0 to 3'],
				pothole: [],
				item: {},
				mods: []
			}
		},
		validations() {
			var item = {};
			for (var i = 0; i < this.potholesFields.length; i++) {
				item[this.potholesFields[i]] = { required };
			}
			return { item: item };
		},
		async created() {
			await this.get();
		},
		watch: {
			'$route': 'get'
		},
		methods: {
			async get() {
				this.projectID = this.$route.params.id;
				this.scenarioID = this.$route.params.scenarioID;
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/subbasin/potholes/${this.scenarioID}/`, this.getTokenHeader());
					this.mods = response.data.mods;

					this.pothole = [];
					for (var i = 0; i < this.potholesFields.length; i++) {
						var n = this.potholesFields[i];
						this.pothole.push({
							variable: n,
							description: this.description[i],
							units: this.units[i],
							valid_range: this.valid_range[i]
						});
						this.item[n] = 0;
					}
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.log(this.$v);

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				}
				else {
					try {
						var item = {};
						var keys = Object.keys(this.potholesFields);

						for (var i = 0; i < keys.length; i++) {
							if (this.$v.item[this.potholesFields[i]] && (this.$v.item[this.potholesFields[i]].$dirty)) {
								item[this.potholesFields[i]] = Number(this.item[this.potholesFields[i]]);
							}
						}
						this.log(item);

						var data = {
							applyToAll: this.hruSelection.applyToAll,
							subbasins: this.hruSelection.subbasins,
							landuses: this.hruSelection.landuse,
							soils: this.hruSelection.soils,
							slopes: this.hruSelection.slopes,
							modificationObject: item
						};
						const response = await this.$http.post(`scenarios/subbasin/save/${this.scenarioID}/Potholes`, data, this.getTokenHeader());
						this.log(response.data);
						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();


					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			async modDeleted(id) {
				await this.get();
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemodtype/${this.scenarioID}/Potholes`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			hruSelectionChange(selection) {
				this.hruSelection = selection;
			}
		},
		computed: {
			validHruSelection() {
				return this.hruSelection.applyToAll ||
					(this.hruSelection.subbasins.length > 0 &&
						this.hruSelection.landuse.length > 0 &&
						this.hruSelection.soils.length > 0 &&
						this.hruSelection.slopes.length > 0);
			}
		}
	}
</script>

<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Output Maps</h2>

			<div v-if="$parent.scenario.status.isRunning">
				<page-running />
			</div>
			<div v-else-if="options.noOutputProcessing">
				<b-alert variant="warning" show>Output processing was skipped for this scenario run. Please re-run your scenario without the "skip output processing" box checked to use this feature.</b-alert>
			</div>
			<div v-else>
				<b-row>
					<b-col md="6">
						<b-form @submit.prevent="save">
							<b-form-group :invalid-feedback="requiredFeedback($v.selection.outputType)">
								<b-form-select v-model="selection.outputType" :options="options.outputTypes.types" :state="getValidState($v.selection.outputType)" @change="selection.parameter = null">
									<template v-slot:first>
										<option :value="null" disabled>-- Select an output type --</option>
									</template>
								</b-form-select>
							</b-form-group>
							<b-form-group :invalid-feedback="requiredFeedback($v.selection.statistic)">
								<b-form-select v-model="selection.statistic" :options="options.statistics" :state="getValidState($v.selection.statistic)">
									<template v-slot:first>
										<option :value="null" disabled>-- Select a statistic --</option>
									</template>
								</b-form-select>
							</b-form-group>
							<b-form-group v-if="!isNullOrEmpty(selection.outputType)" :invalid-feedback="requiredFeedback($v.selection.parameter)">
								<b-form-select v-model="selection.parameter" :state="getValidState($v.selection.parameter)">
									<template v-slot:first>
										<option :value="null" disabled>-- Select a parameter --</option>
									</template>
									<option v-for="(o, i) in options.outputTypes.parameters[selection.outputType]" :key="i" :value="o.variable">
										{{o.variable}} - {{o.description}}
									</option>
								</b-form-select>
							</b-form-group>
							<b-form-group v-if="!isNullOrEmpty(selection.outputType)" :invalid-feedback="requiredFeedback($v.selection.units)">
								<b-form-select v-model="selection.units" :options="options.outputTypes.units" :state="getValidState($v.selection.units)"></b-form-select>
								<small class="text-muted">Set the default units displayed from your <router-link to="/account">account settings</router-link></small>
							</b-form-group>

							<div v-if="options.scenarios.length > 0" class="my-3">
								<div class="checklist-header">
									Select up to {{page.maxToCompare}} other scenarios for output comparison (optional)
								</div>
								<div class="checklist">
									<b-form-checkbox-group v-model="selection.compareScenarios" stacked>
										<div class="item" v-for="(o, i) in options.scenarios" :key="i">
											<b-form-checkbox :value="o.value">{{o.text}}</b-form-checkbox>
										</div>
									</b-form-checkbox-group>
								</div>
							</div>

							<b-alert :show="selection.compareScenarios.length > page.maxToCompare" variant="warning">
								You may only compare to up to {{page.maxToCompare}} scenarios at a time. Please modify your selection.
							</b-alert>

							<error-list :errors="page.saveErrors"></error-list>

							<div class="mt-4 mb-3">
								<save-button :saving="page.saving" class="mr-2" text="Get Map" :disabled="isNullOrEmpty(selection.parameter) || selection.compareScenarios.length > page.maxToCompare" />
								<b-button v-if="maps.scenarioMaps.length > 0" type="button" variant="secondary" @click="page.editColors.show = true">Change Colors</b-button>
							</div>
						</b-form>
					</b-col>
					<b-col md>
						<project-map :project-map="options.map" height="300px"></project-map>
					</b-col>
				</b-row>

				<b-row class="my-3">
					<b-col md="6" v-for="(map, i) in maps.scenarioMaps" :key="i">
						<div class="my-3">
							<highcharts :constructor-type="'mapChart'" :options="map" class="map"></highcharts>
						</div>
					</b-col>
				</b-row>

				<b-modal v-model="page.editColors.show" size="lg" title="Change map colors">
					<b-row>
						<b-col md="8">
							<b-form-group label="Choose how to display colors on the map">
								<b-form-radio-group v-model="outMapColors.useDataClasses">
									<b-form-radio :value="false">Gradient</b-form-radio>
									<b-form-radio :value="true">Defined ranges (advanced)</b-form-radio>
								</b-form-radio-group>
							</b-form-group>
						</b-col>
						<b-col md class="text-right">
							<b-form-checkbox v-model="outMapColors.showLabels" switch>Show labels</b-form-checkbox>
						</b-col>
					</b-row>

					<div v-if="!outMapColors.useDataClasses">
						<b-row>
							<b-col md>
								<b-form-group label="Minimum value color">
									<b-form-input type="color" v-model="outMapColors.min"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md>
								<b-form-group label="Median value color">
									<b-form-input type="color" v-model="outMapColors.mid"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row>
							<b-col md>
								<b-form-group label="Maximum value color">
									<b-form-input type="color" v-model="outMapColors.max"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col md>
								<b-form-group label="Stream color">
									<b-form-input type="color" v-model="maps.riverSeries.nullColor"></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>

						<b-row>
							<b-col md>
								<b-button @click="reverseColors" variant="light" typeof="button">Reverse min/max colors</b-button>
							</b-col>
							<b-col md>
								<b-form-group label="Color axis scale">
									<b-form-select v-model="outMapColors.scale">
										<option>linear</option>
										<option>logarithmic</option>
									</b-form-select>
								</b-form-group>
							</b-col>
						</b-row>
					</div>
					<div v-else>
						<p>
							Set a specific color for a range of values (from value x to value y). You will need to come back and adjust these values if you change the parameter being mapped.
						</p>

						<table class="table border-bottom table-sm mb-2">
							<thead>
								<tr class="bg-light">
									<th>From</th>
									<th>To</th>
									<th>Color</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(dc, i) in outMapColors.dataClasses" :key="i">
									<td><b-form-input type="number" v-model="dc.from" step="any"></b-form-input></td>
									<td><b-form-input type="number" v-model="dc.to" step="any"></b-form-input></td>
									<td><b-form-input type="color" v-model="dc.color"></b-form-input></td>
									<td><font-awesome-icon :icon="['fas', 'times']" class="text-danger pointer" @click="removeColorDataClass(dc)" v-b-tooltip.hover.right="'Delete'" /></td>
								</tr>
							</tbody>
						</table>

						<b-button type="button" variant="primary" @click="addColorDataClass">Add Range</b-button>
					</div>

					<div slot="modal-footer">
						<save-button type="button" :saving="false" @click.native="drawMaps(); page.editColors.show = false" text="Ok" />
						<b-button type="button" variant="secondary" @click="page.editColors.show = false" class="ml-1">Cancel</b-button>
					</div>
				</b-modal>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';
	import ProjectMap from '@/components/ProjectMap';

	export default {
		name: 'OutputMaps',
		components: {
			ProjectMap
		},
		data() {
			return {
				scenarioID: this.$route.params.scenarioID,
				projectID: this.$route.params.id,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					maxToCompare: 3,
					editColors: {
						show: false
					}
				},
				options: {
					outputTypes: {
						types: [],
						parameters: [],
						units: []
					},
					scenarios: [],
					statistics: [],
					map: {},
					noOutputProcessing: false
				},
				geojson: {
					subbasins: {},
					streams: {}
				},
				outMapColors: {
					min: '#e6ebf5',
					mid: '#266eff',
					max: '#003399',
					hover: '#70dbe5',
					scale: 'linear',
					showLabels: false,
					useDataClasses: false,
					dataClasses: [
						{ from: 0, to: 100, color: '#00b721' },
						{ from: 100, to: 200, color: '#f28730' },
						{ from: 200, to: 300, color: '#c40f0f' }
					]
				},
				selection: {
					statistic: null,
					outputType: null,
					parameter: null,
					compareScenarios: [],
					units: 0
				},
				maps: {
					riverSeries: {
						name: 'Streams',
						type: 'mapline',
						mapData: null,
						nullColor: '#7cb5ec'
					},
					scenarioMaps: []
				},
				responseData: {
					scenarioMaps: []
				}
			}
		},
		validations: {
			selection: {
				statistic: { required },
				outputType: { required },
				parameter: { required },
				units: { required }
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/output/mapoptions/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.options = response.data;
					this.selection.units = this.options.outputTypes.defaultUnits;

					const subsResponse = await this.$httpRoot.get(this.options.map.geoJsonUrls.subbasins);
					const streamsResponse = await this.$httpRoot.get(this.options.map.geoJsonUrls.streams);
					this.geojson.subbasins = subsResponse.data;
					this.geojson.streams = streamsResponse.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				this.$v.$touch();

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please complete the form.');
				} else {
					this.log(this.selection);

					try {
						const response = await this.$http.post(`scenarios/output/map/${this.scenarioID}`, this.selection, this.getTokenHeader());
						this.log(response.data);
						this.responseData = response.data;
						this.drawMaps(true);

						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			reverseColors() {
				var max = this.outMapColors.max;
				this.outMapColors.max = this.outMapColors.min;
				this.outMapColors.min = max;
			},
			getColorAxis(map, initialize = false) {
				if (initialize && (map.maxValue - map.minValue) >= 10000 && map.minValue > 0) {
					this.outMapColors.scale = 'logarithmic';
				}

				if (this.outMapColors.useDataClasses && this.outMapColors.dataClasses.length > 0) {
					this.log('use data class');
					return {
						dataClasses: this.outMapColors.dataClasses
					}
				} else {
					this.log('use gradient');
					return {
						type: !map.allowLogAxis ? 'linear' : this.outMapColors.scale,
						allowNegativeLog: true,
						minColor: this.outMapColors.min,
						maxColor: this.outMapColors.max,
						stops: [
							[0, this.outMapColors.min],
							[0.5, this.outMapColors.mid],
							[1, this.outMapColors.max]
						]
					}
				}
				
			},
			updateMaps() {
				let self = this;
				this.$nextTick(() => {
					self.drawMaps();
				});
			},
			drawMaps(initialize = false) {
				this.maps.riverSeries.mapData = this.geojson.streams;

				this.maps.scenarioMaps = [];
				this.$nextTick(() => {
					for (var i = 0; i < this.responseData.scenarioMaps.length; i++) {
						let map = this.responseData.scenarioMaps[i];
						let newMap = {
							title: { text: map.mapTitle },
							legend: {
								title: {
									text: map.legendTitle
								}
							},
							colorAxis: this.getColorAxis(map, initialize),
							xAxis: {
								min: map.xAxisMin,
								max: map.xAxisMax
							},

							yAxis: {
								min: map.yAxisMin,
								max: map.yAxisMax
							},
							mapNavigation: {
								enabled: true
							},
							series: [{
								data: map.mapData,
								mapData: this.geojson.subbasins,
								joinBy: ['name', 'code'],
								name: map.mapDataValuesName,
								states: {
									hover: {
										color: this.outMapColors.hover
									}
								},
								dataLabels: {
									enabled: this.outMapColors.showLabels,
									format: '{point.properties.name}'
								},
								tooltip: {
									pointFormat: '{point.code}: {point.value:.2f} ' + map.units
								}
							}, this.maps.riverSeries]
						};
						this.log(newMap);
						this.maps.scenarioMaps.push(newMap);
						//this.log(this.maps.scenarioMaps);
					}
				});
				
			},
			addColorDataClass() {
				this.outMapColors.dataClasses.push({ from: 0, to: 0, color: '#008cc4' });
			},
			removeColorDataClass(element) {
				this.outMapColors.dataClasses.splice(this.outMapColors.dataClasses.indexOf(element), 1);
			}
		}
	}
</script>

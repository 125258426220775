<template>
	<div>
		<div v-if="$route.name == 'AdminWeatherList'">
			<h2 class="mb-3">Weather</h2>

			<p>
				See the main system datasets section to see what is available for each weather dataset.
				Some weather data may only by valid for specific datasets. This section does not modify weather files on the server, so please be careful making changes.
			</p>

			<grid-view api-url="admin/weather"
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="tiers"
					   item-name="tier"
					   show-create create-route="create"></grid-view>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'AdminWeatherList',
		components: {
			GridView
		},
		data() {
			return {
				table: {
					fields: [
						{ key: 'edit', label: '' },
						{ key: 'name', sortable: true },
						{ key: 'isActive', label: 'Active?', sortable: true, formatter: (value) => { return value ? 'Yes' : 'No' } },
						{ key: 'pathToFiles', sortable: true },
						{ key: 'startDate', label: 'Start', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'endDate', label: 'End', sortable: true, formatter: (value) => { return moment(value).format('M/D/YYYY') } },
						{ key: 'fileFormat', sortable: true },
						{ key: 'cmipVersion', sortable: true },
						{ key: 'sortOrder', sortable: true }
					],
					sort: 'sortOrder',
					reverse: false,
					itemsPerPage: 50
				}
			}
		}
	}
</script>

<template>
	<div>
		<div v-if="$route.name == 'ErrorReportList'">
			<h2 class="mb-3">Project feedback and error reports</h2>

			<p>
				Feedback and error reports submitted for this project are listed below.
				We will update you via email as soon as we are able to look into your error.
				Please do not submit duplicate reports.
			</p>
			<p>
				If you received an error related to the SWAT model run, it could be do to improper input values.
				Please check your input customizations for the scenario.
			</p>

			<grid-view :api-url="`errorreports/${projectID}`"
					   :fields="table.fields"
					   :default-sort="table.sort" :default-reverse="table.reverse"
					   collection-description="error reports"
					   item-name="error report"
					   create-route="create" show-create></grid-view>

			<fixed-action-bar>
				<back-button class="btn btn-secondary mr-2" />
			</fixed-action-bar>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import GridView from '@/components/helpers/GridView';
	import moment from 'moment';

	export default {
		name: 'ErrorReportList',
		components: {
			GridView
		},
		data() {
			return {
				projectID: this.$route.params.id,
				table: {
					fields: [
						{ key: 'edit', label: '', class: 'min' },
						{ key: 'reportStatus', label: 'Status', sortable: true },
						{ key: 'title', sortable: true },
						{ key: 'scenario', sortable: true },
						{ key: 'dateSubmitted', sortable: true, formatter: (value) => { return moment(value).format('llll') } }
					],
					sort: 'dateSubmitted',
					reverse: true,
					itemsPerPage: 50
				}
			}
		}
	}
</script>

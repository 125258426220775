<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Nutrient efficiency</h2>

			<div v-if="$parent.scenario.status.isRunning">
				<page-running />
			</div>
			<div v-else>
				<p>
					The amount of fertilizer applied in auto fertilization is based on the amount of nitrogen removed at harvest.
					If you set nutrient efficiency value to 1.0, the model will apply enough fertilizer to replace the amount of nitrogen removed at harvest.
					If you enter a number greater than 1.0, the model will apply fertilizer to meet harvest removal plus an extra amount to make up for nitrogen losses due to surface runoff/leaching.
					If nutrient efficiency value is less than 1.0, the model will apply fertilizer at the specified fraction below the amount removed at harvest.
				</p>

				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<div v-if="data.items.length < 1">
					<b-alert show variant="info">
						Your project does not contain any <span v-b-popover.hover.bottom="'Land uses utilizing nutrient efficiency: ' + data.autoEffLanduses.join(', ')" class="text-primary" style="text-decoration:underline;cursor:pointer">land use</span>
						that would be affected by the nutrient efficiency variable.
					</b-alert>

					<fixed-action-bar>
						<back-button class="btn btn-secondary mr-2" />
					</fixed-action-bar>
				</div>
				<div v-else>
					<b-form @submit.prevent="save">
						<p>
							<strong>Enter a new nutrient efficiency value (number ranging from 0 to 2). The default value is 0.</strong>
						</p>

						<table class="table table-valign-m">
							<thead class="thead-light">
								<tr>
									<th style="width: 150px">
										<b-form-input id="applyToAll" v-model="applyToAll" type="number" step="any" min="0" max="2" class="max-w-150"></b-form-input>
									</th>
									<th colspan="2">
										<b-button type="button" @click="setAll">Apply value to all</b-button>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, i) in data.items" :key="i">
									<td style="width: 150px">
										<b-form-input :id="item.name"
													  v-model.trim="$v.data.items.$each[i].value.$model"
													  :state="getValidState($v.data.items.$each[i].value)"
													  type="number" step="any" min="0" max="2" class="max-w-150"></b-form-input>
									</td>
									<td class="min">
										<label :for="item.name" class="mb-0">{{item.name}}</label>
									</td>
									<td>
										{{data.descriptions[item.name]}}
									</td>
								</tr>
							</tbody>
						</table>

						<fixed-action-bar>
							<save-button :saving="page.saving" class="ml-2 mr-2" :disabled="!$v.$anyDirty" />
							<back-button class="btn btn-secondary mr-2" />

							<save-button v-if="data.changes.hasChanges" :saving="page.delete.saving" type="button"
										 variant="danger" text="Remove Changes"
										 v-b-tooltip.hover title="Remove your changes and revert to the defaults"
										 @click.native="askDelete" class="ml-auto" />
						</fixed-action-bar>
					</b-form>

					<b-modal v-model="page.delete.show" size="md" title="Confirm delete" no-close-on-backdrop no-close-on-esc hide-header-close>
						<error-list :errors="page.delete.errors"></error-list>

						<p>
							Are you sure you want to delete your nutrient efficiency changes?
							This action is permanent and cannot be undone.
						</p>

						<div slot="modal-footer">
							<save-button type="button" :saving="page.delete.saving" @click.native="confirmDelete" text="Delete" variant="danger" />
							<b-button type="button" variant="secondary" @click="page.delete.show = false" class="ml-1">Cancel</b-button>
						</div>
					</b-modal>
				</div>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required, between } from 'vuelidate/lib/validators';

	export default {
		name: 'EditNutrientEfficiency',
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false,
					delete: {
						show: false,
						errors: [],
						saving: false
					}
				},
				data: {
					items: [],
					descriptions: {},
					changes: {},
					autoEffLanduses: []
				},
				applyToAll: 0
			}
		},
		validations: {
			data: {
				items: {
					required,
					$each: {
						name: { required },
						value: { required, between: between(0, 2) }
					}
				}
			}
		},
		async created() {
			await this.get();
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/general/nutrientefficiency/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;

					this.log(this.$v);
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				this.log(this.data.items);
				this.log(this.$v);

				if (this.$v.$invalid) {
					this.page.saveErrors.push('Please fix the errors below and try again.');
				} else {
					try {
						const response = await this.$http.post(`scenarios/general/nutrientefficiency/${this.scenarioID}`, this.data.items, this.getTokenHeader());
						this.log(response.data);
						await this.get();
						this.$refs.savedAlert.startAlert();
						this.$v.$reset();
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			},
			askDelete() {
				this.page.delete.show = true;
			},
			async confirmDelete() {
				this.page.delete.errors = [];
				this.page.delete.saving = true;

				try {
					const response = await this.$http.delete(`scenarios/deletemod/${this.data.changes.modID}`, this.getTokenHeader());
					this.page.delete.show = false;
					await this.get();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.delete.errors = this.logError(error);
				}

				this.page.delete.saving = false;
			},
			setAll() {
				for (var i = 0; i < this.data.items.length; i++) {
					this.data.items[i].value = this.applyToAll;
				}
				this.$v.$touch();
			}
		}
	}
</script>

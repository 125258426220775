<template>
	<div>
		<page-authorization-container :page="page" show-errors-with-object>
			<h2 class="mb-4">Create a new group</h2>

			<p>
				Groups are a way to share your projects and scenarios with other users.
				After creating a group you can invite users to join by entering their email address.
				Users choose which projects to share with the group by clicking the share button from a project's page.
				All users in the group may view, edit*, and run* projects shared with the group.
				(*Note: tier limits still apply.)
			</p>

			<b-form @submit.prevent="save">
				<error-list :errors="page.saveErrors"></error-list>

				<b-form-group label="Group name" :invalid-feedback="requiredFeedback($v.form.name)">
					<b-form-input v-model.trim="$v.form.name.$model" type="text" autofocus :state="getValidState($v.form.name)"></b-form-input>
				</b-form-group>

				<b-form-group label="Describe the purpose of this group" :invalid-feedback="requiredFeedback($v.form.description)">
					<b-form-textarea v-model.trim="$v.form.description.$model" rows="10" :state="getValidState($v.form.description)"></b-form-textarea>
				</b-form-group>

				<fixed-action-bar :cols="12" :offset="0" :cols-lg="12" :offset-lg="0">
					<save-button :saving="page.saving" class="mr-2" :disabled="!$v.$anyDirty" />
					<back-button class="btn btn-secondary mr-2" />
				</fixed-action-bar>
			</b-form>
		</page-authorization-container>
	</div>
</template>

<script>
	import { required } from 'vuelidate/lib/validators';

	export default {
		name: 'GroupCreate',
		data() {
			return {
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false
				},
				form: {
					name: null,
					description: null
				}
			}
		},
		validations: {
			form: {
				name: { required },
				description: { required }
			}
		},
		methods: {
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;

				if (this.$v.$invalid) {
					this.$v.$touch();
					this.page.saveErrors.push('Please enter both a name and description.');
				} else {
					try {
						const response = await this.$http.post('groups', this.form, this.getTokenHeader());
						this.$router.push({ name: 'GroupView', params: { id: response.data } }).catch(err => {});
					} catch (error) {
						if (this.isApiUnauthorized(error)) this.page.showLogin = true;
						else this.page.saveErrors = this.logError(error);
					}
				}

				this.page.saving = false;
			}
		}
	}
</script>
